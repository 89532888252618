<template>
  <div>
		<div class="modal fade" id='webpage_setting' tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true"  v-if="false" >
			<div class="modal-dialog modal-lg">
				<div class="modal-content"  >
					<component :is='$store.state.modal.component'   ></component>
				</div>
			</div>
		</div>
		
		<a-modal v-model="visible" :maskClosable="false" :footer="null"  width="800px" style="padding:0">
			<component :is='$store.state.modal.component'   ></component>
		</a-modal>
	</div>	
    
</template>

<script>
import Vue from 'vue'
import SettingForm 					from './setting_form'
import SectionForm 					from './section_form'
import SectionAddForm 			from './section_add_form'
import MenuBoardSet 				from './menu_board_set'
import Contactus 		    		from './contactus'
import NewsletterForm   		from './newsletter_form'
import HomepagelistForm 		from './homepagelist_form'
import HomepagesectionForm 	from './homepagesection_form'
import { Modal } from 'ant-design-vue';
Vue.directive('ant-portal', Modal)

export default {
	data(){
		return {
			visible: false,
		}
	},
	
	watch: {
		visible(val){
			this.$store.commit('set_ant_modal_visible', val)
		},
		ant_modal_visible(val){
			this.visible = val
		}
	},
	
	computed: {
		ant_modal_visible: {
			get(){
				this.visible = this.$store.state.ant_modal_visible
				return this.$store.state.ant_modal_visible
			},
			set(val){
				this.$store.commit('set_ant_modal_visible', val)
			}
		}
	},

	components: {
		SettingForm,
		SectionForm,
		SectionAddForm,
		MenuBoardSet,
		Contactus,
		NewsletterForm,
		HomepagelistForm,
		HomepagesectionForm,
		'a-modal': Modal,
	},

}
</script>

<style>
  .ant-modal-body {
		padding : 0px
	}
</style>





