<template>
  <div>
     <!-- Footer Logo -->
      <img class="footer-logo" :src="current_item.file1.thumb" :style="'margin-bottom:10px;'+ imagesize(current_item.config.logo_horizen_size) " v-if="current_item.file1.thumb!=''" />

      <h4 class="letter-spacing-1" :style="'color:'+fontColor+';'+title_style(current_item.subtitle)" v-if="current_item.title!=''">{{ current_item.title }}</h4>
      <h6  v-if="current_item.subtitle!=''" :style="'color:'+fontColor">{{ current_item.subtitle }}</h6>

      <table :style="'margin-bottom:10px;width:100%;'+'color:'+fontColor">
        <tr v-for="i in [1,2,3,4,5]" :key='i'>
          <td style="width:30px;font-size: 16pt">
            <i :class="current_item.config['iconName'+String(i)]"></i>
          </td>
          <td style="font-size:10pt;" v-html="current_item.config['title'+String(i)]" ></td>
        </tr>
      </table>
      <div v-html="htmlDecode(current_item.context)" :style="'text-align:justify;color:'+fontColor" > </div>
  </div>


</template>

<script>
export default {
  props: ['current_item','fontColor'],

  computed: {
    logo_size(){
      if(this.current_item.config.logo_horizen_size!=undefined){
        return this.current_item.config.logo_horizen_size
      }else{
        return 40
      }
    },
  },

  methods: {
    title_style(subtitle){
      if(subtitle!=''){
        return 'margin-bottom: 0px'
      }
    },
    imagesize(size){
      if(size==1){
        return 'width:100%;'
      }else{
        return `height: ${size}px;`
      }
    }

  }

}
</script>

<style>

</style>
