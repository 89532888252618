<template>
  <span>
    <el-select v-model="main_cate_id" size="mini" @change="choice_maincate('main')" placeholder="전체" style="width: 120px">
      <el-option
        v-for="item in main_categories(1)"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
    <el-select v-model="sub_cate_id"  size="mini" @change="choice_maincate('sub')" placeholder="전체" style="width: 120px" v-if="sub_cates.length>1">
      <el-option
        v-for="item in sub_cates"
        :key="'sub_'+item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
    <el-button size="mini" type="primary" @click="set_category()" v-if="mode=='table'" >완료</el-button>

  </span>
</template>

<script>

export default {
  props: ['users','user','mode','popover','search_word'],
  data(){
    return {
      main_cate_id: '',
      sub_cate_id: '',
      sub_cates: [],
    }
  },

  mounted(){
    if(this.user!=undefined){
      this.sub_cates = []
      this.main_cate_id = this.user.group1
      if(this.user.group2!=0 && this.user.group2!=null )this.choice_maincate()
      this.sub_cate_id = this.user.group2
    }
    if(this.mode=='menu'){
      //새로고침시 ssr에서 url에 검색 파라메터가 있을때 검색에 있는 카테고리 초기 셋팅
      if(this.$route.query.main_cate_id){
        this.main_cate_id = Number(this.$route.query.main_cate_id)
        this.mode_table_search_sub_category()
      }
      if(this.$route.query.sub_cate_id)this.sub_cate_id = this.$route.query.sub_cate_id
    }  

  },

  methods: {
    main_categories(deep){
      if(this.users.categories!=undefined){
        let cate = this.users.categories.filter(c=>c.deep==deep)
        let c = []
        for(let i in cate){
          c.push({label: cate[i].cate_name, value: cate[i].id })
        }
        return this.label_sort(c)
      }
    },

    label_sort(cate){
      let csort = cate.sort(function(a,b){ 
        if ( a.label < b.label )return -1;
        if ( a.label > b.label )return 1;
        return 0;
      }) 
      csort.unshift({label: '전체', value: 0 })
      return csort
    },

    choice_maincate(mode){
      if(this.mode == 'menu')this.mode_menu_search_category(mode) //props로 넘어온 값 menu: 검색에 사용되는 카테고리와 table: 유저에 설정되어 있는 카테고리 
      if(mode == 'main')this.mode_table_search_sub_category() //mode는 main과 sub의 구분
    },

    mode_menu_search_category(mode){
      let search = '?get_mode=cate'
      if(mode=='main'){
        search += `&main_cate_id=${this.main_cate_id}`
      }else{
        search += `&main_cate_id=${this.main_cate_id}&sub_cate_id=${this.sub_cate_id}`
      }
      this.$emit('update:search_word', '')
      this.webpage_user_search(search)
    },

    mode_table_search_sub_category(){
      this.sub_cates = []
      this.sub_cate_id = ''
      let cates = this.users.categories.filter(c=>c.deep==2 && c.parent_id==this.main_cate_id)
      let c = []
      for(let i in cates){
        c.push({label: cates[i].cate_name, value: cates[i].id })
      }
      this.sub_cates = this.label_sort(c)
    },

    set_category(){
      const cate = { user_id: this.user.id, main_cate_id: this.main_cate_id , sub_cate_id: this.sub_cate_id}
      this.axios().put(`/${this.$route.params.userid}/${this.$route.params.url}/webpage_user_set_category`, cate)
      .then(result=>{
        this.webpageNotice(result.data);
        if(result.data.result=='success'){
          this.$store.commit('set_webpage_user_category', cate)
          this.$emit("update:popover", false)
        }
      })

    }

  }

}

</script>