<template>
    <!-- wrapper -->
    <div id="wrapper">
      <section>
        <div class="container" style="min-height: 55vh">
          <div class="info-bar info-bar-bordered">
            <div class="container">
              <el-input placeholder="Search" size="mini" v-model="search_word" @keyup.enter.native="search_data()" class="input-with-select" style="width: 250px" clearable  >
                <el-button slot="prepend" icon="el-icon-refresh"  @click="clear()" v-show="!isEmpty(search_word)" ></el-button>
                <el-button slot="append" icon="el-icon-search" @click="search_data()"  ></el-button>
              </el-input> 
              <select-category v-bind:users="users" v-bind:mode="'menu'" v-bind:search_word.sync='search_word'  v-if='is_categories' ></select-category>
              <span style="margin-left: 10px;font-size: 9pt;cursor: pointer" @click="category()" >카테고리설정</span>        
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-md">
              <thead>
                <tr>
                  <th style="text-align:right;padding-right: 10px" >번호</th>
                  <th style="min-width: 100px" v-if='is_categories' >카테고리</th>
                  <th>이름</th>
                  <th>이메일</th>
                  <th>전화번호</th>
                  <th>관리자메모</th>
                  <th>옵션</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(u, index) in users.webpage_users" :key="'sub_'+u.id" >
                  <td style="width: 100px;min-width: 70px;text-align:right;padding-right: 10px"> {{ itemNumber(index) }} </td>
                  <td v-if='is_categories'>
                    <el-popover
                      placement="bottom"
                      min-width="200"
                      trigger="click"
                      v-model="popover[index]"
                      >
                      <select-category v-bind:users="users" v-bind:user="u" v-bind:popover.sync="popover[index]" v-bind:mode="'table'" ></select-category>
                      <el-button size="mini" slot="reference" >{{ get_user_cate(u) }}</el-button>
                    </el-popover>
                  </td>
                  <td>{{ u.name }}</td>
                  <td>{{ u.email }}</td>
                  <td>{{ u.cellphone }}</td>
                  <td style='min-width: 150px;max-width:200px;word-wrap:break-word'>
                    {{showMemo(u.admin_memo, 50)}}
                    <el-popover
                      placement="top"
                      width="400"
                      trigger="hover"
                      v-if="admin_memo_length(u.admin_memo)"
                      >
                      <span v-html="showAllMemo(u.admin_memo)"></span>
                      <span slot="reference" style="font-size:8pt;cursor:pointer;color:#4E8CEB">[전체보기]</span>
                    </el-popover>
                  </td>
                  <td>  
                    <span class='btn btn-sm btn-primary' @click="edit_user(u)"><i class='fa fa-edit'></i> 수정</span>
                    <span class='btn btn-sm btn-success' @click="auth_set(u)"><i class='fa fa-user-plus'></i> 권한설정</span>
                    <span class='btn btn-sm btn-danger'  @click="user_delete(u)"><i class='fa fa-trash'></i>  삭제</span>
                  </td>
                </tr>
               
              </tbody>
            </table>
            <div class="text-center">
              <el-pagination
                :page-size="users.per_page"
                :page-count="10"
                @current-change="current_change"
                layout="prev, pager, next"
                :total="users.total_count">
              </el-pagination>
            </div>  

          </div>
        </div>
      </section>   

      <a-modal :title="modal_title"  v-model="visible" @ok="hideModal" :loading="loading" :maskClosable="false" :footer="null" :width='modal_width' >
        <component :is='modal_component' v-bind:current_user='current_user'  v-bind:modal_visible.sync='visible' v-bind:signup_pagetype="'edit_user'"  :key="'auth_'+ckey" ></component>
      </a-modal>

    </div>
    
</template>


<script>
import { Modal } from 'ant-design-vue';
import SubAdminAuth from '../smarty_pages/modal/setting_form_component/subadmin'
import Category from '../smarty_pages/modal/webpage_user/category'
import SelectCategory from '../smarty_pages/modal/webpage_user/select_category'
import WebpageUser from '../smarty_pages/modal/webpage_user/user_signuppage'

export default {
  data(){
    return {
      search_word: '',
      ModalText: 'Content of the modal',
      visible: false,
      loading: false,
      modal_title: 'title',
      modal_component: 'sub-admin-auth',
      modal_width: 700,
      current_user: {},
      ckey: 1,
      options: [],
      main_cate_id: '',
      sub_cate_id: '',
      sub_cates: [],
      popover: [],
    }
  },

  mounted(){
    for(let u in this.users.webpage_users){
      this.popover.push(false)
    }
    if(this.$route.query.get_mode=='search'){
      this.search_word = this.$route.query.search_word
    }
  },

  computed: {
    users(){
      return this.$store.state.webpage_users
    },
    
    categories() {
      return this.$store.state.webpage_users.categories
    },

    is_categories(){
      if(this.categories!=undefined)return this.categories.length>0
      return false
    }
  },

  methods: {

    edit_user(user){
      this.modal_title = `${user.name}님 정보수정`
      this.visible = true;
      this.modal_component = 'webpage-user'
      this.current_user = user
      this.ckey = `edit_${user.id}`
    },

    itemNumber(index) {
      return this.users.total_count - this.users.per_page *(this.users.current_page - 1) - index ;
    },

    current_change(val){
      const current_page = `?current_page=${val}`
      const search = this.isEmpty(this.search_word) ? '' : `&search=${this.search_word}`
      this.get_users(current_page, search, val);
    },

    get_users(current_page, search, val){
      this.axios().get(`/${this.$route.params.userid}/${this.$route.params.url}/webpage_user/${webpage_id}${current_page}${search}`)
      .then(result=>{
        this.$store.commit('set_webpage_users', result.data.webpage_users)
        this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}/users/${webpage_id}${current_page}${search}`)
      })
    },

    user_delete(user){
      if(confirm("회원을 정말 삭제하시겠습니까?")){
        const search = this.webpage_user_data_search()
        this.axios().delete(`/${this.$route.params.userid}/${this.$route.params.url}/webpage_user/${webpage_id}/${user.id}${search}`)
        .then(result=>{
          this.webpageNotice(result.data)
          this.$store.commit('set_webpage_users', result.data.webpage_users)
        })
      }
    },

    search_data(){
      let search = '?get_mode=search'
      if(!this.isEmpty(this.search_word)){
        search += `&search_word=${this.search_word}`
        this.webpage_user_search(search)
      }else{
        if(this.$route.query.get_mode==undefined){
          this.webpageNotice({type: 'error', msg: '단어를 입력하셔야 합니다.'})
          return false
        }else{
          this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}/users/${webpage_id}`)
          this.get_page_data();
        }
      }
    },

    auth_set(user) {
      this.modal_component = 'sub-admin-auth'
      this.ckey += 1
      this.visible = true;
      this.current_user = this.users.relation.filter(r=>r.webpage_user_id==user.id)[0];
      this.modal_title = `${user.email}의 권한설정`
    },

    category(){
      this.visible = true;
      this.modal_component = 'category'
      this.modal_title = '카테고리 설정'
    },

    hideModal() {
      this.visible = false;
    },

    get_user_cate(user){
      const cate1 = this.categories.filter(c=>c.id==user.group1)
      const cate2 = this.categories.filter(c=>c.id==user.group2)
      const cate_name1 = cate1.length > 0 ? cate1[0].cate_name : ''
      const cate_name2 = cate2.length > 0 ? '/'+cate2[0].cate_name : ''
      return this.isEmpty(cate_name1) ? '없음' : cate_name1 + cate_name2
    },

    admin_memo_length(memo){
      if(memo!=null){
        return memo.length > 50 ? true : false
      }
      return false
    }

 
  },

  components: {
    'a-modal': Modal,
    SubAdminAuth,
    Category,
    SelectCategory,
    WebpageUser
  }
    
  
}
</script>


<style scoped >
 section {
   padding-top: 10px
 }

</style>

