<template>
  <div >
		<ul class="nav nav-tabs" v-if="webpage_type==1 && mainpage_check " >
			<li class="nav-item" ><a :class="tab_active('section-design')" data-toggle="tab" @click="$store.commit('current_component','section-design')" >섹션설정</a></li>
			<li class="nav-item" ><a :class="tab_active('submain-select')" data-toggle="tab" @click="subwebpage_set()" >서브웹페이지섹션설정</a></li>
		</ul>
		<modal-header v-bind:title="current_component=='section-design' ? '섹션추가' : '서브샤플 메인섹션 가져오기' "></modal-header>
    <component :is='current_component' :active.sync='active' ></component>

	
   </div>

</template>


<script>
import ModalHeader from './modal_header'
import SectionDesign from './section_set/_section_design_select'
import SubmainSelect from './section_set/_submain_section_select'
import 'core-js/es7/array'

export default {
  data(){
		return {
			page_info: {
				current_page: 1,
				totalCount: 0,
				search_word: ''
			},
			active: '',
			selected_home: '',
			refresh: 0,
		}
	},

	mounted(){

	},
	
	computed:{
		current_component(){
				return this.$store.state.current_component
		}
	},

	methods:{
	  subwebpage_set(){
			if(this.current_component!='submain-select'){
				this.active = 'list'
				this.$store.commit('current_component','submain-select')
				this.get_select_homepage_list() 
			}
		},

		tab_active(component){
			if(component==this.current_component){
				return 'nav-link active'
			}else{
				return 'nav-link'
			}
		}
	},

	components: {
		ModalHeader,
		SectionDesign,
		SubmainSelect
	}
  
}
</script>
