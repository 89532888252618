<template>
  <div>

    <div v-if="$store.state.modal.active =='delete' ">
      <div v-if="exist_items_check()" >
        현재 섹션 아래에 있는 모든 아이템도 삭제됩니다. 삭제 진행을 하시려면 확인 버튼을 클릭하세요!
      </div>
      <div v-else>
        확인 버튼을 클릭 후 삭제 버튼을 클릭하시면 삭제 됩니다.
      </div>
      <div class="btn btn-danger" @click="delete_confirm()" >확인</div>
      <div>

      </div>
      
      <div v-if="$store.state.modal.del_confirm && exist_items_check()" >
        <div class="divider" style="margin: 10px 0"><!-- divider --></div>
        <label class="input margin-bottom-10">
          "delete" 입력후 삭제 버튼을 클릭 하세요!
          <input type="text" ref='delete_section' placeholder="" v-model="section_name" class="form-control" >
          <b class="tooltip tooltip-bottom-right">아이디는 필수입니다.</b>
        </label>
      </div>
    </div>	
			
  </div>

</template>


<script>

export default {
	data(){
		return {
			active: '',
			del_confirm: false,
			currnet_section: {},
			section_name: ''
		}
  },
  
  watch:{
		del_confirm(val){
			this.$store.state.modal.del_confirm  = val
			return this.$store.state.modal.del_confirm 
    },

    section_name(val){
      this.$store.state.section_name = val
      return val
    }
  },

  computed: {
    current_section(){
			return this.$store.state.modal.section
		},
  },

	methods:{
    exist_items_check(){
      if(this.current_section.component=='contactus01'){
        return true
      }else{
        return this.current_section.item_order.length > 0
      }
    },

		delete_confirm(){
      if(this.current_section.component=='contactus01'){
        this.axios().get(`/webpages/${this.page_type}/contactus_count/${this.current_section.id}` )
        .then(result=>{
          if(result.data.result=="success"){
            if(result.data.count > 0){
              if(confirm(`관리자에 내용이 ${result.data.count}개가 있습니다./n/r삭제 후 내용은 복구 되지 않습니다!/n/r그래도 정말 삭제 하시겠습니까?`))this.del_confirm = !this.del_confirm 
            }else{
              this.del_confirm = !this.del_confirm 
            }
          }
        })
      }else{
        this.del_confirm = !this.del_confirm 
        if(this.current_section.item_order.length > 0)this.$store.state.modal.del_confirm  = this.del_confirm 
      }
     
		}
	},

  
}
</script>