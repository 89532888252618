<template>
	<div v-if="component_show(footer)">
    <div v-if="footer_item_order_size">
			<footer id="footer" :style="cur_section_background+';padding-left:0;padding-right:0'" >
				<div class="container">
					<div class="row">
						<div :class="item_view_class" v-for='f in footer_item_order' :key="f" >
							<div v-if="undefined_check(f)">
								<component :is='current_compo(f)' :current_item='current_item(f)' :fontColor="footer.config.section_set.title_color" ></component>
							</div>
						</div>
					</div>
				</div>
				<div class="copyright">
					<div class="container">
						<ul class="float-right m-0 list-inline mobile-block">
							<li v-html="right_text" :style="'color:'+font_color" >Terms & Conditions • Privacy</li>
						</ul>
						<span v-html="left_text" :style="'color:'+font_color">© All Rights Reserved, Company LTD</span>
					</div>
				</div>
			</footer>
		</div>
    <div v-else >
			<footer01-default  :cur_section_background='cur_section_background' ></footer01-default>
		</div>  

		<slot name="setting"  ></slot>
	</div>

</template>

<script>
import Footer01Default from './default/footer01_default'
import Address1 from './footer_type/address1' 
import General from './footer_type/general' 
import Board from './footer_type/board' 

export default {
	data(){
		return {
			current_section:{}
		}
	},
	components: {
		Footer01Default,
		Address1,
		General,
		Board
	},

	computed: {
		footer:{
			get(){
				return this.$store.state.footer_section
			}
		},

		left_text(){
			if(!this.isEmpty(this.footer.title) ){
				return this.footer.title == 'empty' ? '' : this.footer.title
			}
			return "© All Rights Reserved, Company LTD"
		},

		right_text(){
			if(!this.isEmpty(this.footer.sub_title)){
				return this.footer.sub_title == 'empty' ? '' : this.footer.sub_title
			}
			return "Terms & Conditions • Privacy"
		},

		font_color(){
			return this.footer.config.section_set.title_color;
		},

		cur_section_background(){
			this.current_section = this.footer
			const grnd = this.section_background()
			if(this.footer.config!=undefined){
				if(this.footer.config.section_set.use_background==2){
					return `${grnd};background:no-repeat center center fixed-webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-size: cover;background-attachment: initial` 
				}else if(this.footer.config.section_set.use_background==1){
					return `background: ${this.footer.config.section_set.color}`
				}
			}
			
		},

		footer_class(){
			if(this.footer.item_order.length > 3){
				return "col-md-3"
			}else{
				return "col-md-4"
			}
		},

    footer_item_order_size(){
			if(this.footer.item_order!=undefined){
				return this.footer.item_order.length > 0
			}else{
				return false
			}
		},

		footer_item_order(){
			let item_order = []


			for(const i in this.footer.item_order){
				if(this.$store.state.footer_item[this.footer.item_order[i]]!=undefined) item_order.push(this.footer.item_order[i])
			}
			return item_order
		}

	},

	methods: {
		current_compo(f){
			return this.$store.state.footer_item[f].config.compo
		},

		current_item(f){
			return this.$store.state.footer_item[f];
		},

    undefined_check(f){
			if(this.$store.state.footer_item[f]==undefined)return false
			return true
		}


	}


}
</script>


<style>

</style>

