<template>
<div>
  <div class="heading-title heading-border-bottom heading-color" style="margin-bottom:10px; border-bottom-width: 1px"> 
    <h4 :style="'background-color: transparent;color:'+fontColor.title_color">
      {{ current_item.title }}
    </h4>
    <cite @click="move_board(2)" :style="'cursor:pointer;color:'+fontColor.title_color"  >
      <small class="float-right text-italic" style="margin-top:7px" >전체보기</small>
    </cite>
  </div>
  <table style="width: 100%" >
    <tr v-for=" (b, index) in board_item" :key="index" >
      <td style="width:65px; padding-top:5px; padding-botoom: 5px">
        <img class="thumbnail float-left" :src="get_image(b.config)" width="60" height="60" alt="" style="margin-bottom:0px">
      </td>
      <td >
        <div >
          <a :href="move_board(1,b)" :style="'color:'+fontColor.subtitle_color" v-html="showMemo(b.title,20)" ></a>
        </div>
        <small :style="'color:'+fontColor.subtitle_color">{{ dateFormat(b.created_at,'YY/MM/DD') }} <cite>{{ writers[b.member_id]==undefined ? b.writer : writers[b.member_id]}}</cite></small>
      </td>
    </tr>
  </table>
</div>



</template>

<script>
export default {
  props: ['current_item','fontColor'],

  mounted(){
    // this.axios().get(`/webpages/get_board/${this.current_item.link1}`)
    // .then(result=>{
    //   console.log(result.data);
    // });

  },

  computed: {
    board_item(){
      let item = []
      if(this.$store.state.sumary_boards.board[this.current_item.link1]!=undefined){
        const itm = this.$store.state.sumary_boards.board[this.current_item.link1]
        const itm_cnt = (itm.length > 3) ? 3 : itm.length
        for( let i=0;i<itm_cnt; i++){
          item.push(itm[i])
        }
        return item
      }
      return []
    },
    
    writers(){
      return this.$store.state.sumary_boards.writers
    }
    
  },

  methods: {
    title_style(subtitle){
      if(subtitle!=''){
        return 'margin-bottom: 0px'
      }
    },

    move_page(board){
      return `/${this.$route.params.userid}/${this.$route.params.url}/${this.current_item.link1}/${board.id}`
    },

    get_image(image){
      if(this.object_empty_check(image.file1))return this.no_image
      if(this.isEmpty(image.file1.thumb)){
        return this.no_image
      }else{
        return image.file1.thumb
      }
    }
    
  }

}
</script>

<style scoped>

cite:hover{ color: #3E8FAB}
a:hover{ font-weight: bold }

</style>
