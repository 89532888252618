<template>
  <header :class="item.title=='' && item.subtitle=='' ? 'mb-0' : 'mb-10' ">
    <h3 class="m-0" :style="'cursor:pointer;color:'+item.font_set.title_color">
      <i :class="item.icon" :style="'color:'+item.font_set.icon_color"></i>
      <font v-html="item.title"></font>
    </h3>
    <p
      class="font-lato fs-20 m-0"
      :style="'color:'+item.font_set.subtitle_color"
      v-html="item.subtitle"
    ></p>
  </header>
</template>

<script>
export default {
  props: ["item"]
};
</script>