<template>
  
	<div id="header" :class="menu_class()" :style='header_css'>

		<!-- TOP NAV -->
		<header id="topNav">
			<div class="container" style="padding:0px" >
				<!-- Mobile Menu Button -->
				<button class="sidepanel_btn btn btn-mobile" v-if='false' >
					<i class="fa fa-bars" id="mobile_button" ></i>
				</button>

				<!-- BUTTONS -->
				<ul class="float-right nav nav-pills nav-second-main"  >
					<li class="search" v-if="$route.name!='search_page'" >
						<a href="javascript:;" >
								<i class="menucon fa fa-search" @click="search_click()"></i>
						</a>
						<div class="search-box" style="display: none;">
							<div class="input-group">
								<input type="text" ref="search_input" v-model='search_word' @keypress.enter="search_page()" placeholder="Search" class="form-control">
								<span class="input-group-btn">
									<button class="btn btn-primary btn-sm" style="height: 40px !important" title="검색"  @click="search_page()">검색</button>
								</span>
							</div>
						</div>
					</li>
					<li v-if="show_security_page_logout"  >
						<a href="javascript:;" title="보안페이지 로그아웃" @click="security_page_logout()" :style='login_css' >
							<i class="menucon glyphicon glyphicon-lock" ></i>
						</a>
					</li>
					<li v-if="$store.state.login && $store.state.login_type=='webpage_user'">
						<a href="javascript:;" title="마이페이지" @click="mypage()" :style='login_css'  >
							<i class="menucon glyphicon glyphicon-user" ></i>
						</a>
					</li>
				
					<li v-if="!$store.state.login">
						<a href="javascript:;" title="회원 로그인" @click="login_open()" :style='login_css'  >
							<i class="menucon glyphicon glyphicon-log-in"  ></i>
						</a>
					</li>
					<li v-else >	
						<a href="javascript:;" title="로그아웃" @click="log_out()" :style='login_css' >
							<i class="menucon glyphicon glyphicon-log-out" ref='logout'  ></i>
						</a>
					</li>
					
					<li v-if="admin_head()" >
						<a  href="javascript:void(0)" @click="admin_page()" title="관리자페이지" style="font-weight: bold;" >
							<i class="menucon glyphicon glyphicon-font" ref='admin_head' ></i>
						</a>
					</li>
					<li v-if="mobile_screen"  >
						<a id="sidepanel_btn" href="#" class="menucon fa fa-bars" style="font-size:15pt;margin-right: 30px;margin-left: 10px" ></a>
					</li>

					<!-- /SEARCH -->
				</ul>
				<!-- /BUTTONS -->


				<!-- Logo -->
				<div class="float-left" style="padding-left: 10px" title="로고 - 클릭하면 메인으로 이동" >
					<a class="logo" :href="home_path"  style="margin-right:7px;" >
						<img :src="logo.logofile"  v-if='logo_image' :style="'display:inline-block'" alt="" />
						<i class="fa fa-home" v-if="logo_image && isEmpty(logo.logofile) && $store.state.page_type!='mainpage'" style="display:inline-block;font-size: 25pt"></i>
						<span id="logo_font" v-if="logo_text"  :style="logo_font_css">{{ logo.title }}</span>
					</a>
				</div>
				<!-- 
					Top Nav 
					
					AVAILABLE CLASSES:
					submenu-dark = dark sub menu
				-->
				<div class="navbar-collapse collapse float-right nav-main-collapse submenu-dark" v-if='userMenu.main_order.length > 0' >
					<nav class="nav-main">
						<ul id="topMain" class="nav nav-pills nav-main">
							<li v-for=" m in userMenu.main_order" :key="m"  ><!-- HOME -->
								<a :class="dropdownToggle(userMenu.menus[m].sub_order)" href="javascript:void(0)" @click="gotoMenu(mainmenuLink(userMenu.menus[m]),userMenu.menus[m],'main' )" >
									{{userMenu.menus[m].main.title}}
								</a>
								<ul class="dropdown-menu" v-if="userMenu.menus[m].sub_order.length > 0" >
									<li v-for=" s in userMenu.menus[m].sub_order " :key='s' >
										<a  href="javascript:void(0)" class="sub_menu" @click="gotoMenu(submenuLink(userMenu.menus[m].submenu[s]),userMenu.menus[m].submenu[s],'sub')">{{ userMenu.menus[m].submenu[s].title }}</a>
									</li>
								</ul>	
							</li>
						</ul>
					</nav>
				</div>

				<div class="navbar-collapse collapse float-right nav-main-collapse  submenu-dark" v-if='default_menu_show && userMenu.main_order.length == 0'>
					<nav class="nav-main">
						<ul id="topMain" class="nav nav-pills nav-main" v-if="menu_hidden" >
						
							<li class="active"><!-- HOME -->
								<a class="dropdown-toggle" href="#">
									HOME
								</a>
								<ul class="dropdown-menu">
									<li><a href="#">SUBMENU 1</a></li>
									<li><a href="#">SUBMENU 2</a></li>
									<li><a href="#">SUBMENU 3</a></li>
								</ul>	
							</li>
							<li ><!-- PAGES -->
							<a  href="#">
								PAGES
							</a>
							</li>
						
						</ul>

					</nav>
				</div>

			</div>

      <a-modal :title="modal_title" v-model="visible" @ok="hideModal" :loading="loading" :maskClosable="false" :footer="null" :width='modal_width' >
				<component :is="modal_component" :key="modal_component_key"  ></component>
      </a-modal>

		</header>
	</div>
</template>


<script>
import { Modal } from 'ant-design-vue';
// import { Slide } from 'vue-burger-menu'
import { scroller } from 'vue-scrollto/src/scrollTo'
import LoginForm from '../modal/menu/login_form'
import MyPage from '../modal/menu/mypage'
import 'core-js/es7/array'
import transfer_historyVue from '../../../admin/pages/transfer_history.vue';

export default {
	data() {
    return { 
			fullWidth: document.documentElement.clientWidth,
			scrolled: false,
			header_css:'margin-top: 0px',
			menu: {},
			outerVisible: false,
			menu_header_class: 'navbar-toggleable-md sticky shadow-after-3 clearfix',
			login_css: '',
			userid_focus: false,
			search_word: '',
			modal_title: '',
			modal_component: '',
			modal_component_key: 0,
			modal_width: 700,
			visible: false,
			loading: false,
			default_menu_show: false,
		}
  },
	created(){
		//폰트 사이즈를 수정해야 할 경우 setting_form.vue에도 수정해야 한다.
		this.logo_origin_font_size = this.logo_font_size 
		this.logo_resize_font_size = this.logo_font_size-6
		this.$store.state.screenSize = this.fullWidth
		if(this.fullWidth < 1200){
			this.logo_font_size = this.logo_resize_font_size
		}else{
			this.logo_font_size = this.logo_origin_font_size
		}
	},

	watch: {
		default_menu_show(val){
			if(val)this.menu_default_set()
		}
	},

	mounted(){
		if(design_config.layout_color=='dark') $("#mobile_button").css('color', "#ADADAD")
		$('#header.fixed #topNav a.logo>img').css('padding-top','100px')
		
		if(this.fullWidth < 1200 && this.logo.use_logo == 0){
			$('#header #topNav a.logo>img').css('padding-top','5px')
		}
		document.onreadystatechange = () => { 
			if (document.readyState == "complete") { 
				const self = this
				setTimeout(function(){
					self.default_menu_show = true
					self.menu_default_set()
				}, 500)
			}
		}
	},

	beforeMount() {
		 window.addEventListener('scroll', this.handleScroll);
	},
	
  beforedDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},

	ready: function () {
		window.addEventListener('resize', this.handleResize)
  },
  
	computed:{

		// signup_url(){
		// 	return `/${this.$route.params.userid}/${this.$route.params.url}/webpage_users_sign_up`
		// },

		userMenu:{
			get(){
				return this.$store.state.menu
			}
		},

		menu_hidden(){
			const pagename = ['security_pages_login', 'webpage_signuppage', 'webpage_users']
			return !pagename.includes(this.$route.name)
		},

		show_security_page_logout(){
			return this.$store.state.security_page && !this.$store.state.login && this.$route.name!='security_pages_login'
		},

    logo_origin_font_size:{
			get(){
				return this.$store.state.logo_origin_font_size
			},
			set(val){
				this.$store.state.logo_origin_font_size = val
			}
		},

    logo_resize_font_size:{
			get(){
				return this.$store.state.logo_resize_font_size
			},
			set(val){
				this.$store.state.logo_resize_font_size = val
			}
		},

		logo(){
			return this.$store.state.logo_set
		},

		home_path(){
			return `/${this.$route.params.userid}/${this.$route.params.url}`
		},

		logo_font_size:{
			get(){
				return this.$store.state.logo_font_size
			},
			set(val){
				this.$store.state.logo_font_size = val
			}
		},

		logo_image(){
			var result = false
			if(this.logo.use_logo == 2 || this.logo.use_logo == 0  ){
				result = true
			}
			return result
		},

		logo_text(){
			var result = false
			if(this.logo.use_logo == 2 || this.logo.use_logo == 1  ){
				result = true
			}
			if(this.logo.title=='')this.logo.title='EasyHB'
			return result
		},

		logo_font_css(){
			if(this.fullWidth > 415){
				return `font-size:${this.logo_font_size}pt;font-weight:bold;color:${this.logo.font_color};font-family:${this.logo.font_family};font-weight:bold;letter-spacing:-1px;display:inline-block;word-wrap: break-word;`
			}else{
				return `font-size:1.2em;font-weight:bold;color:${this.logo.font_color};font-family:${this.logo.font_family};font-weight:bold;letter-spacing:-2px;display:inline-block;word-wrap: break-word;`
			}
		},

		login_check:{
			get(){
				return this.$store.state.authCheck
			}
		},

		menu_config(){
			return this.$store.state.menu.config
		},

		transparent_check: {
			get(){
				return this.menu_config.transparent
			}
		}
	},

  methods: {

		search_click(){
			const self = this
			setTimeout(function(){
				self.$refs.search_input.focus();
			},300)
		},

		search_page(){
			if(this.$route.name == 'search_page'){

			}else{
				this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}/search_page?current_page=1&search_word=${this.search_word}`)
			}
		},

		login_open() {
			if(this.$store.state.login==false){
				this.modal_component_key++
				this.visible = true
				this.modal_width = 500
				this.modal_component = 'login-form'
				this.modal_title = '로그인'
			}	
		},


		log_out(){
			if(confirm('정말 로그아웃 하시겠습니까?')){
				 this.axios().delete('/logout', {})
					.then((result) => {
						this.webpageNotice({type: 'success', msg: '성공적으로 로그아웃 되었습니다.'});
						setTimeout(function(){
							// location.reload();
							window.location.href = window.location.href;
						},500)
					})
			}
		},

		async mypage(){
			if(this.$store.state.login){
				if(Object.keys(this.$store.state.current_webpage_user).length==0){
					await this.axios().get(`/${this.$route.params.userid}/${this.$route.params.url}/webpage_user`)
					.then(result=>{
						this.$store.commit('set_current_webpage_user', result.data.webpage_user)
					})
				}
				this.visible = true
				this.modal_width = 700
				this.modal_component = 'my-page'
				this.modal_title = 'MYPAGE'
			}
		},
		
		security_page_logout(){
      if(confirm('보안페이지에서 정말 로그아웃 하시겠습니까?')){
				 this.axios().delete('/security_pages_logout', {})
					.then((result) => {
						if(result.data.result=='success'){
							this.webpageNotice({type: 'success', msg: '성공적으로 로그아웃 되었습니다.'});
							this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}/security_pages_login`)
						}
					})
			}
		},
		
		mainmenuLink(menu){
			if(menu.sub_order.length == 0){
				if(menu.main.userlink == true){
					return `javascript:window.open('${menu.main.link}')`;
				}else{
					if(menu.main.link == 'home_path' ) return home_path;
					return `/${this.$route.params.userid}/${this.$route.params.url}${menu.main.link}`
				}
			}else{
				// return 'javascript:void(0)'
				return '#'
			}
		},

		gotoMenu(link,menu,type){
			
			if(type=='main' && menu.sub_order.length > 0 )return false;
			location.href=link
			const menulink = type == 'main' ? menu.main.link : menu.link
			// if(this.$route.hash!=menulink)this.$router.push(link);
			//메뉴 클릭시 스크롤 애니메이션
			const hash_router = this.$route.hash
			if(!this.isEmpty(hash_router)){
				const firstScrollTo = scroller()
				const options = {
					container: 'body',
					easing: 'ease-in',
					offset: -60,
				}
				firstScrollTo(hash_router,1000,options)
				this.$router.push(this.$route.path)
			}
		},
		
		submenuLink(menu){
			if(menu.userlink == true){
				return `javascript:window.open('${menu.link}')`;
			}else{
				// return menu.link
				if(menu.link == 'home_path' ) return home_path;
				return `/${this.$route.params.userid}/${this.$route.params.url}${menu.link}`
			}
		},
		
		
		dropdownToggle(order){
			if(order.length > 0){
				return 'main_menu dropdown-toggle '
			}else{
				return 'main_menu'
			}
		},

		hideModal() {
      this.visible = false;
    },

		test(){
			this.axios().get('/test',{})
			.then((result)=>{
				// console.log(result);
			})
		},

		menu_default_set(){
			const main_text = document.querySelectorAll('.main_menu')
			main_text.forEach(m=>{
				m.style.color = this.menu_config.main_font_color
			})
			const sub_text = document.querySelectorAll('.sub_menu')
			sub_text.forEach(m=>{
				m.style.color = this.menu_config.sub_font_color
			})
			if(this.menu_config.sub_menu_color!=undefined){
				let submenu_backgorund = document.querySelectorAll('#topNav div.submenu-dark ul.dropdown-menu')
				submenu_backgorund.forEach(s=>{
					s.style.backgroundColor=this.menu_config.sub_menu_color
				})
			}	
			if(this.menu_config.sub_border_color!=undefined){
				let submenu_border = document.querySelectorAll('#topNav div.submenu-dark ul.dropdown-menu')
				submenu_border.forEach(b=>{
					b.style.borderLeftColor=this.menu_config.sub_border_color
					b.style.borderRightColor=this.menu_config.sub_border_color
					b.style.borderBottomColor=this.menu_config.sub_border_color
				})
			}	
			if(this.menu_config.main_menu_color!=undefined){
				if(this.lightOrDark(this.menu_config.main_menu_color) < 185){
					const icon = document.querySelectorAll('.menucon')
					icon.forEach(i=>{
						i.style.color='white'
					})
				}
			}
		},


    // mixins로 이동
		// handleResize (event) {
		// 	this.fullWidth = document.documentElement.clientWidth
		// 	if(this.fullWidth < 992){
		// 		this.logo_font_size = this.$store.state.logo_resize_font_size
		// 		this.$store.state.screenSize = this.fullWidth
		// 		$('#header #topNav a.logo>img').css("max-height", "50px")
		// 	}else{
		// 		this.logo_font_size = this.$store.state.logo_origin_font_size 
		// 		this.$store.state.screenSize = this.fullWidth
		// 	}
		// },
		
		handleScroll: function(e){
			//document.documentElement.scrollTop 익스플로러에서 적용되기 위한 스크롤 이벤트 변수
			this.scrolled = (window.scrollY || document.documentElement.scrollTop)  > 0;
			//로고만 표시할때 위에 붙는 문제로 인해 이 코드가 필요
			if(this.scrolled && this.logo.use_logo == 0){
				if(this.fullWidth > 992){
					$('#header.fixed #topNav a.logo>img').css('padding-top','0px')
				}else{
					$('#header.fixed #topNav a.logo>img').css('padding-top','5px')
				}
			}
			// this.header_css = this.scrolled ? 'margin-top: 0px' : (this.$store.state.preview==true ? 'margin-top: 40px' : 'margin-top: 0px' )
			if(this.scrolled && this.logo.use_logo == 2){
				if(this.fullWidth > 992){
					$('#header.fixed #topNav a.logo>img').css('padding-top','0px')
				}else{
					$('#header.fixed #topNav a.logo>img').css('padding-top','5px')
				}
				this.logo_font_size = this.logo_resize_font_size
			}else{
				if(this.fullWidth > 992)this.logo_font_size = this.logo_origin_font_size 
			}
			this.$store.state.scrolled = this.scrolled
		},

		menu_class(){
			const menu_background = + this.menu_config.main_menu_color == undefined ? '' : `background-color:${this.menu_config.main_menu_color};`
			if(this.menu_config.transparent){
				const body = document.querySelector('body')
				// body.style('padding-top','0px')
				// body.style('margin-top','0px')
				
				if(this.$route.name == "mainpage" ){
					if(this.$store.state.authCheck){
								// this.header_css = this.scrolled ? 'margin-top: 0px' : (this.$store.state.preview ? (this.mobile_screen ?'margin-top: 0px' : 'margin-top: 40px' ) : 'margin-top: 0px' )
						this.header_css = this.scrolled ? 'margin-top: 0px;'+ menu_background : this.mobile_screen ? 'margin-top: 0px;'+ menu_background : 'margin-top: 40px;'
					}else{
						this.header_css = this.scrolled ? 'margin-top: 0px;'+ menu_background : this.mobile_screen ? 'margin-top: 0px;'+ menu_background : 'margin-top: 0px;'
					}
					const icon = document.querySelectorAll('.menucon')
					icon.forEach(i=>{	i.style.color='white'})
					// if(this.mobile_screen)this.login_css = 'padding-top: 0px'
					return this.mobile_screen ? 'navbar-toggleable-md sticky shadow-after-3 clearfix' : 'navbar-toggleable-md sticky shadow-after-3 transparent clearfix' 
				}else{
					this.header_css = 'margin-top: 0px;'+ menu_background 
					return 'navbar-toggleable-md sticky shadow-after-3 clearfix'
				}
				// if(this.scrolled){
				// 	this.header_css = 'margin-top: 0px;'+ menu_background 
				// 	return 'navbar-toggleable-md sticky shadow-after-3 clearfix' 
				// }else{
				// 	// body.style.setProperty('padding-top','0px')
				// 	// body.style.setProperty('margin-top','0px')
				// 	this.header_css = this.mobile_screen ? 'margin-top:0px;' : 'margin-top: 40px;'
				// 	return 'navbar-toggleable-md sticky shadow-after-3 transparent clearfix' 
				// }
			}else{
				this.header_css = 'margin-top: 0px;'+ menu_background
				return 'navbar-toggleable-md sticky shadow-after-3 clearfix'
			}
		}
	},

	components: {
		'a-modal': Modal,
		LoginForm,
		MyPage
	}
}
</script>
<style>
 .bm-burger-button {
		position: relative;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 150px;
		cursor: pointer;
	}

</style>


<style scoped>
#topMain li.search .search-box {
	margin-top: 0px
}

#topNav a.logo>img{
		max-height: 70px !important;
		line-height: 70px !important;
}

#header.fixed #topNav a.logo {
    height: 60px;
    line-height: 60px;
}

#header.fixed #topNav a.logo>img {
		height: 50px;
}

#header.fixed a.logo>#logo_font {
		height: 60px;
}

#header.fixed #topNav #topMain>li>a {
    height: 60px;
		line-height: 60px;
		margin-bottom: 10px;
}

.dropdown-menu {
    background-color: #333;
}

#topMain>li>a {
	line-height: 96px;
}


@media only screen and (max-width: 992px){
	#header #topNav a.logo>img {
			max-height: 55px !important;
			padding-bottom: 5px;
			padding-top: 0px;
	}
	
	#header a.logo#logo_font {
		max-width: 250px;
		padding-top:10px;
	}

	#topNav nav.nav-main li.resp-active>ul.dropdown-menu {
		width: 100%;
	}

	#header ul.nav-second-main{
		margin: 15px 0 0 0;
	}

	#header.fixed ul.nav-second-main li {
		margin-left: 3px;
	}
}

#header.transparent #topMain.nav-pills>li.active>a, #header.transparent #topMain.nav-pills>li>a:hover {
	background-color: rgba(0,0,0,0.1);
}

#header.fixed #topNav #topMain>li>a {
	margin-bottom: 0px
}


</style>
