<template>
	<div>
    <section :style="cur_section_background" >
      <smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
		  <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+');height: 100%'"></div>
      <div class="container" style="z-index: 100"  v-if="item_order.length > 0" >
        <div :class="masory_class" data-img-big="2" data-plugin-options='{"delegate": "a", "gallery": {"enabled": true}}'>
          <a class="image-hover" v-for="(i, idx) in item_order" :key='idx'  :href="ctem[i].file1.large" style="overflow: hidden;position: relative;" >
            <div class="overlay" :style="overlay_css(ctem[i])" v-if="!isEmpty(ctem[i].title) || !isEmpty(ctem[i].subtitle)" >
              <h3 :style="'color:'+ctem[i].font_set.title_color" v-if="!isEmpty(ctem[i].title)" >{{ ctem[i].title }}</h3>
              <div :style="'font-size:8pt;color:'+ctem[i].font_set.subtitle_color" v-if="!isEmpty(ctem[i].subtitle)">{{ ctem[i].subtitle }}</div>
            </div>
            <img :src="ctem[i].file1.thumb" >
          </a>
        </div>  
      </div>
      
      <div class="container" style="z-index: 100" v-else >
        <img src="/images/gallery01.png">
      </div>  
		</section>
		<div>
			<slot name="setting"  ></slot>
		</div>
	</div>
    
</template>


<script>

export default {
	props: ['index','current_section'],
	data(){
		return {
			image1:'',
			image2:'',
		}
	},
	
	watch: {
		item_order(val){
			return val
		}
	},
  
  computed:{

		cur_section_background(){
      const grnd = this.section_background()
      const css = (!this.isEmpty(this.current_section.title) || !this.isEmpty(this.current_section.sub_title))? ';padding-top:70px;padding-bottom:40px;' :';padding-bottom:40px;'
      return `${grnd}${css}` 
    },
    
    use_background(){
      return this.current_section.config.section_set.use_background
    },
		
		item_order(){
			const section = this.$store.state.sections.filter(s=>s.id==this.current_section.id)[0]
			return section.item_order
    },

    masory_class(){
      let column_count = 3
      if(this.current_section.config.section_set.column_count != undefined)column_count = this.current_section.config.section_set.column_count
      return `masonry-gallery columns-${column_count} clearfix lightbox`
    }

		
  },
		
	mounted(){
    this.item_div();
    _lightbox();
    _masonryGallery();
	},

	watch: {
    item_order(val){
			//이 코드 없으면 데이트 업데이트시 적용이 안됨
			this.item_div();
			return val
		}
  },
  
	methods: {	
    overlay_css(item){
      let css = "text-align:center;"
      if(!this.isEmpty(item.title) && !this.isEmpty(item.subtitle) ){
        css += "height: 50px"
      }else{
        css += "height: 35px"
      }
      return css
    },
   
    
	},
	
	components: {
	}
}
</script>


<style scoped>


.overlay {
  display: block;
  position: absolute;
  height: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  color: #fff;
  background-color: rgba(0,0,0,0.7);
  color: #fff;
  z-index: 100;
}


h3 {
  margin: 0;
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}



</style>

