<template>
 <div>

    <div class="owl-carousel buttons-autohide controlls-over" v-if="images.length>1" data-plugin-options='{ "items": 1, "autoPlay":true, "rewindSpeed":5000,  "autoHeight": false, "navigation": false, "pagination": true, "transitionStyle":"fadeUp", "progressBar":"false" }' style="margin-bottom:10px" >
        <img class="img-fluid" v-for="(img,index) in images"  :key="index" :src="img" alt="">
    </div>
    <div v-else-if="images.length==1" style="margin-bottom:10px" >
        <img class="img-fluid"  :src="images[0]" alt="">
    </div>
 </div>

</template>

<script>
export default {
  props: ['item'],
  data(){
      return {

      }
  },

  mounted(){
     this.owl_carousel();
  },

  computed:{
    images(){
      let image = []
      if(!this.isEmpty(this.item.file1.origin))image.push(this.item.file1.origin)
      if(!this.isEmpty(this.item.file2.origin))image.push(this.item.file2.origin)
      return image
    },
   
    
  },

  methods: {

  owl_carousel() {

// OWL CAROUSEL 1
    var _container = jQuery("div.owl-carousel");

    if (_container.length > 0) {

        loadScript(plugin_path + 'owl-carousel/owl.carousel.min.js', function() {

            _container.each(function() {

                var slider = jQuery(this);
                var options = slider.attr('data-plugin-options');

                var $opt = JSON.parse(options)  // convert text to json
                
                if ($opt.progressBar == 'true') {
                    var afterInit = progressBar;
                } else {
                    var afterInit = false;
                }

                var defaults = {
                    items: 5,
                    itemsCustom: false,
                    itemsDesktop: [1199, 4],
                    itemsDesktopSmall: [980, 3],
                    itemsTablet: [768, 2],
                    itemsTabletSmall: false,
                    itemsMobile: [479, 1],
                    singleItem: true,
                    itemsScaleUp: false,

                    slideSpeed: 300,
                    paginationSpeed: 1000,
                    rewindSpeed: 4500,

                    autoPlay: false,
                    stopOnHover: false,

                    navigation: false,
                    navigationText: [
                        '<i class="fa fa-angle-left"></i>',
                        '<i class="fa fa-angle-right"></i>'
                    ],
                    rewindNav: true,
                    scrollPerPage: false,

                    pagination: true,
                    paginationNumbers: false,

                    responsive: true,
                    responsiveRefreshRate: 200,
                    responsiveBaseWidth: window,

                    baseClass: "owl-carousel",
                    theme: "owl-theme",

                    lazyLoad: false,
                    lazyFollow: true,
                    lazyEffect: "fade",

                    autoHeight: false,

                    jsonPath: false,
                    jsonSuccess: false,

                    dragBeforeAnimFinish: true,
                    mouseDrag: true,
                    touchDrag: true,

                    transitionStyle: false,

                    addClassActive: false,

                    beforeUpdate: false,
                    afterUpdate: false,
                    beforeInit: false,
                    afterInit: afterInit,
                    beforeMove: false,
                    afterMove: (afterInit == false) ? false : moved,
                    afterAction: false,
                    startDragging: false,
                    afterLazyLoad: false
                }

                var config = jQuery.extend({}, defaults, options, slider.data("plugin-options"));
                slider.owlCarousel(config).addClass("owl-carousel-init");


                // Progress Bar
                var elem = jQuery(this);

                //Init progressBar where elem is $("#owl-demo")
                function progressBar(elem) {
                    $elem = elem;
                    //build progress bar elements
                    buildProgressBar();
                    //start counting
                    start();
                }

                //create div#progressBar and div#bar then prepend to $("#owl-demo")
                function buildProgressBar() {
                    $progressBar = jQuery("<div>", {
                        id: "progressBar"
                    });
                    $bar = jQuery("<div>", {
                        id: "bar"
                    });
                    $progressBar.append($bar).prependTo($elem);
                }

                function start() {
                    //reset timer
                    percentTime = 0;
                    isPause = false;
                    //run interval every 0.01 second
                    tick = setInterval(interval, 10);
                };


                var time = 7; // time in seconds
                function interval() {
                    if (isPause === false) {
                        percentTime += 1 / time;
                        $bar.css({
                            width: percentTime + "%"
                        });
                        //if percentTime is equal or greater than 100
                        if (percentTime >= 100) {
                            //slide to next item 
                            $elem.trigger('owl.next')
                        }
                    }
                }

                //pause while dragging 
                function pauseOnDragging() {
                    isPause = true;
                }

                //moved callback
                function moved() {
                    //clear interval
                    clearTimeout(tick);
                    //start again
                    start();
                }

            });

        });

    }

    }

  }
  

}
</script>