<template>
	<div>

			<div class="slider fullwidthbanner-container roundedcorners">
				<!--
					Navigation Styles:
					
						data-navigationStyle="" theme default navigation
						
						data-navigationStyle="preview1"
						data-navigationStyle="preview2"
						data-navigationStyle="preview3"
						data-navigationStyle="preview4"
						
					Bottom Shadows
						data-shadow="1"
						data-shadow="2"
						data-shadow="3"
						
					Slider Height (do not use on fullscreen mode)
						data-height="300"
						data-height="350"
						data-height="400"
						data-height="450"
						data-height="500"
						data-height="550"
						data-height="600"
						data-height="650"
						data-height="700"
						data-height="750"
						data-height="800"
				-->
				<div class="fullwidthbanner" data-height="600" data-shadow="0" data-navigationStyle="preview2">
					<ul class="hide" v-if='current_section.item_order.length > 0' >
						<component :is="slide_component(item)" v-for="(item, indx) in current_section.item_order" :key='indx' v-bind:item='current_item(item)' ></component>
					</ul>
           
					<ul class="hide" v-else>
						<slide01-default ></slide01-default>
					</ul>
           
					<div class="tp-bannertimer"><!-- progress bar --></div>
				</div>
			</div>
			<slot name="setting"></slot>
	</div>
</template>


<script>
import Slide01Default from './default/slide01_default'
import Slide01_01 from './slide_types/slide01_01'
import Slide01_02 from './slide_types/slide01_02'
import Slide01_03 from './slide_types/slide01_03'
import Slide01_04 from './slide_types/slide01_04'


export default {
	props: ['current_section', 'index'],

	mounted(){
	 this.fullWidthSlide();
	},

	components: {
		Slide01Default,
		Slide01_01,
		Slide01_02,
		Slide01_03,
		Slide01_04,
	},

	computed: {
		
	},

	methods: {
		
		current_item(item_id){
			let c_item = { item: this.$store.state.items[item_id] };
			c_item['background_image'] = this.item_background_image(c_item.item)
			if(c_item.item.config.slide_set ==undefined){
				 c_item['slide_set'] = { };
				 c_item['slide_set']['background_color'] = "#000000";
				 c_item['slide_set']['slide_type'] = 1;

			}else{
				c_item['slide_set'] = c_item.item.config.slide_set
			}	
			return c_item
		},

		slide_component(item_id){
			const slide = this.current_section.component
			if(this.$store.state.items[item_id]!=undefined){
				let type = this.$store.state.items[item_id].config.slide_set.slide_type;
				if(type == undefined) type = 1
				return `${slide}_0${type}`
			}
			return false
		},


		item_background_image(item){
			const slide_set = item.config.slide_set
			const use = slide_set.background_use
			if(slide_set.background_default_image == undefined)slide_set.background_default_image = "b08.jpg"
			if(slide_set.slide_type == undefined)slide_set.slide_type = 1
			if(slide_set.background_color == undefined)slide_set.background_color = '#000'
			const default_image = `https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/background_images/${slide_set.background_default_image}`
			if(use!=1){
				if(use==2){
					return default_image
				}else{
					if(item.file1.origin=='' || item.file1.origin==undefined ){
						return default_image
					}else{
						return item.file1.origin
					}
				}
			}else{
				return default_image
			}

		},

		fullWidthSlide(){
			var revapi;
    // Make Content Visible
    jQuery(".fullwidthbanner ul , .fullscreenbanner ul").removeClass('hide');
    /**
    	@HALFSCREEN SLIDER
    **/
    if (jQuery(".fullwidthbanner").length > 0) {

        // Default Thumbs [small]
        var thumbWidth = 100,
            thumbHeight = 50,
            hideThumbs = 200,
            navigationType = "bullet",
            navigationArrows = "solo",
            navigationVOffset = 10;

        // Shadow
        var _shadow = jQuery(".fullwidthbanner").attr('data-shadow') || 0;

        // Small Thumbnails
        if (jQuery(".fullwidthbanner").hasClass('thumb-small')) {
            var navigationType = "thumb";
        }

        // Large Thumbnails
        if (jQuery(".fullwidthbanner").hasClass('thumb-large')) {
            var navigationType = "thumb";
            thumbWidth = 195,
                thumbHeight = 95,
                hideThumbs = 0,
                navigationArrows = "solo",
                navigationVOffset = -94;

            // Hide thumbs on mobile - Avoid gaps
            /**
            if(jQuery(window).width() < 800) {
            	setTimeout(function() {
            		var navigationVOffset = 10;
            		jQuery("div.tp-thumbs").addClass('hidden');
            	}, 100);
            }
            **/
        }

        // Init Revolution Slider
        revapi = jQuery('.fullwidthbanner').revolution({
            dottedOverlay: "none",
            delay: 9000,
            startwidth: 1170,
            startheight: jQuery(".fullwidthbanner").attr('data-height') || 500,
            hideThumbs: hideThumbs,

            thumbWidth: thumbWidth,
            thumbHeight: thumbHeight,
            thumbAmount: parseInt(jQuery(".fullwidthbanner ul li").length) || 2,

            navigationType: navigationType,
            navigationArrows: navigationArrows,
            navigationStyle: jQuery('.fullwidthbanner').attr('data-navigationStyle') || "round", // round,square,navbar,round-old,square-old,navbar-old (see docu - choose between 50+ different item)

            touchenabled: "on",
            onHoverStop: "on",

            navigationHAlign: "center",
            navigationVAlign: "bottom",
            navigationHOffset: 0,
            navigationVOffset: navigationVOffset,

            soloArrowLeftHalign: "left",
            soloArrowLeftValign: "center",
            soloArrowLeftHOffset: 20,
            soloArrowLeftVOffset: 0,

            soloArrowRightHalign: "right",
            soloArrowRightValign: "center",
            soloArrowRightHOffset: 20,
            soloArrowRightVOffset: 0,

            parallax: "mouse",
            parallaxBgFreeze: "on",
            parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

            shadow: parseInt(_shadow),
            fullWidth: "on",
            fullScreen: "off",

            stopLoop: "off",
            stopAfterLoops: -1,
            stopAtSlide: -1,

            spinner: "spinner0",
            shuffle: "off",

            autoHeight: "off",
            forceFullWidth: "off",

            hideThumbsOnMobile: "off",
            hideBulletsOnMobile: "on",
            hideArrowsOnMobile: "on",
            hideThumbsUnderResolution: 0,

            hideSliderAtLimit: 0,
            hideCaptionAtLimit: 768,
            hideAllCaptionAtLilmit: 0,
            startWithSlide: 0,
            fullScreenOffsetContainer: ""
        });

        // Used by styleswitcher onle - delete this on production!
        jQuery("#is_wide, #is_boxed").bind("click", function() { revapi.revredraw(); });

			}
		} 

	}
  
}
</script>

<style>
 .slider {
	 margin-bottom: 0px;
 }

 .tp-bullets {
	 margin-bottom:30px;
 }
</style>

