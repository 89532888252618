<template>
  <div >
    <div v-if="mainpage_check">
      <div>
        <label class="radio">
          <input type="radio" name="use_secreat_login" value="0" v-model="config.use_secreat_login">
          <i></i> 공개웹페이지
        </label>

        <label class="radio">
          <input type="radio" name="use_secreat_login" value="1" v-model="config.use_secreat_login">
          <i></i> 보안웹페이지
        </label>
        
        <div class="row"  >
          <div class="col-md-6 col-sm-6">
            <label v-if='security_page_status==0 && config.use_secreat_login==1 '>비밀번호</label>
            <label  @click="password_change=!password_change" v-if="security_page_status==1 && config.use_secreat_login==1" style='color:#EB1B51;cursor:pointer;margin-bottom: 10px' >비밀번호변경</label>
            <div class="fancy-form" v-if='show_password_input()'>
              <input :type="pw_type" class="form-control" v-model='config.password' >
              <i :class="eyeicon" @click="password_show" ></i>
            </div>
            <div v-if="password_error!=''" style='color:red' >{{password_error}}</div>
          </div>
        </div>
      </div>
      
      <div v-if='webpage_type==1 || webpage_type==2' >
        <label class="radio">
          <input type="radio" name="use_signup" value="0" v-model="config.use_signup">
          <i></i> 회원가입 사용안함
        </label>

        <label class="radio">
          <input type="radio" name="use_signup" value="1" v-model="config.use_signup">
          <i></i> 회원가입 사용
        </label>
        <div class='alert alert-info' >
          <span @click="user_list()" style='cursor:pointer'  >회원리스트페이지</span>
        </div>
      </div>
      
      <div>
         <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="회원약관 내용" name="terms">
            <textarea class="form-control" v-model="config.context1" rows="5" ></textarea>

          </el-tab-pane>
          <el-tab-pane label="개인정보 수집 및 이용에 대한 안내 내용" name="agreement">
            <textarea class="form-control" v-model="config.context2" rows="5"></textarea>
          </el-tab-pane>
          
        </el-tabs>

      </div>

      <div class="modal-footer" >
        <button type="button" class="btn btn-default" @click="ant_modal_close()">닫기</button>
        <button type="button" class="btn btn-primary" @click="update()" >수정</button>
      </div>


    </div> 

    <div v-else>
      <div class="alert alert-danger">
        부관리자 설정은 메인 페이지에서만 가능합니다.
      </div>
    </div>

  </div>
</template>

<script>
import 'core-js/es7/array'
import { Loading } from 'element-ui';

export default {

  data(){
    return {
      compo: 'index',
      config: {
        type: 'general_config',
        use_secreat_login: 0,
        use_signup: 0,
        password: '',
        context1: '',
        context2: '',
      },
     
      password_change: false,
      password_error: '',
      password_text: '',
      pw_type: 'password',
      eyeicon: 'fa fa-eye-slash',
      security_page_status: 0,
      activeName: 'terms',
      
    }
  },

  mounted(){
    this.get_config()
  },

  computed:{
    

  },
  
  methods: {

    handleClick(tab, event) {
        // console.log(tab, event);
    },

    password_show(){
      if(this.pw_type=='password'){
        this.pw_type = 'text'
        this.eyeicon = 'fa fa-eye'
      }else{
        this.pw_type = 'password'
        this.eyeicon = 'fa fa-eye-slash'
      }
    },

    show_password_input(){
      let result = false
      if(this.config.use_secreat_login==1 && this.security_page_status==0){
        result = true
      }else if(this.config.use_secreat_login==1 && this.security_page_status==1){
        if(this.password_change)result = true
      }else{
        this.password_error = ''
      }
      return result
    },

    user_list(){
      location.href = `/${this.$route.params.userid}/${this.$route.params.url}/users/${webpage_id}`
    },
    
    update(){
      if(confirm('정말 설정을 변경하시겠습니까?')){
        if(this.password_check()){
          this.axios().put(`/webpages/webpage_config_update`, this.config)
          .then(result=>{
            this.webpageNotice(result.data)
            if(result.data.result=='success'){
              this.config.password =''
              this.password_error = ''
              this.password_change = false
              this.security_page_status = 1
              // setTimeout(function() {
              //   $('#webpage_setting').modal('hide');
              // }, 500)
              this.$store.commit('set_ant_modal_visible', false)
            }
            if(result.data.result=='error'){
              this.password_error = result.data.errors[0]
            }
          })
        }
      }
    },

    get_config(){
      this.axios().get(`/webpages/get_webpage_config`)
      .then(result=>{
        if(result.data.result=='success'){
          this.security_page_status = result.data.security_page
          this.config.use_secreat_login = result.data.config.use_secreat_login
          this.config.use_signup = result.data.config.use_signup
          this.config.context1 = result.data.terms.filter(c=>c.term_type==1)[0].context
          this.config.context2 = result.data.terms.filter(c=>c.term_type==2)[0].context
        }
      })
    },

    password_check(){
      let result = false
      if(this.config.use_secreat_login == 1){
        if(this.security_page_status == 0){
          if(this.config.password.length > 5){
            result = true
          }else{
            this.password_error = '비밀번호는 6자이상이어야 합니다.'
          }  
        }else{
          if(this.config.password.length == 0)result=true
          if(this.config.password.length > 0){
            if(this.config.password.length > 5){
              result = true
            }else{
              this.password_error = '비밀번호를 변경하시려면 6자이상이어야 합니다.'
            }  
          }
        }
      }else{
        result = true
      }
      return result
    }
  },

}
</script>

<style scoped>

.input-group>.input-group-addon {
    line-height: 28px !important;
    padding-top: 10px;
    padding-bottom: 0;
}

</style>
