<template>
  <div  >
    <div class="row">
      <div class="container" style="z-index: 100"  v-if="current_section.item_order.length > 0" >
        <div class="tracking-list">
          <div class="tracking-item"  v-for="(i, idx) in current_section.item_order" :key='idx' >
            <div :class="'tracking-icon status-'+userDateConfig(ctem[i]).date_color" :style="'color: '+badge_icon_color(ctem[i])">
              <template v-if="userDateConfig(ctem[i]).use_number!=undefined && userDateConfig(ctem[i]).use_number">
                {{ idx + timeline_start_number }}
              </template>
              <template v-else>
                <i :class="ctem[i].icon"></i>
              </template>
            </div>
            <div class="tracking-date" v-if="userDateConfig(ctem[i]).use_date" :style="'color:' + font_color" >
              <div >{{ dateFormat(ctem[i].user_date, timeline_date_format(userDateConfig(ctem[i]).use_time) ) }}</div>
              <div v-if="userDateConfig(ctem[i]).use_time == 1">{{ dateFormat(ctem[i].user_date, 'LT' ) }}</div>
            </div>
          
            <div class="tracking-content">
              <div class="timeline-heading">
                <h4 class="timeline-title" :style="'cursor:pointer;color:'+ctem[i].font_set.title_color+(ctem[i].subtitle =='' ? ';margin-bottom:28px' : ';margin-bottom:0px') " @click="link_button(ctem[i].link1)" v-if="!isEmpty(ctem[i].title)" >
                  {{ctem[i].title}}
                </h4>
                <h2 class="fw-400 letter-spacing-1 fs-13" style="margin-bottom: 5px;" v-if="!isEmpty(ctem[i].subtitle)" ><span :style="subtitle_color(ctem[i])">{{ctem[i].subtitle}}</span></h2>
              </div>
              <div class="row">
                <div class="col col-md-6">

                <template v-if="thumb_use(ctem[i]) && media_source_test(ctem[i].media)">
                  <div  class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-html="ctem[i].media"></div>
                </template>
                <template v-else>
                  <a href="javascript:void(0);" @click="use_fulltext(ctem[i]) ? modal_open(ctem[i]) : '' " v-if="thumb_use(ctem[i]) && ctem[i].file1.large!=''"  >
                    <img class="card-img-top" :src="cur_file1_image(ctem[i])" :style="image_height" alt="">
                  </a>
                </template>
                </div>
              </div>

              <div class="timeline-body">
                <p :style="'margin-top:0px;color:'+ctem[i].font_set.context_color" v-html="use_fulltext(ctem[i]) ? htmlDecode(showMemo(ctem[i].context, 150)) : htmlDecode(ctem[i].context) " ></p>
                <button :class="'btn btn-sm btn-'+ctem[i].config.link1.color" @click='link_button(ctem[i].link1)' style='margin-right:10px;margin-top:10px' v-if="ctem[i].config.link1.name!=''" >{{ ctem[i].config.link1.name }}</button>
                  <button :class="'btn btn-sm btn-'+ctem[i].config.link2.color" @click='link_button(ctem[i].link2)' style='margin-top:10px' v-if="ctem[i].config.link2.name!=''"  >{{ ctem[i].config.link2.name }}</button>
                <p v-if="use_fulltext(ctem[i])" style="cursor:pointer;margin-top:20px"  @click="use_fulltext(ctem[i]) ? modal_open(ctem[i]) : '' ">
                  <small :style="'color:'+font_color">
                    <i class="glyphicon glyphicon-folder-open" style="margin-right:5px"></i> 더보기
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" v-else >
        <default></default>
      </div>        

    </div>
          
    <el-dialog :visible.sync="outerVisible" :fullscreen="element_modal_fullscreen" :modal="false" style="z-index:1000" v-if="currentItem!=null" >
      <modal-view :currentItem='currentItem' :image1='image1' :image2='image2' :context_popup='context_popup' ></modal-view>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">닫기</el-button>
      </div>
    </el-dialog> 
  </div>

</template>

<script>
import Default from './timeline_type02_default'
import ModalView from '../../modal/explain_view_modal'

export default {
  props: ['current_section'],

  data(){
    return {
      outerVisible: false,
			currentItem: null,
			image1:'',
			image2:'',
			context_popup: null,
    }
  },

  components: {
    Default,
    ModalView
  },

  computed:{
    font_color(){
      if(this.current_section.config.section_set.use_background == 0){
        return '#2f2f2f'
      }else{
        return this.lightOrDark(this.current_section.config.section_set.color) > 185 ? '#2f2f2f' : '#fff' 
      }
    },

    timeline_start_number(){
      const start = this.current_section.config.section_set.timeLine_start_number
      return start == undefined ? 1 : start        
    }
  },


  methods: {
    modal_open(item){
			this.outerVisible=true;
			this.currentItem=item;
			this.image1=item.file1.origin
			this.image2=item.file2.origin
			this.context_popup=!item.font_set.context_popup
    },
    
    userDateConfig(t){
      return t.config.user_date == undefined ? {use_date: false, date_color: 'default' } : t.config.user_date
    },

    badge_icon_color(ctem){
      return this.userDateConfig(ctem).date_color=='default' ? ctem.font_set.icon_color+' !important' : '#fff'
    },

    timeline_date_format(type){
      switch(type){
          case 0: return 'll' 
          case 1: return 'll' 
          case 2: return 'YYYY년 MM월' 
          case 3: return 'YYYY년' 
      }
    }

  }


}


</script>

<style scoped>
@import '../../../../styles/timeline.css'





</style>