<template>
  <div>
    <modal-header v-bind:title="main_title"></modal-header>
    <div class="card card-default" v-if="mode=='new' || mode=='edit'  " style="margin-top:20px">
      <div class="card-block">
        <div class="row">
          <div class="col-12 alert alert-warning" v-if="mode=='new'" v-html="subpage_msg"></div>
          <div class="col-sm-12" style="margin-bottom:30px" v-if="mode=='new'  || mode=='edit' " >
            <label >뭉글 경로명(영문과 숫자 조합으로 4자~16자까지 가능합니다-숫자는 뒤쪽에 입력)</label>
            <input type="text" class="form-control" @blur="value_test()" placeholder="영문숫자조합 입력" v-model="current_data.subpage_name" style="height: 35px !important" >
            <div v-if="dupleCheckMsg!=''" style="color:red" v-html="dupleCheckMsg"></div>
          </div>
          <div class="col-sm-6" style="margin-bottom:30px">
            <label >뭉글명</label>
            <input type="text" class="form-control" placeholder="뭉글명" v-model="current_data.title" style="height: 35px !important" >
          </div>
          <div class="col-sm-6" style="margin-bottom:30px">
            <label >발행번호</label>
            <input type="text" class="form-control" placeholder="발행번호" v-model="current_data.publish_num" style="height: 35px !important" >
          </div>
          <div class="col-sm-6" style="margin-bottom:30px">
            <label >발행일</label>
            <el-date-picker
              v-model="current_data.publish_date"
              type="date"
              placeholder="발행일">
            </el-date-picker>
          </div>
        </div>
        <div class="divider" style="margin: 0px 0"></div>
        <div class="text-right">
          <span class="btn btn-sm btn-danger"  v-if="mode=='new'"  @click="create_subpage()" >추가</span>
          <div v-else>
            <span class="btn btn-sm btn-primary"  @click="modal_close()" >취소</span>
            <span class="btn btn-sm btn-danger"   @click="update_subpage()" >수정</span>
          </div>
        </div>
      </div>
    </div> 

    <div class="card card-default" v-if="mode=='delete'" style="margin-top:20px">
      <div class="card-block">
        <div class="row">
          <div class="col-12 alert alert-warning" >현재 뭉글의 섹션들과 아이템들이 모두 삭제됩니다. 신중히 생각후 삭제하세요! </div>
          <div class="col-sm-6" >
            <label>"{{ current_data.subpage_name }}"를 입력 후 삭제 하세요 </label>
            <input type="text" class="form-control" v-model="delete_newsletter"  placeholder="뭉글명 입력" style="height: 35px !important" >
          </div>
        </div> 
        <div class="divider" style="margin: 0px 0"></div>
        <div class="text-right">
          <span class="btn btn-sm btn-primary" @click="modal_close()" >취소</span>
          <span class="btn btn-sm btn-warning"  @click="deleteSubpage()" >삭제</span>
        </div>
      </div>
    </div> 

    <div class="card card-default" v-if="mode=='image'" style="margin-top:20px">
      <div class="card-block">
      
          <div><label >대표이미지는 sns(카톡,페이스북)에서도 사용됩니다.</label></div>
          <div>
            <el-upload
              ref="sns_image"
              name="sns_image"
              :limit="1"
              :on-success='return_file'
              :before-upload="before_upload"
              :action="file_upload_url"
              :headers="upload_headers"
              :auto-upload="true">
              <el-button slot="trigger" size="mini" type="primary" style="padding:5px" >선택</el-button>
              <el-button style="margin-left: 10px;padding:5px" size="mini" type="danger"  @click="submitUpload('sns_image')"  v-if="false" >업로더</el-button>
              <div class="el-upload__tip" slot="tip">jpg/png 이미지를 2MB이하 용량으로 업로더 가능합니다.</div>
            </el-upload>
          </div>
          <div v-if="file_exist(letter_data,'sns_image')">
            <img :src="letter_data.sns_image.thumb" alt="" style="width:150px"><br>
            <div class="badge badge-danger" @click="image_delete('sns_image')" style="cursor:pointer">삭제</div>
          </div>
      </div> 
    </div> 
   
    
  </div>
</template>

<script>
import ModalHeader from './modal_header'
import 'core-js/es7/array' //array includes를 윈도우 익스플로러에서 사용하기 위하여 필요함

export default {
  data(){
    return {
      compo: 'index',
      mode: '',
      add_btn_title: '뭉글추가',
      dupleCheckMsg: '',
      subname_valid: [],
      current_data: {},
      delete_newsletter: '',
    }
  },

  components: {
    ModalHeader
  },

  watch:{
    current_id(val){
      // this.current_data = this.$store.state.modal.data
      // console.log(val);
      // console.log(this.$store.state.modal.id);
      if(this.$store.state.modal.id!=val)val=this.$store.state.modal.id
      return val
    },

    mode(val){
      return val
    }
  },

  computed:{
    current_id(){
      this.current_data = JSON.parse(JSON.stringify(this.letter_data));
      this.mode = this.$store.state.modal.action
      this.delete_newsletter = ''
      return this.$store.state.modal.id
    },

    letter_data: {
      get(){
        return this.$store.state.modal.data
      },
      set(val){
        this.$store.state.modal.data = val
      }
    },

    subpage_msg(){
      if(this.$store.state.paid_type.paid_type==0){
        return "뭉글은 10개까지 추가 가능합니다."
      }else{
        //유료사용자일때 제한에 관한 코드 필요
        return "뭉글은 20개까지 추가 가능합니다."
      }
    },

    subpages: {
      get(){
        return this.$store.state.subpages
      },
      set(val){
        this.$store.state.subpages = val
      }
    },

    main_title(){
      const title = { new: '뭉글 새로 만들기', edit: '뭉글 수정', delete: '뭉글 삭제', image: '대표사진변경'  }
      return title[this.mode]
    },

    file_upload_url(){
      return `/webpages/subpage_file_upload/${this.letter_data.subpage_name}/sns_image/${this.letter_data.id}`
    },
   
  },

  methods: {

    addSubpageForm(){
      this.current_data.subpage_name = ''
      this.current_data.subpage_type = 1
      this.current_mode = this.current_mode == 'new' ? '' : 'new'
      this.dupleCheckMsg = ''
      this.subname_valid = []
    },
    
    create_subpage(){
      //뭉글 무료10개 유료 20개까지 추가 할 수 있도록 리미트 코드 작성 요망
      if(this.isEmpty(this.current_data.subpage_name)){
        this.subname_valid.push(false)
        this.dupleCheckMsg = '뭉글 경로명은 필수입니다. 입력해 주세요!'
      } 
      if(!this.subname_valid.includes(false)){
        this.current_data.webpage_id = webpage_id
        // this.current_data.snsimage = ''
        this.axios().post(`/webpages/subpage_create`, this.current_data)
        .then(result=>{
            this.webpageNotice(result.data);
          if(result.data.result == 'success'){
            this.subpages = result.data.newsletters
            this.$store.commit('set_ant_modal_visible', false)
          } 
        })
      }else{
        this.webpageNotice({ type: 'error', msg: this.dupleCheckMsg  });
        var self = this
        setTimeout(function(){ self.dupleCheckMsg = ''},3000)
      } 
    },

    update_subpage(){
      if(this.subpage_name_test(this.current_data.subpage_name)){
        this.axios().put(`/webpages/subpage_update/${this.current_data.subpage_name}/${this.current_data.id}`, this.current_data)
        .then(result=>{
          this.webpageNotice(result.data);
          if(result.data.result == 'success'){
            for(let [k,v] of Object.entries(this.letter_data)){
              this.letter_data[k] = result.data.data[k]
            }
            this.$store.commit('set_ant_modal_visible', false)
          }
        })
      }
    },

    value_test(){
      this.dupleCheckMsg = ''
      this.subname_valid = new Array()
      if(this.subpage_name_test(this.current_data.subpage_name)) this.subpage_duple_check(this.current_data.subpage_name)
      var self = this
      setTimeout(function(){
        self.dupleCheckMsg = ''
      },4000)
    },

    subpage_duple_check(name){
      const id = this.current_data.id ? this.current_data.id : 0
      this.axios().get(`/webpages/subpage_dupleCheck/${name}/${id}`)
      .then(result=>{
        if(result.data.result == 'error'  ){
          this.dupleCheckMsg += result.data.msg
          this.subname_valid.push(false)
        }else{
          this.subname_valid.push(true)
        }
      });
    },

    deleteSubpage(){
      if(this.delete_newsletter == this.current_data.subpage_name){
        if(confirm("삭제하시면 데이터 복구는 불가합니다. 삭제하시겠습니까?")){
          this.axios().delete(`/webpages/subpage_delete/${this.current_data.subpage_name}/${this.current_data.id}`)
          .then(result=>{
            this.webpageNotice(result.data);
            if(result.data.result == 'success'){
              this.subpages = result.data.subpages
              this.$store.commit('set_ant_modal_visible', false)
            }
          })
        }
      }else{
        const r = { result: 'error', type: 'error', msg: '입력하신 뭉글명이 정확하지 않습니다!' }
        this.webpageNotice(r);
      }
    },

    subpage_name_test(text){
      const reg=/^[A-Za-z0-9+]{4,16}$/; 
      let test = reg.test(text)
      if(test==false) this.dupleCheckMsg += '영문과 숫자 조합으로 4자~16자까지 가능합니다.(숫자는 뒤쪽에 입력)'
      this.subname_valid.push(test)
      return test
    },

    modal_close(){
      this.$store.commit('set_ant_modal_visible', false)
    },

    return_file(result){
      this.webpageNotice(result);
      this.letter_data.sns_image = result.data
      this.modal_close()
    },

    image_delete(file_field){
      if(confirm('정말 삭제할까요?')){
        var data = {}
        let url = this.file_upload_url
        this.axios().post(url, data )
        .then(result=>{
          this.webpageNotice(result.data);
          this.letter_data.sns_image = result.data.data
          this.modal_close()
        })
      }
    },





  },
  
 

}
</script>

<style scoped>
  .form-control:not(textarea){
    height: 40px !important
  } 
</style>