<template>
<div class="container">
          
          
<div class="owl-carousel text-center owl-testimonial nomargin" data-plugin-options='{ "singleItem": true, "autoPlay": 3500, "navigation": false, "pagination": true, "transitionStyle":"fade"}'>

    <div class="testimonial" >
		<figure>
			<img class="rounded" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/people/300x300/11-min.jpg" style="width: 150px; height: 150px" alt="" />
		</figure>
		<div class="testimonial-content" style="padding-left:0px">
			<cite>
				Joana Doe
				<span>Company Ltd.</span>
			</cite>
      <div class="row">
        <div class="offset-md-3 col-6">
			    <p class="lead" style="text-align:justify; padding-left:20px;padding-right:20px">Incidunt deleniti blanditiis quas aperiam recusandae consequatur ullam quibusdam cum libero.</p>
        </div>
      </div>
		</div>
	</div>

	<div class="testimonial">
		<figure>
			<img class="rounded" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/people/300x300/12-min.jpg" style="width: 150px; height: 150px" alt="" />
		</figure>
		<div class="testimonial-content " style="padding-left:0px">
			<cite>
				Melissa Doe
				<span>Company Ltd.</span>
			</cite>
      <div class="row">
        <div class="offset-md-3 col-6">
			    <p class="lead" style="text-align:justify; padding-left:20px;padding-right:20px">Quod necessitatibus quis expedita harum provident eos obcaecati id culpa.</p>
        </div>
      </div>    
		</div>

		</div>

	<div class="testimonial">
		<figure>
			<img class="rounded" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/people/300x300/3-min.jpg" style="width: 150px; height: 150px" alt="" />
		</figure>
		<div class="testimonial-content " style="padding-left:0px">
			<cite>
				Melissa Doe
				<span>Company Ltd.</span>
			</cite>
      <div class="row">
        <div class="offset-md-3 col-6">
			    <p class="lead" style="text-align:justify; padding-left:20px;padding-right:20px">Quod necessitatibus quis expedita harum provident eos obcaecati id culpa.</p>
        </div>
      </div>    
		</div>

		</div>

  </div>
        
</div>


</template>


<script>
export default {

  
}
</script>

<style>

</style>

