<template>
  <div>
    <div class="table-responsive" style="margin-top:0px">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>no</th>
            <th>제목</th>
            <th>내용</th>
            <th>이동</th>
            <th>옵션</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for=" (item, index) in item_order" :key='item' >
            <td style="text-align:right"> {{ index +1 }} </td>
            <td style="min-width:130px;max-width:180px;word-wrap: break-word;">
              <div>
                <img :src="citem(item).file1.thumb" v-if="file_exist(citem(item),'file1')" width="50px" height="50px"> 
                <img :src="citem(item).file2.thumb" v-if="file_exist(citem(item),'file2')" width="50px" height="50px"> 
              </div>
              <div>
                {{ citem(item).title }} 
              </div>
            </td>
            <td style="min-width: 180px;max-width:210px;word-wrap: break-word;" v-html="showMemo(citem(item).context, 50)"></td>
            <td style="text-align:center;min-width:60px;max-width:70px;padding-left: 0;padding-right: 0">
              <div v-if="index!=0">
                <ul class="list-inline" style="margin-bottom:0px" >
                  <li class="badge badge-info" style="padding: 5px 10px;cursor:pointer" v-if="index!=1" @click="move_item(index, index-1)" >
                    <i class="fa fa-sort-up" ></i>
                  </li>
                  <li class="badge badge-info" style="padding: 5px 10px;cursor:pointer" v-if="index<item_order.length-1" @click="move_item(index+1, index)" >
                    <i class="fa fa-sort-down"></i>
                  </li>
                </ul>
              </div>
              <div v-else >첫번째 아이템은 순서변경이 불가합니다.</div>
            </td>
            <td>
              <a href="javascript:void(0);" class="btn btn-default btn-sm" @click="item_edit(item)" ><i class="fa fa-edit white"></i></a>
              <a href="javascript:void(0);" class="btn btn-danger btn-sm"  @click="item_delete(item)" ><i class="fa fa-trash white"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import 'core-js/es7/array'

export default {
  props: ['compo','data','outerVisible' ],
  components:{

  },
  
  computed: {
    items(){
      return this.$store.state.board_data.board_item
    },

    item_order: {
      get(){
        let order = []
        let item_order = this.$store.state.board_data.board_item_keys
        for(let i=0; i<item_order.length; i++ ){
          if(this.$store.state.board_data.board_item[item_order[i]] != undefined ){
            order.push(item_order[i])
          }
        }
        return order
      },
      set(val){
        return val
      }
    },
    section_id(){
      return this.$store.state.modal.section.id
    }

  },
  methods: {
    citem(id){
      return this.items[id]
    },

    item_delete(item){
      if(confirm('정말 삭제하시겠습니까?')){
        this.axios().delete(`/webpages/${this.page_type}/board_item_delete/${item}${this.page_query()}`)
        .then(result=>{
					this.webpageNotice(result.data)
          if(result.data.result=='success'){
            this.$store.commit('config_item_count_update', result.data.item_count)
            if(result.data.data.delete_all == true){
              this.$store.state.board_data = result.data.data.board
              this.$emit('update:outerVisible', false); //모든 아이템이 삭제되면 모달창은 닫는다. 그렇치 않으면 없는 보드에 글쓰게 되는 버그 발생
              this.$router.push(`${this.$route.path}?current_page=${result.data.data.current_page}`)
            }else{
              this.$store.state.board_data.board_item_keys = result.data.data.board_item_keys
            }
          }
        })
      }
    },

    item_edit(item){
      this.$emit("update:compo",'board-form')
      this.$emit("update:data", this.citem(item))
    },

    move_item(from, to){
      let items_order = this.item_order
      items_order.splice(to, 0, items_order.splice(from, 1)[0]);
      this.$store.state.linebar01_excu_cnt = 0 //_owl_carousel() 이 문제야 여러번 실행하면 에러나 ㅠㅠ
      this.axios().put(`/webpages/${this.page_type}/board_item_move/${this.$route.params.board_id}`, { item_order: items_order })
      .then(result=>{
        this.webpageNotice(result.data)
        if(result.data.result=='success'){
          this.$store.state.board_data.board_item_keys = items_order
        }
      });
    }
  }
}
</script>

<style scoped>
.btn>i {
  padding-right: 0px
}

ul>li>i {
  margin-right: 0px;
  cursor: pointer;
}

th {
  text-align: center
}

</style>
