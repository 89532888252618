<template>
 

      <!-- SLIDE  -->
      <li data-transition="random" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off"  data-title="Slide">

        <img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/_smarty/1x1.png" :data-lazyload="item.background_image || slide_image_no_response_check"  alt="" data-bgfit="cover" data-bgposition="center bottom" data-bgrepeat="no-repeat" />

        <div class="tp-dottedoverlay twoxtwo"><!-- dotted overlay --></div>
        <div class="overlay dark-3"><!-- dark overlay [1 to 9 opacity] --></div>

        <div class="tp-caption customin ltl tp-resizeme text_white"
          data-x="center"
          data-y="180"
          data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
          data-speed="800"
          data-start="1000"
          data-easing="easeOutQuad"
          data-splitin="none"
          data-splitout="none"
          data-elementdelay="0.01"
          data-endelementdelay="0.1"
          data-endspeed="1000"
          data-endeasing="Power4.easeIn"
          :style="subtitle_font_css">{{ item.item.subtitle }}
        </div>

        <div class="tp-caption customin ltl tp-resizeme large_bold_white"
          data-x="center"
          data-y="230"
          data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
          data-speed="800"
          data-start="1200"
          data-easing="easeOutQuad"
          data-splitin="none"
          data-splitout="none"
          data-elementdelay="0.01"
          data-endelementdelay="0.1"
          data-endspeed="1000"
          data-endeasing="Power4.easeIn" 
          :style="title_font_css">{{ item.item.title }}
        </div>

        <div class="tp-caption customin ltl tp-resizeme small_light_white"
          data-x="center"
          data-y="350"
          data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
          data-speed="800"
          data-start="1400"
          data-easing="easeOutQuad"
          data-splitin="none"
          data-splitout="none"
          data-elementdelay="0.01"
          data-endelementdelay="0.1"
          data-endspeed="1000"
          data-endeasing="Power4.easeIn" :style="context_font_css" v-html="htmlDecode(item.item.context)" >
        </div>

        <div class="tp-caption customin ltl tp-resizeme"
          data-x="center"
          data-y="500"
          data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
          data-speed="800"
          data-start="1550"
          data-easing="easeOutQuad"
          data-splitin="none"
          data-splitout="none"
          data-elementdelay="0.01"
          data-endelementdelay="0.1"
          data-endspeed="1000"
          data-endeasing="Power4.easeIn" style="z-index: 10;">
            <button :class="'btn btn-'+item.item.config.link1.color" @click='link_button(item.item.link1)' style='margin-right:20px' v-if="item.item.config.link1.name!=''" >{{ item.item.config.link1.name }}</button>
            <button :class="'btn btn-'+item.item.config.link2.color" @click='link_button(item.item.link2)' v-if="item.item.config.link2.name!=''"  >{{ item.item.config.link2.name }}</button>
        </div>

      </li>


    

</template>

<script>
export default {
  props: ['item'],

  computed: {
    title_font_css(){
      return `z-index: 4; font-weight:bold;color:${this.item.item.font_set.title_color}`
    },
    subtitle_font_css(){
      return `z-index: 4; font-weight:bold;color:${this.item.item.font_set.subtitle_color}`
    },
    context_font_css(){
      return `z-index: 10; width: 750px; max-width: 750px; white-space: normal; text-align:center;;color:${this.item.item.font_set.context_color}`
    },
  },

  

  
}
</script>