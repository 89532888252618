<template>
  <div class="row">
    <div class="col-12 alert alert-info" style="padding-left:0px">
      <ul style="margin-bottom: 0px;font-size:10pt;">
        <li>
          SNS에 링크를 설정할 수 있으며, 샤플이 포탈(도메인 사용시)에 노출될 수 있도록 설정 할 수 있습니다.
        </li>
        <li>
          이미지 사이즈는 가로X세로 1200X630 또는 600X315으로 업로더 하세요
        </li>
      </ul>
    </div>
    
    <div class="col-12">
      <label>포탈 노출 키워드(200자 내에 키워드 띄워쓰기)</label>
		  <input type="text" class="form-control"  v-model="sns_set.ogcode.keyword" >
    </div>
    
    <div class="col-12">
      <label>사이트명</label>
		  <input type="text" class="form-control"  v-model="sns_set.ogcode.site_name" >
    </div>
    
    <div class="col-12">
      <label>타이틀</label>
		  <input type="text" class="form-control"  v-model="sns_set.ogcode.title" >
    </div>
    
    <div class="col-12">
      <label>설명글</label>
		  <input type="text" class="form-control"  v-model="sns_set.ogcode.description" >
    </div>
    
    <span class="col-12"  >
      <label >미리보기 썸네일 이미지</label>
      <el-upload
        ref="snsimage"
        name="snsimage"
        :limit="1"
        :on-success='return_file'
        :before-upload="before_upload"
        :action="image_upload_url"
        :headers="upload_headers"
        :auto-upload="true">
        <el-button slot="trigger" size="mini" type="primary" style="padding:5px" >선택</el-button>
        <el-button style="margin-left: 10px;padding:5px" size="mini" type="danger"  @click="submitUpload('snsimage')" v-if="false" >업로더</el-button>
        <div class="el-upload__tip" slot="tip">jpg/png 이미지를 2MB이하 용량으로 업로더 가능합니다.</div>
      </el-upload>
      <div v-if="file_exist(sns_set,'snsimage')">
        <img :src="sns_set.snsimage.thumb" alt="" height="100"><br>
        <div class="badge badge-danger" @click="image_delete('snsimage')" style="cursor:pointer">삭제</div>
      </div>
    </span>  
  </div>
</template>

<script>
export default {
  props: ['config'],
  data(){
    return {
      webpage: {}
    }
  },

  computed: {
    sns_set:{
      get(){
        return this.$store.state.sns_set;
      },
      set(val){
        this.$store.state.sns_set = val
      }
    },

    image_upload_url(){
      return `/admin/update_webapi_image/sns_set/snsimage/${webpage_id}.json`
    }
  },

  methods:{
    image_delete(file_field){
      if(confirm('정말 삭제할까요?')){
        const data = {snsimage: ''}
        this.axios().post(this.image_upload_url, data )
          .then(result=>{
            this.webpageNotice(result.data);
            if(result.data.result == 'success'){
              this.sns_set.snsimage = result.data.data
            }
          })
      }
    },

    return_file(result){
      this.webpageNotice(result);
      if(result.result == 'success'){
        this.sns_set.snsimage = result.data
        this.$refs.snsimage.clearFiles()

      }
    },

  }
  
}
</script>

<style>

</style>
