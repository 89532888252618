<template>
    <!-- wrapper -->
	<div>
		<!-- CALLOUT -->
			<section :id="section_tag_id" :class="section_class" :style="section_background()+';padding-top: 40px;min-height:250px'" >
				<div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'" ></div>
				<div class="container" style="z-index: 100" >
					<header class="text-center" v-if='false' >
						<h1 class="fw-300 fs-40" :style="section_title_style" >{{ current_section.title }}</h1>
      			<h2 class="fw-300 letter-spacing-1 fs-13" ><span :style="section_sub_title_style+';font-size: 1.3rem'">{{ current_section.sub_title }}</span></h2>
					</header>
          
					<template v-if="use_section_header"> 
      			<smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section'  ></smarty-sectionheader>
					</template>

					<header class="text-center" v-else >
						<h1 class="fw-300 fs-40">Our Latest Issue</h1>
						<h2 class="fw-300 letter-spacing-1 fs-13"><span>관리가 용이한 홈페이지 빌더 Easy Home Builder</span></h2>
					</header>

					<div class="text-center" v-if="current_section.item_order.length > 0" style="margin-top: 30px"  >
        		<button 
						  v-for="(i, idx) in current_section.item_order" 
							:class="'btn btn-3d btn-'+btn_color(ctem[i]) " 
							@click='link_button(ctem[i].link1)' 
							style='margin-right:20px'  
							:key='idx' >
							<i :class="ctem[i].icon" :style="'cursor:pointer;color:'+ctem[i].font_set.icon_color" v-if="!isEmpty(ctem[i].icon)" ></i>
							<font :color="ctem[i].font_set.font_color">{{ ctem[i].title}}</font>
						</button>
					</div>
				</div>
			</section>
      <slot name="setting"></slot>
			<!-- /CALLOUT -->
	    
  </div>
    
</template>


<script>


export default {
	props: ['index','current_section'],
	mounted(){
		if(this.current_section.config!=undefined){
			var color = this.current_section.config.section_set.color
			if(this.current_section.config.section_set.use_background)$('section').append(`<style>#${this.section_tag_id}:after{border-top-color: ${color}}</style>`)
		}
		
	},
	computed: {
		section_class(){
			if (this.current_section.config.section_set.use_background == 2) {
				return 'callout-dark heading-title'
			}else{
				return 'callout-dark heading-title heading-arrow-bottom'
			}
		}

	},

	methods: {
		
		icon_color(color){
			return color
		}

	}

}
</script>


<style>
/* .heading-title.heading-arrow-bottom:after{
	border-top-color: red
} */
</style>

