<template>
  <div>
		<label>로고설정(디폴트는 샤플명)</label>
		<div v-if='screen_size > 1000'>
			<el-radio v-model="use_logo" :label="0" border style="margin-right:0px">로고만</el-radio>
			<el-radio v-model="use_logo" :label="1" border style="margin-right:0px">샤플명만</el-radio>
			<el-radio v-model="use_logo" :label="2" border style="margin-right:0px">로고+샤플명</el-radio>
		</div>

    <el-radio-group v-model="use_logo" v-else size="small">
      <el-radio-button :label="0" >로고만</el-radio-button>
      <el-radio-button :label="1" >샤플명만</el-radio-button>
      <el-radio-button :label="2" >로고+샤플명</el-radio-button>
    </el-radio-group>


		<div class="divider"><!-- divider --></div>
		<label>샤플명</label>
		<el-input  v-model="config.title" ></el-input>
    <div class="row" style="margin-top:20px">
      <div class="col-md-12" :style="logo_font_style" >{{config.title}}</div>
      <div class="col-sm-3">
        <label>글자크기</label>
        <el-select v-model="config.font_size" placeholder="Select">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        
      </div>
      <div class="col-sm-3">
        <label>글자체</label>
        <el-select v-model="config.font_family" placeholder="Select">
          <el-option
            v-for="font in $store.state.font_list"
            :key="font"
            :label="font"
            :value="font">
          </el-option>
        </el-select>
        
      </div>
      <div class="col-sm-6">
      <color-picker v-bind:font_color.sync='config.font_color' v-bind:label="'글자색'"  v-bind:option="{label: true, input: true}" ></color-picker>
      </div>
   
    </div>

		<div class="divider"><!-- divider --></div>
		<label>로고이미지</label>
		<el-upload
			ref="logofile"
      name="logofile"
      :limit="1"
      :on-success='return_logo'
      :multiple='false'
      :action="image_upload_url"
      :headers="upload_headers"
      :auto-upload="true">
      <el-button slot="trigger" size="mini" type="primary">로고이미지선택</el-button>
      <el-button style="margin-left: 10px;" size="mini" type="success" @click="submitUpload('logofile')" v-if="false" >이미지 업로더</el-button>
      <div class="el-upload__tip" slot="tip">jpg/png 이미지를 500kb이하 용량으로 업로더 가능합니다.</div>
		</el-upload>
		<div v-if='$store.state.logo_set.logofile!=""'>
			<img :src="$store.state.logo_set.logofile" alt="" height="100"><br>
			<div class="badge badge-danger" @click="image_delete" style="cursor:pointer">삭제</div>
		</div>
  </div>

</template>

<script>
  import ColorPicker from '../../../../common_components/color_picker.vue'

  export default {
    props: ['config'],
    data(){
      return {
        options: [
          {value: 12, label: 12},
          {value: 13, label: 13},
          {value: 14, label: 14},
          {value: 15, label: 15},
          {value: 16, label: 16},
          {value: 17, label: 17},
          {value: 18, label: 18},
          {value: 19, label: 19},
          {value: 20, label: 20},
          {value: 21, label: 21},
          {value: 22, label: 22},
          {value: 23, label: 23},
          {value: 24, label: 24},
          {value: 25, label: 25},
          {value: 28, label: 28},
          {value: 30, label: 30},
          {value: 32, label: 32},
        ],
        exceptions: [],
        color: '',
      }
    },
    computed: {
      logo_font_style(){
        return `font-size:${this.config.font_size}pt;color:${this.config.font_color};font-family:${this.config.font_family};font-weight:bold;letter-spacing:-2px`
      },
      
      image_upload_url(){
        return `/admin/update_webapi_image/logoset/logofile/${webpage_id}.json`
      },
      
      use_logo: {
        get(){
          return Number(this.config.use_logo)
        },
        set(val){
          this.config.use_logo = val
        }
      }

    },
    mounted(){
      // _pickers();

    },
    
    methods: {
      font_color_change(color){
        // console.log(color);
      },
      
      image_delete(){
        if(confirm('정말 삭제할까요?')){
          this.axios().post(`/admin/update_webapi_image/logoset/logofile/${webpage_id}.json`, {id: webpage_id, file_field: ''})
          .then(result=>{
            if(result.data.result == 'success'){
               this.$store.state.logo_set.logofile = ''
            }
            this.webpageNotice(result.data)
          })
        }
      },

      return_logo(result){
        this.webpageNotice(result)
        if(result.result=="success"){
          this.$store.state.logo_set.logofile = result.data.large
        }
      },

     
    },
    components: {
      ColorPicker
    }

    
  }
</script>

<style scoped>
  .divider {
    margin: 10px 0
  }
</style>


<style >
  .el-icon-close{
    display: inline !important;
  }
</style>
