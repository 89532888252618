<template>
  <div class="row">
    <div class="col-md-12" style="margin-bottom:30px">
      <h2 style="margin-bottom:0px">{{currentItem.title}}</h2>
      <h2 class="fw-300 letter-spacing-1 fs-13" style="background-color: transparent;margin:0px;" ><span :style="subtitle_color(currentItem)+';font-weight:bold'">{{currentItem.subtitle}}</span></h2>
    </div>
    <div class="col-md-12" v-if="file1_thum_use" >
      <img :src="image1" v-if="image1!=''" width='100%'  >
    </div>
    <div class="col-md-12" v-if="image2_check" >
      <img :src="image2"  width='100%'  >
    </div>
    <div class="col-md-12" v-if="media_source_test(currentItem.media)" >
      <div  class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-html="currentItem.media"></div>
    </div>	
    <div class="col-md-12" v-html="htmlDecode(currentItem.context)" style="overflow:auto;padding: 0 20px" v-if="context_popup" ></div>
    <div class="col-md-12" style="margin-bottom: 20px"> 
      <button :class="'btn btn-md btn-'+currentItem.config.link1.color" @click='link_button(currentItem.link1)' style='margin-right:10px;margin-top:10px' v-if="currentItem.config.link1.name!=''" >{{ currentItem.config.link1.name }}</button>
      <button :class="'btn btn-md btn-'+currentItem.config.link2.color" @click='link_button(currentItem.link2)' style='margin-top:10px' v-if="currentItem.config.link2.name!=''"  >{{ currentItem.config.link2.name }}</button>
    </div>
    <img :src="image2" v-if="!image2_check" width='100%' >
  </div>
</template>

<script>
export default {
  props: ['currentItem', 'image1', 'image2', 'context_popup'],
  data(){
    return {
      
    }
  },

  computed: {
    image2_check(){
      return this.image2!='' && !this.file1_thum_use && !this.media_source_test(this.currentItem.media)
    }  
  }


}
</script>

<style>

</style>