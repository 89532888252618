<template>
  <div id="wrapper" >
    <div>
      <section class="page-header page-header-xs">
        <div class="container">
          <h1>{{ head_title }}</h1>
          <ol class="breadcrumb">
            <li><a href="#">Home</a></li>
            <li class="active">{{ head_title }}</li>
          </ol>
        </div>
      </section>
      
   
      <user-signup v-bind:signup_pagetype="'signup_page'" v-if="webpage_signuppage"></user-signup>
      <find-password v-if="webpage_find_password" ></find-password>
      <reset-password v-if="webpage_reset_password" ></reset-password>
   
    </div>
  
  
  </div>
</template>

<script>
import UserSignup from './modal/webpage_user/user_signuppage'
import FindPassword from './modal/webpage_user/find_password'
import ResetPassword from './modal/webpage_user/reset_password'
export default {
  computed:{
    webpage_signuppage(){
      return this.$route.name=='webpage_signuppage'
    },
    webpage_find_password(){
      return this.$route.name=='webpage_find_password'
    },
    webpage_reset_password(){
      return this.$route.name=='webpage_reset_password'
    },
    head_title(){
      if(this.webpage_signuppage){
        return 'Member Sign Up'
      }else if(this.webpage_find_password){
        return 'Find my password'
      }else if(this.webpage_reset_password){
        return 'To change my password'
      }
    }

  },
  
  components: {
    UserSignup,
    FindPassword,
    ResetPassword
  }, 

}
</script>
