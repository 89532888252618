<template>
  <div >
    <div class='row'>
      <div class="container" style="z-index: 100"  v-if="current_section.item_order.length > 0" >
        <ul class="timeline">
          <template  v-for="(i, idx) in current_section.item_order"  >
            <li :class=" idx%2==0 ? '' : 'timeline-inverted' " :key='idx'>
              <div :class="'timeline-badge ' + userDateConfig(ctem[i]).date_color "  >
                <template v-if="userDateConfig(ctem[i]).use_number!=undefined && userDateConfig(ctem[i]).use_number">
                  <div  :style="'font-size:1.7em;padding-top:12px;font-weight:bold;color: '+badge_icon_color(ctem[i])">
                    {{ idx + timeline_start_number }}
                  </div>
                </template>
                <template v-else>
                  <template v-if="userDateConfig(ctem[i]).use_date">
                    <template v-if="userDateConfig(ctem[i]).use_time == 1">
                      <div :style="'color: '+badge_icon_color(ctem[i])" >
                        <div class="timeline-balloon-date-time">{{ dateFormat(ctem[i].user_date, 'HH:mm' ) }}</div>
                        <div class="timeline-balloon-date-month">{{ dateFormat(ctem[i].user_date, 'DD일' ) }}</div>
                      </div>
                    </template>
                    <template v-else-if="userDateConfig(ctem[i]).use_time == 0">
                      <div :style="'color: '+badge_icon_color(ctem[i])" >
                        <div class="timeline-balloon-date-day">{{ dateFormat(ctem[i].user_date, 'DD' ) }}</div>
                        <div class="timeline-balloon-date-month">{{ dateFormat(ctem[i].user_date, 'MM월' ) }}</div>
                      </div>  
                    </template>
                    <template v-else-if="userDateConfig(ctem[i]).use_time == 2">
                      <div :style="'color: '+badge_icon_color(ctem[i])" >
                        <div class="timeline-balloon-date-time">{{ dateFormat(ctem[i].user_date, 'MM월' ) }}</div>
                        <div class="timeline-balloon-date-month">{{ dateFormat(ctem[i].user_date, 'YYYY' ) }}</div>
                      </div>  
                    </template>
                    <template v-else-if="userDateConfig(ctem[i]).use_time == 3">
                      <div :style="'padding-top:5px;color: '+badge_icon_color(ctem[i])" >
                        <div class="timeline-balloon-date-time">{{ dateFormat(ctem[i].user_date, 'YYYY' ) }}</div>
                      </div>  
                    </template>
                  </template>
                  <template v-else>
                    <div  :style="'color: '+badge_icon_color(ctem[i])">
                      <i :class="ctem[i].icon" style="margin-top:15px;font-size: 20pt;"></i>
                    </div>
                  </template>
                </template>

              
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <p v-if="userDateConfig(ctem[i]).use_date" >
                    <small class="text-muted" style="font-size: 1.2em">
                      <i class="glyphicon glyphicon-time"></i> 
                      {{ dateFormat(ctem[i].user_date, timeline_date_format(userDateConfig(ctem[i]).use_time)) }}
                    </small>
                  </p>
                  <h4 class="timeline-title" :style="'cursor:pointer;color:'+ctem[i].font_set.title_color+(ctem[i].subtitle =='' ? ';margin-bottom:28px' : ';margin-bottom:0px') " @click="link_button(ctem[i].link1)" v-if="!isEmpty(ctem[i].title)" >
                    <i :class="ctem[i].icon" :style="'margin-top:15px;color:'+ ctem[i].font_set.icon_color"></i>
                    {{ctem[i].title}}
                  </h4>
                  <h2 class="fw-400 letter-spacing-1 fs-13" style="margin-bottom: 5px;" v-if="!isEmpty(ctem[i].subtitle)" ><span :style="subtitle_color(ctem[i])">{{ctem[i].subtitle}}</span></h2>
                </div>

                <template v-if="thumb_use(ctem[i]) && media_source_test(ctem[i].media)">
                  <div  class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-html="ctem[i].media"></div>
                </template>
                <template v-else>
                  <a href="javascript:void(0);" @click="use_fulltext(ctem[i]) ? modal_open(ctem[i]) : '' " v-if="thumb_use(ctem[i]) && ctem[i].file1.large!=''"  >
                    <img class="card-img-top" :src="cur_file1_image(ctem[i])" :style="image_height" alt="">
                  </a>
                </template>

                <div class="timeline-body">
                  <p :style="'margin-top:0px;color:'+ctem[i].font_set.context_color" v-html="use_fulltext(ctem[i]) ? htmlDecode(showMemo(ctem[i].context, 150)) : htmlDecode(ctem[i].context) " ></p>
                  <button :class="'btn btn-sm btn-'+ctem[i].config.link1.color" @click='link_button(ctem[i].link1)' style='margin-right:10px;margin-top:10px' v-if="ctem[i].config.link1.name!=''" >{{ ctem[i].config.link1.name }}</button>
                  <button :class="'btn btn-sm btn-'+ctem[i].config.link2.color" @click='link_button(ctem[i].link2)' style='margin-top:10px' v-if="ctem[i].config.link2.name!=''"  >{{ ctem[i].config.link2.name }}</button>
                  <p v-if="use_fulltext(ctem[i])" style="cursor:pointer;margin-top:20px" @click="use_fulltext(ctem[i]) ? modal_open(ctem[i]) : '' "><small class="text-muted"><i class="glyphicon glyphicon-folder-open" style="margin-right:5px"></i> 더보기</small></p>
                </div>
              </div>
            </li>
          </template>
        </ul>
      </div>

      <div class="container" v-else >
        <default></default>
      </div>        
    
    </div>
    
    <el-dialog :visible.sync="outerVisible" :fullscreen="element_modal_fullscreen"  v-if="currentItem!=null" >
      <modal-view :currentItem='currentItem' :image1='image1' :image2='image2' :context_popup='context_popup' ></modal-view>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">닫기</el-button>
      </div>
    </el-dialog> 
    
  </div>



</template>

<script>
import Default from './timeline_type01_default'
import ModalView from '../../modal/explain_view_modal'

export default {
  props: ['current_section'],

  data(){
    return {
      outerVisible: false,
			currentItem: null,
			image1:'',
			image2:'',
			context_popup: null,
    }
  },

  components: {
    Default,
    ModalView
  },

  computed: {
    timeline_start_number(){
      const start = this.current_section.config.section_set.timeLine_start_number
      return start == undefined ? 1 : start        
    }
  },

  methods: {
    modal_open(item){
			this.outerVisible=true;
			this.currentItem=item;
			this.image1=item.file1.origin
			this.image2=item.file2.origin
			this.context_popup=!item.font_set.context_popup
    },
    
    userDateConfig(t){
      return t.config.user_date == undefined ? {use_date: false, date_color: 'default' } : t.config.user_date
    },

    badge_icon_color(ctem){
      return this.userDateConfig(ctem).date_color=='default' ? ctem.font_set.icon_color : '#fff'
    },


    timeline_date_format(type){
      switch(type){
          case 0: return 'YYYY/MM/DD' 
          case 1: return 'YYYY/MM/DD HH:mm' 
          case 2: return 'YYYY년 MM월' 
          case 3: return 'YYYY년' 
      }
    }

  }


}


</script>

<style scoped>
@import '../../../../styles/timeline.css'





</style>