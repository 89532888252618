<template>
  <div >
    <modal-header v-bind:title="main_title"></modal-header>

      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <el-input placeholder="Search Homepage" size="mini" v-model="search_word" @keyup.enter.native="homelis_search_word()" class="input-with-select" style="margin-top:20px;width: 50%">
              <el-button slot="prepend" icon="el-icon-refresh"  @click="search_word='';homelis_search_word()" v-show="!isEmpty(search_word)" ></el-button>
              <el-button slot="append" icon="el-icon-search" @click="homelis_search_word()" ></el-button>
            </el-input>
          </div>
        </div>  
        <table class="table" style="margin-top:10px" >
          <thead>
            <th>번호</th>
            <th>샤플명</th>
            <th>url명</th>
            <th>옵션</th>
          </thead>
          <tbody>
            <tr v-for="(l, index) in current_data" :key="index">
              <td>{{ list_number(index) }}</td>
              <td>{{ l.title }}</td>
              <td>{{ l.url }} </td>
              <td>
                <button class="badge badge-primary" @click="add_homepage(l.id)" v-if="selected_home_check(l.id)" >추가</button> 
                <i class='fa fa-check text-success' v-else ></i>
              </td>
            </tr>

          </tbody>

        </table>
        <div class="text-center"  style="margin-bottom: 30px"  >
          <el-pagination
            :page-size="page_info.per_page"
            @current-change="current_change"
            layout="prev, pager, next"
            :total="page_info.total_count">
          </el-pagination>
        </div>
        
      </div>
        
  </div>
</template>

<script>
import ModalHeader from './modal_header'
import 'core-js/es7/array' //array includes를 윈도우 익스플로러에서 사용하기 위하여 필요함

export default {
  data(){
    return {
      compo: 'index',
      mode: 'index',
      current_data: {},
      selected_home: [],
      search_word: '',
      page_info: {
        total_count: 0,
        current_page: 1,
        per_page: 20,
      },
    }
  },

  mounted() {
    this.get_homepage_list()
  },

  components: {
    ModalHeader
  },

  watch:{
    
    mode(val){
      return val
    },
    
    selected_home(val){
      return val
    },
    
    refresh(val){
      if(val == 0)this.get_homepage_list()
    }

  },

  computed:{
   
    main_title(){
      const title = { index: '추가 할 샤플', edit: '뭉글 수정', delete: '뭉글 삭제', image: '대표사진변경'  }
      return title[this.mode]
    },

    homepage_ids(){
      let ids = []
      for(const d in this.current_data){ ids.push(d.id) }
      return ids
    },

    homepage_list: {
      get(){
        return this.$store.state.homepage_list
      },
      set(val){
        this.$store.state.homepage_list = val
      }
    },

    refresh: {
      get(){ return this.$store.state.refresh },
      set(val) { this.$store.state.refresh = val }
    }
  },

  methods: {

    get_homepage_list(){
      let search = ''
      search += this.page_info.current_page == 1 ? '?current_page=1' :`?current_page=${this.page_info.current_page}`
      search += this.isEmpty(this.search_word) ? '' : `&search_word=${this.search_word}`
      if(this.refresh < 10){
        this.axios().get(`/webpages/homepagelist_index/${search}`)
        .then(result=>{
          try {
            this.current_data = result.data.webpages.list
            this.page_info = result.data.webpages.page_info
            this.selected_home = result.data.webpages.selected_home
            this.refresh += 1
          }catch(e){
            console.log(e);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      }
    },

    selected_home_check(id){
      return !this.selected_home.includes(id)
    },

    current_change(val){
      this.page_info.current_page = val
      this.refresh = 0
      this.get_homepage_list();
    },

    list_number(index) {
      return this.page_info.total_count - this.page_info.per_page *(this.page_info.current_page - 1) - index ;
    },

    add_homepage(homepage_id){
      if(confirm('샤플을 추가 하시겠습니까?')){
        this.axios().post(`/webpages/add_homepage/${this.$store.state.modal.section.id}/${homepage_id}`)
        .then(result=>{
          this.webpageNotice(result.data);
          if(result.data.result=='success'){
            this.selected_home.push(result.data.homepage.webpage_id)
            this.$store.state.homepage_list.list.unshift(result.data.homepage.list)
            this.$store.commit('add_homepage_image', [result.data.homepage.webpage_id, result.data.homepage.webpage])
          } 
        })
      }
    },

    homelis_search_word(){
      if(!this.isEmpty(this.search_word))this.get_homepage_list() 
    }
  

  },
  
 

}
</script>

<style scoped>
  .form-control:not(textarea){
    height: 40px !important
  } 
</style>