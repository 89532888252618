<template>
  <div>
		<div v-if="use_user_date">
			<table>
				<tr>
					<td>
						<label>타임라인 디자인 선택</label>
						<el-radio-group v-model="timeLineType" size="small" style="margin-top: 4px">
							<el-radio-button :label="0" >Type1</el-radio-button>
							<el-radio-button :label="1" >Type2</el-radio-button>
							<el-radio-button :label="2" >Type3</el-radio-button>
						</el-radio-group>
					</td>
				</tr>
				<tr>
					<td style="padding-left: 10px;padding-top: 0px">
						<label>시작번호</label>
						<el-input-number size="small" v-model="timeLineStartNumber" ></el-input-number>
					</td>
				</tr>
			</table>
		</div>

		<div v-if='use_title'>
			<label>제목</label>
			<div>
				<table style="width:100%;margin-bottom:5px">
					<tr>
						<td style="width:40px">
							<color-picker  v-bind:font_color.sync='current_section_set.title_color' v-bind:label="'글자색'" v-bind:option="{label: false, input: false}" ></color-picker>
						</td>
						<td style="width:100px">
							<el-select v-model="current_section_set.title_font" placeholder="폰트선택">
								<el-option
									v-for="font in $store.state.font_list"
									:key="font"
									:label="font"
									:value="font">
								</el-option>
							</el-select>
						</td>
						<td>
							<input type="text" class="form-control"  v-model="current_section.title"  >
						</td>
					</tr>  
				</table>
				<div v-if="current_section.component=='linebar01'">"제목을 사용하고 싶지 않으실 경우 empty 를 입력하세요! "</div>
			</div>
			<div class="divider" style="margin: 0px"><!-- divider --></div>
		</div>
		<div  v-if='use_sub_title'>
			<label>부제목</label>
			<table style="width:100%;margin-bottom:5px">
				<tr>
					<td style="width:50px">
						<color-picker  v-bind:font_color.sync='current_section_set.subtitle_color' v-bind:label="'글자색'" v-bind:option="{label: false, input: false}" ></color-picker>
					</td>
					<td>
						<input type="text" class="form-control"  v-model="current_section.sub_title"  >
					</td>
				</tr>
			</table>    
			<div class="divider" style="margin: 0px"><!-- divider --></div>
		</div>
		<div v-if="timer_except_list">
			<label>섹션타이머설정</label>
			<div class="row">
				<div class="col-md-6" >
					<a-switch  v-model='current_section_set.use_start_date' @change="start_time_set" /> 섹션 보이기 타이머
					<div v-if="current_section_set.use_start_date" style="margin-top:20px">
						<a-date-picker
							showTime
							v-model="startDate"
							:format="date_format"
							placeholder="날짜설정"
							@change="startDateChange"
							:locale="timepicker_lang"
							:allowClear="false"
							:disabled-date="disabledStartDate"
						>
						</a-date-picker>
					</div>
				</div>
				<div class="col-md-6" >
					<a-switch v-model='current_section_set.use_finish_date' @change="finish_time_set" /> 섹션 감추기 타이머
					<div v-if="current_section_set.use_finish_date" style="margin-top:20px">
						<a-date-picker
							showTime
							v-model="finishDate"
							:format="date_format"
							placeholder="날짜설정"
							@change="finishDateChange"
							:locale="timepicker_lang"
							:allowClear="false"
							:disabled-date="disabledEndDate"
						>
						</a-date-picker>
					</div>
				</div>
			</div>
			<div class="divider" style="margin: 0px"><!-- divider --></div>
		</div>
    
		<label>배경설정</label>
		<div v-if='screen_size > 1000'>
			<el-radio v-model="current_section_set.use_background" :label="0" border style="margin-right:0px">사용안함</el-radio>
			<el-radio v-model="current_section_set.use_background" :label="1" border style="margin-right:0px">배경색사용</el-radio>
			<el-radio v-model="current_section_set.use_background" :label="2" border style="margin-right:0px">배경이미지사용</el-radio>
		</div>

		<el-radio-group v-model="current_section_set.use_background" v-else size="small">
      <el-radio-button :label="0" >사용안함</el-radio-button>
      <el-radio-button :label="1" >배경색사용</el-radio-button>
      <el-radio-button :label="2" >배경이미지사용</el-radio-button>
    </el-radio-group>
    
		<div v-if="image_round">
			<div class="divider" ><!-- divider --></div>
			<label>아이템 이미지 아웃라인</label>
			<el-checkbox v-model="current_section_set.image_square" label="이미지 테두리 사각형 사용" border></el-checkbox>
		</div>

		<div v-if="line_count_show" >
			<div class="divider" ><!-- divider --></div>
			<label>한줄에 표시할 아이템 갯수</label>
			<el-radio-group v-model="columnCount" size="mini">
				<el-radio-button v-for="l in line_count" :label="l" :key="l" >{{ l }}단</el-radio-button>
			</el-radio-group>
		</div>

		<div v-if="countdown_section">
			<div class="divider" ><!-- divider --></div>
			<div class="row">
				<div class="col-md-6">
					<label>카운트다운 날짜시간 설정</label>
					<a-date-picker
						showTime
						:value="countdownDate"
						:format="date_format"
						placeholder="날짜설정"
						@change="onChange"
						@ok="onOk"
						:locale="timepicker_lang"
						:allowClear="false"
					/>
				</div>
				<div class="col-md-6">
					<label>카운트다운 글자색</label>
					<el-radio-group v-model="countdown_font_color" size="mini">
						<el-radio-button label="1" >화이트</el-radio-button>
						<el-radio-button label="2" >블랙</el-radio-button>
					</el-radio-group>
				</div>
			</div>
		
		</div>   
		<div class="divider" ><!-- divider --></div>

		<label>배경색상설정</label>
		<color-picker v-bind:font_color.sync='current_section_set.color' v-bind:label="'글자색'"  v-bind:option="{label: false, input: true}" ></color-picker>

		<div class="divider" style="clear:left"><!-- divider --></div>
		<label>배경이미지 설정</label>
		<table class="table" style="padding: 0" >
			<tr>
				<td >
					<label>배경이미지고정</label>
					<a-switch v-model='back_fiximage' @change="backimage_opt_change" />
				</td>
				<td>
					<label>필터투명도({{back_transparent}}%)</label>
					<el-slider
						v-model="back_transparent"
						:step="10"
						show-stops>
					</el-slider>
				</td>
			</tr>
		</table>	
		
		<div v-if="file_error!=''" style='color: red' >{{file_error}}</div>
		<el-upload
			ref="file1"
			name="file1"
			:on-success='return_section_image'
			:before-upload="before_upload"
			:action="'/webpages/'+page_type+'/section_background/file1'"
			:headers="upload_headers"
			:data="{ id: $store.state.modal.section.id }"
			:limit="1"
			:auto-upload="true">
			<el-button slot="trigger" size="mini" type="primary">배경이미지</el-button>
			<el-button style="margin-left: 10px;" size="mini" type="success" @click="submitUpload('file1')" v-if="false" >이미지 업로더</el-button>
			<div class="el-upload__tip" slot="tip">jpg/png 가능하며 2.5MB이하로 업로더 하시면 됩니다.</div>
		</el-upload>
		<div v-if="!object_empty_check($store.state.modal.section.file1) && $store.state.modal.section.file1.thumb!='' && $store.state.modal.section.file1.thumb!='/images/noimage.png'">
			<img :src="$store.state.modal.section.file1.thumb" alt="" height="100"><br>
			<div class="badge badge-danger" @click="image_delete" style="cursor:pointer">삭제</div>
		</div>
  </div>

</template>

<script>
// import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/ko';
moment.locale('ko');
import ColorPicker from '../../../../common_components/color_picker'
import 'core-js/es7/array'
import { DatePicker, Switch } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';  
// Vue.use(DatePicker, Switch);

export default {
	data(){
		return {
			columnCount: 3,
			date_format: "YYYY/MM/DD HH:mm:ss",
			countdown_date: '',
			timeLineType: 0,
			timeLineStartNumber: 1,
			countdown_font_color: 2,
			useStartDate: false,
			useFinishDate: false,
			startDate: moment(new Date(), this.date_format),
			finishDate: moment(new Date(), this.date_format),
			endOpen: false,
			back_transparent: 30,
			back_fiximage: false,
			file_error: '',
			timepicker_lang: {
				"lang": {
					"placeholder": "날짜시간설정",
					"rangePlaceholder": ["Start date", "End date"],
					"today": "Today",
					"now": "지금",
					"backToToday": "Back to today",
					"ok": "확인",
					"clear": "Clear",
					"month": "Month",
					"year": "Year",
					"timeSelect": "시간설정",
					"dateSelect": "Select date",
					"monthSelect": "Choose a month",
					"yearSelect": "Choose a year",
					"decadeSelect": "Choose a decade",
					"yearFormat": "YYYY",
					"dateFormat": "M/D/YYYY",
					"dayFormat": "D",
					"dateTimeFormat": "M/D/YYYY HH:mm:ss",
					"monthFormat": "MMMM",
					"monthBeforeYear": true,
					"previousMonth": "Previous month (PageUp)",
					"nextMonth": "Next month (PageDown)",
					"previousYear": "Last year (Control + left)",
					"nextYear": "Next year (Control + right)",
					"previousDecade": "Last decade",
					"nextDecade": "Next decade",
					"previousCentury": "Last century",
					"nextCentury": "Next century"
				},
				"timePickerLocale": {
					"placeholder": "시간설정"
				}
			}
		}
	},

	mounted(){
		this.columnCount = this.column_count
		this.countdown_font_color = this.countdownFontColor
		this.countdown_date = this.$store.state.modal.section.evnet_date
		this.startDate = this.$store.state.modal.section.show_start_date == null ? moment(new Date(), this.date_format) : moment(this.$store.state.modal.section.show_start_date, this.date_format) 
		this.finishDate = this.$store.state.modal.section.show_finish_date == null ? moment(new Date(), this.date_format) : moment(this.$store.state.modal.section.show_finish_date, this.date_format) 
		if(this.current_section_set.background_transparent!=undefined)this.back_transparent = this.current_section_set.background_transparent
		if(this.current_section_set.fix_backimage!=undefined)this.back_fiximage = this.current_section_set.fix_backimage
		if(this.current_section.component=="timeline01"){
			this.timeLineType = this.timeline_type
			this.timeLineStartNumber = this.timeLine_start_number
		}
	},
	
	watch: {
		columnCount(val){
			this.column_count = val
		},

		countdown_font_color(val){
			this.$store.state.modal.section.config.section_set.countdown_font_color = val 
			return val
		},

		countdownDate(val){
			return val 
		},

		current_section(val){
			this.timepicker_value = this.$store.state.modal.section.event_date
      return val
		},

		back_transparent(val){
			this.current_section_set.background_transparent = val
		},

		timeLineType(val){
			this.current_section_set.timeline_type = val
		},

		timeLineStartNumber(val){
			this.current_section_set.timeLine_start_number = val
		}

	},
 
	computed: {
		use_user_date(){
      return this.current_section_format.userdate!=undefined && this.current_section_format.userdate.length > 0
		},
		
		timeline_type: {
			get(){
				const timeline_type = this.$store.state.modal.section.config.section_set.timeline_type == undefined ? 0 : this.$store.state.modal.section.config.section_set.timeline_type
				return timeline_type
			},
			set(val){
				this.$store.state.modal.section.config.timeline_type = val
			}
		},
		
		timeLine_start_number: {
			get(){
				const timeLine_start_number = this.$store.state.modal.section.config.section_set.timeLine_start_number == undefined ? 1 : this.$store.state.modal.section.config.section_set.timeLine_start_number
				return timeLine_start_number
			},
			set(val){
				this.$store.state.modal.section.config.timeLine_start_number = val
			}
		},
		
		current_section_format(){
			return this.$store.state.current_section_format
		},
		
		countdownFontColor: {
			get(){
				if(this.$store.state.modal.section.config.section_set.countdown_font_color == undefined){
					this.$store.state.modal.section.config.section_set.countdown_font_color = 2
					return 2
				}else{
					return this.$store.state.modal.section.config.section_set.countdown_font_color 
				}
			},
			set(val){
				this.$store.state.modal.section.config.section_set.countdown_font_color = val 
			}
		},

		timer_except_list(){
			//섹션타이머를 사용하지 않아야 할 섹션
			const list = ['newsletterhead01']
			return !list.includes(this.current_section.component)
		},
		
		countdownDate: {
			get(){
				let date = this.$store.state.modal.section.event_date
				if(date==null){
					date = new Date();
				}
				return moment(date, this.date_format)
			},
			set(val){
				this.$store.state.modal.section.event_date = val
			}
		},

		current_id(){
			return this.$store.state.modal.section.id
		},

		current_section:{
			get(){
				return this.$store.state.modal.section
			},
			set(val){
				this.$store.state.modal.section = val
				return val
			}
		},

		current_section_set(){
			return this.$store.state.modal.section.config.section_set
		},

		no_use_section_item(){
			if(this.current_section_format.no_use_section_item!=undefined)return this.current_section_format.no_use_section_item
			return []
		},

		use_title(){
			return !this.no_use_section_item.includes('title')
		},

		use_sub_title(){
			return !this.no_use_section_item.includes('sub_title')
		},

		image_round(){
			const format = this.$store.state.current_section_format
			if(format.image_round==undefined){
				return false
			}else{
				return true
			}
		},

		countdown_section(){
			if(this.current_section.component.includes('countdown')){
				return true
			}else{
				return false
			}
		},

		column_count: {
			get(){
				const vcount = this.current_section_set.column_count
				if(this.current_section_set.column_count==undefined){
					return 3
				}else{
					return this.current_section_set.column_count
				}
			},
			set(val){
				this.current_section_set.column_count = val
			}
		},

		timepicker_value:{
			get(){
				return moment(this.current_section.event_date, this.date_format)
			},
			set(val){
				this.current_section.event_date = val
			}
		}

	},

	methods:{

		disabledStartDate(startDate) {
			const finishDate = this.finishDate;
		  if(this.useFinishDate){
				if (!startDate || !finishDate) {
					return false;
				}
				return startDate.valueOf() > finishDate.valueOf();
			}else{
				return false
			}
		},
		
    disabledEndDate(finishDate) {
			const startDate = this.startDate;
			if(this.useStartDate){
				if (!finishDate || !startDate) {
					return false;
				}
				return startDate.valueOf() >= finishDate.valueOf();
			}else{
				return false
			}
    },

		start_time_set(checked){
			//처음에 section_set config에 입력하려다 했다가 밖으로 빼는 바람에 네이밍과 스코프가 꼬였음, use finish쪽도 마찬가지
			this.current_section_set.use_start_date = checked
		},	
		
		startDateChange(value, dateString){
			this.startDate = moment(dateString, this.date_format)
			this.current_section_set.show_start_date = dateString
			this.$store.state.modal.section.show_start_date = dateString
		},

		finish_time_set(checked){
			this.current_section_set.use_finish_date = checked
		},	

		finishDateChange(value, dateString){
			this.finishDate = moment(dateString, this.date_format)
			this.current_section_set.show_finish_date = dateString
			this.$store.state.modal.section.show_finish_date = dateString
		},

		//엘리먼트io 파일저장은 webpage.js에 있음
    onChange(value, dateString) {
			this.current_section_set.countdownDate = dateString
			this.current_section.event_date = dateString
			this.countdownDate = dateString
		},
		
    onOk(value) {
      // this.countdownDate = value
    },

		backimage_opt_change(checked){
			this.current_section_set.fix_backimage = checked
		},

		return_section_image(result){
			this.webpageNotice(result)
			if(result.result=="success"){
				this.$store.state.modal.section.file1 = result.data
				this.$store.state.modal.section.config.section_set.use_background = 2
				// this.$store.commit('set_ant_modal_visible', false)
				// setTimeout(function() {
				// 	$('#webpage_setting').modal('hide');
				// }, 500)
			}
		},

		image_delete(){
			if(confirm('정말 삭제할까요?')){
				this.axios().post(`/webpages/${this.page_type}/section_background/file1`, {id: this.current_id, file1: '' })
				.then(result=>{
					this.$store.state.modal.section.file1 = result.data.data
					this.webpageNotice(result.data)
				})
			}
		}
	},

  components: {
		ColorPicker,
		'a-switch': Switch,
		'a-date-picker': DatePicker
	}
  
}
</script>

<style scoped>
	.divider { margin: 10px 0 }
</style>

<style>
		.form-control:not(textarea) {
			height: 40px !important
		}
</style>

