<template>
	<div>
    <section :style="cur_section_background" >
      <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'" ></div>
      <div class="container"  style="z-index: 100" >
        <smarty-sectionheader style="margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
        <div class="container text-center">
          <div style="max-width:550px; margin:auto; m-top:60px;" :key='countKey' >
            <div :class="countdown_class" data-labels="년,월,주,일,시,분,초" :data-from="countdown_date" ref='countdw' >
            </div>
          </div>
        </div>
      </div>
		</section>
		<div>
			<slot name="setting"></slot>
		</div>
	</div>
</template>

<script>
import moment from 'moment'

export default {
	props: ['index','current_section'],
	data(){
		return {
      countKey: 1
		}
  },
  
  mounted(){
    this.item_div();
    this.count_down_css()
	},

	watch: {	
    current_section(val){
      return val
    },

	},
  
  computed:{

    countdown_date(){
      if(this.current_section.event_date==null)this.current_section.event_date = new Date()
      this.countKey+=1
      var self = this
      if(this.current_section.event_date!=null && this.current_section.event_date!=undefined)setTimeout(function(){ self.count_down = _countDown(); },1000);
      return this.current_section.event_date
    },

    countdown_class(){
      if(this.current_section.config.section_set.countdown_font_color == 1){
        $('.countdown-section').css('border-left-color','white')
        return "countdown countdown-lg countdown-color-white"
      }else{
        return "countdown countdown-lg countdown-color-dark"
      }
    },
  
    
		cur_section_background(){
      const grnd = this.section_background()
      const css = (!this.isEmpty(this.current_section.title) || !this.isEmpty(this.current_section.sub_title))? ';padding-top:40px;padding-bottom:60px;' :';padding-bottom:60px;'
      return `${grnd}${css}` 
    },
    
  },
		


	watch: {
    item_order(val){
			//이 코드 없으면 데이트 업데이트시 적용이 안됨
			this.item_div();
			return val
		}
  },
  
	methods: {	
    count_down_css(){
      console.log(this.$refs)
      // const sec = $('.countdown-section').css()

      // console.log(sec);

      // const el_id = `#countdown_${this.current_section.id}`
      // setTimeout(function(){
      //   $(el_id).find('.countdown-amount').css('color','white')
      //   $(el_id).find('.countdown-period').css('color','white').css('font-size: 12pt')
      // }, 1500)
    },
    
    
	},
	
	components: {

	}
}
</script>

<style>

.countdown-color-white .countdown-amount {
    display: block;
    font-size: 40pt;
    color: #fff;
    margin-bottom: 5px;
}

.countdown-color-white .countdown-period {
    color: #F1F1F2;
}

.countdown-color-dark .countdown-amount {
    display: block;
    font-size: 40pt;
    color: #333;
    margin-bottom: 5px;
}

.countdown-color-dark .countdown-period {
    color: #333;
}

</style>



<style scoped>


.overlay {
  background-color: rgba(0,0,0, 0.7);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
}


</style>

