<template>
  			<!-- WELCOME -->
	<div>
		<section class="pb-0" :style="section_background()+';padding-top: 50px' " >
			<div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
      <smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
			<div v-if="current_section.item_order.length > 0" style="margin-bottom: 50px" >
				<div class="container" style="z-index: 100" >
					<div class="row"  >
						<div  :class="outline_size(items[tmp])" v-for="(tmp, index) in current_section.item_order" :key='index'  style="margin-bottom:30px " >
							<div class="card card-default" :style="'background:'+outline_css(items[tmp])" v-if="outline(items[tmp])==2" >
								<div class="card-block">
										<writer-context v-bind:item="items[tmp]" v-bind:current_section="current_section" ></writer-context>
								</div>
							</div>
							<div v-else >
								<writer-context v-bind:item="items[tmp]"  v-bind:current_section="current_section" ></writer-context>
							</div> 
						</div>
					</div>
				</div>
			</div>

			<div class="container" v-else>
				<header class="text-center mb-40">
					<h1 class="fw-300">Various layouts</h1>
					<h2 class="fw-300 letter-spacing-1 fs-13"><span>THE MOST COMPLETE TEMPLATE EVER</span></h2>
				</header>
				<div class="text-center">
					<p class="lead">
						EasyHB has powerful options &amp; tools, unlimited designs, responsive framework and amazing support.
						We are dedicated to providing you with the best experience possible. Read below to find out why the sky's the limit when using Smarty.
					</p>
					<div class="mt-40 text-center">
						
						<div class="owl-carousel buttons-autohide controlls-over" data-plugin-options='{"items": 1, "autoPlay": true, "rewindSpeed":5000,  "autoHeight": false, "navigation": false, "pagination": true, "transitionStyle":"fadeUp", "progressBar":"false"}'>
							<div>
								<img class="img-fluid" src="/smart_webpage/demo_files/images/content_slider/21-min.jpg" alt="">
							</div>
							<div>
								<img class="img-fluid" src="/smart_webpage/demo_files/images/1200x800/10-min.jpg" alt="">
							</div>
						</div>
						
					</div>
				</div>
			</div>

	
		</section>
		<slot name="setting"  ></slot>
	</div>			
    
</template>


<script>
import WriterContext from './writer_type/writer01_context'

export default {
	props: ['index','current_section'],
	data(){
		return {
			re_order: [],
			item_id: 0,
		}
	},
		
	mounted(){
		this.item_div();
		//this.lightOrDark 함수는 mixin/common.js에 있음
	},


	watch: {
		item_order(val){
			//이 코드 없으면 데이트 업데이트시 적용이 안됨
			this.item_div();
			return val
		}
	},

	computed:{

		items(){
			return this.$store.state.items
		}
		
	},
	
	methods: {

	  outline(item){
      if(item.font_set.writer_set==undefined) item.font_set.writer_set = this.$store.state.default_writer_set;
			return item.font_set.writer_set.outline
		},

		outline_css(item){
			const outline = this.outline(item)
			return outline == 2 ? '#f9f9f9' : 'transparent'
		},

		outline_size(item){
      // if(this.outline(item)==2){
      //   return `col-md-${item.font_set.writer_set.image_size}`
      // }
			// return 'col-md-12'
			if(item.font_set.writer_set==undefined)return 'col-md-12'
			if(item.font_set.writer_set.write_width==undefined)return 'col-md-12'
      return `col-md-${item.font_set.writer_set.write_width}`
    }

	},

	components: {
		WriterContext
	}
}
</script>


<style scoped>
	p, span {
		line-height: 1.2em !important
	}

	@media only screen and (max-width: 768px){
		section div.row>div {
			margin-bottom: 0px;
		}
	}
</style>

<style >
	/* .controlls-over .owl-controls .owl-prev {
		display: none
	}
	.controlls-over .owl-controls .owl-next {
		display: none
	} */
</style>

