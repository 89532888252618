<template>
<div>
	<section :style="backgorund_css+';padding: 0'" :id='section_tag_id' >
    <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
    <div class="container" :style="container_css" >
      <div class="row">
        <img :src="newsletter_img" style="width:100%;position: relative" v-if="newsletter_img!=false" >
        <header class="text-center mb-40" style="position: absolute; margin-top:1.5vw; text-align:center;width:100%" >
          <h1  :style="section_title_style+';font-weight:bold;line-height: 1.1'+title_size" v-html="current_section.title"></h1>
          <h2  :style="section_sub_title_style+';font-weight:bold;line-height: 1'+subtitle_size" v-html="current_section.sub_title "></h2>
        </header> 
        
      </div>
    </div>
	</section>
	
	<slot name="setting"  ></slot>
</div>

</template>  


<script>

export default {
  props: ['index','current_section'],
  data(){
    return {
      fullWidth: document.documentElement.clientWidth,
    }
  },

  created(){
    this.$store.state.screenSize = this.fullWidth
  },
  
	ready: function () {
    window.addEventListener('resize', this.handleResize)
  },
  

  computed: {

    backgorund_css(){
      let layout_padding = design_config.layout == 'boxed' ? 'padding-left: 30px;padding-right:30px;' : 'padding-left: 0px;padding-right:0px;'
      if (this.current_section.config.section_set.use_background == 1 || this.current_section.config.section_set.use_background == 2) {
          return `${layout_padding}background-color:${ this.current_section.config.section_set.color }`
      }
    },

    container_css(){
      if(this.newsletter_img == false)return 'min-height:200px;z-index: 100'
      return 'z-index: 100'
    },

    title_size(){
      if (this.current_section.config.section_set.use_background == 2) {
        return ';font-size: 2.5vw'
      }else{
        return ';font-size: 30pt'
      }
    },

    subtitle_size(){
      if (this.current_section.config.section_set.use_background == 2) {
        return ';font-size: 1.5vw'
      }else{
        return ';font-size: 15pt'
      }
    },

    newsletter_img(){
      if (this.current_section.config.section_set.use_background == 2) {
          if (this.current_section.file1.origin != '') {
            return this.current_section.file1.origin
          }else{
            return false
          }
      }
      return false
    },
    
    item_class(){
      if(this.current_section.item_order.length < 3){
        return "col-md-6"
			}else{
        return "col-md-4"
			}
    },
    
    section_css(){
      if(!this.isEmpty(this.current_section.title) || !this.isEmpty(this.current_section.sub_title)){
        return ";padding: 20px 0;padding-bottom:30px"
      }else{
        return ";padding-bottom: 60px;padding-top:80px"
      }
    },

    section_set(){
      if(this.current_section.config!=undefined){
        return this.current_section.config.section_set
      }else{
        return {}
      }
    }
	
  },
  

	methods: {
    
    current_compo(type){
      if(type.config!=undefined){
        if(type.config.item_board_type==undefined || type.config.item_board_type==1 ){
          return 'board'
        }else{
          return 'megazine'
        }
      }else{
        return 'board'
      }
    },

    current_item(f){
			return this.$store.state.footer_item[f];
		}
	}

}
</script>


<style scoped>

@media only screen and (max-width: 1215px) {
  .container {
      width: 100%;
      max-width: 100%;

  }
}

html {
  font-size: 20px
}

</style>

