<template>
  			<!-- WELCOME -->
	<div>
		<section class="pb-0" :style="section_background()+';padding-top:70px;padding-bottom:30px'">
			<div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
      <smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
			<div v-if="re_order.length > 0">
				<div class="container" v-for="(tmp, index) in re_order" :key='index' style="z-index: 100"   >
          <div v-for="(i, idx) in tmp" :key='idx' :style="idx!=0 ? 'padding-top:30px' : ''">
						<header class="text-center mb-40">
							<h1 class="fw-300" :style="'cursor:pointer;color:'+ctem[i].font_set.title_color">
								<i :class="ctem[i].icon" :style="'color:'+ctem[i].font_set.icon_color"></i>
								{{ ctem[i].title }}
							</h1>
							<h2 class="fw-400 letter-spacing-1 fs-15" :style="'color:'+ctem[i].font_set.subtitle_color">{{ ctem[i].subtitle }}</h2>
						</header>
						<div >
							<p  :style="'overflow:auto;color:'+ctem[i].font_set.context_color" v-html="htmlDecode(ctem[i].context)" ></p>
							
							<template v-if="media_source_test(ctem[i].media)">
                <div  class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-html="ctem[i].media"></div>
              </template>
              <template v-else>
                <a href="javascript:void(0);" v-if="ctem[i].file1.large!=''"  >
                  <img class="card-img-top" :src="cur_file1_image(ctem[i])" :style="image_height" alt="">
                </a>
              </template>
						</div>
					</div>
				</div>
			</div>

			<div class="container" v-else>
				<header class="text-center mb-40">
					<h1 class="fw-300">Welcome to EasyHb!</h1>
					<h2 class="fw-300 letter-spacing-1 fs-13"><span>THE MOST COMPLETE TEMPLATE EVER</span></h2>
				</header>
				<div class="text-center">
					<p class="lead">
						EasyHB has powerful options &amp; tools, unlimited designs, responsive framework and amazing support.
						We are dedicated to providing you with the best experience possible. Read below to find out why the sky's the limit when using Smarty.
					</p>
					<div class="mt-40">
						<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/index/ipad.jpg" alt="welcome" />
					</div>
				</div>
			</div>

	
		</section>
		<slot name="setting"  ></slot>
	</div>			
    
</template>


<script>


export default {
	props: ['index','current_section'],
	data(){
		return {
			re_order: [],
		}
	},
		
	mounted(){
		this.item_div();
		//this.lightOrDark 함수는 mixin/common.js에 있음
		if(this.current_section.config.section_set.color!=undefined)this.background_color_test = this.lightOrDark(this.current_section.config.section_set.color)
	},
	
	computed: {
		
	},

	watch: {
		item_order(val){
			//이 코드 없으면 데이트 업데이트시 적용이 안됨
			this.item_div();
			return val
		}
	},
	
	methods: {

	}
}
</script>


<style scoped>
	
</style>

