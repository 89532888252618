<template>
  <div>
    <div class="table-responsive" >
      <div v-if="category_data.length > 1" style="margin-bottom: 20px">
        <el-select v-model="category" @change='search_cate' placeholder="분류검색">
          <el-option
            v-for="item in category_data"
            :key="item.id"
            :label="item.cate"
            :value="item.id"
            :disabled="item.disabled">
          </el-option>
        </el-select> 
      </div>    
      <table class="table" >
        <thead>
          <th style="text-align:right">번호</th>
          <th>제목</th>
          <th v-if="category_data.length > 1">분류</th>
          <th>글쓴이</th>
          <th>관리자메모</th>
          <th>작성일</th>
          <th>옵션</th>
        </thead>
        <tbody v-if="!object_empty_check(contactus)" >
          <tr v-for="(b, index) in contactus" :key="index">
            <td style="min-width: 70px;text-align:right">
              {{ boardItemNumber(index) }}
              <span class="mdl-badge mdl-badge--overlap" data-badge="N"  v-if="b.confirm==0" ></span>
            </td>
            <td @click="contact_view(b.id)" style="min-width:230px;cursor:pointer;">
              <img :src="'http:'+b.file1.thumb" v-if="b.file1.thumb!=''" style="height:40px" >
              <div>{{ b.subject }}</div>
              
            </td>
            <td v-if="category_data.length > 1">{{ get_category(b.category) }}</td>
            <td style="width: 80px">{{ b.name }}</td>
            <td style="min-width:150px;" v-html="showMemo(b.admin_memo, 50)" ></td>
            <td style="width: 80px;font-size:9pt">{{ dateFormat(b.created_at,'YY/MM/DD HH:mm') }}</td>
            <td style="width: 80px"><button class="btn btn-danger btn-sm" @click="contactus_delete(b.id)" ><i class="fa fa-trash"></i></button></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4">
              <div class="alert alert-danger">데이터가 없습니다.</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center" style="margin-bottom:50px">
        <el-pagination
          :page-size="per_page"
          :page-count="10"
          :current-page.sync="currentPage"
          @current-change="current_change"
          layout="prev, pager, next"
          :total="total_count">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['compo'],
  data(){
    return {
      currentPage: 1,
      search_word: '',
      category: 0
    }
  },
  
  computed:{
    current_section(){
      return this.$store.state.modal.section 
    },
    contactus:{
      get(){
        return this.$store.state.current_contactus.data
      }
    },
    total_count(){
      return this.$store.state.current_contactus.totalCount
    },
    per_page(){
      return this.$store.state.current_contactus.perPage
    },

    category_data(){
      let cate = [{id:0, cate: '전체검색'}]
      for( const c of this.current_section.config.category){
        cate.push(c)
      }
      return cate
    }
    
  },


  methods:{
    
    current_change(val){
      const params = this.params_data(val)
      this.axios().get(`/webpages/${this.page_type}/contactus_index/${this.current_section.id}${params}`)
      .then(result=>{
        if(result.data.result=="success"){
          this.$store.state.current_contactus = result.data.data
        }else{
          this.$store.state.current_contactus = {}
          this.webpageNotice(result.data)
        }
      })
    },

    boardItemNumber(index) {
      return this.total_count - this.per_page *(this.currentPage - 1) - index ;
    },

    search_cate(val){
      this.current_change(1)
    },

    get_category(cate_id){
      const cate_subject = this.category_data.filter(function(c){return c.id == cate_id});
      if(cate_subject.length==0 ) return "분류없음"
      if(cate_subject[0].cate=="전체검색" || cate_subject[0].cate == undefined ) return "분류없음"
      return cate_subject[0].cate
    },

    contact_view(id){
      this.$emit('view', id )
    },

    contactus_delete(id){
      if(confirm("정말 삭제하시겠습니까?")){
        const params = this.params_data(this.currentPage)
        this.axios().delete(`/webpages/${this.page_type}/contactus_delete/${this.current_section.id}/${id}${params}`)
        .then(result=>{
          if(result.data.result=="success"){
            this.$store.state.current_contactus = result.data.data
          }else{
            this.$store.state.current_contactus = {}
            this.webpageNotice(result.data)
          }
        });
      }
    },

    params_data(val){
      let contactus_search = `?current_page=${val}`
      if(this.category!=0) contactus_search += `&category=${this.category}` 
      contactus_search += this.isEmpty(this.search_word) ? '' : `&contactus_search=${this.search_word}`
      return contactus_search
    }

  }

}
</script>

<style scoped>

.btn>i {
    padding-right: 0px;
}

.mdl-badge[data-badge]:after{
  font-size:8px;
  width:15px;
  height:15px;
  background:#DA3A4A;
}

.mdl-badge.mdl-badge--overlap:after{
  right:-5px;top: -5px
}


</style>
