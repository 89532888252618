<template>
  <div id="wrapper">
    <top-bar v-if="admin_auth_check(2,{},'menu') && !isMobile "></top-bar>
    <Menubar v-if="homepage_type && $store.state.webpage_type!=3" ></Menubar>
    <MenuLogout v-if="$store.state.webpage_type==3 && $store.state.security_page && $route.name!='security_pages_login' && !$store.state.login " ></MenuLogout>
    <router-view></router-view>
    <footer01 v-if="footer_show" >
      <section-setting slot='setting' v-if="admin_auth_check(1)" ></section-setting>
    </footer01>
    <side-menu-bar ></side-menu-bar>

    <a href="#" id="toTop"></a>
    <side-set v-if="admin_auth_check(2) && (mainpage_check || subpage_check)"></side-set>
    <modal></modal>
  </div>
</template>

<script>

import TopBar from "./smarty_pages/components/topbar";
import Menubar from "./smarty_pages/components/menu";
import MenuLogout from "./smarty_pages/components/menu_security_logout_for_newsletter";
import InfoVar from "./smarty_pages/components/infobar01";
import Modal from "./smarty_pages/modal/modal";
import Footer01 from "./smarty_pages/components/footer01";
import SectionSetting from './smarty_pages/components/section_setting_footer'
import Test01 from "./smarty_pages/components/test01";
import Slide02 from "./smarty_pages/components/slide02";
import sideSet from "./smarty_pages/components/side_set";
import SideMenuBar from "./smarty_pages/components/menu_side";
import { isMobile } from 'mobile-device-detect';
import 'core-js/es7/array'

var jwt = require("jsonwebtoken");

export default {
  data(){
    return {
      isMobile: isMobile,
      footer_show: false
    }
  },

  components: {
    TopBar,
    Menubar,
    MenuLogout,
    InfoVar,
    Modal,
    Footer01,
    SectionSetting,
    Test01,
    Slide02,
    sideSet,
    SideMenuBar
  },

  created() {
    this.$store.state.webpage_type = webpage_type;
    this.$store.state.design_config = design_config
    this.$store.state.login = logincheck=='true'? true : false;
    if (this.$store.state.login)this.$store.state.section_format = this.current_page_section_format;
    if (logo_set.font_family == "") logo_set.font_family = "Noto Sans KR";
    this.$store.state.logo_set = logo_set;
    this.$store.state.logo_font_size = logo_set.font_size;
    this.$store.state.paid_type = paid_type;
    //except route 변경시 webpages/main 
    let except_route = ['search_page','webpage_signuppage','webpage_users','security_pages_login']
    if( except_route.includes(this.$route.name) ){
      //메뉴와 푸터만 가져 올수 있도록 하기 위해 -섹션등 쓸데 없는 코드 가져 오지 않도록
      this.$store.state.page_type = this.$route.name
    }else{
      //메인 서브 페이지 데이터 가져오기 - 샤플, 포탈형, 뭉글 모두 
      this.$store.state.page_type = page_type;
    }
    this.get_page_data()
  },

  mounted() {
    this.footer_homepage_type()
  },

  computed: {

    modal_key(){
      return 
    },
    
    mainComponent() {
      // return this.$route.name;
    },

    current_page_section_format(){
      let current_section = []
      if(Object.keys(section_format).length>0){
        if(section_format != {}){
          for(const s of section_format ){
            if(this.page_type=="mainpage"){
              if(s.mainpage_use) current_section.push(s)
            }else{
              if(s.subpage_use) current_section.push(s)
            }
          }
        }
      }
      return current_section
    },

    scrolled: {
      get() {
        return this.$store.state.scrolled;
      }
    }

  },

  methods: {
    auth_admin_check() {
      if (ownerCheck == "true") {
        this.axios()
        .get(`/webpages/get_authkey?token=${authToken}`)
        .then(result => {
          try {
            if(result.data.result=="success"){
              this.$store.state.authData = result.data.authkey.info;
              this.$store.state.authCheck = result.data.authkey.verify;
            }else{
              this.webpageNotice({result: 'error', type: 'error', msg: result.data.authkey.error});
              this.$store.state.authCheck = result.data.authkey.verify;
            }
          } catch (err) {
            this.$store.state.authCheck = false;
          }
        });
      }
    },
    
    footer_homepage_type(){
      //홈페이지 타입과 홈페이지 외의 기능성 페이지는 푸터섹션 감춤
      var self = this
      setTimeout(function(){
        self.footer_show = !['security_pages_login','webpage_users'].includes(self.$route.name)
      },1000)
    }

  }
};
</script>


<style>
body.boxed #wrapper {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>


