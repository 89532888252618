<template>
	<div id="wrapper" >
		<div id="topBar" v-if="mainpage_check || subpage_check" >
			<div class="container" style="padding-right:0px">
				<ul class="top-links list-inline float-right" >
					<li class="text-welcome" @click='webModal("#webpage_setting", "section-add-form", 2,{},1000)' v-if="admin_auth_check(1) && (mainpage_check || subpage_check)" >
						<i class="fa fa-plus"> 섹션추가</i>
					</li>
					<li class="text-welcome" @click='webModal("#webpage_setting", "setting-form", 1)' v-if="mainpage_check && option_auth_check(['webdesign','subadmin'])" >
						<i class="fa fa-gear"> 설정</i>
					</li>
					<li class="text-welcome" @click='webModal("#webpage_setting", "menu-board-set", 3)' v-if="mainpage_check && option_auth_check(['menunsubpage']) && webpage_type!=3 "  >
						<i class="fa fa-list-alt"> 메뉴및서브페이지</i>
					</li>
					<li class="text-welcome" @click='preview()'  >
						<i class="fa fa-eye" v-html="$store.state.preview ? ' 미리보기' : ' <b style=\'color:red\'>관리자보기</b>' " > </i>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>


<script>
import 'core-js/es7/array'

export default {
	components: {

	},
	created(){
		if(localStorage.preview==undefined){
			localStorage.setItem('preview','true')
			this.$store.state.preview = true
		}else{
			this.$store.state.preview = (localStorage.preview == 'true') ? true : false
		}
	},
	
	computed:{
	


	},

	methods:{
		preview(){
			this.$store.state.preview = !this.$store.state.preview
			localStorage.setItem('preview',this.$store.state.preview )
		}
	},

}
</script>


<style scoped>
	
	#topBar ul.top-links li.text-welcome {
		padding: 0 4px;
		cursor: pointer;
	}
	ul>li>i {
		margin-right: 0px
	}

</style>

