<template>
  <div>
    <div class="modal-header">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-if="false"><span aria-hidden="true">&times;</span></button>
      <h4 class="modal-title" id="myLargeModalLabel" style="width:85%">{{ title }}</h4>
    </div>
  </div>

</template>


<script>
export default {
  props: ['title'],
  mounted(){
    // console.log("modal-header-------------");
    // console.log(this.title);
  }
  
}
</script>