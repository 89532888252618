<template>
    <!-- wrapper -->
	<div>
		<!-- CALLOUT -->
			<section :id="section_tag_id"  :style="section_css">
        <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
				<div class="container" style="z-index: 100">
					<header class="text-center" v-if='!isEmpty(current_section.title) || !isEmpty(current_section.sub_title)' >
						<h1 class="fw-300 fs-40" :style="section_title_style" >{{ current_section.title }}</h1>
      			<h2 class="fw-300 letter-spacing-1 fs-13" ><span :style="section_sub_title_style">{{ current_section.sub_title }}</span></h2>
					</header>
					<header class="text-center" v-else >
						<h1 class="fw-300 fs-40">Contact Us</h1>
						<h2 class="fw-300 letter-spacing-1 fs-13"><span style="font-weight: bold">관리자와 연락할 수 있습니다.</span></h2>
					</header>
          <div style="margin-top:30px">
            <div class="row" style="margin-bottom:20px" v-if="!$store.state.login" >
              <div class="col-md-4">
                <label :style="section_sub_title_style">이름</label>
                <el-input placeholder="Name" v-model="contactus.name"></el-input>
                <font color="red">{{ error_msg.name }}</font>
              </div>
              
              <div class="col-md-4">
                <label :style="section_sub_title_style">이메일</label>
                <el-input placeholder="Email" v-model="contactus.email"></el-input>
                <font color="red">{{ error_msg.email }}</font>
              </div>
              
              <div class="col-md-4">
                <label :style="section_sub_title_style">휴대폰</label>
                <el-input placeholder="Phone" v-model="contactus.phone"></el-input>
              </div>
            </div>

            <div class="row" style="margin-bottom:20px">
              <div class="col-md-8">
                <label :style="section_sub_title_style">제목</label>
                <el-input placeholder="subject" v-model="contactus.subject"></el-input>
                <font color="red">{{ error_msg.subject }}</font>
              </div>
              
              <div class="col-md-4" v-if='category.length > 0' >
                <label :style="section_sub_title_style">분류선택</label>
                <el-select v-model="contactus.category" placeholder="Select">
                  <el-option
                    v-for="item in category"
                    :key="item.id"
                    :label="item.cate"
                    :value="item.id"
                    :disabled="item.disabled">
                  </el-option>
                </el-select>
              </div>
            </div>
        
            <div class="row">
              <div class="col-12">
                <label :style="section_sub_title_style">내용</label>
                <textarea class="form-control" v-model="contactus.context" rows="6" style=""></textarea>
              </div>
              <div class="col-md-6">
                <div class="fancy-file-upload" style="margin-top:20px;background:white">
                  <i class="fa fa-upload"></i>
                  <input type="file" class="form-control"  id="file1" ref="file1" name="file1"  v-on:change="handleFileUpload('file1')" />
                  <input type="text" class="form-control"  ref="file1_1" placeholder="no file selected" readonly="" />
                  <span class="button">파일선택</span>
                </div>
                <span style="font-size:9pt">이미지 파일만 가능합니다.( jpg, png, gif )</span>
              </div>
            </div> 

            
            <hr> 
            <div class="text-right">
              <button class="btn btn-primary" @click="contactus_create()" >작성하기</button>
            </div>

          </div>

				</div>
			</section>
      <slot name="setting"></slot>
			<!-- /CALLOUT -->
	    
  </div>
    
</template>


<script>
import 'core-js/es7/array'
import { Loading } from 'element-ui';

export default {
	props: ['index','current_section'],
	data(){
    return {
      contactus: {},
      file1: '',
      error_msg: { },
      options: [{
          value: 'Option1',
          label: 'Option1'
        }, {
          value: 'Option2',
          label: 'Option2',
        }, {
          value: 'Option3',
          label: 'Option3'
        }, {
          value: 'Option4',
          label: 'Option4'
        }, {
          value: 'Option5',
          label: 'Option5'
        }],
    }
  },
  mounted(){
    this.contactus = this.contactus_data
    if(this.category.length>0)this.contactus.category = this.category[0].id 
  },
  
	computed: {
    contactus_data(){
      return {
        name: '',
        email: '',
        phone: '',
        category: '',
        subject: '',
        context: '',
        file1: '',
        file2: '',
      }
    },

    section_css(){
      let padding_css = ';padding: 50px 0px'
      if(this.$store.state.design_config.layout=="boxed") padding_css = ';padding: 50px 100px'
      return this.section_background() + padding_css
    },

    category(){
      return this.current_section.config.category
    },

  },

  methods: {

    handleFileUpload(file){
      var file_item = this.$refs[file]
      jQuery(file_item).next('input').val(file_item.value)
      this[file] = this.$refs[file].files[0]
    },
  
    contactus_create(){
      let formData = new FormData();
      const section_id = this.current_section.id
      formData.append('data',JSON.stringify(this.contactus));
      
      let result = true
      if(!this.object_empty_check(this.file1)){
        result = this.before_upload(this.file1)
        if(result)formData.append('file1', this.file1)
      }
      if(result){
        if(!this.object_empty_check(this.file2)){
          result = this.before_upload(this.file2)
          if(result)formData.append('file2', this.file2)
        }
      }
      if(result && this.create_validate()){
        let loadingInstance = Loading.service({text:'등록 중입니다...'});
        this.axios().post(`/webpages/${this.page_type}/contactus_create/${section_id}`, formData, { headers: {'Content-Type': 'multipart/form-data'} })
        .then(result=>{
          loadingInstance.close();
          if(result.data.result=="success"){
            alert("성공적으로 등록되었습니다.");
          }else{
            this.webpageNotice(result.data)
          }
          for (let [key, value] of Object.entries(this.contactus)) {
            this.contactus[key] = ''
          }
          if(this.category.length>0)this.contactus.category = this.category[0].id 
          this.$refs.file1_1.value = ''
          this.file1 = ''
        });
      }
      
    },

    create_validate(){
      let result = []
      let error = []
      this.error_msg = { name:'', email:'', subject:'' }
      if(!this.$store.state.login){
        const email_reg = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        if(this.isEmpty(this.contactus.name)){
          result.push(false);
          this.error_msg.name='이름은 필수입니다.';
        }
        if(this.isEmpty(this.contactus.email)){
          result.push(false);
          this.error_msg.email='이메일은 필수 입니다.';
        }
        if(!this.isEmpty(this.contactus.email) && !this.contactus.email.match(email_reg)){
          result.push(false);
          this.error_msg.email='이메일 형식이 아닙니다.';
        }
      }
      if(this.isEmpty(this.contactus.subject)){
        result.push(false);this.error_msg.subject='제목은 필수 입니다.';
      }
      return !result.includes(false)
    }
  
  }

}
</script>


<style>
/* .heading-title.heading-arrow-bottom:after{
	border-top-color: red
} */

@media only screen and (max-width: 768px){
  section div.row>div {
      margin-bottom: 20px;
  }
}
</style>

