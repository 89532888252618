<template>
  <div>
    <section style="background-color:#CCCCCC; padding: 0px;margin-bottom:100px" v-if="$store.state.preview && ownerCheck">
      <div class="container" >
        
          <ul class="list-inline float-right" style="margin-bottom:0px">
            <li class="badge badge-danger" v-if="current_section.hidden == 1"  @click='section_show_hidden(0)' >
                <i class="fa fa-eye-slash"><font class='hidden-xs-down'> 숨김상태</font></i>
            </li>
            <li class="badge badge-warning" v-else  @click='section_show_hidden(1)' >
                <i class="fa fa-eye"><font class='hidden-xs-down'> 보임상태</font></i>
            </li>
            <li class="badge badge-primary" v-if='mainpage'  @click='footModal()' >
              <i class="fa fa-gear"><font class='hidden-xs-down'>  섹션설정</font></i>
            </li>

          </ul>
          
        </div>
    </section>

    		<a-modal v-model="visible" :maskClosable="false" :footer="null"  width="800px" style="padding:0">

          <div class="modal-header">
            <h4 class="modal-title" id="myLargeModalLabel" style="width:85%">바닥글 셋팅</h4>
          </div>

          <div class="modal-body" style="padding:0px">
            <div :style="(screen_size < 1000) ? 'padding: 5px' : 'padding:20px' ">
              <ul class="nav nav-tabs">
                <li class="nav-item"><a class="nav-link active "  data-toggle="tab" @click="component='item'"  >아이템설정</a></li>
                <li class="nav-item"><a class="nav-link" data-toggle="tab" @click="component='section-set'" >섹션설정</a></li>
              </ul>
              <component :is="component" :current_section='current_section' :visible.sync="visible" ></component>
            </div>
          </div>
          
   
      </a-modal>  

  </div>
</template>


<script>
// import $ from 'jquery';
import Vue from 'vue'
import Item from '../modal/footer_set/item_set'
import SectionSet from '../modal/footer_set/section_set'
import { Modal } from 'ant-design-vue';
Vue.directive('ant-portal', Modal)

export default {
  data(){
    return {
      component: 'item',
      visible: false,
    }
  },

  computed:{

    current_section(){
      return this.$store.state.footer_section 
    },

    section_name(){
      var sname = ''
      var component = this.section_row.component
      for(var i=0; i <= (this.section_format.length - 1);i++ ){
        if(this.section_format[i].component == component){
          sname = this.section_format[i].name
        }
      } 
      return sname
    },
    
    mainpage(){
      return this.$store.state.page_type == 'mainpage'
    }

  },

	methods: {
  
    section_show_hidden(val) {
      if (confirm('상태를 변경하시겠습니까?')) {
        this.axios().put(`/webpages/${this.page_type}/section_update/${this.current_section.id}`, {
                type: 'section_hidden',
                hidden: val
            })
            .then(result => {
                this.webpageNotice(result.data)
                if (result.data.result == 'success') {
                    this.current_section.hidden = val
                }
            })

      }
    },

    footModal(){
      if(this.mainpage){
        this.visible = true
      }else{
        alert('바닥글 설정은 메인페이지에서만 가능합니다.')
      }

    }

  },

  components: {
    Item,
    SectionSet,
    'a-modal': Modal,
  }
  

}
</script>


<style scoped>
  .badge{
    cursor: pointer
  }
  i {
    margin-right: 0px
  }
</style>

