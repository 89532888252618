<template>
  <div>
    
    <div class="card card-default" style="margin-top:20px">
      <div class="card-block">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary btn-sm" @click="new_cate()" >추가</button >
          </div>
          <div class="col-sm-6" v-if="mode=='create' || mode=='update' " >
            <label > 분류명 (15개까지 추가 가능) </label>
            <input type="text" class="form-control"  placeholder="Category Name" v-model="current_category.cate" style="height: 35px !important" >
          </div>
          
        </div>
        <div  v-if="mode=='create' || mode=='update'" >
          <div class="divider" style="margin: 0px 0"></div>
          <div class="text-right">
            <div >
              <span class="btn btn-sm btn-primary"   @click="mode=''" >취소</span>
              <span class="btn btn-sm btn-danger"    @click="update_cate(1)" v-if="mode=='create'" >추가</span>
              <span class="btn btn-sm btn-success"   @click="update_cate(2)" v-else-if="mode=='update'" >수정</span>
            </div>
          </div>
        </div>
        
        <div v-if="current_categories.length > 0" >
          <table class="table" style="margin-top:20px" >
            <thead>
              <tr>
                <th>번호</th>
                <th>분류명</th>
                <th>옵션</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c,index) in current_categories" :key='index'>
                <td>{{ index + 1 }}</td>
                <td>{{ c.cate }} </td>
                <td>
                  <button class="btn btn-success btn-sm" @click="edit_cate(c)" ><i class="fa fa-edit"></i></button>
                  <button class="btn btn-danger btn-sm"  @click="update_cate(3,c.id)" ><i class="fa fa-trash"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <div class="alert alert-danger" style="margin-top:20px">
            설정되어 있는 분류명이 없습니다. 추가를 클릭하면 분류명을 설정할 수 있습니다.
          </div>
        </div>
      </div>
    </div> 

  </div>
</template>

<script>
export default {
  props: ['contactus_id'],
  data(){
    return{
      mode: '',
      current_category: { id: 1, cate: '' },
      current_categories: [],
    }
  },

  
  mounted(){
    this.current_categories = this.current_section.config.category
  },


  watch: {
    current_section_id(val){
      this.current_categories = this.current_section.config.category
    }
  },

  computed: {
    
    current_section_id(){
      return this.$store.state.modal.section.current_section_id
    },

    categories:{
      get(){
        const cate = this.current_section.config.category
        return cate
      },

      set(val){
        this.current_section.config.category = val
      }
    },

    current_section:{
      get(){
        return this.$store.state.modal.section
      }
    },

    current_section_id:{
      get(){
        return this.$store.state.modal.section.id
      }
    }
    
  },

  methods: {

    new_cate(){
      this.mode = this.mode=='create' ? '' : 'create'
      this.current_category.cate = ''
      this.current_category.id = 0
    },

    edit_cate(c){
      this.mode = 'update'
      this.current_category.id = c.id
      this.current_category.cate = c.cate
    },
   
    update_cate(m, id=0){
      // let category = this.current_categories
      let cate = { id: '0', cate: ''}
      let pass = true
      if(m==1){
        if(this.current_categories.length > 0){
          let keys = this.current_categories.map(function(c){return c.id}).sort(function(a,b){ return b-a })[0]
          const id = parseInt(keys)+1
          cate.id = id
          cate.cate = this.current_category.cate
        }else{
          cate.id = '1'
          cate.cate = this.current_category.cate
        }
        this.current_categories.push(cate)
      }else if(m==2){
        let cate = this.current_categories.filter(c => {return c.id == this.current_category.id})[0]
        cate.cate = this.current_category.cate
      }else if(m==3){
        const idx = this.current_categories.findIndex(function(item) {return item.id === id})
        this.current_categories.splice(idx,1)
        pass = confirm("정말 삭제하시겠습니까?")
      }
      
      if(this.current_categories.length < 15){
        if(pass){
          this.axios().put(`/webpages/${this.page_type}/contactus_category/${this.$store.state.modal.section.id}`, { data: this.current_categories})
          .then(result=>{
            this.webpageNotice(result.data)
            if(result.data.result=="error"){
              this.current_categories = this.current_section.config.category
            }else{
              this.current_category.cate = ''
              if(m==2){
                this.mode = ''
              }
            }
          })
        }
      }else{
        this.webpageNotice({ type: 'error', msg: '분류명은 15개까지 추가 가능합니다.' })
      }

    }


  }

}
</script>

<style>

</style>
