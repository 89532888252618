<template>
	<div>
   	<section class="page-header">
				<div class="container">
          <div class="row">
						<div class="col-md-4"></div>
						<div class="col-md-4">
							<el-input
								placeholder="Search"
								v-model="search_word"
								class="input-with-select"
								style="width:100%"
								size="medium"
								@keyup.enter.native="search_page()"
							>
								<el-button slot="append" icon="el-icon-search" @click="search_page()" ></el-button>
          		</el-input>
						</div>
					</div>

				</div>
			</section>

	  <section  style="min-height:600px;padding: 30px 20px 0 20px;" >
			<div class="container" >
				<div class="row margin-bottom-40" >
					<div style="width: 100%;padding-bottom:10px;" v-for="v of search_data.mainitem" :key='v.id'>
						<div style="font-weight: bold;cursor:pointer" @click="search_link('main', v.id)" >{{ v.title }} {{ !isEmpty(v.subtitle) ? " - " + v.subtitle : ''  }}</div>
						<div @click="search_link('main', v.id)" style="cursor:pointer">{{ v.context }} </div>
						<cite style="font-size:8pt">{{ dateFormat(v.created_at ,'YYYY/M/DD')  }} </cite>
						<div class="divider" style="margin: 10px 0 0 0"><!-- divider --></div>
					</div>
				
					<div style="width: 100%;padding-bottom:10px;" v-for="v of search_data.subitem" :key='v.id'>
						<div style="font-weight: bold;cursor:pointer" @click="search_link('sub', v.id)">{{ v.title }} {{ !isEmpty(v.subtitle) ? " - " + v.subtitle : ''  }}</div>
						<div @click="search_link('sub', v.id)" style="cursor:pointer">{{ v.context }} </div>
						<cite style="font-size:8pt">{{ dateFormat(v.created_at ,'YYYY/M/DD')  }} </cite>
						<div class="divider" style="margin: 10px 0"><!-- divider --></div>
					</div>
				
					<div style="width: 100%;padding-bottom:10px;" v-for="v of search_data.boarditem" :key='v.id'>
						<div style="font-weight: bold;cursor:pointer" @click="search_link('board', v.id)">{{ v.title }} {{ !isEmpty(v.subtitle) ? " - " + v.subtitle : ''  }}</div>
						<div @click="search_link('board', v.id)" style="cursor:pointer">{{ v.context }} </div>
						<cite style="font-size:8pt">{{ dateFormat(v.created_at ,'YYYY/M/DD')  }} </cite>
						<div class="divider" style="margin: 10px 0"><!-- divider --></div>
					</div>
				</div>

				<div style="text-align:center;margin: 30px 0">
					<el-pagination
						layout="prev, pager, next"
						:page-size="page_info.per_page"
						:total="page_info.total_count"
						:current-page.sync="current_page"
						@current-change = "pageChange"
						>
					</el-pagination>
				</div>
			</div>
		</section>
	</div>			
    
</template>


<script>
import {scroller} from 'vue-scrollto/src/scrollTo'

export default {

	data(){
		return {
			old_search_word: '',
			search_word: '',
			search_time: '',
		}
	},

	mounted(){
		this.search_word = this.$route.query.search_word
		this.old_search_word = this.$route.query.search_word
		this.search_time = new Date
		this.$store.state.page_type = 'search_page'
		this.current_page = Number(this.$route.query.current_page)
    this.get_search_page_data(this.$route.query.current_page)
		setTimeout(function(){
				const firstScrollTo = scroller()
				const options = {
					container: 'body',
					easing: 'ease-in',
					offset: -80,
				}
				firstScrollTo('body',1000,options);
		},500)
	},

	computed: {
		search_data(){
			return this.$store.state.search_data;
		},
		page_info(){
			return this.search_data.page_info
		},
		current_page: {
			get(){
				return Number(this.page_info.current_page)
			},
			set(val){
				this.page_info.current_page = val
			}
		}
	},
		
	methods: {
		search_page(){
			// 5초 내에는 같은 단어를 검색하지 않도록 한다. 검색 공격을 막기 위해
      if((new Date - this.search_time) > 5000 ){
				this.search_page_post(1);
			}else{
				if(this.old_search_word != this.search_word)this.search_page_post(1);
			}
		},	

    search_page_post(current_page){
			this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}/search_page?current_page=${current_page}&search_word=${this.search_word}`)
			this.get_search_page_data(current_page) 
			this.old_search_word = this.search_word;
			this.search_time = new Date
		},

		pageChange(val){
			this.current_page = val
			this.get_search_page_data(val);
		}

	}


}
</script>


