<template>


				<div class="container">

					<header class="text-center mb-60">
						<h1 class="fw-300">Speaker Introduction</h1>
						<h2 class="fw-300 letter-spacing-1 fs-13"><span>WE TRULY CARE ABOUT OUR CUSTOMERS</span></h2>
					</header>

					<div class="row">
					
						<!-- item -->
						<div class="col-md-3 col-sm-6">

							<div class="box-flip box-color box-icon box-icon-center box-icon-round box-icon-large text-center">
								<div class="front">
									<div class="box1 box-default">
										<div class="box-icon-title">
											<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/people/460x700/7-min.jpg" style="height: 340px" alt="" />
											<h2>Felica Doe</h2>
											<small>CEO</small>
										</div>
									</div>
								</div>

								<div class="back">
									<div class="box2 box-default">
										<h4 class="m-0">Felica Doe</h4>
										<small>CEO</small>

										<hr />

										<p>Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere</p>

										<hr />

										<a href="#" class="social-icon social-icon-sm social-facebook">
											<i class="fa fa-facebook"></i>
											<i class="fa fa-facebook"></i>
										</a>
										<a href="#" class="social-icon social-icon-sm social-twitter">
											<i class="fa fa-twitter"></i>
											<i class="fa fa-twitter"></i>
										</a>
										<a href="#" class="social-icon social-icon-sm social-linkedin">
											<i class="fa fa-linkedin"></i>
											<i class="fa fa-linkedin"></i>
										</a>
									</div>
								</div>
							</div>

						</div>
						<!-- /item -->

						<!-- item -->
						<div class="col-md-3 col-sm-6">

							<div class="box-flip box-color box-icon box-icon-center box-icon-round box-icon-large text-center">
								<div class="front">
									<div class="box1 box-default">
										<div class="box-icon-title">
											<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/people/460x700/12-min.jpg" alt="" />
											<h2>Joana Doe</h2>
											<small>Art Director</small>
										</div>
									</div>
								</div>

								<div class="back">
									<div class="box2 box-default">
										<h4 class="m-0">Joana Doe</h4>
										<small>Art Director</small>

										<hr />

										<p>Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere</p>

										<hr />

										<a href="#" class="social-icon social-icon-sm social-facebook">
											<i class="fa fa-facebook"></i>
											<i class="fa fa-facebook"></i>
										</a>
										<a href="#" class="social-icon social-icon-sm social-twitter">
											<i class="fa fa-twitter"></i>
											<i class="fa fa-twitter"></i>
										</a>
										<a href="#" class="social-icon social-icon-sm social-linkedin">
											<i class="fa fa-linkedin"></i>
											<i class="fa fa-linkedin"></i>
										</a>
									</div>
								</div>
							</div>

						</div>
						<!-- /item -->

						<!-- item -->
						<div class="col-md-3 col-sm-6">

							<div class="box-flip box-color box-icon box-icon-center box-icon-round box-icon-large text-center">
								<div class="front">
									<div class="box1 box-default">
										<div class="box-icon-title">
											<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/people/460x700/10-min.jpg" alt="" />
											<h2>Melissa Doe</h2>
											<small>Web Developer</small>
										</div>
									</div>
								</div>

								<div class="back">
									<div class="box2 box-default">
										<h4 class="m-0">Melissa Doe</h4>
										<small>Web Developer</small>

										<hr />

										<p>Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere</p>

										<hr />

										<a href="#" class="social-icon social-icon-sm social-facebook">
											<i class="fa fa-facebook"></i>
											<i class="fa fa-facebook"></i>
										</a>
										<a href="#" class="social-icon social-icon-sm social-twitter">
											<i class="fa fa-twitter"></i>
											<i class="fa fa-twitter"></i>
										</a>
										<a href="#" class="social-icon social-icon-sm social-linkedin">
											<i class="fa fa-linkedin"></i>
											<i class="fa fa-linkedin"></i>
										</a>
									</div>
								</div>
							</div>

						</div>
						<!-- /item -->

						<!-- item -->
						<div class="col-md-3 col-sm-6">

							<div class="box-flip box-color box-icon box-icon-center box-icon-round box-icon-large text-center">
								<div class="front">
									<div class="box1 box-default">
										<div class="box-icon-title">
											<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/people/460x700/8-min.jpg" alt="" />
											<h2>Jessica Doe</h2>
											<small>Manager</small>
										</div>
									</div>
								</div>

								<div class="back">
									<div class="box2 box-default">
										<h4 class="m-0">Jessica Doe</h4>
										<small>Manager</small>

										<hr />

										<p>Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere</p>

										<hr />

										<a href="#" class="social-icon social-icon-sm social-facebook">
											<i class="fa fa-facebook"></i>
											<i class="fa fa-facebook"></i>
										</a>
										<a href="#" class="social-icon social-icon-sm social-twitter">
											<i class="fa fa-twitter"></i>
											<i class="fa fa-twitter"></i>
										</a>
										<a href="#" class="social-icon social-icon-sm social-linkedin">
											<i class="fa fa-linkedin"></i>
											<i class="fa fa-linkedin"></i>
										</a>
									</div>
								</div>
							</div>

						</div>
						<!-- /item -->

					</div>

					
				</div>

</template>


<script>
export default {

  
}
</script>

<style>

</style>

