<template>
	<div>
	<section :id="section_tag_id+' testimonials' " class="alternate" :style="cur_background()" >
    <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
		<div class="container" style="z-index: 100" >
      <smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>

			<ul class="row clients-dotted list-inline" v-if="current_section.item_order.length > 0">
				<li :class="item_view_class" v-for="(i, idx) in current_section.item_order" :key='idx'>
					<div class="testimonial" style="padding:0 20px;text-align:left">
						<figure class="float-left" v-if="!object_empty_check(ctem[i].file1.thumb)">
							<img  :class="image_round" :src="ctem[i].file1.thumb" style="width: 100px; height: 100px" alt="" />
						</figure>
						<div class="testimonial-content" :style="`padding-left: ${!object_empty_check(ctem[i].file1.thumb) ? 120 : 0}px`">
							<cite  >
								<span :style="'font-size: 1.3em;font-weight: 600;color:'+ctem[i].font_set.title_color">{{ ctem[i].title }}</span> 
								<span :style="'font-weight:400;font-size:9pt;margin-bottom:0px;color:'+ctem[i].font_set.subtitle_color">{{ ctem[i].subtitle}}</span>
							</cite>
							<div class="embed-responsive embed-responsive-16by9 block margin-bottom-60"  v-html="ctem[i].media" v-if="media_source_test(ctem[i].media)" ></div>
							<div :style="profile_css(ctem[i])+';padding-top:5px;color:'+ctem[i].font_set.icon_color" v-html="showAllMemo(ctem[i].media)" v-else ></div>
							<div :style="context_css(ctem[i])+';color:'+ctem[i].font_set.context_color" v-html="htmlDecode(ctem[i].context)"></div>
						</div>
					</div>
				</li>
			</ul> 

		 	<default v-else></default>
		</div>
	</section>
	
  <slot name="setting"></slot>
		
  </div>
    
</template>


<script>
import Default from './default/introduce02_default'


export default {
		props: ['index','current_section'],

	mounted(){
		_misc(); //뒤집어지는 소개 섹션 다시 로더 
	},
	components: {
		Default,
	},

	computed: {
		item_count_css(){
			if(this.current_section.item_order.length > 3){
				return "col-md-3 col-sm-6"
			}else{
				return "col-md-4 col-sm-6"
			}
		}
	},
	methods:{
		profile_css(item){
			if(!this.media_source_test(item.media) && !this.isEmpty(item.context)){
				return 'width: 100%;padding-top:20px;padding-bottom:5px;font-size:0.9em;border-bottom: 1px solid #d1d1d1'
			}else if(!this.media_source_test(item.media) && this.isEmpty(item.context)){
				return 'mairgin-top:10px;width:100%;padding-top:10px;padding-bottom:5px;font-size:0.9em;border-top: 1px solid #d1d1d1'
			}else{
				return 'padding-top:20px;padding-bottom:5px;font-size:0.9em'
			}
		},

			
		cur_background(){
			let cssp = this.section_background()
			cssp += (!this.isEmpty(this.current_section.title) || !this.isEmpty(this.current_section.sub_title))? ';padding-top:0px;' :''
			return cssp
		},
		

		context_css(item){
			if(this.media_source_test(item.media) && !this.isEmpty(item.context)){
				return 'margin-top:15px;padding-top:10px;border-top: 1px solid #d1d1d1'
			}else {
				return 'padding-top:20px;'
			}
			
		}
	}
}
</script>


<style>

</style>

