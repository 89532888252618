<template>
<div class="container">
  <template v-if="current_section.title!='empty'">
    <header class="text-center" v-if='use_header' style="padding-bottom: 10px;padding-top:30px" >
      <h2 class="fw-300" :style="section_title_style" v-html="current_section.title"></h2>
      <h2 class="fw-300 letter-spacing-1 fs-15">
        <span :style="section_sub_title_style+';font-weight:bold'" v-html="current_section.sub_title" ></span>
      </h2>
      <div style="text-align:center;padding-left:15%" >
        <hr class="under_line" ref="underline" :style="css">
      </div>
    </header> 
  </template>
  <template v-else></template>
</div>
</template>

<script>
export default {
  props:['current_section'],
  data(){
    return {
      css: this.white_line,
      white_line: "background:linear-gradient(90deg, rgba(255,254,254,0) 0%, rgba(126,125,125,0.7) 50%, rgba(254,254,254,0) 100%);",
      grey_line: "background: linear-gradient(90deg, rgba(99,99,99,0) 0%, rgba(228,228,228,1) 50%, rgba(99,99,99,0) 100%);"
    }
  },

  mounted(){
    if(this.current_section.component=='linebar01' && this.current_section.config.section_set.use_background==0 ){
      this.css = this.white_line;
    }else{
      if(this.current_section.config.section_set.use_background==0){
        this.css = this.grey_line;
      }else{
        this.css = this.lightOrDark(this.current_section.config.section_set.color) > 185 ? this.white_line : this.grey_line

      }
    }
   
  }

}
</script>

<style scoped>
  .under_line {
    border: 0;
    height: 1px;
    max-width: 80%;
    text-align:center;
  }

</style>