<template>
  <div>
      <h4 class="letter-spacing-1" :style="'color:'+fontColor+';'+title_style(current_item.subtitle)" v-if="current_item.title!=''">{{ current_item.title }}</h4>
      <h6  v-if="current_item.subtitle!=''" :style="'color:'+fontColor">{{ current_item.subtitle }}</h6>

      <div v-html="htmlDecode(current_item.context)" :style="'text-align:justify;color:'+fontColor" > </div>
      <img class="footer-logo" :src="current_item.file1.origin" :style="'margin-bottom:10px;'+ imagesize(current_item.config.logo_horizen_size) " v-if="current_item.file1.origin!=''" />
      
  </div>


</template>

<script>
export default {
  props: ['current_item','fontColor'],

  methods: {
    title_style(subtitle){
      if(subtitle!=''){
        return 'margin-bottom: 0px'
      }
    },
    imagesize(size){
      if(size==1){
        return 'width:100%;'
      }else{
        return `height: ${size}px;`
      }
    }
  }  

}
</script>

<style>

</style>
