<template>
	<div>
    <section :style="cur_section_background" >
      <div class="overlay" v-if="use_background==2 || use_background==0 " :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
      <smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>

      <div class="container"  v-if="item_order.length > 0" style="z-index: 100" >
        <div class="row" >
            <div :class="item_view_class" v-for="(i, idx) in item_order" :key='idx' >
              <div class="card" >
                <div class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-html="ctem[i].media" v-if="media_source_test(ctem[i].media)" ></div>
                <img class="card-img-top" :src="cur_file1_image(i)"  v-else>

                <div class="card-block" :style="is_title(ctem[i])">
                  <a href="javascript:void(0);" class="fs-20 block" v-if="!isEmpty(ctem[i].title)" :style="'cursor:pointer;color:'+ctem[i].font_set.title_color" @click="link_button(ctem[i].link1)" >
                    <i :class="ctem[i].icon" :style="'color:'+ctem[i].font_set.icon_color"></i>
                    {{ctem[i].title}}
                  </a>
                  <h2 class="fw-500 fs-13" style="margin-bottom: 0px;" v-if="!isEmpty(ctem[i].subtitle)" :style="'cursor:pointer;color:'+ctem[i].font_set.subtitle_color" >{{ctem[i].subtitle}}</h2>
                  <div :style="'color:'+ctem[i].font_set.context_color" v-html="htmlDecode(ctem[i].context)" ></div>
                  <button :class="'btn btn-sm btn-'+ctem[i].config.link1.color" @click='link_button(ctem[i].link1)' style='margin-right:10px;margin-top:10px' v-if="ctem[i].config.link1.name!=''" >{{ ctem[i].config.link1.name }}</button>
                  <button :class="'btn btn-sm btn-'+ctem[i].config.link2.color" @click='link_button(ctem[i].link2)' style='margin-top:10px' v-if="ctem[i].config.link2.name!=''"  >{{ ctem[i].config.link2.name }}</button>
                </div>

              </div>
          </div>
        </div>
				</div>
				
				<div class="container" style="z-index: 100" v-else >
					<img src="/images/video01.png">
        </div>  
		</section>
		<div>
			<slot name="setting"  ></slot>
		</div>
	</div>
    
</template>


<script>


export default {
	props: ['index','current_section'],
	data(){
		return {
			re_order: [],
			outerVisible: false,
			currentItem: {},
			image1:'',
			image2:'',
			context_popup: null,
		}
	},
	
	watch: {
		item_order(val){
			return val
    },
  },
  
  computed:{
    
		cur_section_background(){
      let grnd = ";background-image:url('/images/background/section_back.jpg');background-color:rgba(0,0,0,0.8)"
      if(this.use_background!=0)grnd = this.section_background()
      const css = (!this.isEmpty(this.current_section.title) || !this.isEmpty(this.current_section.sub_title))? ';padding-top:70px;padding-bottom:40px;' :';padding-bottom:40px;'
      return `${grnd}${css}` 
    },
		
		item_order(){
      const section = this.$store.state.sections.filter(s=>s.id==this.current_section.id)[0]
			return section.item_order
    },
    
		
  },
		
	mounted(){
    this.item_div();
		//this.lightOrDark 함수는 mixin/common.js에 있음
		this.background_color_test = this.lightOrDark(this.current_section.config.section_set.color)
	},

	watch: {
    item_order(val){
			//이 코드 없으면 데이트 업데이트시 적용이 안됨
			this.item_div();
			return val
		}
  },
  
	methods: {	
    cur_file1_image(i){
      if(this.ctem[i]==undefined)return false 
      const item = this.ctem[i]
      if(item.file1.large==''){
        return this.no_image
      }else{
        return item.file1.large
      }
    },

    is_title(item){
      if(this.isEmpty(item.title) && this.isEmpty(item.subtitle) && this.isEmpty(item.context)) return ';padding: 0 !important'
      return ''
    }
	},
	
	components: {

	}
}
</script>


<style scoped>


.overlay {
  background-color: rgba(0,0,0, 0.7);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
}


</style>

