<template>
  
  <div id="header" :class="menu_class()" >

    <!-- TOP NAV -->
    <header id="topNav">
      <div class="container" style="padding:0px" >
        <ul class="float-right nav nav-pills nav-second-main"  >
          <li >
            <a href="javascript:;" title="보안페이지 로그아웃" @click="security_page_logout()"  >
              <i class="glyphicon glyphicon-lock" ></i>
            </a>
          </li>
        </ul>
      </div>  
    </header> 	
  </div>
</template>


<script>

export default {
	data() {
    return { 

		}
  },
	
  methods: {
		security_page_logout(){
			// console.log('security_page_logout------------------');
      if(confirm('보안페이지에서 정말 로그아웃 하시겠습니까?')){
				 this.axios().delete('/security_pages_logout', {})
					.then((result) => {
						if(result.data.result=='success'){
							this.webpageNotice({type: 'success', msg: '성공적으로 로그아웃 되었습니다.'});
							let path = `/${this.$route.params.userid}/${this.$route.params.url}/security_pages_login`
							if(this.$route.params.subpage)path += `?subpage=${this.$route.params.subpage}`
							this.$router.push(path)
						}
					})
			}
		},
		
		menu_class(){
			if(this.$store.state.menu.config.transparent){
				if(this.$route.name == "mainpage" ){
					if(this.$store.state.authCheck){
						this.header_css = this.scrolled ? 'margin-top: 0px' : (this.$store.state.preview ? (this.mobile_screen ?'margin-top: 0px' : 'margin-top: 40px' ) : 'margin-top: 0px' )
					}
					// if(this.mobile_screen)this.login_css = 'padding-top: 0px'
					$('body').css('padding-top', '0px')
					return 'navbar-toggleable-md sticky shadow-after-3 transparent clearfix'
				}else{
					this.header_css = 'margin-top: 0px'
					// if(this.mobile_screen)this.login_css = 'padding-top: 6px'
					return 'navbar-toggleable-md sticky shadow-after-3 clearfix'
				}
			}else{
				this.header_css = 'margin-top: 0px'
				// if(this.mobile_screen)this.login_css = 'padding-top: 6px'
				return 'navbar-toggleable-md sticky shadow-after-3 clearfix'
			}
		}


	},

}
</script>
<style>
 .bm-burger-button {
		position: relative;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 150px;
		cursor: pointer;
	}

</style>


<style scoped>
#topMain li.search .search-box {
	margin-top: 0px
}

#topNav a.logo>img{
		max-height: 70px !important;
		line-height: 70px !important;
}

#header.fixed #topNav a.logo {
    height: 60px;
    line-height: 60px;
}

#header.fixed #topNav a.logo>img {
		height: 50px;
}

#header.fixed a.logo>#logo_font {
		height: 60px;
}

#header.fixed #topNav #topMain>li>a {
    height: 60px;
		line-height: 60px;
		margin-bottom: 10px;
}

.dropdown-menu {
    background-color: #333;
}

#topMain>li>a {
	line-height: 96px;
}

#header ul.nav-second-main {
  margin-top: 10px
}

</style>
