<template>
    <!-- wrapper -->
	<div>
		<section class="info-bar info-bar-clean" :style="cur_section_background">
      <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
			<div class="container"  :style="re_order.length > 1 ? 'padding-bottom:70px;z-index: 100' : 'z-index: 100' " >
				<smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
				<div v-if="re_order.length > 0" >
					<div class="row"  v-for="(tmp, index) in re_order" :key='index' >
						<div :class="grid" v-for="(i, idx) in tmp" :key='idx' :style="border_right(tmp.length, idx)" >
							<i :class="ctem[i].icon" :style="'color:'+ctem[i].font_set.icon_color"></i>
							<h3 :style="'cursor:pointer;color:'+ctem[i].font_set.title_color" @click="link_button(ctem[i].link1)" >{{ ctem[i].title }}</h3>
							<p :style="'color:'+ctem[i].font_set.subtitle_color" @click="link_button(ctem[i].link2)">{{ ctem[i].subtitle }}</p>
						</div>
					</div>	
				</div>
        <div class="row" v-else>
					<div class="col-sm-4">
						<i class="glyphicon glyphicon-globe"></i>
						<h3>responsive Webpage</h3>
						<p>easyhb Template is fully responsive</p>
					</div>

					<div class="col-sm-4">
						<i class="glyphicon glyphicon-usd"></i>
						<h3>admin Mode</h3>
						<p>easyhb include admin</p>
					</div>

					<div class="col-sm-4">
						<i class="glyphicon glyphicon-flag"></i>
						<h3>easy Homepage</h3>
						<p>Free support Homepage</p>
					</div>
				</div>
			</div> 
		</section>
		<slot name="setting"  ></slot>
	
  </div>
    
</template>


<script>

export default {
	props: ['index','current_section'],
	data(){
		return {
			grid: 'col-md-12',
			re_order: [],
			background_color_test: 127.5

		}
	},
	
	mounted(){
		this.item_div();
		// console.log(this.re_order);
		//this.lightOrDark 함수는 mixin/common.js에 있음
		if(this.current_section.config.section_set!=undefined){
			if(this.current_section.config.section_set.color!=undefined)this.background_color_test = this.lightOrDark(this.current_section.config.section_set.color)
		}
	
	},
	
	watch: {
		item_order(val){
			//이 코드 없으면 데이트 업데이트시 적용이 안됨
			this.item_div();
			return val
		}
	},

	computed: {
    cur_section_background(){
			const grnd = this.section_background()
			if(this.current_section.title != '' &&  this.current_section.sub_title != ''){
				return `${grnd};padding-bottom:10px !important;`
			}else{	
				return grnd
			}
		}
	},
	methods: {
		border_right(len, index){
			if (this.current_section.config.section_set.use_background != 1)return false
			if (this.$store.state.screenSize < 415) return "border-right: 0px rgba(255,255,255,0)";
			if(this.background_color_test < 185  ){
				if( len > index+1 ) return "border-right: 1px solid rgba(255,255,255,0.4)"
			}
		},
  
	},
  

}
</script>


<style>

.info-bar div.row>div  {
	margin-top: 10px !important
}

</style>

