<template>
  <div>
    <table class="table">
      <thead>
        <th>번호</th>
        <th>서브페이지url</th>
        <th>형식</th>
        <th>옵션</th>
      </thead>
      <tbody>
        <tr v-for="(page, index) in subpages" :key="index" >
          <td>{{ index+1 }}</td>
          <td @click="goto_subpage(page.subpage_name)" style="cursor:pointer" >{{ page.subpage_name }} </td>
          <td>{{ subpage_type(page.subpage_type) }}</td>
          <td>
            <span class="btn btn-sm btn-primary" @click="edit_subpage(page)"  >수정</span> 
            <span class="btn btn-sm btn-danger" @click="delete_subpage(page)">삭제</span> 
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['comp','current_mode','current_subpage_name'],
  computed: {
    subpages: {
      get(){
        return this.$store.state.subpages
      },
      set(val){
        this.$store.state.subpages = val
      }
    },
     
    // subpage: {
    //   get(){
    //     return this.current_subpage
    //   },
    //   set(val){
    //     this.current_subpage = val
    //   }
    // }

  },
  methods: {

    goto_subpage(subpage_name){
      location.href = `/${this.$route.params.userid}/${this.$route.params.url}/${subpage_name.toLowerCase()}`
    },

    delete_subpage(subpage){
      this.$emit("update:current_mode",'del_mode')
      this.$emit("update:subpage", subpage)
      this.$emit("update:current_subpage_name", '')
      this.$emit("update:delete_confirm", '')
    },

    edit_subpage(subpage){
      this.$emit("update:current_mode",'edit_mode')
      this.$emit("update:subpage", subpage)
      this.$emit("update:current_subpage_name", subpage.subpage_name)
    },

    subpage_type(type){
      const subpagetype = { 1: '게시판형', 2: '페이지형'  }
      return subpagetype[type]

    }

  }

  
}
</script>

