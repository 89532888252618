<template>
  <div >
    <div v-if="mainpage_check || webpage_user_check">
      <div class="col-12" v-if="!webpage_user_check" >
        <span class="btn btn-sm btn-primary" v-if="compo!='index'"  @click="go_index()" ><i class="fa fa-arrow-left"></i>뒤로</span>  
        <span class="btn btn-sm btn-danger" v-if="compo=='index'"  @click="add_subadmin()" ><i class="fa fa-users"></i>부관리자 추가</span>  
      </div>

      <div  style="margin-top:10px;" v-if="compo=='add' || compo=='edit'  ">
        <div v-if="compo=='add' && !webpage_user_check ">
          <div class="col-md-6" style="margin-bottom: 10px;" >
            <label>아이디 입력</label> 
            <div class="input-group">
              <input type="text" placeholder="아이디입력" ref="userid" v-model="userid" class="form-control" style="height: 35px;"> 
              <span class="input-group-btn">
                <button class="btn btn-primary" style="height: 40px !important;" @click="search_userid()" >검색</button>
              </span>
            </div>
          </div>
          <div class="alert alert-danger" v-if="error_message!=''" >
            {{ error_message }}
          </div>
        </div>
        <div class="row" style="margin-bottom: 10px;margin-left:10px" v-if="compo=='edit' && !webpage_user_check  ">
          <div class="col-4">
            <label>아이디</label>
            <div><b>{{ current_relation.submember.userid }}</b></div>
          </div>
          <div class="col-6">
            <label>이름</label>
            <div><b>{{ current_relation.submember.username }}</b></div>
          </div>
        </div>  

        <div v-if="userid_check">
          <hr>
          <div class="col-md-12" style="margin-top:10px;margin-bottom:10px" >
            <el-radio v-model="auth.admin_type" label="1" border>전체관리</el-radio>
            <el-radio v-model="auth.admin_type" label="2" border>부분관리</el-radio>
            <el-radio v-model="auth.admin_type" label="5" v-if="webpage_user_check" border>권한없음</el-radio>
          </div>

          <div v-if="auth.admin_type=='5'">
            일반회원은 권한 설정 할 수 없습니다.<br>
            부분관리 또는 전체관리에서 권한을 설정할 수 있습니다. 
          </div>  

          <div v-if="auth.admin_type=='2'">
            <hr/>
            <div style="font-size: 10pt" >
              <i class="fa fa-hashtag"></i> 부분관리의 경우 새로운 섹션이나 서브페이지 추가 되면 권한을 새로 부여해야 합니다. 
            </div>
            <div class="col-md-12" style="margin-top:10px;margin-bottom:10px" >
              <div style="margin-bottom:10px">
                <span class="btn btn-sm btn-primary"  @click="select_item(1)" ><i class="fa fa-check"></i>전체선택</span>  
                <span class="btn btn-sm btn-danger"   @click="select_item(2)" ><i class="fa fa-close"></i>전체해제</span>  
              </div>

              <div class="heading-title heading-line-single">
                <h5>메인페이지 권한설정</h5>
              </div>
              
              <label class="checkbox" v-for="(or, index) in  this.$store.state.section_order" :key="index" >
                <input type="checkbox" :value="or" :ref="'main_'+index" :checked="item_checked('main',or)" >
                <i></i> {{ sectionnsubpage.main[or] }}
              </label>
            </div>
            
            <div class="col-md-12" style="margin-top:10px;margin-bottom:10px" >
              <hr/>
              <div class="heading-title heading-line-single">
                <h5>서브페이지 권한설정</h5>
              </div>
              <label class="checkbox" v-for="(val, key, index) in  sectionnsubpage.sub" :key="index" >
                <input type="checkbox" :value="key" :ref="'sub_'+index" :checked="item_checked('sub',key)" >
                <i></i> {{ val }}
              </label>
            </div>
            
            <div class="col-md-12" style="margin-top:10px;margin-bottom:10px" v-if="!object_empty_check(sectionnsubpage.board)" >
              <hr/>
              <div class="heading-title heading-line-single">
                <h5>게시판 권한설정</h5>
              </div>
              <label class="checkbox" v-for="(val, key, index) in  sectionnsubpage.board" :key="index" >
                <input type="checkbox" :value="key" :ref="'board_'+index" :checked="item_checked('board',key)" >
                <i></i> {{ val }} 쓰기
              </label>
            </div>
            
            <div class="col-md-12" style="margin-top:10px;margin-bottom:10px" >
              <hr/>
              <div class="heading-title heading-line-single">
                <h5>메인 권한설정</h5>
              </div>
              <label class="checkbox" v-for="(val, key, index) in  option_auth" :key="index" >
                <input type="checkbox" :value="key" :ref="'option_'+index" :checked="item_checked('option',key)">
                <i></i> {{ val }}
              </label>
            </div>
          </div>
        </div>
        
        <div class="modal-footer" v-if="mainpage_check" >
          <button type="button" class="btn btn-default" data-dismiss="modal">닫기</button>
          <button type="button" class="btn btn-primary" @click="create()" v-if="compo=='add'" ><i class="fa fa-check"></i> 추가</button>
          <button type="button" class="btn btn-primary" @click="update()" v-else ><i class="fa fa-check"></i> 수정</button>
        </div>
        
        <div class="modal-footer" v-if="webpage_user_check" >
          <button type="button" class="btn btn-default" data-dismiss="modal" @click='modal_close()' ><i class="fa fa-close"></i> 닫기</button>
          <button type="button" class="btn btn-primary" @click="update()" ><i class="fa fa-check"></i> 수정</button>
        </div>

      </div>
      
      <div class="col-12" v-if="compo=='index' && $route.name=='mainpage' " style="margin-top:20px">
        <table class="table">
          <thead>
            <th>번호</th>
            <th>이름</th>
            <th>아이디</th>
            <th>옵션</th>
          </thead>
          <tbody>
            <tr v-for="(s, index) in relations" :key="index" >
              <td>{{listNumber(index)}}</td>
              <td>{{ submember[s.member_id].username }}</td>
              <td>{{ submember[s.member_id].userid }}</td>
              <td>
                <div v-if="$store.state.authData.id!=s.member_id" >
                  <span class="btn btn-sm btn-success" @click="edit_item(s,submember[s.member_id] )" ><i class="fa fa-edit"></i>수정</span>  
                  <span class="btn btn-sm btn-danger" @click="delete_item(s.id )" ><i class="fa fa-trash"></i>삭제</span>  
                </div> 
                <div v-else>
                  수정불가
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center">
          <el-pagination
            :page-size="per_page"
            :page-count="10"
            :current-page.sync="currentPage"
            @current-change="current_change"
            layout="prev, pager, next"
            :total="total_count">
          </el-pagination>
        </div>
      </div>
    </div> 
    <div v-else>
      <div class="alert alert-danger">
        부관리자 설정은 메인 페이지에서만 가능합니다.
      </div>
    </div>

  </div>
</template>

<script>
import 'core-js/es7/array'
import { Loading } from 'element-ui';

export default {
  props: ['current_user', 'modal_visible'],
  data(){
    return {
      compo: 'index',
      userid: '',
      userid_check: false,
      relations: [],
      submember: {},
      auth: {
        admin_type: '1',
        main: [],
        sub: [],
        option: [],
        board:[],
      },
      error_message: '',
      main_count: 0,
      sub_count: 0,
      board_count: 0,
      option_auth: {
        webdesign: '메인설정(디자인,로고,카톡)',
        subadmin: '부관리자 관리',
        menunsubpage: '메뉴및서브페이지관리',
        section_move: '섹션 아래위 이동권한'
      },
      total_count: 0,
      per_page: 20,
      currentPage: 1,
      current_relation: {}
    }
  },

  created(){
    if(this.mainpage_check) this.submembers_index()
    if(this.webpage_user_check){
      this.userid_check = true
      this.compo = 'edit'
      this.auth = JSON.parse(JSON.stringify(this.current_user.auth_set.auth))
    } 
  },

  computed:{
    webpage_user_check(){
      return this.$route.name == 'webpage_users' 
    },

    sectionnsubpage(){
      const format = this.$store.state.section_format
      const order = this.$store.state.section_order;
      const sections = this.$store.state.sections;
      const subpage = this.$store.state.subpages;
      let frt = {}
      for(let i=0;i<Object.keys(format).length;i++){
        frt[format[i].component] = format[i].name 
      }
      let auth = { main: {}, sub: {}, board: {} }
      let sec = {}
      for(let i=0;i<order.length;i++){
        sec = sections.filter(s=>s.id==order[i])[0]
        auth.main[order[i]] = `${i+1}번[${sec.component}]섹션`
      }
      this.main_count = order.length
      for(let key in subpage){
        auth.sub[`${subpage[key].id}`] = `${subpage[key].subpage_name}`
        if(subpage[key].subpage_type==1){
          auth.board[`${subpage[key].id}`] = `${subpage[key].subpage_name}`
          this.board_count++;
        }
        this.sub_count++;
      }
      return auth
    } 

  },
  
  methods: {
    add_subadmin(){
      this.compo = 'add'
      this.userid = ''
      this.userid_check = false
      const self = this
      setTimeout(function(){ self.$refs.userid.focus(); },500)
    },

    go_index(){
      this.compo = 'index'
    },

    modal_close(){
      //webpage_user에서만 사용된다.
      this.$emit("update:modal_visible", false)
    },

    edit_item(relation, submember){
      this.compo = 'edit'
      this.userid_check = true
      this.current_relation = { relation: relation, submember: submember}
      this.auth = relation.auth
    },

    current_change(val){
      const currentPage = `?current_page=${val}`
      // const search = this.isEmpty(this.search_word) ? '' : `&search=${this.search_word}`
      this.submembers_index(currentPage)
    },

    item_checked(type, key ){
      // console.log(this.auth);
      if(this.auth[type] == undefined){
        return false
      }else{
        return this.auth[type].includes(String(key)) ? true : false
      }
    },

    listNumber(index) {
      return (this.total_count - (this.per_page * (this.currentPage - 1))) - index;
    },

    select_item(type){
      for(let i=0;i<this.main_count;i++ ){
        this.$refs[`main_${i}`][0].checked = type == 1 ? true : false
      }
      for(let i=0;i<this.sub_count;i++ ){
        this.$refs[`sub_${i}`][0].checked = type == 1 ? true : false
      }
      for(let i=0;i<this.board_count;i++ ){
        this.$refs[`board_${i}`][0].checked = type == 1 ? true : false
      }
      for(let i=0;i<Object.keys(this.option_auth).length;i++ ){
        this.$refs[`option_${i}`][0].checked = type == 1 ? true : false
      }
    },
    
    submembers_index(params=''){
      let loadingInstance = Loading.service({text:'데이터 가져 오는 중입니다...'});
      this.axios().get(`/webpages/relation_index/${this.$store.state.authData.relation_id}${params}?type=subadmin`)
      .then(result=>{
        loadingInstance.close();
        if(result.data.result=="success"){
          this.update_item(result)
        }else{
          this.webpageNotice(result.data);
        }
      })
    },

    search_userid(){
      if(this.homepage_owner_check()==true) return false
      this.userid_check = false
      let url = ''
      let loadingInstance = Loading.service({text:'검색중입니다...'});
      // if(this.$route.name=='mainpage'){
        url = `/webpages/relation_userid_check/${this.$store.state.authData.relation_id}/${this.userid}?type=subadmin`
      // }else{

      //   console.log(this.current_user);
      //   url = `/webpages/relation_userid_check/${this.current_user.id}/webpage_user?type=subadmin`
        

      // }
      // console.log(url);
      this.auth = {
        admin_type: '',
        main: [],
        sub: [],
        option: [],
        board:[],
      },

      this.axios().get(url)
      .then(result=>{
        
        this.webpageNotice(result.data);
        if(result.data.result=="success"){
          // console.log("성공 한 케이스============");
          this.userid_check = true
          this.error_message = ''
        }else{
          // console.log("실패 한 케이스============");
          this.userid_check = false
          this.errorMessage_update(result.data.msg)
        }
        loadingInstance.close();
      })
      .catch(e=>{
        loadingInstance.close();
        this.webpageNotice({result: 'error', type: 'error', msg: '새로고침후 다시 시도해 주세요'});
      })
    },

    errorMessage_update(msg){
      this.error_message = msg
      var self = this
      setTimeout(function(){
        self.error_message = ''
      },4000)
    },

    homepage_owner_check(){
      if(this.userid == this.$route.params.userid){
        this.errorMessage_update("현재 샤플 소유자 아이디는 등록이 불가 합니다.")
        return true 
      }
      return false
    },

    checked_item(){
      let auth = { admin_type: 0, main: [], sub: [], option: [],board:[] }
      if(this.auth.admin_type == '2'){
        for(let i=0;i<this.main_count;i++ ){
          if(this.$refs[`main_${i}`][0].checked==true){
            auth.main.push(this.$refs[`main_${i}`][0].value)
          }
        }
        for(let i=0;i<this.sub_count;i++ ){
          if(this.$refs[`sub_${i}`][0].checked==true)auth.sub.push(this.$refs[`sub_${i}`][0].value)
        }
        for(let i=0;i<this.board_count;i++ ){
          if(this.$refs[`board_${i}`][0].checked==true)auth.board.push(this.$refs[`board_${i}`][0].value)
        }
        for(let i=0;i<Object.keys(this.option_auth).length;i++ ){
          if(this.$refs[`option_${i}`][0].checked==true)auth.option.push(this.$refs[`option_${i}`][0].value)
        }
      }else{
        auth = this.auth
      }
      auth.admin_type = this.auth.admin_type
      return auth
    },

    update(){
      const auth = this.checked_item()
      auth.type = 'subadmin'
      const relation_id = this.mainpage_check ? this.current_relation.relation.id : this.current_user.id
      this.axios().put(`/webpages/relation_update/${relation_id}`, auth)
      .then(result=>{
        this.webpageNotice(result.data);
        if(this.mainpage_check){
          this.compo = 'index'
          for( let r in this.relations ){
              if(this.relations[r].id == relation_id)this.relations[r].auth = auth
          }
        }
        if(this.webpage_user_check){
          this.current_user.auth_set.auth = JSON.parse(JSON.stringify(auth))
          this.$emit("update:current_user", this.current_user)  
          this.$emit("update:modal_visible", false)
        }
      })
    },

    delete_item(id){
      if(confirm('정말 삭제 하시겠습니까?')){
        this.axios().delete(`/webpages/relation_delete/${id}?current_page=${this.currentPage}`, {type: 'subadmin'})
        .then(result=>{
          this.webpageNotice(result.data);
          this.update_item(result)
        })
      }
    },

    update_item(result){
      this.relations = JSON.parse(JSON.stringify(result.data.data.relations))
      this.submember = JSON.parse(JSON.stringify(result.data.data.submember))
      this.total_count = result.data.data.total_count
      this.per_page = result.data.data.per_page
      this.currentPage = Number(result.data.data.current_page)
    },

    create(){
      this.axios().post(`/webpages/relation_create/${this.$store.state.authData.relation_id}`, {type: 'subadmin', auth: this.checked_item(), userid: this.userid} )
      .then(result=>{
				this.webpageNotice(result.data);
        if(result.data.result=="success"){
          this.compo = 'index'
          this.update_item(result)
        }else{
          this.error_message = result.data.msg
        }
      })
    }

  }
}
</script>

<style scoped>
.checkbox { margin-top: 0px !important; margin-bottom: 0px }

.heading-title { margin-bottom: 10px; }

</style>
