<template>
<div>
	<section :style="section_background()+section_css" :id='section_tag_id' >
    <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
	  <smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
    <div class="container" style="z-index: 100" >
      
      <div class="row" v-if="current_section.item_order.length>0">
        <div :class="item_view_class" v-for='f in current_section.item_order' :key="f" >
          <component :is='current_compo(current_item(f))' :current_item='current_item(f)' :current_section='current_section' :fontColor="section_set" ></component>
        </div>
      </div>

      <div v-else>
        <img src="/images/sumaryboard01.png" style="width:100%">
      </div>

    </div>
	</section>
	
	<slot name="setting"  ></slot>
</div>

    
</template>


<script>
import Default from './default/link01_default';
import Board from './sumaryboard_type/board_type'
import Megazine from './sumaryboard_type/magazin_type'

export default {
	props: ['index','current_section'],
	mounted(){
	
	},
	components: {
    Default,
    Board,
    Megazine
	},

  computed: {
    
    item_class(){
      if(this.current_section.item_order.length < 3){
        return "col-md-6"
			}else{
        return "col-md-4"
			}
    },
    
    section_css(){
      if(!this.isEmpty(this.current_section.title) || !this.isEmpty(this.current_section.sub_title)){
        return ";padding: 20px 0;padding-bottom:30px"
      }else{
        return ";padding-bottom: 60px;padding-top:80px"
      }
    },

    section_set(){
      if(this.current_section.config!=undefined){
        return this.current_section.config.section_set
      }else{
        return {}
      }
    }
	
  },
  

	methods: {
    
    current_compo(type){
      if(type.config!=undefined){
        if(type.config.item_board_type==undefined || type.config.item_board_type==1 ){
          return 'board'
        }else{
          return 'megazine'
        }
      }else{
        return 'board'
      }
    },

    current_item(f){
			return this.$store.state.items[f];
		}
	}

}
</script>


<style>

</style>

