<template>
  <!-- wrapper -->
  <section>
    <div class="container" style="min-height: 45vh">
      <div class="row">
        <div class="col-md-6 col-md-offset-3">
          <form class="nomargin sky-form boxed" style="display:table-cell;vertical-align:center"  >
            <header>
              <i class="fa fa-lock"></i> 비밀번호 찾기
            </header>
            <fieldset class="nomargin">	
              <div class="row margin-bottom-10">
                <div class="col-sm-12" v-if="valid_page">
                  <div class="alert alert-primary" style="margin: 15px">
                    <label>비밀번호 변경 방법</label>
                    <ol style="margin-bottom: 0px;font-weight:bold">
                      <li>가입시 입력하신 이메일을 입력하고 변경 버튼을 클릭 합니다.</li>
                      <li>이메일을 확인 하시면 비밀번호를 변경할 수 있는 페이지 버튼이 있습니다.</li>
                      <li>[패스워드 변경하러 가기]링크를 클릭하시면 비밀번호를 변경하실 수 있습니다.</li>
                    </ol>
                  </div>
                  <b>이메일</b>
                  <label class="input margin-bottom-10">
                      <i class="ico-append fa fa-lock"></i>
                      <input type="text" placeholder="Email" v-model="email" @keyup.enter="find_password()" :class="{error: validation.hasError('email')}">
                  </label>
                  <div class="message" style="color:#CD554A">{{ validation.firstError('email') }}</div>
                </div>  

                <div class="col-sm-12" v-else>
                    <div class="alert alert-primary" style="margin: 15px">
                    <label>이메일 전송완료</label>
                    <ol style="margin-bottom: 0px;font-weight:bold">
                      <li>이메일을 확인하시고 [패스워드 변경하러 가기]를 클릭하셔서 비밀번호를 변경하세요!</li>
                      <li>자동으로 메인페이지로 이동합니다.</li>
                    </ol>
                  </div>

                  <span class="btn btn-small btn-default" @click="move_home()" >메인홈이동</span>  

                </div>


              </div>
            </fieldset>
            <div class="row margin-bottom-20 text-right">
              <div class="col-md-12" style="padding-right: 40px;margin-bottom: 30px">
                <div  class="btn btn-primary" @click="find_password()"><i class="fa fa-check"></i> 변경</div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>  

    
</template>


<script>

import Vue from 'vue'
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator.create({
  templates: {}
});
Vue.use(SimpleVueValidation);

export default {
  data(){
    return {
      email: '',
      headers: {'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),'webpage-id': webpage_id},
      valid_page: true,
    }
  },
  validators: {
    
    'email': function(value) {
        return Validator.value(value).required("required").email();
    },
      
  },

  methods:{
    find_password(){
      this.$validate()
      .then(result =>{
        if(result){
          this.$http.put(`/${this.$route.params.userid}/${this.$route.params.url}/find_password`, {email: this.email }, { headers: this.headers } )
          .then(result=>{
            this.webpageNotice(result.data)
            if(result.data.result=="success"){
              this.email = ''
              this.valid_page = false
              var self=this
              setTimeout(function(){self.$router.push(`/${self.$route.params.userid}/${self.$route.params.url}`)},3000)
            }
          })
        }
      })
      .catch(e=>{
        this.webpageNotice({type: "error", msg: '페이지가 오래 되어 토큰이 만료되었습니다. 새로고침 후 다시 시도해 주세요'})
        setTimeout(function(){
          location.reload()
        },2000)
      })
   
    },

    move_home(){
      this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}`)
    }
      
    
  }

}
</script>


<style>


</style>

