<template>
  <div style="margin-top:20px">
      <div>
        <label>제목</label>
        <input type="text" class="form-control"  v-model="data.title" >
      </div>

      <div>
        <label>부제목</label>
        <input type="text" class="form-control"  v-model="data.subtitle" >
      </div>  

      <div style="margin-top: 20px">
        <label>내용</label>
        <textarea class="summernote" v-html="data.context" ref="item_context" data-height="200" data-lang="ko-KR"></textarea>
      </div>

      <div style="margin-top: 20px" v-if="mode=='edit'" >
        <label>이미지 세로 사이즈</label>
        <el-select v-model="data.config.logo_horizen_size" size="small" placeholder="서브페이지 연결" style="width:100px" >
          <el-option
            v-for="(key, value) in logo_h_size"
            :key="key"
            :label="key"
            :value="value">
          </el-option>
        </el-select>
        <label>이미지</label>
          <el-upload
          ref="file1"
          name="file1"
          :limit="1"
          :on-success='return_file'
          :multiple='true'
          :action="file_upload_url('file1')"
          :headers="upload_headers"
          :auto-upload="true">
          <el-button slot="trigger" size="mini" type="primary" style="padding:5px" >이미지 선택</el-button>
          <el-button style="margin-left: 10px;padding:5px" size="mini" type="danger"  @click="submitUpload('file1')" v-if="false" >이미지 업로더</el-button>
          <div class="el-upload__tip" slot="tip">jpg/png 이미지를 500kb이하 용량으로 업로더 가능합니다.</div>
        </el-upload>
        <div v-if="file_exist(data,'file1')">
          <img :src="data.file1.thumb" alt="" height="100"><br>
          <div class="badge badge-danger" @click="image_delete('file1')" style="cursor:pointer">삭제</div>
        </div>
      </div>

  </div>
</template>

<script>
export default {
  props: [ 'data', 'mode', 'compo' ],
  
  data(){
    return {

   
    }
  },

  mounted(){
   this.summernote_set();
   if(this.data.config.logo_horizen_size==undefined)this.data.config.logo_horizen_size = 40
  },

  watch: {
    data(){
      this.$emit('update:data',this.data)
    }
  },


  computed:{

   logo_h_size(){
      let size = {}
      size[1] = '100%'
      for(let i=40; i<=150;i++){
        size[i] = `${i}픽셀`
      }
      return size;
    }

  },

  methods: {

    handleFileUpload(file){
      var file_item = this.$refs[file]
      jQuery(file_item).next('input').val(file_item.value)
      this[file] = this.$refs[file].files[0]
    },

    file_upload_url(file_type){
      return `/webpages/${this.page_type}/item_file_upload/${file_type}/${this.data.id}`
    },

    return_file(result){
      this.webpageNotice(result);
      if(result.result == 'success'){
          this.data[result.item.file_field] = result.item[result.item.file_field] 
          this.$refs[result.item.file_field].clearFiles()
      }
    },

    image_delete(file_field){
      if(confirm('정말 삭제할까요?')){
        var data = {}
        let url = this.file_upload_url(file_field)
        this.axios().post(url, data )
          .then(result=>{
            this.webpageNotice(result.data);
            if(result.data.result == 'success'){
                let item = result.data.item
                this.data[item.file_field] = item[item.file_field] 
            }
          })
      }
    },

    item_update(){
      this.data.context = $('.note-editable').html()
      let url = ''
      if(this.writerType != 'board'){
        url = `/webpages/${this.page_type}/item_update/${this.data.id}`
      }else{
        //this.writerType ==> board에서 글쓰기 구분을 위한 type이다 보통은 undefind 글쓰기에서는 board
        url = `/webpages/${this.page_type}/board/${this.data.id}`
      }
      this.axios().put( url, {data: this.data} )
        .then(result=>{
          this.webpageNotice(result.data);
          if(result.data.result=='success'){
            if(this.writerType != 'board'){
              this.data.context = result.data.data.context
              this.go_back();
            }else{
              this.board_items[result.data.board_item.id] = result.data.board_item
              this.$emit('elmodalClose')
            }  
          }
        })
    },

  }

}
</script>

<style>

</style>
