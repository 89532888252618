<template>
	<div>
		<modal-header v-bind:title="'웹페이지 설정'"></modal-header>

		<div class="modal-body" style="padding:0px">
			<div :style="(screen_size < 1000) ? 'padding: 5px' : 'padding:20px' ">
				<ul class="nav nav-tabs">
					<li class="nav-item" v-if="option_auth_check(['webdesign'])"><a class="nav-link active" data-toggle="tab" @click="active='webdesign'" >디자인설정</a></li>
					<li class="nav-item" v-if="option_auth_check(['webdesign'])"><a class="nav-link" data-toggle="tab" @click="active='logoset' " v-if='webpage_type!=3' >로고설정</a></li>
					<li class="nav-item" v-if="option_auth_check(['webdesign'])"><a class="nav-link" data-toggle="tab" @click="active='snsset' " >카톡설정</a></li>
					<li class="nav-item" v-if="option_auth_check(['subadmin'])" ><a :class="active_subadmin" data-toggle="tab" @click="active='subadmin' " >부관리자설정</a></li>
					<li class="nav-item" v-if="option_auth_check(['adminset'])" ><a class="nav-link" data-toggle="tab" @click="active='adminset' " >부가설정</a></li>
				</ul>

				<div class="tab-content box-static box-transparent box-bordered p-30 relative" style="border-top-style: none">
					<div class="tab-pane active" id="design">
						<component :is='active' v-bind:config.sync='config_props'  ></component>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer" v-if="active!='subadmin' && active!='adminset'  " >
			<button type="button" class="btn btn-default" @click="ant_modal_close()">닫기</button>
			<button type="button" class="btn btn-primary" @click="update()" >수정</button>
		</div>
	</div>

</template>


<script>
import Webdesign from './setting_form_component/design'
import Logoset from './setting_form_component/logo_set'
import Snsset from './setting_form_component/sns_set'
import Subadmin from './setting_form_component/subadmin'
import Adminset from './setting_form_component/adminset'
import ModalHeader from './modal_header'

export default {
	data(){
    return {
			active: 'webdesign',
			active_subadmin: 'nav-link',
      webdesign: {
        button: null,
        layout: 'wide',
        layout_color: null,
        pattern: null,
        background_image: null,
			},
			logoset: {
				title: logo_set.title,
				logofile: logo_set.logofile,
				use_logo: logo_set.use_logo,
				font_size: logo_set.font_size,
				font_color: logo_set.font_color,
				font_family: logo_set.font_family,
			},
			snsset: {
			},
			subadmin: {
			},

    }
	},

  mounted(){
		if(!this.option_auth_check(['webdesign']) && this.option_auth_check(['subadmin'])){
			this.active = 'subadmin'
			this.active_subadmin = "nav-link active"
		}
	},

	computed: {
		config_props(){
			return this[this.active]
		},
	},
	methods: {
		
		update(){
			if( this.active == 'webdesign' || this.active == 'logoset' ){
				this.config_save();
			}else if(this.active == 'snsset'){
				this.sns_set_save();
			}else if(this.active == 'subadmin'){

			}
		},

		config_save(){
			let condata = this[this.active]
			let config = { type: this.active, data: condata }
			this.axios().put('/webpages/webpage_config_update', config )
			.then(result=>{
				this.webpageNotice(result.data);
				if(result.data.result=='success'){
					if(this.active=='logoset')this.logoset_update()
					this.$store.commit('set_ant_modal_visible', false)
				}
			})
		},
		
		sns_set_save(){
			const data = { data: this.$store.state.sns_set, type: 'sns_set' }
			this.axios().put(`/admin/update_webapi/${webpage_id}`, data)
			.then(result => {
				this.webpageNotice(result.data);
				this.$store.commit('set_ant_modal_visible', false)
			})
		},
	
		logoset_update(){
			this.$store.state.logo_set.title = this.logoset.title
			this.$store.state.logo_set.use_logo = this.logoset.use_logo
			this.$store.state.logo_set.font_size = this.logoset.font_size
			this.$store.state.logo_set.font_color = this.logoset.font_color
			this.$store.state.logo_set.font_family = this.logoset.font_family
			this.$store.state.logo_origin_font_size = this.logoset.font_size
			this.$store.state.logo_resize_font_size = this.logoset.font_size - 6
			if(this.mobile_screen){
				this.$store.state.logo_font_size = this.$store.state.logo_resize_font_size
			}else{
				this.$store.state.logo_font_size = this.$store.state.logo_origin_font_size
			}
		}
	},
	components: {
		Webdesign,
		Logoset,
		Snsset,
		Subadmin,
		Adminset,
		ModalHeader,
	}
  
}
</script>

<style scoped>
 .nav-link { font-size: 10pt }
</style>

