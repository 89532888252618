<template>
  <div>
    <h4 class="letter-spacing-1" :style="'color:'+fontColor+';'+title_style(current_item.subtitle)" v-if="current_item.title!=''">{{ current_item.title }}</h4>
    <h6  v-if="current_item.subtitle!=''" :style="'color:'+fontColor">{{ current_item.subtitle }}</h6>

    <ul class="footer-posts list-unstyled" >
      <li v-for=" b in board_item " :key="b.id"  >
        <a :href="move_page(b)" v-html="showMemo(b.title,40)" :style="'color:'+fontColor"></a>
        <small :style="'color:'+fontColor">{{ dateFormat(b.created_at,'YY/MM/DD') }}</small>
      </li>
    </ul>
    <!-- /Latest Blog Post -->

  </div>


</template>

<script>
export default {
  props: ['current_item','fontColor'],

  mounted(){
    // this.axios().get(`/webpages/get_board/${this.current_item.link1}`)
    // .then(result=>{
    //   console.log(result.data);
    // });

  },

  computed: {
    board_item(){
      if(this.$store.state.sumary_boards.board[this.current_item.link1]!=undefined){
        return this.$store.state.sumary_boards.board[this.current_item.link1]
      }
      return []
    },
  },

  methods: {
    title_style(subtitle){
      if(subtitle!=''){
        return 'margin-bottom: 0px'
      }
    },

    move_page(board){
      return `/${this.$route.params.userid}/${this.$route.params.url}/${this.current_item.link1}/${board.id}`
    }
    
  }

}
</script>

<style>

</style>
