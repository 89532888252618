<template>
	<div>

    <section :style="cur_section_background ">
      <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
			<smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
      <component :is="compo" :current_section="current_section"></component>
		</section>
		<div>
			<slot name="setting"  ></slot>
		</div>
	</div>
    
</template>


<script>
import Default from './default/explain01_default'
import Tline01 from './timeline_type/timeline_type01'
import Tline02 from './timeline_type/timeline_type02'
import Tline03 from './timeline_type/timeline_type03'


export default {
	props: ['index','current_section'],
	data(){
		return {
      compo: 'Tline01',
			re_order: [],
			outerVisible: false,
			currentItem: {},
			image1:'',
			image2:'',
      context_popup: null,
		}
	},

	created(){
		this.compo = `Tline0${this.timeline_type+1}`
	},
		
	mounted(){
		this.item_div();
	},

	watch: {
		item_order(val){
			//이 코드 없으면 데이트 업데이트시 적용이 안됨
			this.item_div();
			return val
		},
		timeline_type(val){
			this.compo = `Tline0${val+1}`
		}
	},
	
	computed: {
    cur_section_background(){
			const grnd = this.section_background()
			const css = this.use_section_header ? `;padding-top:20px;padding-bottom:40px` :`;margin-bottom:0px`
			return `${grnd}${css}` 
		},

		timeline_type(){
			return this.current_section.config.section_set.timeline_type==undefined ? 0 : this.current_section.config.section_set.timeline_type
		},

	},

	methods: {	
		modal_open(item){
			this.outerVisible=true;
			this.currentItem=item;
			this.image1=item.file1.origin
			this.image2=item.file2.origin
			this.context_popup=!item.font_set.context_popup
		},

	},
	
	components: { 
    Default,
    Tline01,
    Tline02,
		Tline03,
	}
}
</script>


<style>

.el-dialog__title{
  margin-left:20px
}

</style>

<style scoped>





</style>




