<template>
  <div>
    <div style="margin-top:20px">
      <table style="width:100%;margin-bottom:5px">
        <tr>
          <td colspan="2" style="padding-bottom:10px">
            <span>
              <label>제목</label>
              <input type="text" class="form-control"  v-model="data.title" >
            </span>

            <span>
              <label>부제목</label>
		          <input type="text" class="form-control"  v-model="data.subtitle" >
            </span>      
          </td>
        </tr>  
        <tr>
          <td colspan="2" style="border-top: 1px solid  #ADADAD;padding-top:10px " >
            연락처
          </td>
        </tr>
       
        <tr>
          <td style="width:50px"  >
            <icon-selector v-bind:iconName.sync="data.config.iconName1" ></icon-selector>
          </td>
          <td>
		        <input type="text" class="form-control"  v-model="data.config.title1" >
          </td>
        </tr>  
        <tr>
          <td style="width:50px"  >
            <icon-selector v-bind:iconName.sync="data.config.iconName2" ></icon-selector>
          </td>
          <td>
		        <input type="text" class="form-control"  v-model="data.config.title2" >
          </td>
        </tr>  
        <tr>
          <td style="width:50px"  >
            <icon-selector v-bind:iconName.sync="data.config.iconName3" ></icon-selector>
          </td>
          <td>
		        <input type="text" class="form-control"  v-model="data.config.title3" >
          </td>
        </tr>  
        <tr>
          <td style="width:50px"  >
            <icon-selector v-bind:iconName.sync="data.config.iconName4" ></icon-selector>
          </td>
          <td>
		        <input type="text" class="form-control"  v-model="data.config.title4" >
          </td>
        </tr>  
        <tr>
          <td style="width:50px"  >
            <icon-selector v-bind:iconName.sync="data.config.iconName5" ></icon-selector>
          </td>
          <td>
		        <input type="text" class="form-control"  v-model="data.config.title5" >
          </td>
        </tr>  
        <tr>
          <td colspan='2' >
            <textarea class="summernote" v-html="data.context" ref="item_context" data-height="200" data-lang="ko-KR"></textarea>
          </td>
        </tr>
        <tr v-if="mode=='edit'"  >
          <td colspan="2" style="padding-top: 20px">
            <label>로고 세로 사이즈</label>
		        <el-select v-model="data.config.logo_horizen_size" size="small" placeholder="서브페이지 연결" style="width:100px" >
              <el-option
                v-for="(key, value) in logo_h_size"
                :key="key"
                :label="key"
                :value="value">
              </el-option>
            </el-select>
          </td>
        </tr>  
        <tr v-if="mode=='new'">
          <td colspan='2'>로고 이미지는 아이템 생성 후 수정페이지에서 업로더 가능합니다. </td>
        </tr>
        <tr v-if="mode=='edit'" >
          <td colspan="2" style="padding-top: 20px">
            <label>로고 이미지</label>
            <el-upload
            ref="file1"
            name="file1"
            :limit="1"
            :on-success='return_file'
            :multiple='true'
            :action="file_upload_url('file1')"
            :headers="upload_headers"
            :auto-upload="true">
            <el-button slot="trigger" size="mini" type="primary" style="padding:5px" >로고이미지 선택</el-button>
            <el-button style="margin-left: 10px;padding:5px" size="mini" type="danger"  @click="submitUpload('file1')" v-if="false" >로고 이미지 업로더</el-button>
            <div class="el-upload__tip" slot="tip">jpg/png 이미지를 500kb이하 용량으로 업로더 가능합니다.</div>
          </el-upload>
          <div v-if="file_exist(data,'file1')">
            <img :src="data.file1.thumb" alt="" height="100"><br>
            <div class="badge badge-danger" @click="image_delete('file1')" style="cursor:pointer">삭제</div>
          </div>
          </td>
        </tr>
      </table>

    </div>
  </div>
</template>

<script>
import IconSelector from '../../../components/common/icon_selector'

export default {
  props: ['current_section', 'data', 'mode', 'compo' ],
  data(){
    return{
      iconPopover: false,
      iconName: 'fa fa-viadeo-square',
    }
    
  },

  mounted(){
   this.summernote_set();
   if(this.data.config.logo_horizen_size==undefined)this.data.config.logo_horizen_size = 40
  },

  watch: {
    data(){
      this.$emit('update:data',this.data)
    }
  },

  computed:{
    logo_h_size(){
      let size = {}
      size[1] = '100%'
      for(let i=40; i<=150;i++){
        size[i] = `${i}픽셀`
      }
      return size;
    }
    
  },

  methods: {

    handleFileUpload(file){
      var file_item = this.$refs[file]
      jQuery(file_item).next('input').val(file_item.value)
      this[file] = this.$refs[file].files[0]
    },

    file_upload_url(file_type){
      return `/webpages/${this.page_type}/item_file_upload/${this.current_section.id}/${file_type}/${this.data.id}`
    },

    return_file(result){
      this.webpageNotice(result);
      if(result.result == 'success'){
        this.data[result.item.file_field] = result.item[result.item.file_field] 
        this.$refs[result.item.file_field].clearFiles()
        this.modalClose('#footer_setting')
      }
    },

    image_delete(file_field){
      if(confirm('정말 삭제할까요?')){
        var data = {}
        let url = this.file_upload_url(file_field)
        this.axios().post(url, data )
          .then(result=>{
            this.webpageNotice(result.data);
            if(result.data.result == 'success'){
                let item = result.data.item
                this.data[item.file_field] = item[item.file_field] 
            }
          })
      }
    },

  },

  components: {
    IconSelector,
  }
  
}
</script>

