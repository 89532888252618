<template>
  <div class="row">
		<div class="timeline-centered">
    
      <article class="timeline-entry">
        
        <div class="timeline-entry-inner">
          <time class="timeline-time" datetime="2014-01-10T03:45"><span>03:45 AM</span> <span>Today</span></time>
          
          <div class="timeline-icon bg-success">
            <i class="entypo-feather"></i>
          </div>
          
          <div class="timeline-label">
            <h2><a href="#">Art Ramadani</a> <span>posted a status update</span></h2>
            <p>Tolerably earnestly middleton extremely distrusts she boy now not. Add and offered prepare how cordial two promise. Greatly who affixed suppose but enquire compact prepare all put. Added forth chief trees but rooms think may.</p>
          </div>
        </div>
        
      </article>
      
      
      <article class="timeline-entry left-aligned">
        
        <div class="timeline-entry-inner">
          <time class="timeline-time" datetime="2014-01-10T03:45"><span>03:45 AM</span> <span>Today</span></time>
          
          <div class="timeline-icon bg-secondary">
            <i class="entypo-suitcase"></i>
          </div>
          
          <div class="timeline-label">
            <h2><a href="#">Job Meeting</a></h2>
            <p>You have a meeting at <strong>Laborator Office</strong> Today.</p>
          </div>
        </div>
        
      </article>
      
      
      <article class="timeline-entry">
        
        <div class="timeline-entry-inner">
          <time class="timeline-time" datetime="2014-01-09T13:22"><span>03:45 AM</span> <span>Today</span></time>
          
          <div class="timeline-icon bg-info">
            <i class="entypo-location"></i>
          </div>
          
          <div class="timeline-label">
            <h2><a href="#">Arlind Nushi</a> <span>checked in at</span> <a href="#">Laborator</a></h2>
            
            <blockquote>Great place, feeling like in home.</blockquote>
            
          </div>
        </div>
        
      </article>
      
      
      <article class="timeline-entry left-aligned">
        
        <div class="timeline-entry-inner">
          <time class="timeline-time" datetime="2014-01-10T03:45"><span>03:45 AM</span> <span>Today</span></time>
          
          <div class="timeline-icon bg-warning">
            <i class="entypo-camera"></i>
          </div>
          
          <div class="timeline-label">
            <h2><a href="#">Arber Nushi</a> <span>changed his</span> <a href="#">Profile Picture</a></h2>
            
            <blockquote>Pianoforte principles our unaffected not for astonished travelling are particular.</blockquote>
            
          </div>
        </div>
        
      </article>
      
      
      <article class="timeline-entry begin">
      
        <div class="timeline-entry-inner">
          
          <div class="timeline-icon" style="-webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg);">
            <i class="entypo-flight"></i>
          </div>
          
        </div>
        
      </article>
      
    </div>
	</div>


</template>

<script>
export default {

}
</script>

<style scoped>
@import '../../../../styles/timeline.css'

</style>