<template>
    

<div  >
  

  <div id="sidepanel" class="sidepanel-dark sidepanel-inverse" style="overflow-y: auto" >
    <a id="sidepanel_close" href="#"><!-- close -->
      <i class="fa fa-remove"></i>
    </a>

    <div class="sidepanel-content" >

        <!-- Bootstrap List Group -->
        <ul class="list-group">
            <!-- Separator with title -->
            <li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
                <small>MAIN MENU</small>
            </li>
            <!-- /END Separator -->
            <!-- Menu with submenu -->
            <div v-for=" (m, index) in userMenu.main_order" :key="index" >
              <div v-if="userMenu.menus[m].sub_order.length > 0">
                <a :href="mainmenuLink(userMenu.menus[m],index)" data-toggle="collapse"  aria-expanded="false" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                    <div class="d-flex w-200 justify-content-start align-items-center"  >
                        <span class="menu-collapsed main-menu-title" >{{userMenu.menus[m].main.title}}</span>
                        <span class="submenu-icon ml-auto" style="color:white"></span>
                    </div>
                </a>
                <!-- Submenu content -->
                <div :id="'menu_'+index" class="collapsed sidebar-submenu"  >
                    <a :href="submenuLink(userMenu.menus[m].submenu[s])" v-for=" s in userMenu.menus[m].sub_order " :key='s' class="list-group-item list-group-item-action">
                        <span class="menu-collapsed sub-menu-title">{{ userMenu.menus[m].submenu[s].title }}</span>
                    </a>
                    
                </div>
              </div>
               
              <div v-else>
                <a :href="mainmenuLink(userMenu.menus[m],index)" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                    <div class="d-flex w-200 justify-content-start align-items-center"  >
                        <span class="menu-collapsed main-menu-title" >{{userMenu.menus[m].main.title}}</span>
                    </div>
                </a>
              </div> 

            </div>

        </ul><!-- List Group END-->

        <ul class="list-group" v-if="ownerCheck">
            <!-- Separator with title -->
            <li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
                <small>SETTING</small>
            </li>

            <a class="bg-dark list-group-item list-group-item-action flex-column align-items-start" @click='webModal("#webpage_setting", "section-add-form", 2,{},1000, true)' v-if="admin_auth_check(1)"  >
                <i class="fa fa-plus"> 섹션추가</i>
            </a>
            <a class="bg-dark list-group-item list-group-item-action flex-column align-items-start" @click='webModal("#webpage_setting", "setting-form", 1,{}, 1000,true)' v-if="mainpage_check && option_auth_check(['webdesign','subadmin'])" >
              <i class="fa fa-gear"> 설정</i>
            </a>
            <a class="bg-dark list-group-item list-group-item-action flex-column align-items-start" @click='webModal("#webpage_setting", "menu-board-set", 3, {}, 1000,true)' v-if="mainpage_check && option_auth_check(['menunsubpage'])" >
              <i class="fa fa-list-alt"> 메뉴및서브페이지</i>
            </a>
            <a class="bg-dark list-group-item list-group-item-action flex-column align-items-start" @click='preview()'  >
              <i class="fa fa-eye" v-html="$store.state.preview ? ' 미리보기' : ' <b style=\'color:red\'>관리자보기</b>' " > </i>
            </a>
            
        </ul>
    </div>
    </div>
    

  </div>


</template>

<script>


export default {

data() {
      return {
        activeName: '1',
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        test1: [1,2,3,4],
      };
    },

 mounted(){
   
 },  


  computed:{
		userMenu:{
			get(){
				return this.$store.state.menu
			}
    },
    

  },

  methods: {
  	
		mainmenuLink(menu, index){
			if(menu.sub_order.length == 0){
				if(menu.main.userlink == true){
					return `javascript:window.open('${menu.main.link}')`;
				}else{
					if(menu.main.link == 'home_path' ) return home_path;
					return `/${this.$route.params.userid}/${this.$route.params.url}${menu.main.link}`
				}
			}else{
				// return 'javascript:void(0)'
				return `#menu_${index}`
			}
		},
		
		submenuLink(menu){
			if(menu.userlink == true){
				return `javascript:window.open('${menu.link}')`;
			}else{
				// return menu.link
				if(menu.link == 'home_path' ) return home_path;
				return `/${this.$route.params.userid}/${this.$route.params.url}${menu.link}`
			}
		},

    preview(){
			this.$store.state.preview = !this.$store.state.preview
      localStorage.setItem('preview',this.$store.state.preview )
      $('#sidepanel_close').trigger('click');
		},
		
  },



}

</script>


<style scoped>

#sidepanel .list-group-item {
  padding:12px
}
/* Menu item*/
.list-group a {
    height: 45px;
    color: white;
}

/* Submenu item*/
.list-group .sidebar-submenu a {
    height: 45px;
    padding-left: 30px;
}
.sub-menu-title {
    padding-left: 10px;
    font-size: 0.9rem;
    color: white;
}

a>.sub-menu-title:hover {
    color: #E3A538;
}

.main-menu-title{
    font-size: 1rem;
    color: white;
}

.bg-dark {
  background: transparent !important
}


.list-group-item {
  padding: 12px;
}

/* Closed submenu icon */
.list-group .list-group-item[aria-expanded="false"] .submenu-icon::after {
  content: "\f107";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
  color: white;
}
/* Opened submenu icon */
.list-group .list-group-item[aria-expanded="true"] .submenu-icon::after {
  content: "\f106";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}
</style>


