<template>
<div>
	<section :style="section_background()+section_css" :id='section_tag_id' >
    <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
    <div class="container" style="z-index: 100"  > 
      <div class="row">
        <div class="col-12">
          <button class="btn btn-primary btn-md"  @click='home()'  >
            <i class="fa fa-home"></i>
          </button>
        
          <button class="btn btn-primary btn-md"  v-if="ownerCheck"  @click='webModal("#webpage_setting", "newsletter-form", 3, current_section, 1000, false, "new", new_data)'  >
            <i class="fa fa-list-alt"> 뭉글 새로 만들기</i>
          </button>
          <el-select v-model="cate_year" @change='get_cate_year_data'   placeholder="년도검색">
            <el-option
              v-for="item in cate_years"
              :key="item.value"
              :label="item.label"
              :value="item.value" >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
	  <smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
    <div class="container" style="min-height: 400px;z-index: 100">
      <div class="row">
        <div class="col-md-3 col-6" v-for="(s, idx) in subpages" :key='idx' >
          <div class="card">
            <a href="javascript:void(0);" @click="move_newsletter(s.subpage_name)" >
              <img class="card-img-top" :src="thumb_image(s.sns_image.thumb)" style="max-height: 200px" alt="" >
            </a>
              
            <div class="card-block">
              <a href="javascript:void(0);" @click="move_newsletter(s.subpage_name)"  class="fs-15 mb-20 block" style="cursor:pointer;"  >{{ s.subpage_name }}</a>
              <h2 class="fw-500 letter-spacing-1 fs-20" style="margin-bottom: 5px" ><span>{{s.title}}</span></h2>
              <span style="font-size:9pt" v-if="ownerCheck">{{ newsletter_path(s.subpage_name)  }}</span>
              <p style="margin-bottom:5px;margin-top:0px;" >통권 {{s.publish_num}}호 </p>
              <p style="margin-bottom:5px;margin-top:0px;" >발행일 {{ dateFormat(s.publish_date ,'YYYY년 MM월 DD일')  }} </p>
              <p style="margin-bottom:5px;margin-top:0px;" v-if="ownerCheck" >작성일 {{ dateFormat(s.created_at ,'YYYY년 MM월 DD일')  }} </p>
            </div>

            <div style="padding:20px;padding-top:0px" v-if="ownerCheck" >
              <span :class="hidden_mode(s.hidden)[0]"   @click='hidden_option(s)'><i :class="hidden_mode(s.hidden)[1]" style="padding-right:0px"></i></span> 
              <span class="btn btn-sm btn-warning"  @click='webModal("#webpage_setting", "newsletter-form", 3, current_section, 1000, false, "image", s)'><i class="fa fa-image" style="padding-right:0px"></i></span> 
              <span class="btn btn-sm btn-primary" @click='webModal("#webpage_setting", "newsletter-form", 3, current_section, 1000, false, "edit", s)' ><i class="fa fa-edit" style="padding-right:0px"></i></span> 
              <span class="btn btn-sm btn-danger"  @click='webModal("#webpage_setting", "newsletter-form", 3, current_section, 1000, false, "delete", s)'><i class="fa fa-trash" style="padding-right:0px"></i></span> 
            </div>
          </div>
        </div>

        <div class="col-md-12 text-center">
          <el-pagination
            :page-size="page_info.perPage"
            :page-count="10"
            @current-change="current_change"
            layout="prev, pager, next"
            :total="page_info.totalCount">
          </el-pagination>
        </div>

      </div>


    </div>
	</section>
	
	<slot name="setting"  ></slot>
</div>

    
</template>


<script>

export default {
	props: ['index','current_section'],
  
  data(){
    return {
      fullWidth: document.documentElement.clientWidth,
      page_info: {
        perPage: 5,
        totalCount: 0,
        current_page: 1,
      },
      search_word: '',
      cate_year: '',
    }
  },

  created(){
    this.$store.state.screenSize = this.fullWidth
    this.page_info = this.$store.state.pageinfo
    this.search_word = this.$route.query.search_word
    if(this.$route.query.cate_year)this.cate_year = this.$route.query.cate_year+'년도'
    this.page_info.current_page = this.$route.query.current_page
  },

  ready: function () {
    window.addEventListener('resize', this.handleResize)
  },

  // watch:{
  //   page_info(val){
  //     console.log("test================");
  //     console.log(val);
  //     val = this.$store.state.pageinfo
  //     return val
  //   }
  // },

  computed: {
    subpages: {
      get(){
        return this.$store.state.subpages
      },
      set(val){
      }
    },

    cate_years(){
      let years = [{value:'',label:'전체검색'}]
      for(let c of this.page_info.cate_years){
        years.push({value: c, label: `${c}년도` })
      }
      return years
    },

    new_data(){
      return {
        id: 0,
        subpage_name: '',
        title: '',
        publish_num: '',
        publish_date: '',
        subpage_type: 11,
      }  
    },

    item_class(){
      if(this.current_section.item_order.length < 3){
        return "col-md-6"
			}else{
        return "col-md-4"
			}
    },
    
    section_css(){
      if(!this.isEmpty(this.current_section.title) || !this.isEmpty(this.current_section.sub_title)){
        return ";padding: 20px 0;padding-bottom:30px"
      }else{
        return ";padding-bottom: 60px;padding-top:80px"
      }
    },

    section_set(){
      if(this.current_section.config!=undefined){
        return this.current_section.config.section_set
      }else{
        return {}
      }
    },
	
  },
  

	methods: {
    
    current_compo(type){
      if(type.config!=undefined){
        if(type.config.item_board_type==undefined || type.config.item_board_type==1 ){
          return 'board'
        }else{
          return 'megazine'
        }
      }else{
        return 'board'
      }
    },

    current_change(val){
      this.page_info.current_page = val
      this.get_letter_data();
    },

    get_cate_year_data(val){
      this.page_info.current_page = '1'
      this.get_letter_data()
    },

    get_letter_data(){
      let search = ''
      search += this.isEmpty(this.page_info.current_page) ? '' :`?current_page=${this.page_info.current_page}`
      search += this.isEmpty(this.search_word) ? '' : `&search=${this.search_word}`
      if(this.cate_year==undefined)this.cate_year=''
      search += this.cate_year=='' ? '' : `&cate_year=${this.cate_year}`
      this.axios().get(`/webpages/newsletter_index/${search}`)
      .then(result=>{
        try {
          this.$store.state.subpages = result.data.subpages
          this.page_info.totalCount = result.data.page_info.totalCount
          this.$router.push(search)
        }catch(e){
            console.log(e);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    },

    search_newsletter(){
      console.log("search----------");
    },

    hidden_option(subpage){
      const msg = subpage.hidden == 0 ? '뭉글을 목록에서 보이지 않도록 바꾸시겠습니까?' : '뭉글을 목록에서 보이도록 하시겠습니까?'
      if(confirm(msg)){
        subpage.hidden = subpage.hidden == 0 ? 1 : 0
        this.axios().put(`/webpages/newsletter_hidden_update/${subpage.subpage_name}/${subpage.id}`, { hidden: subpage.hidden })
        .then(result=>{
          this.webpageNotice(result.data);
          if(result.data.result == 'success'){
            subpage.hidden = result.data.data['hidden']
          }
        })
      }
    },

    hidden_mode(mode){
      if(mode == 0){
        return ['btn btn-sm btn-success', 'fa fa-eye']
      }else{
        return ['btn btn-sm btn-danger', 'fa fa-eye-slash']
      }
    },

    thumb_image(image){
      if(image == ''){
        return '/images/newsletter_sample.png'
      }else{
        return image
      }
    },

    current_item(f){
			return this.$store.state.footer_item[f];
    },

    newsletter_path(url){
      return `${location.protocol}//${location.host}${this.$route.path}/${url}`
    },
    
    move_newsletter(url){
      window.open( this.newsletter_path(url), url)
    },

    home(){
      this.cate_year = ''
      this.page_info.current_page = '1'
      this.get_letter_data()
    },
    

	}

}
</script>


<style>
@media only screen and (max-width: 1215px) {
  .container {
      width: 100%;
      max-width: 100%;

  }
}

</style>

