<template>
  <div>
    <div id="switcher" class="hide" v-if="mainpage_check || subpage_check">
      <div class="content-switcher">
        <h4>전체설정</h4>
        <ul >
          <li class="text-welcome" @click='webModal("#webpage_setting", "section-add-form", 2,{},1000)' v-if="admin_auth_check(1)&& (mainpage_check || subpage_check)"  >
            <i class="fa fa-plus"> 섹션추가</i>
          </li>
          <li class="text-welcome" @click='webModal("#webpage_setting", "setting-form", 1)' v-if="mainpage_check && option_auth_check(['webdesign','subadmin'])" >
            <i class="fa fa-gear"> 설정</i>
          </li>
          <li class="text-welcome" @click='webModal("#webpage_setting", "menu-board-set", 3)' v-if="mainpage_check && option_auth_check(['menunsubpage']) && webpage_type!=3 " >
            <i class="fa fa-list-alt"> 메뉴및서브페이지</i>
          </li>
          <li class="text-welcome" @click='preview()'  >
            <i class="fa fa-eye" v-html="$store.state.preview ? ' 미리보기' : ' <b style=\'color:red\'>관리자보기</b>' " > </i>
          </li>
          <li class="text-welcome" @click="admin_page()"  v-if="admin_auth_check(1)" >
            <i class="fa fa-shield" > 관리자페이지</i>
          </li>
        </ul>
        <div id="hideSwitcher">&times;</div>
      </div>
    </div>
    <div id="showSwitcher" class="styleSecondColor hide"><i class="fa fa-gear styleColor"></i></div>
  </div>
</template>
<script>
export default {
  mounted(){
    this.switcher()
  },

  methods: {

    preview(){
			this.$store.state.preview = !this.$store.state.preview
			localStorage.setItem('preview',this.$store.state.preview )
		},
   
    async switcher(){

      jQuery(document).ready(function() {
        "use strict";

        var _sw = `
            <div id="switcher" class="hide">
              <div class="content-switcher">
                <h4>STYLE SWITCHER</h4>

                <div id="hideSwitcher">&times;</div>
              </div>
            </div>

          <div id="showSwitcher" class="styleSecondColor hide hidden-xs"><i class="fa fa-flask styleColor"></i></div>
          `
          // ADD CLASS
          jQuery("head").append('<link href="/smart_webpage/assets/plugins/styleswitcher/styleswitcher.css" rel="stylesheet" type="text/css" />');
          // jQuery("body").append(_sw);
          jQuery("#switcher, #showSwitcher").removeClass('hide');

          jQuery("#hideSwitcher, #showSwitcher").click(function () {
            if (jQuery("#showSwitcher").is(":visible")) {
              var _identifier = "#showSwitcher";
              jQuery("#switcher").animate({"margin-left": "0px"}, 500).show();
            } else {
              var _identifier = "#switcher";
              jQuery("#showSwitcher").show().animate({"margin-left": "0"}, 500);
            }

            jQuery(_identifier).animate({"margin-left": "-500px"}, 500, function () {
              jQuery(this).hide();
            });

          });
      });

    }

  }



}
</script>

<style scoped>

  
  #switcher ul li {
    display: block;
    margin-bottom: 10px;
  }

	li.text-welcome {
		padding: 0 4px;
		cursor: pointer;
	}

 .search-box {
    width: 350px !important;
  }


@media only screen and (max-width: 479px) {
  
    #switcher {
        top: 60px;
        z-index: 1000;
    }
    #showSwitcher {
        top: 130px;
        z-index: 1000;
    }
}
</style>