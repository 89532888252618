<template>
  <div>
		<modal-header v-bind:title="modal_title"></modal-header>
		<div class="modal-body" style="padding:10px" >
			<div >
				<ul class="nav nav-tabs">
					<li class="nav-item" @click="tab_click('item_set')" ><a :class="tab_class('item_set')"  data-toggle="tab" >아이템설정</a></li>
					<li class="nav-item" @click="tab_click('section_set')" v-if="use_section_set" ><a :class="tab_class('section_set')"  data-toggle="tab" >섹션설정</a></li>
					<li class="nav-item" @click="tab_click('design')"      v-if="section_change" ><a :class="tab_class('design')" data-toggle="tab"  >섹션종류선택</a></li>
					<li class="nav-item" @click="tab_click('delete')"      v-if="current_section_format.delete_section && admin_auth_check(1)" ><a :class="tab_class('delete')" data-toggle="tab" >삭제</a></li>
				</ul>
				<div class="tab-content box-static box-transparent box-bordered p-20 relative " style="border-top-style: none">

					<div class="tab-pane active" id="item">
						<section-design v-if="active =='design'" ></section-design>  
						<section-delete v-if="active =='delete'"></section-delete>  
						<section-setting v-if="active =='section_set'"></section-setting>  
						<item-set v-if="active =='item_set'"></item-set>  
						
					</div>
					<div class="tab-pane fade" id="design">
					</div>
					<div class="tab-pane fade" id="delete">
					</div>

				</div>
			</div>
		</div>
		<div class="modal-footer" v-if='active!="item_set"' >
			<button type="button" class="btn btn-default" @click="ant_modal_close()">닫기</button>
			<button type="button" v-if="active == 'delete' && $store.state.modal.del_confirm == true "  class="btn btn-danger" @click="delete_section" >삭제</button>
			<button type="button" v-if ="active == 'design' || active == 'section_set' "  class="btn btn-primary" @click="section_save()" >수정</button>
		</div>
  </div>

</template>


<script>
import ModalHeader 			from './modal_header'
import Design 					from './setting_form_component/design'
import SectionDesign 		from './section_set/_section_design_select'
import SectionDelete 		from './section_set/_section_delete'
import SectionSetting 	from './section_set/_section_set'
import ItemSet 					from './section_set/_item_set'
import { Loading }      from 'element-ui';
import { close } from 'fs';

export default {
	data(){
		return {
			del_confirm: false,
			currnet_section: {},
		}
	},
	
	mounted(){
		
	},


	watch:{

		del_confirm(val){
			this.$store.state.modal.del_confirm  = val
			return this.$store.state.modal.del_confirm 
		},
	},

	computed:{
		modal_title(){
			return (this.$store.state.modal.index + 1)+'번 ['+ this.current_section_format.name +']섹션'
		},

		current_section(){
			return this.$store.state.modal.section
		},

		active(){
			return this.$store.state.modal.active
		},

		use_section_set(){
			let result = true
			const section_format = this.$store.state.current_section_format 
			if(section_format != undefined){
				if(section_format.section_set!=undefined)result = section_format.section_set
			}
			return result
		},

		current_section_format(){
			return this.$store.state.current_section_format 
		},

		section_change(){
			if(this.current_section_format.section_change==undefined)this.current_section_format.section_change=true
			if(this.current_section_format.section_change==false)return false
			return this.current_section_format.section_select && this.admin_auth_check(1)
		}

	},

	methods:{

		section_num(){
			return this.$store.state.section_num
		},

		tab_click(val){
			this.currnet_section = this.$store.state.modal
			this.$store.state.modal.active = val 
		},

		tab_class(val){
			if(this.$store.state.modal.active == val){
				return 'nav-link active'
			}else{
				return 'nav-link'
			}
		},

		section_save(){
			if(this.active == 'design' || this.active == 'section_set'){
				this.design_change()
			}
		},

		design_change(){
			// console.log('design_change----------------');
			if(this.active == 'design'){
				var component = this.$store.state.section_format[this.$store.state.section_num].component
				var data = {type: this.active, section_type: component }
			}else{
				var data = {type: this.active, section_set: this.current_section.config.section_set, title: this.current_section.title, sub_title: this.current_section.sub_title }
			}
			this.axios().put(`/webpages/${this.page_type}/section_update/${this.current_section.id}`, data )
			.then(result=>{
				this.webpageNotice(result.data)
				if(result.data.result=='success'){
					// setTimeout(function() {$('#webpage_setting').modal('hide');}, 500)
					this.$store.commit('set_ant_modal_visible', false)
					if(this.active == 'design'){
						this.$store.state.modal.section.component = component
						this.$store.state.modal.section.item_limit = result.data.item_limit 
					}else if(this.active == 'section_set'){
						let sec = this.$store.state.page_sections.filter(s=>s.id==this.$store.state.modal.section.id)[0]
						sec.section_set = JSON.parse(JSON.stringify(result.data.section_set)) 
						this.$store.commit('set_page_sections')
						if(this.$store.state.modal.section.component == 'linebar01'){
							//콤포넌트 중 linebar01에만 적용되며, 섹션의 아래쪽 화살표 색상을 적용한다.
							var color = this.$store.state.modal.section.config.section_set.color
							if(this.$store.state.modal.section.config.section_set.use_background){
								let tag_id = `${this.$store.state.modal.section.component}_${this.$store.state.modal.index}`
								$('section').append(`<style>#${tag_id}:after{border-top-color: ${color}}</style>`)
							}
						}
					}
				}
			})
		},

		delete_section(){
			let delete_section = false
      if(this.current_section.item_order.length > 0){
				// if(this.current_section.component == this.$store.state.section_name){
				if(this.$store.state.section_name == 'delete'){
					delete_section = true
				}else{
					this.webpageNotice({type: 'error',msg: '섹션을 삭제 하시려면 delete를 입력하세요!' })
					return false
				}
			}else{
				delete_section = true
			}
			if(delete_section){
				this.$store.state.linebar01_excu_cnt = 0 //linebar01을 위한 _owl_carousel() 두번이상 실행되면 에러가 나기 때문에 0으로 초기화 해야 실행된다.
				let loadingInstance = Loading.service({text:'섹션 삭제 중입니다...'});
				this.axios().delete(`/webpages/${this.page_type}/section_delete/${this.current_section.id}`)
				.then(result=>{
					loadingInstance.close();
					this.webpageNotice(result.data)
					this.$store.commit('set_ant_modal_visible', false)
					if(result.data.result == 'success'){
						this.$store.state.section_order = result.data.section_order
						delete this.$store.state.sections[this.current_section.id]
						this.$store.commit('config_section_count_update', result.data.section_order.length)
					}
				})
			}else{
			}
		},	

		delete_confirm(){
			this.del_confirm = !this.del_confirm 
		}
	},
	components: {
		ModalHeader,
		Design,
		SectionDesign,
		SectionDelete,
		SectionSetting,
		ItemSet,
	}
  
}
</script>

<style>
.nav-link { font-size: 10pt }

@media only screen and (max-width: 500px){
	.tab-content {
		padding-left: 5px !important;
		padding-right:50px !important;
	}
}
</style>
