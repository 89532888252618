<template>
  <div>
    <div
      v-if="write_type == 1"
      class="row"
      :style="outline==3 ? 'margin-bottom:30px;padding-bottom: 30px;border-bottom: 1px solid rgba(0,0,0,0.1)' : '' "
    >
      <div  :class="'col-md-12'" >
        <component :is="header_compo" v-bind:item="item" v-if="subject_location == 1"></component>
        <div class="row mt-0 text-center"  >
          <div :class="'col-md-'+img_size" >
            <img :src="item.file1.origin" style="width:100%" v-if="item.file1.origin!=''">
            <div class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-html="item.media" v-if="!isEmpty(item.media)" ></div>
          </div>
        </div>
    
        <div style="margin-top: 0px">
          <component :is="header_compo" v-bind:item="item" v-if="subject_location == 2"></component>
        </div>
        <div class="row">
          <div
            :class="'col-12 '+ dropcap"
            :style="'margin-bottom: 0px;overflow:auto;color:'+item.font_set.context_color"
            v-html="item.context"
          ></div>
        </div>
      </div>
    </div>

    <div
      class="row"
      v-if="write_type==2"
      :style="outline==3 ? 'padding-bottom: 30px;border-bottom: 1px solid rgba(0,0,0,0.1)' : '' "
    >
      <div class="col-md-12">
        <component :is="header_compo" v-bind:item="item"></component>
      </div>
      <div :class="'col-md-'+img_size">
        <img :src="item.file1.origin" style="width:100%">
        <div class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-if="!isEmpty(item.media)" v-html="item.media" ></div>
      </div>
      <div :class="'col-md-'+(12-img_size)">
        <div
          :class="dropcap"
          :style="'overflow:auto;color:'+item.font_set.context_color"
          v-html="item.context"
        ></div>
      </div>
    </div>

    <div
      class="row"
      v-if="write_type==3"
      :style="outline==3 ? 'padding-bottom: 30px;border-bottom: 1px solid rgba(0,0,0,0.1)' : '' "
    >
      <div class="col-md-12">
        <component :is="header_compo" v-bind:item="item"></component>
      </div>
      <div :class="'col-md-'+(12-img_size)">
        <div
          :class="dropcap"
          :style="'overflow:auto;color:'+item.font_set.context_color"
          v-html="item.context"
        ></div>
      </div>
      <div :class="'col-md-'+img_size">
        <img :src="item.file1.origin" style="width:100%;margin-bottom: 10px">
        <div class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-if="!isEmpty(item.media)" v-html="item.media" ></div>
      </div>
    </div>

    <div
      class="row"
      v-if="write_type==4"
      :style="outline==3 ? 'padding-bottom: 30px;border-bottom: 1px solid rgba(0,0,0,0.1)' : '' "
    >
      <div class="col-md-12">
        <component :is="header_compo" v-bind:item="item"></component>
      </div>
      <div :class="'col-md-12'">
        <span :style="type45_image_size">
          <img :src="item.file1.origin" style="width:100%">
          <div class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-if="!isEmpty(item.media)" v-html="item.media" ></div>
        </span>
        <span :style="'overflow:auto;color:'+item.font_set.context_color">
          <div :class="dropcap" v-html="item.context"></div>
        </span>
      </div>
    </div>

    <div
      class="row"
      v-if="write_type==5"
      :style="outline==3 ? 'padding-bottom: 30px;border-bottom: 1px solid rgba(0,0,0,0.1)' : '' "
    >
      <div class="col-md-12">
        <component :is="header_compo" v-bind:item="item"></component>
      </div>
      <div :class="'col-md-12'">
        <span :style="type45_image_size" >
          <img :src="item.file1.origin" style="width:100%;margin-bottom:10px" >
          <div class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-if="!isEmpty(item.media)" v-html="item.media" ></div>
        </span>
        <span :style="'overflow:auto;color:'+item.font_set.context_color" >
          <div :class="dropcap" v-html="item.context"></div>
        </span>
      </div>
    </div>
  </div>
</template>


<script>
import Header01 from "./header_01";
import Header02 from "./header_02";
import Header03 from "./header_03";
import Header04 from "./header_04";
import Image01 from "./image_01";

export default {
  props: ["item", "idx", "index", "current_section"],
  data() {
    return {};
  },

  mounted() {},

  computed: {
   type45_image_size(){
      var size = { "12":100, "8":80, "6":50, "4":35, "3": 25, "2": 15  }
      console.log(size[this.img_size]);
      const float_css = this.write_type==4 ? `float:left;margin-right:20px` : `float:right;margin-left:10px`
      let css = `width:${size[this.img_size]}%;${float_css}`
      return css
    },
    board_items: {
      get() {
        return this.$store.state.baord_data.board[this.current_section.id];
      },
      set(val) {
        this.$store.state.baord_data.board[this.current_section.id] = val;
      }
    },
    img_size() {
      return this.item.font_set.writer_set.image_size;
    },
    dropcap() {
      return this.item.font_set.writer_set.dropcap == 1 ? "dropcap" : "";
    },
    write_type() {
      return this.item.font_set.writer_set.write_type;
    },
    outline() {
      return this.item.font_set.writer_set.outline;
    },
    subject_location() {
      return this.item.font_set.writer_set.subject_location;
    },
    header_compo() {
      // const font_set = this.font_set(item.id);
      return `header0${this.item.font_set.writer_set.subject_type}`;
    },
  },

  methods: {

    font_set(id) {
      return this.$store.state.items[id].font_set;
    },


    wcontext(text) {
      return this.htmlDecode(text);
    }
  },

  components: {
    Header01,
    Header02,
    Header03,
    Header04,
    Image01
  }
};
</script>


<style scoped>
p,
span {
  line-height: 1.2em !important;
}

@media only screen and (max-width: 768px) {
  section div.row > div {
    margin-bottom: 0px;
  }
}
</style>

<style >
 p {
   margin-bottom: 0px
 }

/* .controlls-over .owl-controls .owl-prev {
		display: none
	}
	.controlls-over .owl-controls .owl-next {
		display: none
	} */
</style>

