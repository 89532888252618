<template>

	<div>
		<section class="page-header" :style="section_background()+';padding:40px;min-height:150px'" :id='section_tag_id'  >
			<div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
			<template v-if="current_section.item_order.length > 0" >
				<div class="container">
					<span v-if="!isEmpty(citem.file1.large)" >
						<img class="img-fluid" :src="citem.file1.large" alt="" style="max-height: 120px;float:left" >
					</span>
					<span >
						<h1 :style="'cursor:pointer;color:'+title_color">
							<i :class="icon" :style="'min-width: 30px;color:'+icon_color"></i>
							<font v-html="pageheader_title" style="font-weight:bold"></font>
						</h1>
						<span class="font-lato fs-18 fw-300" :style="'margin-left:'+isicon_margin(icon) +'color:'+subtitleColor" v-html="pageheader_subtitle" ></span>
					</span>
				</div>
			</template>
		</section>
		<slot name="setting"  ></slot>
	</div>
</template>


<script>


export default {
	props: ['index','current_section'],
	mounted(){
	
	},

	computed: {
		item(){
			return this.$store.state.items[this.current_section.item_order[0]]
		},

		citem(){
			if(this.ctem[this.item.id]==undefined)return { font_set: { title_color: '#000', subtitle_color: '#000', icon_color: '#000'}, icon: ''}
			return this.ctem[this.item.id]
		},

		pageheader_title(){
			if(this.item==undefined)return "Title"
			return this.item.title
		},

		pageheader_subtitle(){
			if(this.item==undefined)return ""
			return this.item.subtitle
		},

		title_color(){
			return this.citem.font_set.title_color
		},

		subtitleColor(){
			return this.citem.font_set.subtitle_color
		},

		icon_color(){
			return this.citem.font_set.icon_color
		},

		icon(){
			return this.citem.icon
		},



	},

	methods: {

		isicon_margin(icon){
			if(icon == '' || icon=="none"){
				return "0px;";
			}else{
				return "35px;"
			}
		}
	},

	components: {

	}
}
</script>


<style scoped>
img {
	margin-right: 20px;
}
@media (max-width: 767px) {
	img {
		margin-right: 0px;
	}

}

</style>

