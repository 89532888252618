<template>
	<div>
    <section :style="section_background()" :id='section_tag_id'>
			<div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
			<header class="text-center mb-60" style="z-index: 100" v-if="!isEmpty(current_section.title) || !isEmpty(current_section.sub_title)" >
				<h1 class="fw-300">{{ current_section.title }}</h1>
				<h2 class="fw-400 letter-spacing-1 fs-13"><span>{{ current_section.sub_title }}</span></h2>
			</header>
			<div class="container" style="z-index: 100" v-if="current_section.item_order.length > 0"  >

				<div class="row">
				
					<!-- item -->
					<div :class="item_count_css" v-for="(i, idx) in current_section.item_order" :key='idx'>

						<div class="box-flip box-color box-icon box-icon-center box-icon-round box-icon-large text-center">
							<div class="front" >
								<div class="box1 box-default" style="min-height: 350px">
									<div class="box-icon-title">
										<img class="img-fluid" :src="ctem[i].file1.large" style="width: 100%;max-height:340px" />
										<div style="font-size: 1.3em;font-weight:bold">{{ctem[i].title}}</div>
										<div style="font-size: 1.2em">{{ctem[i].subtitle}}</div>
										<hr v-if="!isEmpty(ctem[i].media)" />
										<div v-html="showAllMemo(ctem[i].media)" style="text-align:left" v-if="!isEmpty(ctem[i].media)" ></div>
									</div>
								</div>
							</div>

							<div class="back" >
								<div class="box2 box-default" style="min-height: 350px">
									<h3 class="m-0"  style="font-size:1.3em;margin-bottom:0px; padding-bottom:0px">{{ctem[i].title}}</h3>
									<div style="margin-top:0px; padding-top:0px">{{ctem[i].subtitle}}</div>
									<div v-html="showAllMemo(ctem[i].media)" style="text-align:left;" v-if="!isEmpty(ctem[i].media) && false" ></div>
									<hr />
									<div v-html="htmlDecode(ctem[i].context)" style="text-align:justify"></div>
								</div>
							</div>
						</div>

					</div>
					<!-- /item -->
				</div>
			</div>
		
    	<default v-else></default>   
    </section>			 

    <slot name="setting"></slot>
		
  </div>
    
</template>


<script>
import Default from './default/introduce04_default'


export default {
		props: ['index','current_section'],

	mounted(){
		_misc(); //뒤집어지는 소개 섹션 다시 로더 
	},
	components: {
		Default,
	},

	computed: {
		item_count_css(){
			if(this.current_section.item_order.length > 3){
				return "col-md-3 col-sm-6"
			}else{
				return "col-md-4 col-sm-6"
			}
		}
	}
}
</script>


<style>

</style>

