<template>
  <div>
    <span class="btn btn-sm btn-danger"  v-if="compo=='index'"  @click="addSubpageForm()" >{{current_mode == "add_mode" ? '서브페이지추가취소' : '서브페이지추가'}}</span>  
    <div class="card card-default" v-if="current_mode=='add_mode' || current_mode=='edit_mode'  " style="margin-top:20px">
      <div class="card-block">
        <div class="row">
          <div class="col-12 alert alert-warning" v-if="current_mode=='add_mode'">
            <p>현재 서브페이지 사용현황</p>
            <div style="font-size: 18pt">{{ subpage_count }} / {{ subpage_limit }} </div>
            <cite style="font-size: 9pt"><b style='color:red'>{{ subpage_limit - subpage_count }}개 </b>더 추가 가능합니다.</cite>
          </div>
          <div class="col-sm-6" >
            <label >서브페이지 경로명(영문숫자조합-숫자는 뒷쪽에 사용)</label>
            <input type="text" class="form-control" @blur="value_test()" placeholder="영문입력" v-model="current_subpage_name" style="height: 35px !important" >
            <div v-if="dupleCheckMsg!=''" style="color:red" v-html="dupleCheckMsg"></div>
          </div>
          <div class="col-sm-6" v-if="current_mode=='add_mode'" >
            <label>페이지형 선택</label>
            <el-radio-group v-model="subpage.subpage_type" size="small">
              <el-radio-button label="1">게시판형</el-radio-button>
              <el-radio-button label="2">페이지형</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="divider" style="margin: 0px 0"></div>
        <div class="text-right">
          <span class="btn btn-sm btn-danger"  v-if="current_mode=='add_mode'"  @click="create_subpage()" >추가</span>
          <div v-else>
            <span class="btn btn-sm btn-primary"  @click="current_mode=''" >취소</span>
            <span class="btn btn-sm btn-danger"   @click="update_subpage()" >수정</span>
          </div>
        </div>
      </div>
    </div> 

    <div class="card card-default" v-if="current_mode=='del_mode'" :key="subpage.id" style="margin-top:20px">
      <div class="card-block">
        <div class="row">
          <div class="col-12 alert alert-warning" >서브페이지의 모든 섹션과 게시판 내용이 모두 삭제됩니다. 신중히 생각후 삭제하세요! </div>
          <div class="col-sm-6" >
            <label>"delete"를 입력 후 삭제 하세요 </label>
            <input type="text" class="form-control" v-model="delete_confirm"  placeholder="delete 입력" style="height: 35px !important" >
          </div>
        </div> 
        <div class="divider" style="margin: 0px 0"></div>
        <div class="text-right">
          <span class="btn btn-sm btn-primary" @click="current_mode=''" >취소</span>
          <span class="btn btn-sm btn-warning"  @click="deleteSubpage()" >삭제</span>
        </div>
      </div>
    </div> 

    <transition  name="slide-fade" >
			<component 
        :is="compo" 
        :compo.sync="compo" 
        :current_mode.sync="current_mode" 
        :current_subpage_name.sync="current_subpage_name"
        :subpage.sync="subpage" 
        :delete_confirm.sync="delete_confirm" 
        style="margin-top:20px"  >
      </component>
		</transition>
    
  </div>
</template>

<script>
import Index from './page/subpage_index'
import 'core-js/es7/array' //array includes를 윈도우 익스플로러에서 사용하기 위하여 필요함

export default {
  data(){
    return {
      compo: 'index',
      current_mode: '',
      add_btn_title: '서브페이지추가',
      subpage: {
        subpage_name: '',
        subpage_type: 1,
      },
      delete_confirm: '',
      current_subpage: {},
      dupleCheckMsg: '',
      subname_valid: null,
      current_subpage_name: '',
    }
  },

  mounted(){
    this.delete_confirm = ''
  },

  computed:{
    subpage_msg(){
      if(this.$store.state.paid_type.paid_type==0){
        return "서브페이지 섹션은 10개까지 추가 가능합니다."
      }else{
        return "서브페이지 섹션은 20개까지 추가 가능합니다."
      }
    },

    subpages: {
      get(){
        return this.$store.state.subpages
      },
      set(val){
        this.$store.state.subpages = val
      }
    },

    subpage_count(){
      return this.$store.state.config.subpage_count
    },
   
    subpage_limit(){
      return this.$store.state.config.subpage_limit
    },
   
  },

  methods: {

    addSubpageForm(){
      this.current_subpage_name = ''
      this.subpage.subpage_type = 1
      this.current_mode = this.current_mode == 'add_mode' ? '' : 'add_mode'
      this.dupleCheckMsg = ''
      this.subname_valid = []
    },
    
    create_subpage(){
      //서브페이지 무료10개 유료 20개까지 추가 할 수 있도록 리미트 코드 작성 요망
      if(!this.subname_valid.includes(false)){
        this.subpage.webpage_id = webpage_id
        this.subpage.subpage_name = this.current_subpage_name
        this.axios().post(`/webpages/subpage_create`, this.subpage)
        .then(result=>{
          this.webpageNotice(result.data);
          if(result.data.result == 'success'){
            this.$store.commit('config_subpage_count_update', result.data.subpage_count)
            this.subpages.push(result.data.data)
            this.subpage.subpage_name = ''
            this.current_mode = ''
          } 
        })
      } 
    },

    update_subpage(){
      if(this.subpage_name_test(this.current_subpage_name)){
        this.subpage.webpage_id = webpage_id
        this.axios().put(`/webpages/subpage_update/${this.current_subpage_name}/${this.subpage.id}`, this.subpage)
        .then(result=>{
          this.webpageNotice(result.data);
          if(result.data.result == 'success'){
            this.subpage.subpage_name = this.current_subpage_name
            this.$store.state.menu = result.data.menu;
            this.current_mode = ''
          }
        })
      }
    },

    value_test(){
      this.dupleCheckMsg = ''
      this.subname_valid = new Array()
      if(this.subpage_name_test(this.current_subpage_name)) this.subpage_duple_check(this.current_subpage_name)
    },

    subpage_duple_check(name){
      const id = this.subpage.id ? this.subpage.id : 0
      this.axios().get(`/webpages/subpage_dupleCheck/${name}/${id}`)
      .then(result=>{
        if(result.data.result == 'error'  ){
          this.dupleCheckMsg += result.data.msg
          this.subname_valid.push(false)
        }else{
          this.subname_valid.push(true)
        }
      });
    },

    deleteSubpage(){
      if(this.delete_confirm == 'delete'){
        if(confirm("삭제하시면 데이터 복구는 불가합니다. 삭제하시겠습니까?")){
          this.axios().delete(`/webpages/subpage_delete/${this.subpage.subpage_name}/${this.subpage.id}`)
          .then(result=>{
            this.webpageNotice(result.data);
            if(result.data.result == 'success'){
              this.$store.commit('config_subpage_count_update', result.data.subpage_count)
              if(this.$route.params.subpage == this.subpage.subpage_name){
                location.href = `/${this.$route.params.userid}/${this.$route.params.url.toLowerCase()}`
              }
              this.subpages = result.data.subpages
            }
            this.current_mode = ''
          })
        }
      }else{
        const r = { result: 'error', type: 'error', msg: '삭제하시려면 delete를 입력하세요!' }
        this.webpageNotice(r);
      }
    },

    subpage_name_test(text){
      const reg=/^[A-Za-z0-9+]{4,16}$/; 
      let test = reg.test(text)
      if(test==false) this.dupleCheckMsg += '영문과 숫자 조합으로 4자~16자까지 가능합니다.(숫자는 뒤쪽에 입력)'
      this.subname_valid.push(test)
      return test
    }

  },

  components: {
    Index,
  }

}
</script>

<style scoped>
  .form-control:not(textarea){
    height: 40px !important
  } 
</style>