<template>
 <header class="text-center mb-40">
    <h2 class="fw-400" :style="'line-height: 1.2;cursor:pointer;color:'+item.font_set.title_color">
      <i :class="item.icon" :style="'color:'+item.font_set.icon_color"></i>
      <font v-html="item.title "></font>
    </h2>
    <h2 class="fw-350 letter-spacing-1 fs-20" :style="'color:'+item.font_set.subtitle_color" v-html="item.subtitle"></h2>
    <hr style="margin: 10px 0"> 
  </header>
</template>

<script>
export default {
  props: ['item']
}
</script>