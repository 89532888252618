<template>
   <div class="tab-content box-static box-transparent box-bordered p-30 relative" style="border-top-style: none">
      <div class="tab-pane active">
        <div>
          <span class="btn btn-sm btn-danger" v-if="mode=='index' &&  item_order_length"   @click="compoSelect('address1', 'new')" >아이템추가</span>  
          <span class="btn btn-sm btn-warning" v-if="mode!='index'"  @click="compoSelect('item-list','index')" >아이템리스트</span>  
          <div class="col-12 alert alert-warning" style="margin-top: 10px" v-if="mode=='index'" >
            <p>전체 아이템 사용현황</p>
            <div style="font-size: 18pt">{{ item_count }} / {{ item_limit }} </div>
            <cite style="font-size: 9pt"><b style='color:red'>{{ item_limit - item_count }}개 </b>더 추가 가능합니다.</cite>
            <div>바닥글 섹션은 <font color='red'>4개</font>의 아이템을 사용할 수 있습니다.</div>
          </div>
        </div>

        <div v-if="mode=='new'" style="margin-top:20px">
          <label>타입</label>
          <div >
            <el-radio v-model="compo" :label="'address1'" border style="margin-right:0px">연락처형</el-radio>
            <el-radio v-model="compo" :label="'general'"  border style="margin-right:0px">글쓰기형</el-radio>
            <el-radio v-model="compo" :label="'board'"    border style="margin-right:0px" v-if="webpage_type!=3" >보드연결형</el-radio>
          </div>
        </div> 
        <component :is="compo" :data.sync='current_item' :current_section.sync='current_section' :mode.sync="mode" :compo.sync="compo" ></component>

      </div>

      <div class="modal-footer"  v-if="mode=='new' || mode=='edit'  " >
        <button type="button" class="btn btn-default" @click="mode='index';compo='item-list' ">취소</button>
        <button type="button" class="btn btn-primary" v-html="mode=='new'? '작성' : '수정'" @click="item_update()" >수정</button>
      </div>
    </div>
</template>

<script>
import ItemList from './component/item_list'
import Address1 from './component/address1'
import Board from './component/board'
import General from './component/general'

export default {
  props: ['current_section'],
  data(){
    return {
      item_type: 'address',
      compo: 'item-list',
      mode: 'index',
      current_item: { },
    } 
  },

  computed: {
    items:{
      get(){
        return this.$store.state.footer_item
      },
      set(val){
        this.$store.state.footer_item = val
      }
    },
     
    item_order_length(){
      
      if(this.current_section.item_order != undefined){
        return this.current_section.item_order.length < 4
      }
      return true
    },
    		
		item_limit(){
			return this.$store.state.config.item_limit
		},

		item_count(){
			return this.$store.state.config.item_count
		}

  },

  methods: {
    compoSelect(compo, mode){
      this.compo = compo
      this.mode = mode
      if(mode=='new'){
        if(compo=='address1'){
          this.current_item = {
            title: '',
            subtitle: '',
            config: {
              compo: 'address1',
              iconName1: 'none',
              title1: '',
              iconName2: 'none',
              title2: '',
              iconName3: 'none',
              title3: '',
              iconName4: 'none',
              title4: '',
              iconName5: 'none',
              title5: '',
            },
            context: '',
          }
        }

      }
    },

    item_update(){
      this.current_item.context = $('.note-editable').html()
      if(this.current_item.id==undefined)this.current_item.id = 0
      let url = `/webpages/${this.page_type}/${this.current_section.id}/footer_item_update/${this.current_item.id}`
      this.current_item.config.compo = this.compo
      this.axios().put( url, { data: this.current_item } )
        .then(result=>{
          this.webpageNotice(result.data);
          if(result.data.result=='success'){
            this.compo = 'item-list'
            this.mode = 'index'
            if(this.current_item.id == 0){
              const items = result.data.data.item
              this.current_section.item_order = result.data.data.item_order
              this.items[items.id] = result.data.data.item
              this.$store.commit('sumary_board_update', result.data.sumary_boards)
              this.$store.commit('config_item_count_update', result.data.item_count)
            }else{
              this.items[result.data.data.item.id] = result.data.data.item
            }
          }
        })
    },

  },

  components: {
    ItemList,
    Address1,
    Board,
    General,
  }
}
</script>

