<template>
 
  <div class="heading-title heading-border heading-color mt-0" style="margin-bottom:20px">
    <h2 class="fs-25 fw-400" :style="'background:transparent; cursor:pointer;color:'+item.font_set.title_color">
      <i :class="item.icon" :style="'color:'+item.font_set.icon_color"></i>
      <font v-html="item.title "></font>
    </h2>
    <p class="fw-400 fs-15" :style="'color:'+item.font_set.subtitle_color" v-html="item.subtitle"></p>
  </div>


</template>

<script>
export default {
  props: ['item'],

  mounted() {
    $('div.heading-title.heading-border.heading-color, div.heading-title.heading-border.heading-inverse.heading-color').css('border-color',this.item.font_set.icon_color)
  }
}
</script>