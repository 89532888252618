<template>
  <!-- wrapper -->
  <div id="wrapper" >
    <section >
      <div class="container" >
        <div class="row" style="height: 55vh">
          <div class="col-lg-4 col-md-3" ></div>
          <div class="col-lg-4 col-md-6"  >
            <form class="nomargin sky-form boxed" style="display:table-cell;vertical-align:center"  >
              <header>
                <i class="fa fa-lock"></i> 보안페이지로그인
              </header>
              <fieldset class="nomargin">	
                <div class="row margin-bottom-10">
                  <div class="col-sm-12">
                    <div style="margin-bottom: 20px">
                      보안페이지를 열람하시기 위해서 비밀번호를 입력하셔야 합니다.
                    </div>
                    <b>비밀번호</b>
                    <label class="input margin-bottom-10">
                        <i class="ico-append fa fa-lock"></i>
                        <input type="password" placeholder="Password" v-model="password" @keyup.enter="memberLogin()" :class="{error: validation.hasError('password')}">
                    </label>
                    <div class="message" style="color:#CD554A">{{ validation.firstError('password') }}</div>
                  </div>    
                </div>
              </fieldset>
              <div class="row margin-bottom-20 text-right">
                <div class="col-md-12" style="padding-right: 40px;margin-bottom: 30px">
                  <div  class="btn btn-primary" @click="memberLogin()"><i class="fa fa-check"></i> 로그인</div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>  
  </div>
    
</template>


<script>

import Vue from 'vue'
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator.create({
  templates: {
    accept1: '개인정보보호 약관에 동의하셔야 가입이 가능합니다.(You can sign up only if you agree to the privacy terms and conditions.)'
  }
});
Vue.use(SimpleVueValidation);

export default {
  data(){
    return {
      password: '',
      headers: {'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),'webpage-id': webpage_id}
    }
  },
  validators: {
    
    'password' : function (value) {
      return Validator.value(value).required("required");
    },
      
  },

  created(){
    console.log("test------------------");
  },

  components: {
      
  }, 

  methods:{
    memberLogin(){
      this.$validate()
      .then(result =>{
        if(result){
          this.$http.defaults.headers.common["authToken"] =  this.$store.state.authToken
          this.$http.post(`/${this.$route.params.userid}/${this.$route.params.url}/security_pages_login`, {security_page: {password: this.password, webpage_id: webpage_id } }, { headers: this.headers } )
          .then(result=>{
            if(result.data.result=="success"){
              let path = `/${this.$route.params.userid}/${this.$route.params.url}`
              if(this.$route.query.subpage) path += `/${this.$route.query.subpage}`
              this.notice({title: '로그인',msg: '성공적으로 로그인 되었습니다.', type: 'success', loc: path, loctype: 'a',time: 1000})
            }else if(result.data.result=="password_incorrect"){
              this.notice({title: '비밀번호 에러', msg: result.data.msg})
            }else{
              this.notice({title: '로그인 에러',msg: '문제가 발생하여 로그인되지 않았습니다.'})
              this.error = result.data.info
            }
          })
          .catch(e=>{
            alert('토큰이 만료되었습니다. 자동 새로고침 됩니다. 다시 로그인해 주세요!');
            location.reload();
          })
        
        }
      })
   
    },
      
    
  }

}
</script>


<style>


</style>

