<template>
  <div >
    <section :style="section_background()+';padding-top:50px;min-height: 400px' " :id="section_tag_id">
      <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
      <div id='board_view' v-if="$store.state.board_data.board_item_keys.length > 0 && $route.params.board_id  "> 
        <board-view v-bind:current_section="current_section" v-on:board_modal_open='board_modal_open()' ></board-view>
      </div>
      <div class="container" style="z-index: 100">
        <div style="margin-bottom: 20px">
          <span
            v-if="ownerCheck && board_auth_check()"
            class="btn btn-sm btn-primary"
            type="text"
            @click="modal_open()"
          >글쓰기</span>

          <el-input placeholder="Search" size="mini" v-model="search_word" @keyup.enter.native="search_data()" class="input-with-select" style="width: 300px" clearable  >
            <el-button slot="prepend" icon="el-icon-refresh"  @click="clear()" v-show="!isEmpty(search_word)" ></el-button>
            <el-button slot="append" icon="el-icon-search" @click="search_data()"  ></el-button>
          </el-input> 
        </div>

        <div class="table-responsive" >
          <table class="table" >
            <thead>
              <th style="text-align:right">번호</th>
              <th>제목</th>
              <th>글쓴이</th>
              <th style="text-align:center">날짜</th>
            </thead>

            <tbody >
              <tr v-for="(n, index) in notice_data" :key="index">
                <td style="width: 100px;min-width: 70px;text-align:right" v-html="notice_icon(n)"> </td>
                <td @click="board_context(n.id)" style="min-width:230px;cursor:pointer;word-wrap: break-word;"  v-scroll-to="'#board_view'">{{ n.title }}</td>
                <td style="word-wrap: break-word;min-width:100px">{{ writers[n.member_id] == undefined ? n.writer : writers[n.member_id] }}</td>
                <td style="">{{ dateFormat(n.created_at,'YY/MM/DD') }}</td>
              </tr>
            </tbody>
            <tbody v-if="board_data.length>0" >
              <tr v-for="(b, index) in board_data" :key="index" :style="Number($route.params.board_id)==b.id ? 'font-weight:bold;' : ''" >
                <td style="width: 100px;min-width: 70px;text-align:right; ">
                  <i class="fa fa-caret-right" style='color:red'  v-if="Number($route.params.board_id)==b.id " ></i>
                  {{ boardItemNumber(index) }}
                </td>
                <td @click="board_context(b.id)" style="min-width:230px;cursor:pointer;"  v-scroll-to="'#board_view'"> {{ b.title }}</td>
                <td style="width: 80px">{{ writers[b.member_id] == undefined ? b.writer : writers[b.member_id] }}</td>
                <td style="width: 80px">{{ dateFormat(b.created_at,'YY/MM/DD') }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4">
                  <div class="alert alert-danger">데이터가 없습니다.</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>  
        <div>
          <span
            v-if="ownerCheck && board_auth_check()"
            class="btn btn-sm btn-primary text-right"
            type="text"
            @click="modal_open()"
          >글쓰기</span>
        </div>
        <div class="text-center">
          <el-pagination
            :page-size="board.perPage"
            :page-count="10"
            :current-page.sync="board_current_page"
            @current-change="current_change"
            layout="prev, pager, next"
            :total="board.totalCount">
          </el-pagination>
        </div>
      </div>


      <el-dialog :title="'글쓰기'" :close-on-click-modal="false" :visible.sync="outerVisible" :fullscreen="element_modal_fullscreen" >
        <div class="divider" style="margin:0px"></div>
        <div style="padding: 0 20px">
          <span class="btn btn-sm btn-danger" style="margin-bottom: 20px" v-if="compo=='board-itemindex'"  @click="addItem()" >아이템추가</span>  
          <span class="btn btn-sm btn-primary" v-if="compo=='board-form'"     @click="item_index()">목록</span>
          <span class="btn btn-sm btn-warning" v-if="compo=='board-form'"     @click="itemWriteLayoutset()">본문레이아웃설정</span>
          <span class="btn btn-sm btn-primary" v-if="compo=='item-layoutset' && false " @click="compo='board-form' " >뒤로</span>
          <div class="col-12 alert alert-warning" style="margin-top:10px">
            <p>현재 아이템 사용현황</p>
            <div>게시판 글쓰기는 아이템으로 분류 됩니다.</div>
            <div style="font-size: 18pt">{{ item_count }} / {{ item_limit }} </div>
            <cite style="font-size: 9pt"><b style='color:red'>{{ item_limit - item_count }}개 </b>더 추가 가능합니다.</cite>
          </div>
          <transition name="slide-fade">
            <component
              :is="compo"
              :compo.sync="compo"
              :current_section="current_section"
              :data.sync="data"
              :writerType="writerType"
              :outerVisible.sync="outerVisible"
              v-on:elmodalClose="elmodalClose()"
            ></component>
          </transition>
        </div>
      </el-dialog>


    </section>
    <slot name="setting"></slot>
  </div>
</template>

<script>
import BoardForm from "../modal/item_set/form";
import ItemLayoutset from "../modal/item_set/writer_set";
import BoardView from "./board_view";
import BoardItemindex from "../modal/subpage_set/board_item_index";
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto';

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -90,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

export default {
  props: ["index", "current_section"],
  data() {
    return {
      outerVisible: false,
      search_word: "",
      compo: "BoardForm",
      data: { },
      writerType: "board",
      board_totalCount: 0,
      board_perPage: 30,
      board_current_page: 1,
    };
  },

  mounted() {
    this.board_current_page = this.$route.query.current_page ? Number(this.$route.query.current_page) : 1
    this.search_word = this.$route.query.board_search
    if (this.$store.state.linebar01_excu_cnt == 0) {
      _owl_carousel();
      this.$store.state.linebar01_excu_cnt++;
    }
  },

  watch:{
    currentPage(val){
      return val
    }
  },

  computed: {
    board_section_format() {
      for (let i = 0; i < this.$store.state.section_format.length; i++) {
        if (this.$store.state.section_format[i].component == "writer01")return this.$store.state.section_format[i];
      }
    },

    board(){
      return this.$store.state.board_data;
    },

    board_items: {
      get() {
        return this.$store.state.board_data.board;
      },
      set(val) {}
    },

    notice_data(){
      return this.board.notice

    },

    writers(){
      return this.board.writers
    },

    board_data() {
      return this.board.boards
      // if(this.board_items[this.current_section.id] != undefined){
      //   let arr = this.board_items[this.current_section.id].reverse();
      //   let data = []
      //   if(arr.length > this.$store.state.board_data.perPage){
      //     //보드에 표시할 페이지 수가 perPage 수보다 넘어면 그 뒤를 자르는 코드
      //     for(let i=0; i < this.$store.state.board_data.perPage; i++ ){
      //       data.push(arr[i])
      //     }
      //   }else{
      //     data = arr
      //   }
      //   return data
      // }else{
      //   return {}
      // }
    },

    board_item: {
      get() {
        return this.$store.state.board_data.board_item;
      },
      set(val) {
        this.$store.state.board_data.board_item = val;
      }
    },
    
    item_limit(){
			return this.$store.state.config.item_limit
		},

		item_count(){
			return this.$store.state.config.item_count
    },
    
    font_set(){
      if(this.data.font_set!=undefined)return this.data.font_set
    },

    new_data(){
      return {
        id: "",
        title: "",
        subtitle: "",
        context: "",
        config: {
          type: "title",
          link1: { color: "primary", name: "" },
          link2: { color: "primary", name: "" }
        },
        font_set: {
          icon_color: "#4E5155",
          title_color: "#4E5155",
          subtitle_color: "#4E5155",
          context_color: "#4E5155"
        },
        icon: ""
      }
    }
    

  },

  methods: {
    itemWriteLayoutset() {
      this.data.context = $('.note-editable').html()
      this.compo = "item-layoutset";
    },

    item_index(){
      this.compo = "board-itemindex"
    },

    notice_icon(item){
      const icon_color = this.isEmpty(item.config.icon_color) ? '#CD554A' : item.config.icon_color
      if(this.isEmpty(item.config.icon)){
        return `<i class='fa fa-info' style='color:${icon_color}' ></i> `
      }else{
        return `<i class='${item.config.icon}' style='color:${icon_color}' ></i> `
      }
    },

    current_change(val){
      const current_page = `?current_page=${val}`
      const board_search = this.isEmpty(this.search_word) ? '' : `&board_search=${this.search_word}`
      this.get_board_data(current_page, board_search, val);
    },

    search_data(){
      const current_page = `?current_page=1`
      const board_search = this.isEmpty(this.search_word) ? '' : `&board_search=${this.search_word}`
      this.get_board_data(current_page, board_search, 1);
    },

    get_board_data( current_page, board_search, val ){
      this.axios().get(`/webpages/${this.page_type}/board${current_page}${board_search}`)
      .then(result=>{
        try {
          this.$router.push(`${this.$route.path}${current_page}${board_search}`)
          // this.$store.state.board_data.boards = result.data.board.boards
          // this.$store.state.board_data.current_page = val
          // this.$store.state.board_data.totalCount = result.data.board.totalCount
          this.$store.commit('set_board_data', result.data.board)
          this.board_current_page = val
        }catch(e){
            console.log(e);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    },

    board_context(board_id) {
      if(board_id != this.$route.params.board_id){
        const query = this.page_query()
        this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}/${this.$route.params.subpage}/${board_id}${query}`);
        this.axios().get(`/webpages/${this.page_type}/board_item/${board_id}`)
          .then(result => {
            if(result.data.result=="success"){
              this.$store.state.board_data.current_board= result.data.data.current_board
              this.board_item = result.data.data.board_item
              this.$store.state.board_data.board_item_keys = result.data.data.board_item_keys
            }
          });
      }
    },

    boardItemNumber(index) {
      return this.$store.state.board_data.totalCount - this.$store.state.board_data.perPage *(this.$store.state.board_data.current_page - 1) - index ;
    },

    board_modal_open(){
      this.outerVisible = true;
      this.compo = 'board-itemindex'
      this.$store.state.modal.section = this.current_section;
      this.$store.state.current_section_format = this.board_section_format;
      this.$store.state.board_writer_type = 'item_create'
    },

    modal_open() {
      this.outerVisible = true;
      this.compo = 'board-form'
      this.$store.state.modal.section = this.current_section;
      this.$store.state.current_section_format = this.board_section_format;
      this.$store.state.board_writer_type = 'create'
      $('.note-editable').html('')
      this.data = JSON.parse(JSON.stringify(this.new_data))
    },

    elmodalClose() {
      this.data.id = "";
      this.data.title = "";
      this.data.subtitle = "";
      this.data.context = "";
      this.data.config = {
        type: "title",
        link1: { color: "primary", name: "" },
        link2: { color: "primary", name: "" }
      };
      this.data.icon = "";
      $('.note-editable').html('')
      this.outerVisible = false;
    },

		addItem(){
      this.data = {
        id: '',
				title: '',
				subtitle: '',
				context: '',
				config: {type:'title', link1: { color: 'primary', name: '' }, link2: { color: 'primary', name: '' }, slide_set: {} },
				font_set: { icon_color: '#4E5155', title_color: '#4E5155', subtitle_color:'#4E5155', context_color: '#4E5155' },
				icon: '',
			}
      this.compo = 'board-form'
      
      const items = this.$store.state.board_data.board_item_keys
      if(items.length>0){
        const last_id = items[items.length-1]
        const last = this.$store.state.board_data.board_item[last_id]
        this.data.config = last.config
        this.data.font_set = last.font_set
      }
			// if(this.cur_section.item_order.length > 0){
			// 	//아이템 새로 등록시 앞에 작성했던 폰트 색상을 적용한다.
			// 	const last_item_id = this.cur_section.item_order[this.cur_section.item_order.length-1]
			// 	const last_item = this.$store.state.items[last_item_id].font_set
			// 	this.data.font_set.icon_color = last_item.icon_color
			// 	this.data.font_set.title_color = last_item.title_color
			// 	this.data.font_set.subtitle_color = last_item.subtitle_color
			// 	this.data.font_set.context_color = last_item.context_color
			// }
    },
    

    clear(){
      this.search_word = ''
      this.search_data()
    }

  },

  components: {
    BoardForm,
    ItemLayoutset,
    BoardView,
    BoardItemindex
  }
};
</script>

<style scoped>
 td {
   padding: 10px
 }
  
</style>


<style >
.el-dialog__body {
  padding-top: 0px;
}
.slide-fade-enter-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-active {
  transition: all 0.1s ease-out;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(200px);
  opacity: 0.5;
}
</style>

