<template>
  <!-- wrapper -->
  <section>
    <div class="container" :style="new_signup_page ? 'min-height: 45vh' : '' ">
      <div class="row">
        <div :class="new_signup_page ? 'col-md-6 col-md-offset-3' : 'col-md-12' ">
      
          <form :class="form_class">
          <fieldset class="nomargin">	
            <div class="alert alert-danger" v-if="error.length > 0" >
              <ul>
                <li v-for="(e,i) in error" :key='i'>{{ e }}</li>
              </ul>
            </div>

            <div class="row margin-bottom-10" v-if="!password_change_page" >
              <div class="col-md-6" v-if="mypage_signup_page" >
                <b>Email</b>
                <label class="input margin-bottom-10" >
                  <h3>{{ member.email }}</h3>
                </label>
              </div>    
              <div class="col-md-6" v-else >
                <b>Email</b>
                <label class="input margin-bottom-10" >
                  <i class="ico-append fa fa-envelope"></i>
                    <input 
                      type="text"  
                      placeholder="Email address" 
                      v-model="member.email" 
                      :class="{error: validation.hasError('member.email')}"
                      @blur="duplicate_email_check()"
                    >
                    <b class="tooltip tooltip-bottom-right">Email required</b>
                </label>
                <div class="message" style="color:#CD554A">{{ validation.firstError('member.email') }}</div>
              </div>    
            </div>
          
            <div class="row margin-bottom-10" v-if="password_change_page" >
              <div class="col-sm-6">
                <b>Current Password</b>
                  <label class="input margin-bottom-10">
                    <el-input placeholder="PASSWORD" v-model="member.current_password" show-password  ></el-input>
                  </label>
              </div>    
            </div>  

            <div class="row margin-bottom-10" v-if="new_signup_page || password_change_page" >
              <div class="col-sm-6">
                <b>Password</b>
                  <label class="input margin-bottom-10">
                    <el-input placeholder="PASSWORD" v-model="member.password" show-password :class="{error: validation.hasError('member.password')}" ></el-input>
                    <b class="tooltip tooltip-bottom-right">Alphabet, special symbols, and numbers are more than 8 characters</b>
                  </label>
                <div class="message" style="color:#CD554A">{{ validation.firstError('member.password') }}</div>
              </div>    

              <div class="col-sm-6">
                <b>Password_confirmation</b>
                  <label class="input margin-bottom-10">
                    <el-input placeholder="Confirm password" v-model="member.password_confirmation" show-password :class="{error: validation.hasError('member.password_confirmation')}" ></el-input>
                    <b class="tooltip tooltip-bottom-right">비밀번호를 확인합니다(Confirm your password).</b>
                  </label>
                  <div class="message" style="color:#CD554A">{{ validation.firstError('member.password_confirmation') }}</div>
              </div>
            </div>    

            <div class="row margin-bottom-10" v-if='!password_change_page' >
              <div class="col-md-6">
                <b>Name</b>
                <label class="input">
                  <input type="text" placeholder="Name" v-model="member.name" :class="{error: validation.hasError('member.name')}" >
                  <b class="tooltip tooltip-bottom-right">Name required</b>
                </label>
                <div class="message" style="color:#CD554A">{{ validation.firstError('member.name') }}</div>
              </div>
              <div class="col-md-6">
                <b>Cellphone</b>
                <label class="input">
                  <input type="text" placeholder="Phone" v-model="member.cellphone">
                  <div class="message" style="color:#CD554A">{{ validation.firstError('member.cellphone') }}</div>
                  </label>
              </div>
            </div>
            
            <div class="row margin-bottom-10" v-if='admin_edit_signup_page' >
              <div class="col-md-12">
                <b>관리자메모</b>
                <div class="fancy-form">
                  <textarea rows="5" class="form-control" v-model="member.admin_memo"  placeholder="관리자 메모"></textarea>
                </div>
              </div>
            </div>
            
            <div class="margin-top-30" v-if="new_signup_page" >
              <label class="checkbox nomargin">
                <input :class="{error: validation.hasError('accept1')}" v-model='accept1' type="checkbox" name="checkbox">
                <i></i> <a href="#" data-toggle="modal" data-target="#termsModal" @click="term_modal(1)" >개인정보 수집 및 이용에 대한 안내</a>에 동의합니다.
              </label>
              <label class="checkbox nomargin">
                <input :class="{error: validation.hasError('accept2')}" v-model='accept2' type="checkbox" name="checkbox">
                <i></i> <a href="#" data-toggle="modal" data-target="#termsModal" @click="term_modal(2)">회원 이용약관</a>에 동의합니다.
              </label>
            </div>
            <div class="message" style="color:#CD554A;font-weight: bold">{{ validation.firstError('accept1') }}</div>
            <div class="message" style="color:#CD554A;font-weight: bold">{{ validation.firstError('accept2') }}</div>

          </fieldset>

          <div class="row margin-bottom-30 text-right">
            <div class="col-md-12" style="padding-right: 40px">
              <div  class="btn btn-default" @click="close_modal()" style="margin-bottom: 30px" v-if="admin_edit_signup_page" ><i class="fa fa-close"></i> 닫기</div>
              <div  class="btn btn-primary" @click="memberPost()" style="margin-bottom: 30px" ><i class="fa fa-check"></i> {{ post_button }}</div>
            </div>
          </div>
          </form>

          <!-- MODAL -->
          <div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true" v-if="signup_pagetype=='signup_page'">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title" id="myModal">{{modal_context.title}}</h4>
                </div>

                <div class="modal-body modal-short"  >
                  <div v-html="showAllMemo(modal_context.context)"></div>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-default" data-dismiss="modal">닫기</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>     
    </div>     
  </section>
    
</template>


<script>

import Vue from 'vue'
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator.create({
  templates: {
    accept1: '개인정보 수집 및 이용에 대한 안내에 동의하셔야 가입이 가능합니다.(You can sign up only if you agree to the privacy terms and conditions.)'
  }
});
Vue.use(SimpleVueValidation);

//pagetype 1.signup_page:최초신청 2.edit_user:관리자수정 3.mypage_edit:마이페이지 수정, 4.password_change:패스워드 수정

export default {
  props: ['signup_pagetype', 'current_user','modal_visible'],
  data(){
    return {
      options: [],
      submited: false,
      member: { 
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
        current_password: '',
        cellphone : '',
        option1: '',
        option2: '',
        option3: '',
        memo:'',
        admin_memo: '',
      },
      accept1: [],
      accept2: [],
      error: [],
      email_duple_check_result:'',
      email_duple_check_msg:'',
      email_duple_checking: 0,
      headers: {'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),'webpage-id': webpage_id},
      modal_context: {
        title: '',
        context: '',
        type: 1,
      },
    }
  },
  validators: {
    'member.email': function(value) {
      if(this.mypage_signup_page){
        return Validator.value(value)
      }else{
        if(!this.password_change_page ){
          return Validator.value(value).required("required").email();
        }else{
          return Validator.value(value)
        }
      }
    },

    // 'member.userid' : function (value) {
    //   return Validator.value(value).required("required").regex('^[a-z]+[a-z0-9]{5,32}$', 'alphabet + number and More then 8 characters');
    // },

    'member.cellphone' : function (value) {
      if(!this.password_change_page ){
        return Validator.value(value).regex('^[0-9-]{8,20}$', '숫자와 - 만 가능하며 8자이상(Only numbers and hyphens are available. more than 8 characters)');
      }else{
        return Validator.value(value)
      }
    },

    'member.name' : function (value) {
      if(!this.password_change_page ){
        return Validator.value(value).required("required");
      }else{
        return Validator.value(value)
      }
    },
    
    'member.password' : function (value) {
      if(this.new_signup_page || this.password_change_page ){
        return Validator.value(value).required("required").regex('^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-_])(?=.*[0-9]).{8,32}', '알파벳(alphabet) + 숫자(number) + 특수문자(special characters) 혼용 그리고 8자이상(More than 8 characters)');
      }else{
        return Validator.value(value)
      }
    },

    'member.password_confirmation, member.password' : function (confirmation, password) {
      if(this.new_signup_page || this.password_change_page ){
        // if (this.submited || this.validation.isTouched('member.password_confirmation')) {
          return Validator.value(confirmation).required("required").match(password);
        // }
      }else{
        return Validator.value(confirmation)
      }

    },

    accept1: function(value){
      if(this.new_signup_page){
        return Validator.value(value).required('개인정보 수집 및 이용에 대한 안내에 동의하셔야 가입이 가능합니다.(You can sign up only if you agree to the information on personal information collection and use)')
      }else{
        return Validator.value(value)
      }
    },
    
    accept2: function(value){
      if(this.new_signup_page){
        return  Validator.value(value).required('회원이용 약관에 동의 하셔야 가입이 가능합니다.(You can sign up only if you agree to the terms and conditions for membership.)')
      }else{
        return Validator.value(value)
      }
    }
      
  },

  created(){
    var self=this
    setTimeout(function(){
      if(self.$store.state.use_signup==0)location.href=`/${self.$route.params.userid}/${self.$route.params.url}`
    },500)
  },

  mounted(){
    if(this.new_signup_page){
      if(process.env.NODE_ENV=='development'){
        this.member['email'] = 'test55@test.com'
        this.member['name'] = 'test55'
        this.member['password'] = 'asdf1111!'
        this.member['password_confirmation'] = 'asdf1111!'
        this.member['cellphone'] = '01079290141'
      }
    }
    if(this.current_user!=undefined)this.member = JSON.parse(JSON.stringify(this.current_user)) 
  },

  computed: {
    post_button(){
      if(this.new_signup_page){
        return 'REGISTER'
      }else{
        return '수정'
      } 
    },

    form_class(){
      return `nomargin sky-form ${ (this.mypage_signup_page || this.password_change_page) ? '' : 'boxed' }`
    },

    new_signup_page(){
      return this.signup_pagetype=='signup_page';
    },

    admin_edit_signup_page(){
      return this.signup_pagetype=='edit_user';
    },

    mypage_signup_page(){
      return this.signup_pagetype=='mypage_edit';
    },

    password_change_page(){
      return this.signup_pagetype=='password_change';
    },
  },

  methods:{

    memberPost(){
      if(this.new_signup_page){
        this.memberCreate()
      }else{
        if(this.admin_edit_signup_page){
          this.memberUpdate()
        }else if(this.mypage_signup_page){
          this.mypageUpdate()
        }else if(this.password_change_page){
          this.mypagePasswordChange()
        }
      }  
    },

    memberUpdate(){
      this.$validate()
      .then(result =>{
        const check =  this.duplicate_email_check();
        if(check==false)result=false
        if(result){
          const search = this.webpage_user_data_search()
          this.$http.defaults.headers.common["authToken"] = this.$store.state.authToken
          this.$http.put(`/${this.$route.params.userid}/${this.$route.params.url}/webpage_user_update/${webpage_id}${search}`, { webpage_user: this.member } )
          .then(result=>{
            this.webpageNotice(result.data)
            if(result.data.result=='success'){
              this.$store.commit('set_webpage_user', this.member)
              this.close_modal()
            }else{
              let error = []
              const keys = Object.keys(result.data.error)
              for(let k in keys){error.push(result.data.error[keys[k]][0])}
              this.error = error
              let self = this
              setTimeout(function(){ self.error = []},5000)
            }
          })
        }
      })
    },

    mypagePasswordChange(){
      this.$validate()
      .then(result =>{
        if(result){
          this.$http.defaults.headers.common["authToken"] = this.$store.state.authToken
          this.$http.put(`/${this.$route.params.userid}/${this.$route.params.url}/self_change_webpage_user`, this.member  )
          .then(result=>{
            this.webpageNotice(result.data)
            this.member.password = ''
            this.member.password_confirmation = ''
            this.member.current_password = ''
            if(result.data.result=='success'){
              setTimeout(function(){ location.reload() },2000)
            }else{
              this.error=[result.data.msg]
              var self = this
              setTimeout(function(){ self.error = '' },6000)
            }
          })
        }
      })
    },

    memberCreate(){
      this.submited = true
      this.$validate()
      .then(result =>{
        if(result){
          const check = this.duplicate_email_check();
          if(check){
          this.$http.defaults.headers.common["authToken"] =  this.$store.state.authToken
          this.$http.post(`/${this.$route.params.userid}/${this.$route.params.url}/webpage_users`, { webpage_user: this.member, auth: {admin_type: '5', main: [],sub: [],option: [],board:[]}} )
          .then(result=>{
            if(result.data.result){
              // localStorage.setItem('token', result.data.token)
              // this.$store.commit('set_login',result)
              this.notice({title: '로그인',msg: '정상적으로 회원가입 되었으며, 자동으로 로그인 됩니다.', type: 'success', loc:`/${this.$route.params.userid}/${this.$route.params.url}`,loctype: 'a',time: 1000})
            } else {
              console.log("error---------------");
              this.webpageNotice({type: 'error',  title: '회원가입 에러', msg: '문제가 발생하여 신청하지 못했습니다.' })
              this.error = result.data.info.map(e=>e[1])
              let self = this
              setTimeout(function(){ self.error = []},5000)
            }
            })
          }
        }else{
          this.webpageNotice({type: 'error',  title: '회원가입 에러',  msg: '문제가 발생하여 신청하지 못했습니다.' })
        }
      })
      .catch(e=>{
        console.log(e);
      })
   
    },

    mypageUpdate(){
      let check = []
      const cuser = this.$store.state.current_webpage_user
      const cuserkey = Object.keys(cuser) 
      for( let u in cuserkey){
        check.push(cuser[cuserkey[u]]==this.member[cuserkey[u]])
      }
      const change = check.filter(c=>c==false).length > 0
      if(change==false){
        this.webpageNotice({type: 'info', msg: '변경된 내용이 없습니다.' })
        return false
      }
      this.$validate()
      .then(result =>{
          this.$http.defaults.headers.common["authToken"] = this.$store.state.authToken
          this.$http.post(`/${this.$route.params.userid}/${this.$route.params.url}/webpage_user`, this.member  )
          .then(result=>{
            this.webpageNotice(result.data)
            if(result.data.result=='success'){
						  this.$store.commit('set_current_webpage_user', result.data.webpage_user)
            }
          })
      })

    },
      
    duplicate_email_check(){
      let data = { email: this.member.email }
      if(this.new_signup_page==false)data['user_id'] = this.current_user.id
      const result = this.$http.post(`/${this.$route.params.userid}/${this.$route.params.url}/email_duple_check`,data, { headers: this.headers })
      .then(result=>{
        this.email_duple_check_result = result.data.result
        this.email_duple_check_msg = result.data.msg
        if(result.data.result==false){
          this.error.push(result.data.msg[0][1])
          if(result.data.result==false)this.notice({title: '회원가입 에러',msg: '현재 이메일은 사용되고 있습니다. 다른 이메일을 사용하세요!'})
          let self = this
          setTimeout(function(){ self.error = []},5000)
        }
        return result.data.result
      })
      return result
    },

    term_modal(type){
      this.modal_context.title = (type==1) ? "개인정보 수집 및 이용에 대한 안내" : "회원 이용약관"
      this.modal_context.context = this.$store.state.terms.filter(t=>t.term_type==type)[0].context
    },

    form_check_errors(errors){
      let error = []
      const keys = Object.keys(errors)
      for(let k in keys){
        error.push(errors[keys[k]][0])
      }
      return error
    },

    close_modal(){
    //관리자 모드에서의 모달창 닫기
      this.$emit("update:modal_visible", false)

    },

  }

}
</script>


<style>


</style>

