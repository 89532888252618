<template>
  <div>
    <modal-header v-bind:title="'메뉴 및 서브페이지 설정'"></modal-header>
		<div class="modal-body" style="padding:10px" >

      <el-tabs type="border-card">
        <el-tab-pane label="메뉴설정" @click="tab_click('menu_set')">
          <menu-set></menu-set>
        </el-tab-pane>
        <el-tab-pane label="서브페이지설정"  @click="tab_click('subpage_set')">
          <subpage-set></subpage-set>
        </el-tab-pane>
      </el-tabs> 

		</div>
  </div>
</template>

<script>
import ModalHeader 	from './modal_header'
import SubpageSet   from './subpage_set/subpage_set_main'
import MenuSet      from './menu_set/menu_set_main'


export default {
  data(){
    return {
      compo: 'menu-set',
    }
  },

  methods: {
    tab_click(compo){
      this.compo = compo
    },
    
		tab_class(val){
			if(this.$store.state.modal.active == val){
				return 'nav-link active'
			}else{
				return 'nav-link'
			}
		},
  },

  components: {
    ModalHeader,
    SubpageSet,
    MenuSet
  }
  
}
</script>


