<template>
 

      <!-- SLIDE  -->
      <li :data-transition="item.slide_set.transition" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off" :data-title="item.item.title"  :style="'background-color:'+ item.slide_set.background_color">

        <img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/_smarty/1x1.png" :data-lazyload="item.background_image || slide_image_no_response_check"  v-if="item.slide_set.background_use!=1" alt="" data-bgfit="cover" data-bgposition="left top" data-bgrepeat="no-repeat" />
        
        <div class="tp-dottedoverlay twoxtwo"><!-- dotted overlay --></div>
        <div class="overlay dark-3"><!-- dark overlay [1 to 9 opacity] --></div>

        <div v-if="item.item.file2.origin!='' || item.item.file2.origin!=undefined"
          class="tp-caption lfb" 
          data-x="right" data-hoffset="-70" 
          data-y="120" 
          data-speed="2000" 
          data-start="500" 
          data-easing="easeOutExpo" 
          data-elementdelay="0.1" 
          data-endelementdelay="0.1" 
          data-endspeed="300" 
          style="z-index: 2;">
          <img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/_smarty/1x1.png" alt="" :data-lazyload="item.item.file2.origin || slide_file2_noimage">
        </div>


        <div class="tp-caption large_bold_grey skewfromrightshort customout font-open-sans"
          data-x="170"
          data-y="70"
          data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
          data-speed="500"
          data-start="800"
          data-easing="Back.easeOut"
          data-endspeed="500"
          data-endeasing="Power4.easeIn"
          data-captionhidden="off"
          :style="title_font_css">{{ item.item.title }}
        </div>

        <div class="tp-caption large_bold_grey skewfromleftshort customout font-open-sans"
          data-x="170"
          data-y="133"
          data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
          data-speed="300"
          data-start="1100"
          data-easing="Back.easeOut"
          data-endspeed="500"
          data-endeasing="Power4.easeIn"
          data-captionhidden="off"
          :style="subtitle_font_css">{{ item.item.subtitle }}
        </div>

        <div class="tp-caption ltl customin customout small_light_white font-lato"
          data-x="170"
          data-y="235"
          data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1.3;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
          data-speed="500"
          data-start="1300"
          data-easing="easeOutQuad"
          data-splitin="none"
          data-splitout="none"
          data-elementdelay="0.01"
          data-endelementdelay="0.1"
          data-endspeed="500"
          data-endeasing="Power4.easeIn"  :style="context_font_css" v-html="htmlDecode(item.item.context)" >
        </div>


        <div class="tp-caption medium_light_white skewfromleftshort customout"
          data-x="170" data-hoffset="-90"
          data-y="430"
          data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
          data-speed="500"
          data-start="1650"
          data-easing="Back.easeOut"
          data-endspeed="500"
          data-endeasing="Power4.easeIn"
          data-captionhidden="off"
          style="z-index: 11; text-shadow:none;">
            <button :class="'btn btn-'+item.item.config.link1.color" @click='link_button(item.item.link1)' style='margin-right:20px' v-if="item.item.config.link1.name!=''" >{{ item.item.config.link1.name }}</button>
            <button :class="'btn btn-'+item.item.config.link2.color" @click='link_button(item.item.link2)' v-if="item.item.config.link2.name!=''"  >{{ item.item.config.link2.name }}</button>
        </div>

      </li>


    

</template>

<script>
export default {
  props: ['item'],

  computed: {
    title_font_css(){
      return `z-index: 4; font-weight:bold;color:${this.item.item.font_set.title_color}`
    },
    subtitle_font_css(){
      return `z-index: 4; font-weight:bold;color:${this.item.item.font_set.subtitle_color}`
    },
    context_font_css(){
      return `z-index: 10; width: 750px; max-width: 750px; white-space: normal;color:${this.item.item.font_set.context_color}`
    },
  },

  

  
}
</script>