<template>

  <div  style="margin-top:20px">
    <div class="row">
      <div  class="col-6">
        <label>글제목색</label>
        <color-picker  v-bind:font_color.sync='color.title_color' v-bind:label="'글제목색'"      v-bind:option="{label: false, input: false}" ></color-picker>
        <input type="text" class="form-control"  v-model="color.title_color" style="max-width:120px" >
      </div>
      <div  class="col-6">
        <label>글부제목색</label>
        <color-picker  v-bind:font_color.sync='color.subtitle_color' v-bind:label="'글부제목색'"  v-bind:option="{label: false, input: false}" ></color-picker>
        <input type="text" class="form-control"  v-model="color.subtitle_color" style="max-width:120px" >
      </div>
      <div  class="col-6" style="margin-top:20px">
        <label>글본문색</label>
        <color-picker  v-bind:font_color.sync='color.context_color' v-bind:label="'글본문색'"    v-bind:option="{label: false, input: false}" ></color-picker>
        <input type="text" class="form-control"  v-model="color.context_color" style="max-width:120px" >
      </div>
      <div  class="col-6" style="margin-top:20px">
        <label>아이콘색</label>
        <color-picker  v-bind:font_color.sync='color.icon_color' v-bind:label="'아이콘색'"       v-bind:option="{label: false, input: false}" ></color-picker>
        <input type="text" class="form-control"  v-model="color.icon_color" style="max-width:120px" >
      </div>

    </div>
      
      <div class="divider" style="margin: 10px 0"><!-- divider --></div>

      <div style="text-align:right">
        <button class="btn btn-md btn-danger" @click="go_back()">취소</button>
        <button class="btn btn-md btn-success" @click="item_update()" >수정</button>
      </div>

  </div>
  

</template>

<script>
import ColorPicker from '../../../../common_components/color_picker.vue'
export default {
  props: ['compo'],
  data(){
    return{
      color: {},
      item_id: 0,
      
    }
  },

  mounted(){
    if(this.cur_section_order.length > 0){
      this.color = {
          icon_color: this.item_first_option.icon_color,
          title_color: this.item_first_option.title_color,
          subtitle_color: this.item_first_option.subtitle_color,
          context_color: this.item_first_option.context_color
        }

    } else {
      this.$emit('update:compo','index')
    }   
  },

  computed:{
    cur_section_order: {
      get(){
        return this.$store.state.modal.section.item_order;
      }
    },
    cur_items: {
      get(){
        return this.$store.state.items
      }
    },
    cur_item: {
      get(){
        return this.cur_items[this.item_id]
      },
      set(val){

      }
    },
    item_first_option(){
      return this.cur_items[this.cur_section_order[0]].font_set
    }
  },

  components: {
    ColorPicker
  },

  methods:{
    
    item_update(){
      if(this.cur_section_order.length > 0){
        this.axios().put(`/webpages/${this.page_type}/items_all_option_update/${this.$store.state.modal.section.id}`,this.color)
        .then(result=>{
          if(result.data.result=='success'){
            let curitem = {}
            for(let i=0;i<this.cur_section_order.length;i++){
              this.item_id = this.cur_section_order[i]
              curitem = this.cur_item
              curitem.font_set.icon_color = this.color.icon_color 
              curitem.font_set.title_color = this.color.title_color 
              curitem.font_set.subtitle_color = this.color.subtitle_color 
              curitem.font_set.context_color = this.color.context_color 
              this.cur_item = curitem
              this.$emit('update:compo', 'index');
              // this.modalClose('#webpage_setting')
              this.$store.commit('set_ant_modal_visible', false)
            }
          }
          this.webpageNotice(result.data);
  
        })
      }else{
        this.webpageNotice({ result: 'error', type: 'error', msg: '현재 섹션에서 수정할 아이템이 없습니다.' });
      }

    },

    go_back(){
      this.$emit("update:compo", 'index')
    },
  }

}
</script>

<style scoped >
  .form-control:not(textarea){
    height: 40px !important
  } 

</style>