<template>
    <div>
      <div>
        <label v-if="option.label==true">{{label}}</label>
        <verte picker="square" model="hex" v-if="!$browserDetect.isIE" v-model='fontColorInput' :enableAlpha='false' :menuPosition="vlocation" ></verte>
        <swatches v-model="fontColorInput" v-if='$browserDetect.isIE' row-length="10" colors="text-advanced" :popover-to="slocation" style="float:left;margin-right:5px"/>
        <input type="text" class="form-control" v-model='fontColorInput' style="width:150px;float:left;background-color:white" v-if="option.input==true" >
      </div>
      <div v-if="$browserDetect.isIE && option.input==true" style="float:right">크롬이나 파이어폭스 브라우져를 사용하시면 디테일한 색상조정표를 사용할 수 있습니다.</div>
    </div>
</template>


<script>
  import Vue from 'vue';
  import Verte from 'verte';
  import 'verte/dist/verte.css';
  Vue.component(Verte.name, Verte);
  import Swatches from 'vue-swatches'
  import "vue-swatches/dist/vue-swatches.min.css"
  
  export default {
    props: ['font_color','label', 'option'],
    data(){
      return {
        slocation: 'center',
        vlocation: 'center'
      }
    },
    
    created(){
      this.slocation = this.option.location == undefined ? 'center' : this.option.location
      this.vlocation = this.mobile_screen ? 'center' : this.option.location == undefined ? 'bottom' : this.option.location
    },

    computed: {
      fontColorInput: {
        get(){
          return this.font_color
        },
        set(val){
          this.$emit('update:font_color', val)
        }
      },
    },
    components:{
       Verte,
       Swatches,
    },
  }


</script>


<style >

  .verte__guide {
    width: 30px;
    height: 30px;
    padding: 0;
    border: 1;
    background: transparent;
  }

  .verte {
    justify-content: center;
    width: 40px;
    margin-right:10px;
    margin-top: 0px;
    border: 1px solid #CCCCCC;
    float: left;
    padding: 3px
  }

</style>