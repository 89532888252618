<template>
  <span>
      <el-input
        size="mini"
        style="width:50%;margin-right:0px"
        v-model="text_sample">
      </el-input>
      <el-popover
        placement="left"
        width="400"
        trigger="click">
        <div style="height:500px">
            <el-slider v-model="font_size" :min="8" :max="40"></el-slider>
            <div style="overflow: auto;height:440px">
              <div v-for="f in $store.state.font_list" :key='f' >
                <label style="color:#CD554A">[{{ f }}]</label>
                <p :style="'font-family:'+f+';font-size:'+font_size+'px;margin-bottom:10px;color:#212121'">{{text_sample}}</p>
              </div>
            </div>
        </div>
        <el-button slot="reference" size="mini" >폰트확인</el-button>
      </el-popover>   
  </span>
</template>

<script>
export default {
  data(){
    return {
      text_sample: "폰트 샘플입니다. 입력하시면 폰트를 확인할 수 있습니다.",
      font_size: 16,
    }
  }
}
</script>
