<template>
    <!-- wrapper -->
    <div id="wrapper">
        <sub-bar></sub-bar>
        <section>
            <div class="container">
                
                <div class="row">

                    <!-- LOGIN -->
                    <div class="col-md-4 col-md-offset-4">
                        <form class="nomargin sky-form boxed">
                            <header>
                             <i class="fa fa-users"></i> 로그인
                            </header>

                            <fieldset class="nomargin">	
                                <div class="alert alert-danger" v-if="error!=''">{{error}}</div> 
                                <div class="row margin-bottom-10">
                                    <div class="col-md-12">
                                        <b>아이디</b>
                                        <label class="input margin-bottom-10">
                                            <i class="ico-append fa fa-user"></i>
                                            <input type="text" ref='userid' placeholder="UserID" v-model="member.userid" :class="{error: validation.hasError('member.userid')}" >
                                            <b class="tooltip tooltip-bottom-right">아이디는 필수입니다.</b>
                                        </label>
                                        <div class="message" style="color:#CD554A">{{ validation.firstError('member.userid') }}</div>

                                    </div>  
                                </div>
                            

                                <div class="row margin-bottom-10">
                                    <div class="col-sm-12">
                                        <b>비밀번호</b>
                                        <label class="input margin-bottom-10">
                                            <i class="ico-append fa fa-lock"></i>
                                            <input type="password" placeholder="Password" v-model="member.password" @keyup.enter="memberLogin()" :class="{error: validation.hasError('member.password')}">
                                            <b class="tooltip tooltip-bottom-right">알파벳, 특수기호, 숫자조합으로 8자 이상입니다.</b>
                                        </label>
                                        <div class="message" style="color:#CD554A">{{ validation.firstError('member.password') }}</div>

                                    </div>    
                                    
                                </div>
                            
                                
                            </fieldset>

                            <div class="row margin-bottom-20 text-right">
                                <div class="col-md-12" style="padding-right: 40px">
                                    <div  class="btn btn-primary" @click="memberLogin()"><i class="fa fa-check"></i> 로그인</div>
                                </div>
                            </div>

                        </form>
                        <!-- /register form -->

                    </div>
                    <!-- /LOGIN -->

                    

                </div>

                
            </div>
        </section>
        <!-- / -->

			<!-- MODAL -->
			<div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<button type="button" class="close" data-dismiss="modal" aria-hidden="true">
								&times;
							</button>
							<h4 class="modal-title" id="myModal">Terms &amp; Conditions</h4>
						</div>

						<div class="modal-body modal-short">
							<h4><b>Introduction</b></h4>
							

						</div>

						<div class="modal-footer">
							<button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
							<button type="button" class="btn btn-primary" id="terms-agree"><i class="fa fa-check"></i> I Agree</button>
							
							<a href="page-print-terms.html" target="_blank" rel="nofollow" class="btn btn-danger pull-left"><i class="fa fa-print"></i><span class="hidden-xs"> Print</span></a>
						</div>

					</div><!-- /.modal-content -->
				</div><!-- /.modal-dialog -->
			</div>
			<!-- /MODAL -->

    </div>
    
</template>


<script>
import SubBar from "../../common_components/subbar"
import Vue from 'vue'
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);

export default {
   data(){
       return {
           member: { 
               userid: '',
               password: '',
           },
           error: '',

       }
    },
    mounted(){
      this.$refs.userid.focus()
    },
    validators: {

        'member.userid' : function (value) {
            return Validator.value(value).required();
        },
       
        'member.password' : function (value) {
            return Validator.value(value).required();
        },

    },
    components: {
        SubBar
    }, 
    methods:{
        memberLogin(){
            this.submited =  true
            this.$validate()
            .then((result)=>{
                if(result){
                  this.$http.post('/login',  {member: this.member} )
                  .then((result)=>{
                    if(result.data.result){
                      localStorage.setItem('token', result.data.token)
                      this.$store.commit('set_login',result)
                      this.notice({title: '로그인',msg: '정상적으로 로그인 되었습니다.', type: 'success', loc:'/',loctype: 'a',time: 500})
                      this.error = ''
                    } else {
                      this.error = result.data.errors.error[0]
                    }
                  })
                  .catch((e)=>{
                      this.notice({title: '에러',msg: '토큰이 만료되어 새로고침 됩니다. 다시 로그인해 주세요', type: 'error'})
                      setTimeout(function(){
                          location.reload();
                      },1500)
                  })
                }
            })
            .catch((e)=>{
                this.notice({title: '에러',msg: e, type: 'error'})
            })
        }
    }
  
}
</script>


<style>


</style>

