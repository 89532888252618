<template>
  <!-- wrapper -->
  <section>
    <div class="container" style="min-height: 45vh">
      <div class="row">
        <div class="col-md-6 col-md-offset-3" v-if="valid_page" >
          <form class="nomargin sky-form boxed"  >
            <header>
              <i class="fa fa-lock"></i> 비밀번호 변경
            </header>
            <fieldset class="nomargin">	
              <div v-if="error!=''" >{{error}}</div>
              <div style="margin-bottom: 20px">
                변경하실 비밀변호를 입력하여 주세요!
              </div>
              <b>변경하실 비밀번호</b>
              <label class="input margin-bottom-10">
                  <i class="ico-append fa fa-lock"></i>
                  <input type="password" placeholder="Password" v-model="password" @keyup.enter="resetPassword()" :class="{error: validation.hasError('password')}">
              </label>
              <div class="message" style="color:#CD554A">{{ validation.firstError('password') }}</div>
          
            </fieldset>
            <div class="row margin-bottom-20 text-right" >
              <div class="col-md-12" style="padding-right: 40px;margin-bottom: 30px">
                <div  class="btn btn-primary" @click="resetPassword()"><i class="fa fa-check"></i> 변경</div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-6 col-md-offset-3" v-else >
          <div class="alert alert-danger"> 
            <h3>유효하지 않는 페이지입니다. 메인페이지로 이동합니다.</h3>
          </div>
        </div>

      </div>
    </div>
  </section>
    
</template>


<script>

import Vue from 'vue'
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator.create({
  
});
Vue.use(SimpleVueValidation);

export default {
  data(){
    return {
      password: '',
      headers: {'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),'webpage-id': webpage_id},
      valid_page: true,
      error: '',
    }
  },
  validators: {
    'password' : function (value) {
      return Validator.value(value).required("required").regex('^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,32}', '알파벳(alphabet) + 숫자(number) + 특수문자(special characters) 혼용 그리고 8자이상(More than 8 characters)');
    },
  },

  created(){
    this.page_valid_check()
  },

  methods:{
    page_valid_check(){
      this.axios().get(`/reset_password_page_valid/${webpage_id}/${this.$route.query.reset_password_token}`)
      .then(result=>{
        this.valid_page = result.data.result
        if(!result.data.result){
          this.webpageNotice({type: 'error', msg: '유효하지 않는 페이지입니다. 메인페이지로 이동합니다.'});
          let self = this
          setTimeout(function(){
            self.$router.push(`/${self.$route.params.userid}/${self.$route.params.url}`)
          },3000)
        }
      })
    },

    resetPassword(){
      this.$validate()
      .then(result =>{
        if(result){
          this.$http.defaults.headers.common["authToken"] =  this.$store.state.authToken
          this.axios().put(`/${this.$route.params.userid}/${this.$route.params.url}/reset_password_update`, {password: this.password, reset_password_token: this.$route.query.reset_password_token }, { headers: this.headers } )
          .then(result=>{
            this.webpageNotice(result.data);
            if(result.data.type=='success'){
              this.password = ''
              var self = this
              setTimeout(function(){self.$router.push(`/${self.$route.params.userid}/${self.$route.params.url}`)},2000)
            }else{
              if(result.data.type=="redirect"){
                this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}`)
              }else{
                let err_msg = '<ul style="font-weight:bold;margin-bottom:0px">' 
                for( let e of result.data.msg ){
                  err_msg +=`<li>${e}</li>`
                }
                err_msg +='</ul>'
                this.error=err_msg
                result.data.msg = err_msg
                let self = this
                setTimeout(function(){ self.error='' },4000)
              }
            }
          })
          .catch(e=>{
            alert('토큰이 만료되었습니다. 자동 새로고침 됩니다. 다시 시도해 주세요!');
            location.reload();
          })
        
        }
      })
   
    },
      
    
  }

}
</script>


<style>


</style>

