<template>
	<div>

			<div class="slider fullwidthbanner-container roundedcorners">
				<!--
					Navigation Styles:
					
						data-navigationStyle="" theme default navigation
						
						data-navigationStyle="preview1"
						data-navigationStyle="preview2"
						data-navigationStyle="preview3"
						data-navigationStyle="preview4"
						
					Bottom Shadows
						data-shadow="1"
						data-shadow="2"
						data-shadow="3"
						
					Slider Height (do not use on fullscreen mode)
						data-height="300"
						data-height="350"
						data-height="400"
						data-height="450"
						data-height="500"
						data-height="550"
						data-height="600"
						data-height="650"
						data-height="700"
						data-height="750"
						data-height="800"
				-->
				<div class="fullwidthbanner" data-height="600" data-shadow="0" data-navigationStyle="preview2">
					<ul class="hide">

						<!-- SLIDE  -->
						<li data-transition="curtain-3" data-slotamount="1" data-masterspeed="1500" data-delay="10000" data-saveperformance="off" data-title="Slide 1" style="background-color: #4E5155;">

							<img src="/images/background/b07.jpg"  data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat">

							<div class="tp-caption customin ltl tp-resizeme revo-slider-caps-text uppercase"
								data-x="left" data-hoffset="0"
								data-y="70"
								data-customin="x:-200;y:0;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="400"
								data-start="1000"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn">
								<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/desktop_slider_2.png" alt="">
							</div>

							<div class="tp-caption large_bold_grey skewfromrightshort customout font-open-sans"
								data-x="645"
								data-y="70"
								data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="800"
								data-easing="Back.easeOut"
								data-endspeed="500"
								data-endeasing="Power4.easeIn"
								data-captionhidden="off"
								style="z-index: 4; font-weight:bold;">EasyHB
							</div>

							<div class="tp-caption large_bold_grey skewfromleftshort customout font-open-sans"
								data-x="645"
								data-y="133"
								data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="300"
								data-start="1100"
								data-easing="Back.easeOut"
								data-endspeed="500"
								data-endeasing="Power4.easeIn"
								data-captionhidden="off"
								style="z-index: 7; font-weight:bold !important;">Multipurpose
							</div>

							<div class="tp-caption ltl customin customout small_light_white font-lato"
								data-x="650"
								data-y="235"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1.3;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="500"
								data-start="1300"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="500"
								data-endeasing="Power4.easeIn" style=" color: #333; font-size:20px; max-width: 550px; white-space: normal; text-shadow:none;">
								EasyHB Multipurpose Template, now offering you
								over 600 html pages fully customizable + Admin + RTL!<br />
								<strong>Lifetime free update!</strong>
							</div>


							<div class="tp-caption medium_bg_darkblue skewfromleftshort customout"
								data-x="650" data-hoffset="-90"
								data-y="430"
								data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="1650"
								data-easing="Back.easeOut"
								data-endspeed="500"
								data-endeasing="Power4.easeIn"
								data-captionhidden="off"
								style="z-index: 11; text-shadow:none;">Html Pages
							</div>


							<div class="tp-caption medium_bg_red skewfromleftshort customout"
								data-x="800" data-hoffset="-90"
								data-y="430"
								data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="2300"
								data-easing="Back.easeOut"
								data-endspeed="500"
								data-endeasing="Power4.easeIn"
								data-captionhidden="off"
								style="z-index: 21; text-shadow:none;">Admin Included
							</div>

						</li>

						<!-- SLIDE  -->
						<li data-transition="zoomout" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off" data-title="Slide 2">

							<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/_smarty/1x1.png" data-lazyload="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/index-min.jpg" alt="" data-bgfit="cover" data-bgposition="center bottom" data-bgrepeat="no-repeat" />

							<div class="overlay dark-1"><!-- dark overlay [1 to 9 opacity] --></div>

							<div class="tp-caption customin ltl tp-resizeme text_white"
								data-x="center"
								data-y="155"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1000"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 10;">
								<span class="fw-300">DEVELOPMENT / MARKETING / DESIGN / PHOTO</span>
							</div>

							<div class="tp-caption customin ltl tp-resizeme large_bold_white"
								data-x="center"
								data-y="205"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1200"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 10;">
								WELCOME TO EASYHB
							</div>

							<div class="tp-caption customin ltl tp-resizeme small_light_white font-lato"
								data-x="center"
								data-y="295"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1400"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 10; width: 100%; max-width: 750px; white-space: normal; text-align:center; font-size:20px;">
								Fabulas definitiones ei pri per recteque hendrerit scriptorem in errem scribentur mel fastidii propriae philosophia cu mea.
							</div>

							<div class="tp-caption customin ltl tp-resizeme"
								data-x="center"
								data-y="383"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1550"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 10;">
								<a href="#purchase" class="btn btn-default btn-lg">
									<span>Purchase EasyHB Now</span> 
								</a>
							</div>

						</li>

						<!-- SLIDE  -->
						<li data-transition="flyin" data-slotamount="7" data-masterspeed="300"  data-saveperformance="off" data-title="Slide 3">
							<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/_smarty/1x1.png" data-lazyload="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/wall2.jpg" alt="" data-bgfit="cover" data-bgposition="left top" data-bgrepeat="no-repeat" />

							<div class="tp-caption lfr" 
								data-x="left" data-hoffset="60"
								data-y="85" 
								data-speed="1500" 
								data-start="500" 
								data-easing="easeOutExpo" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 2;">
								<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/_smarty/1x1.png" data-lazyload="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/ipad.png" alt="" />
							</div>

							<div class="tp-caption block_black sft" 
								data-x="right" data-hoffset="-70"
								data-y="137" 
								data-speed="750" 
								data-start="1100" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 3; max-width: auto; max-height: auto; white-space: nowrap;">EasyHB Template
							</div>

							<div class="tp-caption block_black sfb" 
								data-x="right" data-hoffset="-70" 
								data-y="176" 
								data-speed="750" 
								data-start="1400" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 4; max-width: auto; max-height: auto; white-space: nowrap;">100% Fully Responisve
							</div>

							<div class="tp-caption block_theme_color sft" 
								data-x="right"  data-hoffset="-70"
								data-y="239" 
								data-speed="750" 
								data-start="1700" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 5; max-width: auto; max-height: auto; white-space: nowrap;">With Incredible
							</div>

							<div class="tp-caption block_theme_color sfb" 
								data-x="right"  data-hoffset="-70"
								data-y="278" 
								data-speed="750" 
								data-start="2000" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 6; max-width: auto; max-height: auto; white-space: nowrap;">Additions &amp; Optimizations
							</div>

							<div class="tp-caption block_black sft" 
								data-x="right"  data-hoffset="-70"
								data-y="340" 
								data-speed="750" 
								data-start="2300" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap;">For Your Next Projects
							</div>
						</li>

						<!-- SLIDE  -->
						<li data-transition="parallaxtoright" data-slotamount="7" data-masterspeed="300" data-title="Slide 4" data-target="_blank" data-saveperformance="off">

							<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/_smarty/1x1.png" data-lazyload="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/wall.jpg" alt="" data-bgfit="cover" data-bgposition="left top" data-bgrepeat="no-repeat" />

							<div class="tp-caption lfb" 
								data-x="right" data-hoffset="-70" 
								data-y="120" 
								data-speed="2000" 
								data-start="500" 
								data-easing="easeOutExpo" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300" 
								style="z-index: 2;">
								<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/_smarty/1x1.png" alt="" data-lazyload="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/desktop_slider.png">
							</div>


							<div class="tp-caption large_bold_grey skewfromrightshort customout font-open-sans"
								data-x="170"
								data-y="70"
								data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="800"
								data-easing="Back.easeOut"
								data-endspeed="500"
								data-endeasing="Power4.easeIn"
								data-captionhidden="off"
								style="color: #fff;z-index: 4; font-weight:bold;">EasyHB
							</div>

							<div class="tp-caption large_bold_grey skewfromleftshort customout font-open-sans"
								data-x="170"
								data-y="133"
								data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="300"
								data-start="1100"
								data-easing="Back.easeOut"
								data-endspeed="500"
								data-endeasing="Power4.easeIn"
								data-captionhidden="off"
								style="color: #fff;z-index: 7; font-weight:bold !important;">Multipurpose
							</div>

							<div class="tp-caption ltl customin customout small_light_white font-lato"
								data-x="170"
								data-y="235"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1.3;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="500"
								data-start="1300"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="500"
								data-endeasing="Power4.easeIn" style=" color: #fff; font-size:20px; max-width: 550px; white-space: normal; text-shadow:none; z-index: 4">
								EasyHB Multipurpose Template, now offering you
								over 600 html pages fully customizable + Admin + RTL!<br />
								<strong>Lifetime free update!</strong>
							</div>


							<div class="tp-caption medium_bg_darkblue skewfromleftshort customout"
								data-x="170" data-hoffset="-90"
								data-y="430"
								data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="1650"
								data-easing="Back.easeOut"
								data-endspeed="500"
								data-endeasing="Power4.easeIn"
								data-captionhidden="off"
								style="z-index: 11; text-shadow:none;">Html Pages
							</div>


							<div class="tp-caption medium_bg_red skewfromleftshort customout"
								data-x="350" data-hoffset="-90"
								data-y="430"
								data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="2300"
								data-easing="Back.easeOut"
								data-endspeed="500"
								data-endeasing="Power4.easeIn"
								data-captionhidden="off"
								style="z-index: 21; text-shadow:none;">Admin Included
							</div>

						</li>

						<!-- SLIDE  -->
						<li data-transition="zoomin" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off" data-title="Slide 5">

							<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/_smarty/1x1.png" data-lazyload="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/1200x800/24-min.jpg" alt="" data-bgposition="center center" data-kenburns="on" data-duration="10000" data-ease="Linear.easeNone" data-bgfit="100" data-bgfitend="110" />

							<div class="tp-caption very_large_text lfb ltt tp-resizeme"
								data-x="right" data-hoffset="-100"
								data-y="center" data-voffset="-50"
								data-speed="600"
								data-start="800"
								data-easing="Power4.easeOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="500"
								data-endeasing="Power4.easeIn">
								RUN WILD
							</div>

							<div class="tp-caption medium_light_white lfb ltt tp-resizeme"
								data-x="right" data-hoffset="-110"
								data-y="center" data-voffset="60"
								data-speed="600"
								data-start="900"
								data-easing="Power4.easeOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="500"
								data-endeasing="Power4.easeIn">
								Happiness is an accident of nature,<br/>
								a beautiful and flawless aberration.<br/>
								<span style="font-size:24px;font-weight:400;">&ndash; Pat Conroy</span>
							</div>

						</li>

						<!-- SLIDE -->
						<li v-if="false" data-transition="scaledownfrombottom" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off" data-title="Slide 6">

							<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/_smarty/1x1.png" data-lazyload="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/video/back.jpg" alt="video" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat">

							<div class="tp-caption tp-fade fadeout fullscreenvideo"
								data-x="0"
								data-y="50"
								data-speed="1000"
								data-start="1100"
								data-easing="Power4.easeOut"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1500"
								data-endeasing="Power4.easeIn"
								data-autoplay="true"
								data-autoplayonlyfirsttime="false"
								data-nextslideatend="true"
								data-volume="mute" 
								data-forceCover="1" 
								data-aspectratio="16:9" 
								data-forcerewind="on" style="z-index: 2;">

								<div class="tp-dottedoverlay twoxtwo"><!-- dotted overlay --></div>

								<video class="" preload="none" style="widt:100%;height:100%" poster="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/video/back.jpg">
									<source src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/video/back.webm" type="video/webm" />
									<source src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/video/back.mp4" type="video/mp4" />
								</video>

							</div>

							<div class="tp-caption customin ltl tp-resizeme text_white"
								data-x="center"
								data-y="155"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1000"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 3;">
								<span class="fw-300">DEVELOPMENT / MARKETING / DESIGN / PHOTO</span>
							</div>

							<div class="tp-caption customin ltl tp-resizeme large_bold_white"
								data-x="center"
								data-y="205"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1200"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 3;">
								WELCOME TO EASYHB
							</div>

							<div class="tp-caption customin ltl tp-resizeme small_light_white font-lato"
								data-x="center"
								data-y="295"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1400"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 3; width: 100%; max-width: 750px; white-space: normal; text-align:center; font-size:20px;">
								Fabulas definitiones ei pri per recteque hendrerit scriptorem in errem scribentur mel fastidii propriae philosophia cu mea.
							</div>

							<div class="tp-caption customin ltl tp-resizeme"
								data-x="center"
								data-y="363"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1550"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 3;">
								<a href="#purchase" class="btn btn-default btn-lg">
									<span>Purchase EasyHB Now</span> 
								</a>
							</div>

						</li>

					</ul>

					<div class="tp-bannertimer"><!-- progress bar --></div>
				</div>
			</div>
			<slot name="setting"></slot>
	</div>
</template>


<script>
export default {
	mounted(){
		// var revapi;
    // // Make Content Visible
    // jQuery(".fullwidthbanner ul , .fullscreenbanner ul").removeClass('hide');


    // /**
    // 	@HALFSCREEN SLIDER
    // **/
    // if (jQuery(".fullwidthbanner").length > 0) {

    //     // Default Thumbs [small]
    //     var thumbWidth = 100,
    //         thumbHeight = 50,
    //         hideThumbs = 200,
    //         navigationType = "bullet",
    //         navigationArrows = "solo",
    //         navigationVOffset = 10;

    //     // Shadow
    //     var _shadow = jQuery(".fullwidthbanner").attr('data-shadow') || 0;

    //     // Small Thumbnails
    //     if (jQuery(".fullwidthbanner").hasClass('thumb-small')) {
    //         var navigationType = "thumb";
    //     }

    //     // Large Thumbnails
    //     if (jQuery(".fullwidthbanner").hasClass('thumb-large')) {
    //         var navigationType = "thumb";
    //         thumbWidth = 195,
    //             thumbHeight = 95,
    //             hideThumbs = 0,
    //             navigationArrows = "solo",
    //             navigationVOffset = -94;

    //         // Hide thumbs on mobile - Avoid gaps
    //         /**
    //         if(jQuery(window).width() < 800) {
    //         	setTimeout(function() {
    //         		var navigationVOffset = 10;
    //         		jQuery("div.tp-thumbs").addClass('hidden');
    //         	}, 100);
    //         }
    //         **/
    //     }

    //     // Init Revolution Slider
    //     revapi = jQuery('.fullwidthbanner').revolution({
    //         dottedOverlay: "none",
    //         delay: 9000,
    //         startwidth: 1170,
    //         startheight: jQuery(".fullwidthbanner").attr('data-height') || 500,
    //         hideThumbs: hideThumbs,

    //         thumbWidth: thumbWidth,
    //         thumbHeight: thumbHeight,
    //         thumbAmount: parseInt(jQuery(".fullwidthbanner ul li").length) || 2,

    //         navigationType: navigationType,
    //         navigationArrows: navigationArrows,
    //         navigationStyle: jQuery('.fullwidthbanner').attr('data-navigationStyle') || "round", // round,square,navbar,round-old,square-old,navbar-old (see docu - choose between 50+ different item)

    //         touchenabled: "on",
    //         onHoverStop: "on",

    //         navigationHAlign: "center",
    //         navigationVAlign: "bottom",
    //         navigationHOffset: 0,
    //         navigationVOffset: navigationVOffset,

    //         soloArrowLeftHalign: "left",
    //         soloArrowLeftValign: "center",
    //         soloArrowLeftHOffset: 20,
    //         soloArrowLeftVOffset: 0,

    //         soloArrowRightHalign: "right",
    //         soloArrowRightValign: "center",
    //         soloArrowRightHOffset: 20,
    //         soloArrowRightVOffset: 0,

    //         parallax: "mouse",
    //         parallaxBgFreeze: "on",
    //         parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

    //         shadow: parseInt(_shadow),
    //         fullWidth: "on",
    //         fullScreen: "off",

    //         stopLoop: "off",
    //         stopAfterLoops: -1,
    //         stopAtSlide: -1,

    //         spinner: "spinner0",
    //         shuffle: "off",

    //         autoHeight: "off",
    //         forceFullWidth: "off",

    //         hideThumbsOnMobile: "off",
    //         hideBulletsOnMobile: "on",
    //         hideArrowsOnMobile: "on",
    //         hideThumbsUnderResolution: 0,

    //         hideSliderAtLimit: 0,
    //         hideCaptionAtLimit: 768,
    //         hideAllCaptionAtLilmit: 0,
    //         startWithSlide: 0,
    //         fullScreenOffsetContainer: ""
    //     });

    //     // Used by styleswitcher onle - delete this on production!
    //     jQuery("#is_wide, #is_boxed").bind("click", function() { revapi.revredraw(); });
    // }


    // /**
    // 	@FULLSCREEN SLIDER
    // **/
    // if (jQuery(".fullscreenbanner").length > 0) {
		// 	// var tpj = jQuery;
    //     // tpj.noConflict();
    //     var revapi25;

    //     // Shadow
    //     _shadow = jQuery(".fullscreenbanner").attr('data-shadow') || 0;
    //   //  console.log("fullscreen------------------------------");

    //     $(document).ready(function() {

    //         if ($('.fullscreenbanner').revolution != undefined) {
    //             revapi25 = $('.fullscreenbanner').show().revolution({
    //                 dottedOverlay: "none",
    //                 delay: 9000,
    //                 startwidth: 1200,
    //                 startheight: 700,
    //                 hideThumbs: 10,

    //                 thumbWidth: 100,
    //                 thumbHeight: 50,
    //                 thumbAmount: 4,

    //                 navigationType: "none",
    //                 navigationArrows: "solo",
    //                 navigationStyle: jQuery('.fullscreenbanner').attr('data-navigationStyle') || "round",

    //                 touchenabled: "on",
    //                 onHoverStop: "on",

    //                 swipe_velocity: 0.7,
    //                 swipe_min_touches: 1,
    //                 swipe_max_touches: 1,
    //                 drag_block_vertical: false,

    //                 keyboardNavigation: "on",

    //                 navigationHAlign: "center",
    //                 navigationVAlign: "bottom",
    //                 navigationHOffset: 0,
    //                 navigationVOffset: 30,

    //                 soloArrowLeftHalign: "left",
    //                 soloArrowLeftValign: "center",
    //                 soloArrowLeftHOffset: 20,
    //                 soloArrowLeftVOffset: 0,

    //                 soloArrowRightHalign: "right",
    //                 soloArrowRightValign: "center",
    //                 soloArrowRightHOffset: 20,
    //                 soloArrowRightVOffset: 0,

    //                 parallax: "mouse",
    //                 parallaxBgFreeze: "on",
    //                 parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

    //                 shadow: parseInt(_shadow),
    //                 fullWidth: "off",
    //                 fullScreen: "on",

    //                 stopLoop: "off",
    //                 stopAfterLoops: -1,
    //                 stopAtSlide: -1,

    //                 shuffle: "off",

    //                 forceFullWidth: "off",
    //                 fullScreenAlignForce: "off",

    //                 hideThumbsOnMobile: "off",
    //                 hideBulletsOnMobile: "on",
    //                 hideArrowsOnMobile: "off",
    //                 hideThumbsUnderResolution: 0,

    //                 hideSliderAtLimit: 0,
    //                 hideCaptionAtLimit: 768,
    //                 hideAllCaptionAtLilmit: 0,
    //                 startWithSlide: 0,
    //                 fullScreenOffsetContainer: jQuery("#header").hasClass('transparent') || jQuery("#header").hasClass('translucent') ? null : "#header"
    //             });

    //         }
    //     }); //ready

    // }

    // /**
    // 	@KEN BURNS
    // **/
    // if (jQuery(".fullscreenbanner.ken-burns").length > 0) {

    //     revapi = jQuery('.fullwidthbanner').revolution({
    //         dottedOverlay: "none",
    //         delay: 9000,
    //         startwidth: 1170,
    //         startheight: 400,
    //         hideThumbs: 200,

    //         thumbWidth: 100,
    //         thumbHeight: 50,
    //         thumbAmount: 5,

    //         navigationType: "bullet",
    //         navigationArrows: "solo",
    //         navigationStyle: jQuery('.fullwidthbanner').attr('data-navigationStyle') || "round",

    //         touchenabled: "on",
    //         onHoverStop: "off",

    //         navigationHAlign: "center",
    //         navigationVAlign: "bottom",
    //         navigationHOffset: 0,
    //         navigationVOffset: 10,

    //         soloArrowLeftHalign: "left",
    //         soloArrowLeftValign: "center",
    //         soloArrowLeftHOffset: 20,
    //         soloArrowLeftVOffset: 0,

    //         soloArrowRightHalign: "right",
    //         soloArrowRightValign: "center",
    //         soloArrowRightHOffset: 20,
    //         soloArrowRightVOffset: 0,

    //         shadow: 0,
    //         fullWidth: "on",
    //         fullScreen: "off",

    //         stopLoop: "off",
    //         stopAfterLoops: -1,
    //         stopAtSlide: -1,


    //         shuffle: "off",

    //         autoHeight: "off",
    //         forceFullWidth: "off",

    //         hideThumbsOnMobile: "off",
    //         hideBulletsOnMobile: "off",
    //         hideArrowsOnMobile: "off",
    //         hideThumbsUnderResolution: 0,

    //         hideSliderAtLimit: 0,
    //         hideCaptionAtLimit: 0,
    //         hideAllCaptionAtLilmit: 0,
    //         startWithSlide: 0,
    //         fullScreenOffsetContainer: ""
    //     });

    //     // Used by styleswitcher only - delete this on production!
    //     jQuery("#is_wide, #is_boxed").bind("click", function() { revapi.revredraw(); });

    // }

	}
  
}
</script>

<style>
 .slider {
	 margin-bottom: 0px;
 }

 .tp-bullets {
	 margin-bottom:30px;
 }
</style>

