<template>
	<div>
		<section :class="section_class" :style="cur_background" :id='section_tag_id'>
      <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
      <smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
      <div class="container" style="z-index: 100" v-if="current_section.item_order.length > 0" >

        <div class="owl-carousel text-center owl-testimonial nomargin" :data-plugin-options='data_css'  >
          <div class="testimonial" v-for="(i, idx) in current_section.item_order" :key='idx' >
            <figure  v-if="!object_empty_check(ctem[i].file1.thumb)">
              <img :class="image_round" :src="ctem[i].file1.thumb" style="width: 150px; height: 150px" alt="" />
            </figure>
            <div class="testimonial-content" style="padding-left:0px">
              <cite :style="'font-size:1.3em;color:'+ctem[i].font_set.title_color">
                {{ ctem[i].title }}
                <span :style="'margin-bottom:0px;color:'+ctem[i].font_set.subtitle_color">{{ ctem[i].subtitle}}</span>
              </cite>
              <div v-if="current_section.item_order.length > 3" >
                <div :style="'text-align:left;margin-top:30px;padding-top:20px;color:'+ctem[i].font_set.icon_color" v-html="showAllMemo(ctem[i].media)" v-if="media_source_test(ctem[i].media)" ></div>
								<div :style="'text-align:justify; padding-left:20px;padding-right:20px;color:'+ctem[i].font_set.context_color" v-html="htmlDecode(ctem[i].context)"></div>
              </div>
              <div class="row" v-else>
                <div class="offset-md-3 col-6" style="margin-top:30px;" >
									<div :style="'color:'+ctem[i].font_set.context_color" v-html="showAllMemo(ctem[i].media)" ></div>
								  <div :style="'text-align:justify; padding-left:20px;padding-right:20px;color:'+ctem[i].font_set.context_color" v-html="htmlDecode(ctem[i].context)"></div>
                </div>
              </div>
            
            </div>
          </div>

        </div>
                
      </div>



      <default v-else></default>
		</section>
	
	
  <slot name="setting"></slot>
		
  </div>
    
</template>


<script>
import Default from './default/introduce03_default'


export default {
		props: ['index','current_section'],

	mounted(){
    _owl_carousel();
	},
	components: {
		Default,
	},

	computed: {
		item_count_css(){
			if(this.current_section.item_order.length > 3){
				return "col-md-3 col-sm-6"
			}else{
				return "col-md-4 col-sm-6"
			}
    },

    section_class(){
      if(this.current_section.config.section_set.use_background==0){
        return "padding-xxs dark"
      }
      return ''
    },

    cur_background(){
			let cssp = this.section_background()
			cssp += (!this.isEmpty(this.current_section.title) || !this.isEmpty(this.current_section.sub_title))? ';padding-top:0px;' :''
			return cssp
		},
 
    data_css(){
      
      if(this.current_section.item_order.length > 3){
        return '{"items":4, "singleItem": false, "autoPlay": 3500, "navigation": false, "pagination": true, "transitionStyle":"fade"}'
      }else{
        if(this.current_section.item_order.length == 1){
          return '{ "singleItem": true, "autoPlay": 0, "navigation": false, "pagination": false, "transitionStyle":""}'
        }else{
          return '{ "singleItem": true, "autoPlay": 3500, "navigation": false, "pagination": true, "transitionStyle":"fade"}'
        }  
      }
    },

    font_color(){
      if(this.current_section.config.section_set.use_background == 0){
        return '#fff !important'
      }else{
        return this.lightOrDark(this.current_section.config.section_set.color) > 185 ? '#2f2f2f ' : '#fff' 
      }
    }

	}
}
</script>


<style>

</style>

