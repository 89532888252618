<template>
<div>
	<section :style="section_background('background: transparent;')+';padding:0px'" :id='section_tag_id' >
		<div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
		
		<smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>

		<div class="container" >
			<div class="mt-30 mb-30" v-if="current_section.item_order.length > 0" >
				<div class="owl-carousel m-0 text-center" :data-plugin-options='data_option'>
					<div  v-for="(i, idx) in current_section.item_order" :key='idx'  @click="link_button(ctem[i].link1)" style="cursor:pointer; margin-right: 10px" >
						<img class="img-fluid" :src="ctem[i].file1.large" alt="" style="height: 45px;" v-if="!isEmpty(ctem[i].file1.large)" >
						<h3 :style="'padding-bottom:0px;margin-bottom: 0px;color:'+ctem[i].font_set.title_color" >
							<i :class="ctem[i].icon" :style="'color:'+ctem[i].font_set.icon_color"></i> {{ctem[i].title}}
						</h3>
						<h5 :style="'margin-bottom:0px;color:'+ctem[i].font_set.subtitle_color" >{{ctem[i].subtitle}}</h5>
					</div>
					
				</div>
			</div>
			<default v-else ></default>
		</div>
	</section>
	
	<slot name="setting"  ></slot>
</div>

    
</template>


<script>
import Default from './default/link01_default';

export default {
	props: ['index','current_section'],
	mounted(){
		if(this.$store.state.linebar01_excu_cnt == 0){
			_owl_carousel();
			this.$store.state.linebar01_excu_cnt++
		}

	},
	components: {
		Default,
	},

  computed: {
		data_option(){
			return `{"items":5, "singleItem": false,  "stopOnHover":false, "autoPlay":4000, "autoHeight": false, "navigation": false, "pagination": false }`
		}
	}

}
</script>


<style>

</style>

