<template>
  <div>
    <div class="modal-body"> 
      <div class="alert alert-danger" v-if="!isEmpty(loading_data_msg)" >
        {{ loading_data_msg }}
      </div>

      <div class="container">
        <div v-if="active=='list'">
          <div class="row">
            <div class="col-lg-6">
              <el-input placeholder="Search Homepage" size="mini" v-model="search_word" @keyup.enter.native="homelist_search_word()" class="input-with-select" style="margin-top:20px;width: 100%">
                <el-button slot="prepend" icon="el-icon-refresh"  @click="search_word='';homelist_search_word()" v-show="!isEmpty(search_word)" ></el-button>
                <el-button slot="append" icon="el-icon-search" @click="homelist_search_word()" ></el-button>
              </el-input>
            </div>
          </div>
          <table class="table" style="margin-top:10px" >
            <thead>
              <th style="text-align: right;width: 100px">번호</th>
              <th>샤플명</th>
              <th>부제목</th>

            </thead>
            <tbody>
              <tr v-for="(l, index) in homepage_list" :key="index">
                <td style="text-align: right;width: 100px">{{ list_number(index) }}</td>
                <td style="cursor: pointer" @click="section_list(l)" >{{ l.title }}</td>
                <td>{{ l.subtitle }} </td>
              </tr>
            </tbody>
          </table>

          <div class="text-center"  style="margin-bottom: 30px"  >
            <el-pagination
              :page-size="page_info.per_page"
              @current-change="current_change"
              layout="prev, pager, next"
              :total="page_info.totalCount">
            </el-pagination>
          </div>
        </div>

        <div v-if="active=='section_list'">
          <div class="btn btn-sm btn-warning" @click="$emit('update:active','list')" >샤플 목록</div>
          <h1></h1>
          <table class="table" style="margin-top:10px" >
            <thead>
              <th style="text-align: right;width: 80px">섹션번호</th>
              <th>섹션종류</th>
              <th>섹션제목</th>
              <th>섹션부제목</th>
              <th>옵션</th>
            </thead>
            <tbody>
              <tr v-for="(l, index) in sections.section_order" :key="index">
                <td style="text-align: right;width: 100px">{{ index + 1 }}번</td>
                <td style="cursor: pointer"  v-html="component_name(sections.section_data[l].component)" ></td>
                <td>{{ sections.section_data[l].title }} </td>
                <td>{{ sections.section_data[l].subtitle }} </td>
                <td>
                  <span class="btn btn-sm btn-primary" @click="section_add('subsection_add',sections.section_data[l] )" v-if="unable_add_section(sections.section_data[l])" >추가</span> 
                  <span style="color: red" v-else >{{ used_section(sections.section_data[l])==false ? "이미 추가된 섹션" : "추가 불가섹션"  }} </span>
              </td>
              </tr>
            </tbody>
          </table>
        </div>   

      </div>
    </div>
    
  </div>

</template>


<script>

export default {
  props: ['active'],
  data(){
    return {
      search_word: '',
      section_formats: [],
      sections: {
        section_data: {},
        section_order: []
      },
      loading_data_msg: '',
      current_webpage: {}
    }
  },

  created(){
    
  },

  mounted(){
    // this.select_section_format()
  },

  computed: {
    current_data(){
      return this.$store.state.homepage_list;
    },

    page_info: {
      get(){
        return this.current_data.page_info
      },
      set(val){
        this.current_data.page_info = val
      }
    },

    homepage_list(){
      return this.current_data.list
    },

  },
 
	methods:{
    current_change(val){
      this.page_info.current_page = val
      this.get_select_homepage_list()
    },

    component_name(component){
      var sname = ''
      var component = component
      for(var i=0; i <= (this.section_format.length - 1);i++ ){
        if(this.section_format[i].component == component)sname = this.section_format[i].name
      } 
      return sname
    },

    list_number(index) {
      return this.page_info.totalCount - this.page_info.per_page *(this.page_info.current_page - 1) - index ;
    },

    used_section(section){
      for(const s of this.$store.state.section_order){
        if(section.id==s)return false
      }
      return true
    },

    unable_add_section(section){
      if(section.component=='sumaryboard01') return false
      if(this.used_section(section)==false) return false
      return true
    },

    homelist_search_word(){
      this.get_select_homepage_list()
    },

    section_list(data){
      this.current_webpage = data
      this.$emit("update:active",'section_list');
      this.loading_data_msg = '데이터를 가져오는 중입니다. 잠시만 기다려 주세요!'
      this.axios().get(`/webpages/get_section_list/${data.webpage_id}`)
      .then(result=>{
        if(result.data.result == 'success'){
          this.sections = result.data.section_list
          this.loading_data_msg = '성공적으로 데이터를 가져왔습니다.'
          self=this
          setTime = setTimeout(function(){self.loading_data_msg = ''; },1000)
        }
      })
    },

  },

  beforeDestroy(){
    clearTimeout(setTime);
  }
  
}
</script>

<style>
		@media (max-width: 500px) {
			#knob {
				padding-left: 100px
			}
		}
</style>
