<template>
  <div>
    <modal-header v-bind:title="main_title"></modal-header>
    <div class="container" style="margin-bottom: 30px">
      <div class="row" v-if="mode=='edit'" style="margin-top: 30px">
        <div class="col-md-12 alert alert-warning" v-if='!isEmpty(errMsg)' v-html='errMsg'  ></div>
        <div class="col-sm-6" style="margin-bottom:30px">
          <label >샤플명</label>
          <input type="text" class="form-control" v-model="current_data.title" placeholder=""  style="height: 35px !important" >
        </div>
        <div class="col-sm-6" style="margin-bottom:30px">
          <label >부제목명</label>
          <input type="text" class="form-control" v-model="current_data.subtitle"  placeholder=""  style="height: 35px !important" >
        </div>
      </div>
      <div class="divider" style="margin: 0px 0"></div>
      <div class="text-right">
        <div>
          <span class="btn btn-sm btn-primary"  @click="modal_close()" >취소</span>
          <span class="btn btn-sm btn-danger"   @click="update()" >수정</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalHeader from './modal_header'
import 'core-js/es7/array' //array includes를 윈도우 익스플로러에서 사용하기 위하여 필요함

export default {
  data(){
    return {
      compo: 'index',
      dupleCheckMsg: '',
      errMsg: '',
      current_data: {}
    }
  },

  components: {
    ModalHeader
  },

  watch:{
   
    mode(val){
      return this.$store.state.modal.action
    },

    item_data(val){
      this.current_data =  val
    }
  },

  computed:{

    mode(){
      return this.$store.state.modal.action
    },

    item_data(){
      this.current_data = JSON.parse(JSON.stringify(this.$store.state.modal.data)); 
      return this.current_data
    },

    main_title(){
      const title = {  edit: '샤플 수정', delete: '샤플 삭제' }
      return title[this.mode]
    },

  },

  methods: {

    update(){
      if(!this.isEmpty(this.current_data.title)){
        this.axios().put(`/webpages/update_homepagelist/${this.$store.state.modal.data.section_id}/${this.current_data.id}/`, this.current_data)
        .then(result=>{
          this.webpageNotice(result.data);
          if(result.data.result=='success'){
            for(let h of this.$store.state.homepage_list.list ){
              if(h.id == this.current_data.id){
                h.title = this.current_data.title
                h.subtitle = this.current_data.subtitle
              } 
            }
            this.$store.commit('set_ant_modal_visible', false)
          }
        })
      }else{
        this.errMsg = '샤플명은 필수입니다.'
      }
    },

    modal_close(){
      // $('#webpage_setting').modal('hide');
      this.$store.commit('set_ant_modal_visible', false)
    },


  },
  
 

}
</script>

<style scoped>
  .form-control:not(textarea){
    height: 40px !important
  } 
</style>