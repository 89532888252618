<template>
  <div>
    <div class="divider" style="margin: 10px 0"></div>
    <div class="row"  style="padding-left:20px;margin-top:20px">
      <div class="col-xs-6" style="padding-right:10px">
        <label>페이지전환</label>
        <el-select v-model="slide_set.transition" size='small' placeholder="선택" style="width:100px">
          <el-option
            v-for="t in transitionArr"
            :key="t"
            :label="t"
            :value="t">
          </el-option>
        </el-select>
      </div>
        <div class="col-xs-6" >   
          <label >배경 사용</label>
          <el-radio-group v-model="slide_set.background_use" size="small">
            <el-radio-button label="1">배경색</el-radio-button>
            <el-radio-button label="2">기본배경</el-radio-button>
            <el-radio-button label="3">사용자배경</el-radio-button>
          </el-radio-group>
        </div>
    </div> 

    <div class="row" style="padding-left:20px" >
      <div class="col-xs-6"  style="margin-top:20px;margin-right:5px">
        <label>배경색선택</label>
        <color-picker  v-bind:font_color.sync='slide_set.background_color' v-bind:label="'글자색'" v-bind:option="{label: false, input: false, location: 'top' }" ></color-picker>
        
      </div>
      <div class="col-xs-6"  style="margin-top:20px;margin-right:5px">
        <label >기본배경 이미지선택</label>
    
        <button v-for=' index in 8' @click="background_select(index)" :key='index' style="margin-left:5px" class="pointer switcher_thumb" >
          <img :src="'/images/background/b0'+index+'.jpg'" width="50" height="50" :style="select_image(index)" />
        </button>
        
        
      </div>
         
    </div>

    <div style="margin-top:20px">
      <label>슬라이드 type</label>
      <el-radio-group v-model="slide_set.slide_type">
        <el-radio :label="index" v-for='index in cur_format.slide_type_cnt' :key='index' >{{index}}번</el-radio> 
      </el-radio-group>
      <img :src="slide_img" width="100%">
    </div>

    <div class="divider" style="margin: 10px 0"></div>
    <div style="text-align:right">
      <button class="btn btn-md btn-success" @click="move_slide_form()" >다음</button>
    </div>     

  </div>
</template>


<script>
import ColorPicker from '../../../../common_components/color_picker'
export default {
  props: ['data','compo'],
  data(){
    return {
      transitionArr: [ 'random','curtain-1', 'curtain-2','curtain-3','slotzoom-horizontal','slotzoom-vertical','slotfade-vertical','boxslide','boxfade','slideleft','fade','cubic-horizontal','parallaxtoright', 'zoomout','zoomin','flyin' ]         
    }
  },
  computed: {

    slide_set: {
      get(){
        return this.data.config.slide_set
      },
      set(val){
        this.data.config.slide_set = val
      }
    },
    cur_format(){
      return this.$store.state.current_section_format
    },
    slide_img(){
      let num = this.slide_set.slide_type
      if(num < 10) num = `0${num}`
      return `/images/slide_type/${this.cur_format.component}_${num}.png`
    },

    transitionArrAll(){
      return [ "boxslide", "boxfade", "slotslide-horizontal", "slotslide-vertical", "curtain-1", "curtain-2", "curtain-3", "slotzoom-horizontal", "slotzoom-vertical", "slotfade-horizontal", "slotfade-vertical", "fade", "slideleft", "slideup", "slidedown", "slideright", "papercut", "3dcurtain-horizontal", "3dcurtain-vertical", "cubic", "cube", "flyin", "turnoff", "incube", "cubic-horizontal", "cube-horizontal", "incube-horizontal", "turnoff-vertical", "fadefromright", "fadefromleft", "fadefromtop", "fadefrombottom", "fadetoleftfadefromright", "fadetorightfadetoleft", "fadetobottomfadefromtop", "fadetotopfadefrombottom", "parallaxtoright", "parallaxtoleft", "parallaxtotop", "parallaxtobottom", "scaledownfromright", "scaledownfromleft", "scaledownfromtop", "scaledownfrombottom", "zoomout", "zoomin", "notransition" ]
    }
   
  },

  methods: {
    move_slide_form(){
      this.$emit('update:compo','slide-form')
    },

    background_select(index){
      this.slide_set.background_default_image = 'b0'+index+'.jpg'
    },

    select_image(index){
      if(this.slide_set.background_default_image == 'b0'+index+'.jpg'  ){
        return 'border: 3px solid #E3A538'
      }
    },


    slide_set_update(){

    }
  },

  components: {
		ColorPicker
	}
  
}
</script>