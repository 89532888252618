<template>
  <!-- WELCOME -->
  <div class="pb-0">
    <div class="container" style="min-height: 40vh" >
      <div v-if="ownerCheck && board_auth_check()" style="margin-top:50px; font-size: 9pt;color: #CD554A">
        <span @click="go_list()" style="cursor:pointer" >목록</span> | 
        <span @click="$emit('board_modal_open')"  style="cursor:pointer">수정 </span> 
        <span @click="notice_save()"  v-if="webpage_id==current_board.webpage_id"  style="cursor:pointer" v-html="notice_title" ></span> 
      </div>

      <div class="row" >
        <div @click="search_link()"  v-if="webpage_id!=current_board.webpage_id" style="color: #3E8FAB;cursor:pointer;margin-top: 20px;margin-left: 10px">[원본페이지보기]</div> 
        <div v-for="(tmp, idx) in board_item_keys" :key="idx" :class="outline_size(board_items[tmp])">
          <div>
            <div class="card card-default" style="background:transparent" v-if="outline(board_items[tmp])==2">
              <div class="card-block">
                <board-context
                  v-bind:item="board_items[tmp]"
                  v-bind:idx="idx"
                  v-bind:current_section="current_section"
                ></board-context>
              </div>
            </div>

            <div v-else>
              <board-context
                v-bind:item="board_items[tmp]"
                v-bind:idx="idx"
                v-bind:current_section="current_section"
              ></board-context>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top:50px; font-size: 9pt;color: #CD554A">
        <span @click="go_list()" style="cursor:pointer" >목록</span> 
        <span v-if="ownerCheck && board_auth_check()" >
          | 
          <span @click="$emit('board_modal_open')"  style="cursor:pointer">수정</span>
          <span @click="notice_save()" v-if="webpage_id==current_board.webpage_id"  style="cursor:pointer" v-html="notice_title" ></span> 
        </span>
      </div>
      <div @click="search_link()"  v-if="webpage_id!=current_board.webpage_id" style="color: #3E8FAB;cursor:pointer;margin-top: 20px;">[원본페이지보기]</div> 

      <div class="divider" style="margin: 0; margin-top: 50px" ></div>
    </div>
  </div>
</template>


<script>
import BoardContext from "./writer_type/board_context";

export default {
  props: ["current_section"],
  data() {
    return {
      re_order: [],
      item_id: 0,
      webpage_id: 0,
    };
  },

  mounted() {
    this.webpage_id = webpage_id
    this.item_div();
    //this.lightOrDark 함수는 mixin/common.js에 있음
    this.background_color_test = this.lightOrDark(
      this.current_section.config.section_set.color
    );
  },

  watch: {
    item_order(val) {
      //이 코드 없으면 데이트 업데이트시 적용이 안됨
      this.item_div();
      return val;
    }
  },

  computed: {
    current_board(){
      return this.$store.state.board_data.current_board
    },

    board() {
      return this.$store.state.board_data.board[this.$route.params.board_id];
    },

    board_item_keys() {
      return this.$store.state.board_data.board_item_keys;
    },

    board_items() {
      return this.$store.state.board_data.board_item;
    },

    notice_title(){
      if(this.current_board.notice == 0){
        return "| <font><b>공지사항 등록</b></font>"
      }else{
        return "| <font color='green'><b>공지사항 취소</b></font>"
      }
    },

  },

  methods: {
    outline(item) {
      if(item.font_set.writer_set==undefined) item.font_set.writer_set = this.$store.state.default_writer_set;
      return item.font_set.writer_set.outline;
    },

    go_list(){
      this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}/${this.$route.params.subpage}`);
    },
    
    modal_open(){

    },

    outline_size(item){
      // if(this.outline(item)==2){
      //   return `col-md-${item.font_set.writer_set.image_size}`
      // }
      // return 'col-md-12'
      if(item.font_set.writer_set.write_width==undefined)return 'col-md-12'
      return `col-md-${item.font_set.writer_set.write_width}`
    },

		search_link(){
			this.axios().post('/webpages/search_link',{ userid: this.$route.params.userid, type: 'board', id: this.board_item_keys[0] })
				.then(result=>{
					this.pageMove(result.data.link,true,result.data.url  )
				})

		},

    notice_save(){
      const msg = this.current_board.notice==0 ? "공지사항으로 등록하시겠습니까?" : "공지사항을 취소하시겠습니까?"
      let data = { type: "notice", notice: (this.current_board.notice==0 ? 1 :0), subpage_id: this.current_section.subpage_id }
      if(confirm(msg)){
        this.axios().put(`/webpages/${this.page_type}/board_option/${this.current_board.id}${this.page_query()}`, data)
        .then(result=>{
          this.webpageNotice(result.data);
          if(result.data.result=="success"){
            this.current_board.notice = data.notice
            this.$store.state.board_data = result.data.data
          } 
        })
      }
      return false
    }

  },

  components: {
    BoardContext
  }
};
</script>


<style scoped>
p,
span {
  line-height: 1.2em !important;
}

@media only screen and (max-width: 768px) {
  section div.row > div {
    margin-bottom: 0px;
  }
}
</style>

<style >
/* .controlls-over .owl-controls .owl-prev {
		display: none
	}
	.controlls-over .owl-controls .owl-next {
		display: none
	} */
</style>

