<template>
  <div>
    <button class="btn btn-primary btn-sm"  @click="go_list()" >목록</button>
    <div v-if="category_data.length > 1" style="margin-bottom: 20px;margin-top:10px">
      <el-select v-model="contactus.category" @change='category_change' placeholder="분류변경">
        <el-option
          v-for="item in category_data"
          :key="item.id"
          :label="item.cate"
          :value="String(item.id)"
          :disabled="item.disabled">
        </el-option>
      </el-select> 
    </div>    
    <table class="table" style="margin-top: 20px">
      <tr>
        <td>
          <b>{{ contactus.subject }}</b>
        </td>
        <td style="text-align:right;font-size: 9pt">
          작성자 : {{ contactus.name }} {{contactus.email}} <font v-if="contactus.phone!=''" >({{contactus.phone}})</font>
        </td>
      </tr>
      <tr>
        <td colspan='2'>
          <div style="font-size:9pt;margin-bottom:10px;text-align:right" > {{ dateFormat(contactus.created_at,'YY/MM/DD HH:mm') }}</div>
          <div v-html="contactus.context"></div>
          <img :src="contactus.file1.origin" style="width:100%">
        </td>
      </tr>
      <tr>
        <td colspan='2'>
          <div v-if="!admin_memo.show" >
            <div style="margin-bottom:10px">
              <label style="width:100px;display:inline">관리자메모 </label>
              <button class="btn btn-success btn-sm" @click="admin_memo_edit()"><i class="fa fa-edit"></i></button>
            </div>
            <div class="card card-default" v-if="contactus.admin_memo!=''">
              <div class="card-block" v-html="showAllMemo(contactus.admin_memo)"></div>
            </div>
          </div>

          <div v-else>
            <label>관리자메모 수정</label>
            <textarea v-model="admin_memo.data" class="form-control"></textarea>
            <div style="text-align:right">
              <button class="btn btn-danger btn-sm" @click="admin_memo.show=false" style="margin-top:10px; text-align:right">취소</button>
              <button class="btn btn-primary btn-sm" @click="admin_memo_update()" style="margin-top:10px; text-align:right">수정</button>
            </div>
          </div>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
export default {
  props: ['contactus_id','compo'],
  data(){
    return {
      admin_memo: { show: false, data: '' },
      category: 0,
    }
  },

  mounted(){
    if(this.contactus.confirm==0)this.item_confirm()
    this.admin_memo.show = false
    if(this.category_data.length>1){
      if(!this.isEmpty(this.contactus.category))this.category = parseInt(this.contactus.category)
    }
  },

  watch: {
    current_section_id(val){
      if(val != this.contactus.section_id) this.go_list();
    }
  },

  computed: {
    current_section_id(){
      return this.$store.state.modal.section.id
    },

    // category: {
    //   get(){
    //     //category_data의 아이디가 int이므로 타입을 맞춰줘야 한다.
    //     console.log(this.contactus.category);
    //     return parseInt(this.contactus.category)
    //   },
    //   set(val){
    //     this.contactus.category = val
    //   }
    // },

    contactus(){
      return this.$store.state.current_contactus.data.find(c=>c.id==this.contactus_id)
    },

    category_data(){
      let cate = [{id:0, cate: '분류없음'}]
      for( const c of this.$store.state.modal.section.config.category){
        cate.push(c)
      }
      return cate
    }

  },


  methods: {
    go_list(){
      this.$emit('update:compo','index')
    },

    item_confirm(){
      this.axios().put(`/webpages/${this.page_type}/contactus_update/${this.contactus.section_id}/${this.contactus_id}`, { data: {confirm: 1} })
      .then(result=>{
        if(result.data.result=="success"){
          this.contactus.confirm = 1
          this.$store.state.modal.section.config.new_contactus = result.data.new_contactus
        }
      
      })
    },

    category_change(val){
      if(confirm('분류를 정말 변경하시겠습니까?')){
        this.axios().put(`/webpages/${this.page_type}/contactus_update/${this.contactus.section_id}/${this.contactus_id}`, { data: {category: val} })
        .then(result=>{
          this.webpageNotice(result.data)
          if(result.data.result=="success"){
            this.contactus.category = val
          }
        })
      }
    },

    admin_memo_edit(){
      this.admin_memo.show = !this.admin_memo.show
      this.admin_memo.data = this.contactus.admin_memo
    },

    admin_memo_update(){
      this.axios().put(`/webpages/${this.page_type}/contactus_update/${this.contactus.section_id}/${this.contactus_id}`, { data: {admin_memo: this.admin_memo.data} })
      .then(result=>{
        this.webpageNotice(result.data)
        if(result.data.result=="success"){
          this.contactus.admin_memo = this.admin_memo.data
          this.admin_memo.show = false
        }
      })

    }

  }
}
</script>

<style>

</style>
