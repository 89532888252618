<template>

  <section style="background-color:#CCCCCC; padding: 0px" v-if="$store.state.preview && ownerCheck">
    <div class="container"> 
      <ul class="list-inline float-right" style="margin-bottom:0px"  >
        <li  v-if="current_section_timer" style="padding-right: 0px" >
          <a href="javascript:;" title="섹션타이머작동" style="margin-top: 5px;padding-bottom: 0px">
            <i class="et-alarmclock" style="font-weight:bold" ></i>
          </a>  
        </li>
        <li class="badge badge-danger" v-if="current_section.hidden == 1 && mainPage_use(current_section)"  @click='section_show_hidden(0)'  >
            <i class="fa fa-eye-slash"><font class='hidden-xs-down'> 숨김상태</font></i>
        </li>
        <li class="badge badge-warning" v-if="current_section.hidden == 0 && mainPage_use(current_section)"  @click='section_show_hidden(1)' >
            <i class="fa fa-eye"><font class='hidden-xs-down'> 보임상태</font></i>
        </li>
        <li class="badge badge-primary" v-if="mainPage_use(current_section)"  @click='webModal("#webpage_setting", "section-form", 1, current_section, index)' >
          <i class="fa fa-gear"><font class='hidden-xs-down'>  섹션설정</font></i>
        </li>
        <li class="badge badge-danger" v-if="!mainPage_use(current_section) && this.mainpage_check "  @click='submain_section_delete()' >
          <i class="fa fa-trash"><font class='hidden-xs-down'>  삭제</font></i>
        </li>
        <li class="badge badge-danger relative" @click='contactus_modal()' v-if="isContactus" >
          <span class="badge badge-success badge-corner radius-5" v-if="new_contactus_count>0">{{ new_contactus_count }}</span>
          <i class="fa fa-gear"><font class='hidden-xs-down'> 관리자</font></i>
        </li>
        <li class="badge badge-info"  v-if="index!=0 && option_auth_check(['section_move'])" @click="move_section(index, index-1 )"  >
          <i class="fa fa-sort-up" ><font class='hidden-xs-down'> 위로</font></i>
        </li>
        <li class="badge badge-info" v-if="index<(section_length-1) && option_auth_check(['section_move'])" @click="move_section(index+1, index)" >
          <i class="fa fa-sort-down"><font class='hidden-xs-down'> 아래로</font></i>
        </li>
      </ul>
 
      <ul class="list-inline " style="margin-bottom:0px;font-size:10pt; color: #393D41">
        <li style="margin-right: 2px"  >
          <i class="fa fa-arrow-up"></i>
          <span>{{ index + 1 }}번 [{{ section_name }}] 섹션 :</span>
          <span v-if="mainpage_check" >
            <span v-if="mainPage_use(current_section)" >아이템{{ current_section.item_order.length }}/{{ current_section.item_limit }} </span>
            <span v-else style="cursor: pointer;color: #1179FC" @click="subhome_move( current_section.id )" >
              sub홈섹션 
            </span>
          </span>
          <span v-else>
            <span>아이템{{ current_section.item_order.length }}/{{ current_section.item_limit }} </span>
          </span>
        </li>
        <li class="badge badge-success" @click='webModal("#webpage_setting", "section-add-form", 2, {},index)' v-if="admin_auth_check(1)" >
          <i class="fa fa-plus"><font class='hidden-xs-down'>  아래에 섹션추가</font></i>
        </li>
      </ul>
    </div>

  </section>
</template>


<script>
// import $ from 'jquery';

export default {
  props: ['index', 'current_section'],
 
  computed:{
    section_name(){
      var sname = ''
      var component = this.current_section.component
      sname = section_format.find(s=>s.component==component)
      return sname.name
    },

    isContactus(){
      if(this.current_section.component=='contactus01')return true
      return false
    },

    new_contactus_count(){
      if(this.current_section.config.new_contactus==undefined) return 0
      return this.current_section.config.new_contactus
    },

    current_webpage_id(){
      return webpage_id
    },

    current_section_timer(){
      if(this.current_section.config.section_set != undefined){
        if(this.current_section.config.section_set.use_start_date || this.current_section.config.section_set.use_finish_date )return true
      }
      return false
    }

  },
	methods: {

    mainPage_use(current_section){
      if(this.mainpage_check && webpage_type==1){
        return this.current_webpage_id==current_section.webpage_id ? true : false
      }else{
        return true
      }
    },

    contactus_modal(){
      // $('#webpage_setting').modal('show');
      this.$store.commit('set_ant_modal_visible', true)
      this.$store.state.modal.section = this.current_section
      this.$store.state.modal.section.current_section_id = this.current_section.id
      this.$store.state.modal.component = 'contactus'
      this.axios().get(`/webpages/${this.page_type}/contactus_index/${this.current_section.id}`)
      .then(result=>{
        if(result.data.result=="success"){
          this.$store.state.current_contactus = result.data.data
        }else{
          this.$store.state.current_contactus = {}
          this.webpageNotice(result.data)
        }
      })
    },

    section_show_hidden(val) {

      if (confirm('상태를 변경하시겠습니까?')) {
        const section = this.$store.state.sections.filter(s=>s.id==this.section_id)
        this.axios().put(`/webpages/${this.page_type}/section_update/${section[0].id}`, {
          type: 'section_hidden',
          hidden: val
        })
        .then(result => {
          this.webpageNotice(result.data)
          if (result.data.result == 'success') {
              section[0].hidden = val
          }
        })

      }
    },

    submain_section_delete(){
      //포탈형 홈페이지의 경우 삭제 - 일반 삭제는 모달창으로 이름 확인후 삭제됨
      if(confirm('정말 삭제하시겠습니까?')){
        this.axios().delete(`/webpages/${this.page_type}/subsection_delete/${this.current_section.id}`)
          .then(result=>{
              this.webpageNotice(result.data)
              if(result.data.result == 'success'){
                  this.$store.state.section_order = result.data.section_order
                  delete this.$store.state.sections[this.current_section.id]
              }
          })
      }
    },

    subhome_move(section_id){
      this.axios().get(`/get_webpage_url/${section_id}`)
      .then(result=>{
        if(result.data.result=='success'){
          this.pageMove(`/${this.$route.params.userid}/${result.data.url}`,true, result.data.url)
        }else{
          this.webpageNotice({type: 'error', msg: '문제가 발생하여 이동할 수 없습니다.'})
        }
      })
    }

	}
}
</script>


<style scoped>
  .badge{
    cursor: pointer
  }
  i {
    margin-right: 0px
  }
</style>

