<template>
<div>
	<section :style="cur_section_background+';padding: 0;min-height: 60px'"  >
    <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
    <div class="container" :style="container_css" >
      <div  v-if="item_order.length > 0" >
        <div class="row"  v-for="(i, index) in item_order" :key='index' >
          <div class="col-12" style="margin-bottom: 0px" >
            <img :src="section_image(ctem[i])" style="width:100%"  >
          </div>
        </div>	
      </div>
      <div class="row" v-else>
        <div class="col-12" style="margin-bottom: 0px" >
          <img :src="section_image('default')" style="width:100%"  >
        </div>  
      </div>
      
    </div>
	</section>
	
	<slot name="setting"  ></slot>
</div>

    
</template>  


<script>

export default {
  props: ['index','current_section'],
  data(){
    return {
      fullWidth: document.documentElement.clientWidth,
    }
  },

  created(){
    this.$store.state.screenSize = this.fullWidth
  },
  
	ready: function () {
    window.addEventListener('resize', this.handleResize)
  },
  

  computed: {

    backgorund_css(){
      let layout_padding = design_config.layout == 'boxed' ? 'padding-left: 30px;padding-right:30px;' : 'padding-left: 0px;padding-right:0px;'
      if (this.current_section.config.section_set.use_background == 1 || this.current_section.config.section_set.use_background == 2) {
          return `${layout_padding}background-color:${ this.current_section.config.section_set.color }`
      }
    },

    container_css(){
      if(this.newsletter_img == false)return 'min-height:200px;z-index: 100'
      return 'z-index: 100'
    },

    item_order(){
			const section = this.$store.state.sections.filter(s=>s.id==this.current_section.id)[0]
			return section.item_order
    },
    
    section_css(){
      if(!this.isEmpty(this.current_section.title) || !this.isEmpty(this.current_section.sub_title)){
        return ";padding: 20px 0;padding-bottom:30px"
      }else{
        return ";padding-bottom: 60px;padding-top:80px"
      }
    },

    cur_section_background(){
			const grnd = this.section_background()
			return `${grnd}` 
		},

    section_set(){
      if(this.current_section.config!=undefined){
        return this.current_section.config.section_set
      }else{
        return {}
      }
    }
	
  },
  
	methods: {
    
    section_image(item){
      if (item=='default') {
        if (this.$vssWidth < 800) {
          return '/images/image01_ex02.png'  
        }else{
          return '/images/image01_ex01.png'  
        }
      }else{
        if (this.$vssWidth < 800) {
          if(this.object_empty_check(item.file2) || item.file2.large==''){
            if(!this.object_empty_check(item.file1) && item.file1.large!='') return item.file1.large 
            return ''
          }else{
            return item.file2.large   
          }
        }else{
          if(this.object_empty_check(item.file1) || item.file1.large==''){
            if(!this.object_empty_check(item.file2) && item.file2.large!='') return item.file2.origin 
            return ''
          }else{
            return item.file1.origin   
          }
        }
      }
    },

    current_compo(type){
      if(type.config!=undefined){
        if(type.config.item_board_type==undefined || type.config.item_board_type==1 ){
          return 'board'
        }else{
          return 'megazine'
        }
      }else{
        return 'board'
      }
    },

    current_item(f){
			return this.$store.state.footer_item[f];
		}
	}

}
</script>


<style scoped>

@media only screen and (max-width: 1215px) {
  .container {
      width: 100%;
      max-width: 100%;

  }
}

html {
  font-size: 20px
}

</style>

