<template>
  <div>
    <modal-header v-bind:title="'관리자'"></modal-header>
		<div class="modal-body" style="padding:10px" >

      <el-tabs type="border-card" >
        <el-tab-pane label="목록"  @click="tab_click('contact_list','index')">
          <component :is='compo' :compo.sync='compo' v-on:view='contact_view($event)' :contactus_id='contactus_id' v-if="page=='contact_list'" ></component>
        </el-tab-pane>
        <el-tab-pane label="분류명설정"   @click="tab_click('category','category')">
          <category ></category>
        </el-tab-pane>
      </el-tabs> 

		</div>
  </div>
</template>

<script>
import ModalHeader   from './modal_header'
import Index 	       from './contactus/index'
import ContactusView from './contactus/contactus_view'
import Category 	   from './contactus/category'


export default {
  data(){
    return {
      page: 'contact_list',
      compo: 'index',
      contactus_id: 0,
    }
  },

  methods: {
    tab_click(page,compo){
      this.page = page
      this.compo = compo
    },
    
		tab_class(val){
			if(this.$store.state.modal.active == val){
				return 'nav-link active'
			}else{
				return 'nav-link'
			}
    },
    
    contact_view(id){
      this.compo = 'contactus-view'
      this.contactus_id = id
    }

  },

  components: {
    ModalHeader,
    Index,
    Category,
    ContactusView
  }
  
}
</script>


