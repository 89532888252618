<template>
  <div>
		<div>
			<label> 버튼색상 </label>
			<ul class="list-inline">
				<li><a href="#" @click="setActiveStyleSheet('green')" title="green" class="color"><img src="/smart_webpage/assets/plugins/styleswitcher/color_schemes/6.png" alt="" width="30" height="30" /></a></li>
				<li><a href="#" @click="setActiveStyleSheet('red')" title="red" class="color"><img src="/smart_webpage/assets/plugins/styleswitcher/color_schemes/2.png" alt="" width="30" height="30" /></a></li>
				<li><a href="#" @click="setActiveStyleSheet('orange')" title="orange" class="color"><img src="/smart_webpage/assets/plugins/styleswitcher/color_schemes/1.png" alt="" width="30" height="30" /></a></li>
				<li><a href="#" @click="setActiveStyleSheet('pink')" title="pink" class="color"><img src="/smart_webpage/assets/plugins/styleswitcher/color_schemes/3.png" alt="" width="30" height="30" /></a></li>
				<li><a href="#" @click="setActiveStyleSheet('yellow')" title="yellow" class="color"><img src="/smart_webpage/assets/plugins/styleswitcher/color_schemes/4.png" alt="" width="30" height="30" /></a></li>
				<li><a href="#" @click="setActiveStyleSheet('darkgreen')" title="darkgreen" class="color"><img src="/smart_webpage/assets/plugins/styleswitcher/color_schemes/5.png" alt="" width="30" height="30" /></a></li>
				<li><a href="#" @click="setActiveStyleSheet('darkblue')" title="darkblue" class="color"><img src="/smart_webpage/assets/plugins/styleswitcher/color_schemes/7.png" alt="" width="30" height="30" /></a></li>
				<li><a href="#" @click="setActiveStyleSheet('blue')" title="blue" class="color"><img src="/smart_webpage/assets/plugins/styleswitcher/color_schemes/8.png" alt="" width="30" height="30" /></a></li>
				<li><a href="#" @click="setActiveStyleSheet('brown')" title="brown" class="color"><img src="/smart_webpage/assets/plugins/styleswitcher/color_schemes/9.png" alt="" width="30" height="30" /></a></li>
				<li><a href="#" @click="setActiveStyleSheet('lightgrey')" title="lightgrey" class="color"><img src="/smart_webpage/assets/plugins/styleswitcher/color_schemes/10.png" alt="" width="30" height="30" /></a></li>
			</ul>
		</div>
		<div class="divider"><!-- divider --></div>
		
		<div class="clearfix">
			<label> 레이아웃 </label>
			<label style='float:left;margin-right: 50px'>
				<input class="boxed_switch" 
					type="radio" 
					name="layout_style" 
					id="is_wide" 
					value="wide" 
					@click='layout_switch("wide")' 
					v-model='config.layout'
					v-if="false"
					> 
				<input class="boxed_switch" 
					type="radio" 
					name="layout_style" 
					id="is_wide" 
					value="wide" 
					@click='layout_switch("wide")' 
					v-model='config.layout'
					> 
					Wide
			</label>
			<label style='cursor:pointer'>
				<input 
					class="boxed_switch" 
					type="radio" 
					name="layout_style" 
					id="is_boxed" 
					value="boxed"  
					@click='layout_switch("boxed")' 
					:checked="(config.layout=='boxed') ? 'checked' : '' "  
					v-if="false" 
					> 
				<input 
					class="boxed_switch" 
					type="radio" 
					name="layout_style" 
					id="is_boxed" 
					value="boxed"  
					@click='layout_switch("boxed")' 
					:checked="(config.layout=='boxed') ? 'checked' : '' "   
					> 
					Boxed
			</label>
		</div>

		<div class="divider"><!-- divider --></div>
		
		<div class="clearfix">
			<label> 배경분위기 </label>
			<label style='float:left;margin-right: 50px;cursor:pointer'><input class="dark_switch" type="radio" name="color_skin" id="is_light" value="light" @click='layout_color_switch("light")' :checked="(config.layout_color=='light') ? 'checked' : '' " /> 밝은 배경</label>
			<label style='cursor:pointer'><input class="dark_switch" type="radio" name="color_skin" id="is_dark" value="dark" @click='layout_color_switch("dark")' :checked="(config.layout_color=='dark') ? 'checked' : '' " /> 어두운 배경 </label>
		</div>
		<div class="divider"><!-- divider --></div>
		<div style="margin-bottom:20px">
			<label> 배경패턴 </label>
			<button @click="pattern_switch('none')" class="pointer switcher_thumb"><img src="/smart_webpage/assets/images/_smarty/patterns/none.jpg" width="27" height="27" alt="" /></button>
			<button @click="pattern_switch('pattern2')"  class="pointer switcher_thumb"><img src="/smart_webpage/assets/images/_smarty/patterns/pattern2.png" width="27" height="27" alt="" /></button>
			<button @click="pattern_switch('pattern3')"  class="pointer switcher_thumb"><img src="/smart_webpage/assets/images/_smarty/patterns/pattern3.png" width="27" height="27" alt="" /></button>
			<button @click="pattern_switch('pattern4')"  class="pointer switcher_thumb"><img src="/smart_webpage/assets/images/_smarty/patterns/pattern4.png" width="27" height="27" alt="" /></button>
			<button @click="pattern_switch('pattern5')"  class="pointer switcher_thumb"><img src="/smart_webpage/assets/images/_smarty/patterns/pattern5.png" width="27" height="27" alt="" /></button>
			<button @click="pattern_switch('pattern6')"  class="pointer switcher_thumb"><img src="/smart_webpage/assets/images/_smarty/patterns/pattern6.png" width="27" height="27" alt="" /></button>
			<button @click="pattern_switch('pattern7')"  class="pointer switcher_thumb"><img src="/smart_webpage/assets/images/_smarty/patterns/pattern7.png" width="27" height="27" alt="" /></button>
			<button @click="pattern_switch('pattern8')"  class="pointer switcher_thumb"><img src="/smart_webpage/assets/images/_smarty/patterns/pattern8.png" width="27" height="27" alt="" /></button>
			<button @click="pattern_switch('pattern9')"  class="pointer switcher_thumb"><img src="/smart_webpage/assets/images/_smarty/patterns/pattern9.png" width="27" height="27" alt="" /></button>
			<button @click="pattern_switch('pattern10')" class="pointer switcher_thumb"><img src="/smart_webpage/assets/images/_smarty/patterns/pattern10.png" width="27" height="27" alt="" /></button>
			<button @click="pattern_switch('pattern11')" class="pointer switcher_thumb"><img src="/smart_webpage/assets/images/_smarty/patterns/pattern11.png" width="27" height="27" alt="" /></button>
			<button @click="pattern_switch('pattern12')" class="pointer switcher_thumb"><img :src="image.pattern" width="27" height="27" alt="" v-if='image.pattern!=""' ></button>
		</div>
		<el-upload
			class="upload-demo"
			ref="file1"
			name="file1"
			:limit="1"
			:on-success='return_image'
			:action="file_upload_path('file1')"
			:headers="upload_headers"
			:auto-upload="true">
			<el-button slot="trigger" size="mini" type="primary">패턴이미지선택</el-button>
			<el-button style="margin-left: 10px;" size="mini" type="success" @click="submitUpload('file1')" v-if="false">이미지 업로더</el-button>
			<div class="el-upload__tip" slot="tip">jpg/png 가능하며 500kb이하로 업로더 하시면 됩니다.</div>
		</el-upload>

		<div class="divider"><!-- divider --></div>

		<div style="margin-bottom:20px">
			<label> 배경이미지 </label>
			<button @click="background_switch('none')" class="pointer switcher_thumb"><img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/boxed_background/none.jpg" width="27" height="27" alt="" /></button>
			<button @click="background_switch('https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/boxed_background/1.jpg')" class="pointer switcher_thumb"><img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/boxed_background/1_thumb.jpg" width="27" height="27" alt="" /></button>
			<button @click="background_switch('https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/boxed_background/2.jpg')" class="pointer switcher_thumb"><img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/boxed_background/2_thumb.jpg" width="27" height="27" alt="" /></button>
			<button @click="background_switch('https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/boxed_background/3.jpg')" class="pointer switcher_thumb"><img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/boxed_background/3_thumb.jpg" width="27" height="27" alt="" /></button>
			<button @click="background_switch('https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/boxed_background/4.jpg')" class="pointer switcher_thumb"><img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/boxed_background/4_thumb.jpg" width="27" height="27" alt="" /></button>
			<button @click="background_switch('http:'+image.background_image)" class="pointer switcher_thumb"><img :src="'http:'+image.background_image_thumb" width="27" height="27" alt="" v-if='image.background_image_thumb!=""' /></button>
		</div>
		<el-upload
			class="upload-demo"
			ref="file2"
			name="file2"
			:limit="1"
			:on-success='return_image'
			:multiple='true'
			:action="file_upload_path('file2')"
			:headers="upload_headers"
			:auto-upload="true">
			<el-button slot="trigger" size="mini" type="primary">배경이미지선택</el-button>
			<el-button style="margin-left: 10px;" size="mini" type="success" @click="submitUpload('file2')" v-if="false" >이미지 업로더</el-button>
			<div class="el-upload__tip" slot="tip">jpg/png 이미지를 5MB이하 용량으로 업로더 가능합니다.</div>
		</el-upload>

  </div>
      		 
</template>


<script>
export default {
	props: ['config'],
	data(){
		return {
			image: {
				background_image_thumb: files.background_image_thumb,
				background_image: files.background_image,
				pattern: files.pattern
			}
		}
	},
	created(){

	},
  computed: {
    set_config(){
      return this.$store.state.webconfig['setting-form']
    }
  },

	created(){
    this.config.button = design_config.button
    this.config.pattern = design_config.pattern
    this.config.layout = (jQuery('body').hasClass('boxed')) ? 'boxed' : 'wide'
    this.config.layout_color = ($('#css_dark_skin').length) ? 'dark' : 'light' 
    this.config.background_image = jQuery('body').attr('data-background');
	},

	methods:{
		return_image(result){
			if(result.result=='success'){
				if(result.file_field=='file1'){
					this.image.pattern = result.data.thumb
				}else{
					this.image.background_image_thumb = result.data.thumb
					this.image.background_image = result.data.origin
				}
			}
		},

		file_upload_path(file_field){
			return `/admin/update_webapi_image/webdesign/${file_field}/${webpage_id}`
		},
    
		layoutBoxed(){
      $('body').removeClass('boxed')
			this.$store.state.design_config.layout = "wide"
		},

		layoutWide(){
			$('body').addClass('boxed')
			this.$store.state.design_config.layout = "boxed"
		},
		setActiveStyleSheet(title) {
			if(title != 'null' && title != null) {
        this.config.button =  title
				$("#color_scheme").attr('href', '/smart_webpage/assets/css/color_scheme/' + title + '.css');
			}
    },

    layout_color_switch(color_skin){
			this.config.layout_color = color_skin
			if(color_skin == 'dark') {
				jQuery("#css_dark_skin").remove();
				jQuery("head").append('<link id="css_dark_skin" href="/smart_webpage/assets/css/layout-dark.css" rel="stylesheet" type="text/css" title="dark" />');
				jQuery("#logo_font").attr('color', 'white');
			} else {
				jQuery("#css_dark_skin").remove();
				jQuery("#logo_font").attr('color', '#212529');
			}
			_misc(); //뒤집어지는 소개 섹션 다시 로더 
    },
    
    layout_switch(boxed_switch){
			this.config.layout = boxed_switch; 
			if(boxed_switch == 'boxed') {
        jQuery("body").removeClass('boxed');
				jQuery("body").addClass('boxed');
			} else {
        jQuery("body").removeClass('boxed');
				jQuery('body').removeClass('transparent');
      }
      /* 
				IE Fix - boxed & sticky header 
				@Styleswitcher bug only.
			*/
			if(jQuery('html').hasClass('ie')) {
				jQuery(window).scroll(function() {
					if(jQuery('body').hasClass('boxed')) {
						jQuery("#header").removeClass('sticky');
						jQuery("#header").removeClass('affix');
					}
				});
			}

    },

		pattern_switch(pattern) {
			this.config.pattern = pattern
      this.config.background_image = ''
			
			if(pattern == 'none' || pattern == '') {
				this.remove_pattern();
			} else {

				if(!jQuery('body').hasClass('boxed')) {
          jQuery('body').addClass('boxed');
          // jQuery("#is_boxed").trigger('click');
          this.layout_switch('boxed')
				}
				jQuery('body').attr('data-background', '');
				jQuery('.backstretch').remove();
				jQuery('body').removeClass('transparent');
				this.remove_pattern();
				if(pattern == 'pattern12')jQuery('.pattern12').css('background', `url(${this.image.pattern})`)
				jQuery('body').addClass(pattern);
			}
		},

		remove_pattern() {
			jQuery('body').removeClass('pattern1');
			jQuery('body').removeClass('pattern2');
			jQuery('body').removeClass('pattern3');
			jQuery('body').removeClass('pattern4');
			jQuery('body').removeClass('pattern5');
			jQuery('body').removeClass('pattern6');
			jQuery('body').removeClass('pattern7');
			jQuery('body').removeClass('pattern8');
			jQuery('body').removeClass('pattern9');
			jQuery('body').removeClass('pattern10');
			jQuery('body').removeClass('pattern11');
		},

		background_switch(imgbkg) {
      this.config.background_image = imgbkg
      this.config.pattern = ''
			if(imgbkg == 'none' || imgbkg == '') {
				jQuery('body').attr('data-background', '');
				jQuery('.backstretch').remove();
				jQuery('body').removeClass('transparent');
				this.remove_pattern();

			} else {

				if(!jQuery('body').hasClass('boxed')) {
          jQuery('body').addClass('boxed');
          // jQuery("#is_boxed").trigger('click');
          this.layout_switch('boxed')
				}

				jQuery('body').attr('data-background', imgbkg);
				this.remove_pattern();

				var data_background = jQuery('body').attr('data-background');
				if(data_background) {

					loadScript(plugin_path + 'jquery.backstretch.min.js', function() {

						if(data_background) {
							jQuery.backstretch(data_background);
							jQuery('body').addClass('transparent'); // remove backround color of boxed class
						}

					});

				}
			}
    },
    
		createCookie(name,value,days) {
			if (days) {
				var date = new Date();
				date.setTime(date.getTime()+(days*24*60*60*1000));
				var expires = "; expires="+date.toGMTString();
			} else {
				expires = "";
			}	document.cookie = name+"="+value+expires+"; path=/";
		}

	},
  
}
</script>

<style scoped>
  .divider { margin: 10px 0 }
</style>
