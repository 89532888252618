<template>
  <div>
    <div style="padding: 0 20px">
      <div class="alert alert-danger" v-if="error_msg!=''" v-html="error_msg"  ></div>
      <div>
        <label>이메일 또는 아이디</label>
        <el-input ref='userid' placeholder="EMAIL or USERID"  v-model="login.userid" autofocus="true" ></el-input>
        <label style="margin-top:20px">비밀번호</label>
        <el-input placeholder="PASSWORD" v-model="login.password" show-password @keyup.native.enter="login_post()" ></el-input>
        <hr>
      </div>
      <div class="row" style="width:100%">
        <span class="col-6" style="width:50%"  v-if="$store.state.use_signup"  > 
          <a href="javascript:void(0)" @click="pageMove(link_url('webpage_users_sign_up'), false)">회원가입</a> |
          <a href="javascript:void(0)" @click="pageMove(link_url('find_password'), false)" >비번찾기</a>
        </span>
        <span :class="$store.state.use_signup ? 'col-6' : 'col-12' " style="text-align: right;padding-right:0px">
          <el-button type="primary" @click="login_post()" >
            <i class="glyphicon glyphicon-log-in"></i> 로그인
          </el-button>
        </span>
      </div>    
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      login: {
				userid: '',
				password: '',
			},
			error_msg: '',
    }
  },

  mounted(){
    this.$refs.userid.focus()
  },

  methods: {
		
		link_url(link){
			return `/${this.$route.params.userid}/${this.$route.params.url}/${link}`
		},

    login_post(){
			this.error_msg = ''
			let result = { userid: true, password: true }
			result.userid = this.isEmpty(this.login.userid)
			result.password = this.isEmpty(this.login.password)
			if(result.userid || result.password  ){
				this.error_msg += '<ul style="margin-bottom: 0px">'
				if(result.userid)this.error_msg += '<li>아이디를 입력해 주세요</li>'
				if(result.password)this.error_msg += '<li>패스워드를 입력해 주세요</li>'
				this.error_msg += '</ul>'
			}else{
				this.error_msg = ''
				this.axios().post('/login',  {member: this.login, webpage_id: webpage_id} )
      	.then((result)=>{
					if(result.data.logincheck){
						this.webpageNotice({type: 'success', msg: '성공적으로 로그인 되었습니다.'});
						setTimeout(function(){
							// location.reload();
							window.location.href = window.location.href;
						},500)
					}else{
						this.login.password = ''
						this.error_msg = '<ul style="margin-bottom: 0px">'
						for(let e of result.data.errors.error){
							this.error_msg += `<li>${e}</li>`
						}
						this.error_msg += '</ul>'
					}
				})
				.catch((e)=>{
					this.error_msg = e
				})
			}
		},

  }

}
</script>