<template>

		<div class="row margin-bottom-60">
			 <div class="col-md-6">
					<div class="testimonial testimonial-bordered padding-15">
						<table>
							<tr>
								<td style="width:100px;padding-top:0px">
									<img class="rounded" src="/smart_webpage/demo_files/images/people/300x300/2-min.jpg" style="width: 100px; height: 100px;" alt="" />
								</td>
								<td style="padding-left:20px">
									<cite >
										Felicia Doe
										<span>Company Ltd.</span>
									</cite>
									<p style="padding-top:20px">Incidunt deleniti blanditiis quas aperiam recusandae consequatur ullam quibusdam cum libero!Incidunt deleniti blanditiis quas aperiam recusandae consequatur ullam quibusdam cum libero!</p>

								</td>
							</tr>
						</table>
					</div>
				</div>

				<div class="col-md-6">
					<div class="testimonial testimonial-bordered padding-15">
						<table>
							<tr>
								<td style="width:100px;padding-top:0px">
									<img class="rounded" src="/smart_webpage/demo_files/images/people/300x300/6-min.jpg" style="width: 100px; height: 100px;" alt="" />
								</td>
								<td style="padding-left:20px">
									<cite>
										Melissa Doe
										<span>Company Ltd.</span>
									</cite>
									<p style="padding-top:20px">Incidunt deleniti blanditiis quas aperiam recusandae consequatur ullam quibusdam cum libero!Incidunt deleniti blanditiis quas aperiam recusandae consequatur ullam quibusdam cum libero!</p>

								</td>
							</tr>
						</table>
					</div>
						
				</div>
			</div>

</template>


<script>
export default {

  
}
</script>

<style>

</style>

