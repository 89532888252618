<template>
    <!-- wrapper -->
	<div v-if="page_type == 'search_page'" >
		<search-page></search-page>
	</div>
	<div v-else>	 
		<div v-for="(c, index) in current_sections" :key="c.component + index" >
			<component :ref="section_menu_id(c)" :id="section_menu_id(c)" :is="c.component"  v-bind:current_section='current_section(c)' 	v-bind:index="index" v-if="component_show(c)"	>
				<section-setting slot='setting' v-bind:index="index" v-bind:current_section='c' v-if="admin_auth_check(2, c)" :key="c.component + index + c.id" ></section-setting>
			</component>
		</div>
  </div>
    
</template>

<script>
import Slide01 				  	from './components/slide01'
import Slide02 						from './components/slide02'
import Infobar01					from './components/infobar01'
import Image01					  from './components/image01'
import Introduce01 				from './components/introduce01'
import Introduce02 				from './components/introduce02'
import Introduce03 				from './components/introduce03'
import Introduce04				from './components/introduce04'
import Linebar01 	    		from './components/linebar01'
import SectionSetting 		from './components/section_setting'
import Welcome01 					from './components/welcome01'
import Explain01 					from './components/explain01'
import Explain02 					from './components/explain02'
import Explain03					from './components/explain03'
import Explain04					from './components/explain04'
import Video01  					from './components/video01'
import Gallery01  			  from './components/gallery01'
import Timeline01  			  from './components/timeline01'
import Countdown01  			from './components/countdown01'
import Writer01						from './components/writer01'
import Footer01 					from './components/footer01'
import Link01 						from './components/link01'
import Pageheader01 		  from './components/pageheader01'
import Board01 		  			from './components/board01'
import Contactus01 		  	from './components/contactus01'
import Sumaryboard01 		  from './components/sumaryboard01'
import Newsletter01 		  from './components/newsletter01'
import Newsletterhead01 	from './components/newsletterhead01'
import Homepagelist01 	  from './components/homepagelist01'
import SearchPage         from "./components/search_page"

import {scroller} from 'vue-scrollto/src/scrollTo'

export default {
  data: function () {
    return {
			cpnts : ['slide', 'infobar'],
    }
	},
	created(){
		// console.log(this.$http.defaults.headers.common);
		var req = new XMLHttpRequest();
		req.open('GET', document.location, false);
		req.send(null);
		var header = req.getAllResponseHeaders();
		var headers = header.split('\r\n').reduce(function (acc, current, i){
      var parts = current.split(': ');
      acc[parts[0]] = parts[1];
      return acc;
		}, {});
		this.$store.commit('config',headers)
	},

	mounted(){
		var self = this
		var hash_router = this.$route.hash
		if(!this.isEmpty(hash_router)){
			setTimeout(function(){
				const firstScrollTo = scroller()
				const options = {
					container: 'body',
					easing: 'ease-in',
					offset: -80,
				}
				firstScrollTo(hash_router,1000,options);
			},500)
		}
	},

	computed: {
		page_type(){
			return this.$route.name
		},

		current_sections(){
			// let sections = []
			// const order = this.$store.state.section_order
			// let section = ''
			// for(let or in order){
			// 	section = this.$store.state.sections.filter(s=> s.id==order[or])
			// 	if(section.length>0)sections.push(section[0])
			// }
      return this.$store.state.page_sections
		},

		
	},

	methods: {
		current_section(section){
			
			// console.log(">>>>>>>>>>>=================");
			// console.log(section);

			const section_set = { "color": "#ffffff", "use_background": 0, "background_transparent": 30, "fix_backimage": false, "title_color": "#202020", "subtitle_color": "#3E8FAB", "title_font": "Nanum Gothic" }
			if(section.config==undefined)section.config = { "section_set": section_set, "category": [], "new_contactus": 0 }
			if(section.config.section_set==undefined)section.config.section_set = section_set
			// if()
			return section
		},

    props_section(id){
			return this.current_sections.filter(s=>s.id==id)[0]
		}

	
	},

	components: {
		Slide01,
		Slide02,
		Infobar01,
		Image01,
		Introduce01,
		Introduce02,
		Introduce03,
		Introduce04,
		SectionSetting,
		Linebar01,
		Welcome01,
		Explain01,
		Explain02,
		Explain03,
		Explain04,
		Video01,
		Gallery01,
		Timeline01,
		Countdown01,
		Writer01,
		Footer01,
		Link01,
		Pageheader01,
		Board01,
		Contactus01,
		Sumaryboard01,
		Newsletter01,
		Newsletterhead01,
		Homepagelist01,
		SearchPage,
	},
	
}
</script>


<style>

</style>
