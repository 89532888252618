<template>
  <div style="margin-top:20px">
      <div>
        <label>제목</label>
        <input type="text" class="form-control"  v-model="data.title" >
      </div>

      <div>
        <label>부제목</label>
        <input type="text" class="form-control"  v-model="data.subtitle" >
      </div>  

      <div style="margin-bottom: 20px">
        <label>게시판 연결</label>
        <el-select v-model="data.link1" size="small" placeholder="게시판 연결">
          <el-option
            v-for="(key, value) in board_pages"
            :key="key"
            :label="key"
            :value="value">
          </el-option>
        </el-select>
      </div>    

  </div>
</template>

<script>
export default {
  props: [ 'data', 'mode', 'compo' ],
  
  data(){
    return {
      
    }
  },

 
  watch: {
    data(){
      this.$emit('update:data',this.data)
    }
  },

  computed:{


  }

}
</script>

<style>

</style>
