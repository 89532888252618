<template>

<div>

  <div
    v-if="write_type == 1"
    class="row"
    :style="outline_css"
  >
    <div  :class="'col-md-12'" >
      <component :is="header_compo" v-bind:item="item" v-if="subject_location == 1"></component>
      <div class="row">
        <div :class="'col-md-'+img_size">
          <image01 v-bind:item='item' ></image01>
          <div class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-if="!isEmpty(item.media)"  v-html="item.media" ></div>
        </div>
        </div>
      <div style="margin-top: 10px">
        <component :is="header_compo" v-bind:item="item" v-if="subject_location == 2"></component>
      </div>
      <div class="row">
        <div :class="'col-md-12 '+ dropcap" :style="'overflow:auto;color:'+item.font_set.context_color" v-html="htmlDecode(item.context)"></div>
        <div class="col-md-12">
          <button :class="'btn btn-'+item.config.link1.color" @click='link_button(item.link1)' style='margin-right:20px' v-if="item.config.link1.name!=''" >{{ item.config.link1.name }}</button>
          <button :class="'btn btn-'+item.config.link2.color" @click='link_button(item.link2)' v-if="item.config.link2.name!=''"  >{{ item.config.link2.name }}</button>
        </div>
      </div>
    </div>
  </div>

  
  <div class="row" v-if="write_type==2" :style="outline_css" >
    <div class="col-md-12">
      <component :is='header_compo' v-bind:item='item' ></component>
    </div>
    <div :class="'col-md-'+img_size">
      <image01 v-bind:item='item'  v-if="!isEmpty(item.file1.origin)" ></image01>
      <div class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-if="!isEmpty(item.media)" v-html="item.media" ></div>
    </div>

    <div :class="'col-md-'+(12-img_size)">
      <div :class="dropcap" :style="'overflow:auto;color:'+item.font_set.context_color" v-html="htmlDecode(item.context)" ></div>
      <button :class="'btn btn-'+item.config.link1.color" @click='link_button(item.link1)' style='margin-right:20px' v-if="item.config.link1.name!=''" >{{ item.config.link1.name }}</button>
      <button :class="'btn btn-'+item.config.link2.color" @click='link_button(item.link2)' v-if="item.config.link2.name!=''"  >{{ item.config.link2.name }}</button>
    </div>
  </div>

  <div class="row" v-if="write_type==3" :style="outline_css" >
    <div class="col-md-12">
      <component :is='header_compo' v-bind:item='item' ></component>
    </div>
    <div :class="'col-md-'+(12-img_size)">
      <div :class="dropcap"  :style="'overflow:auto;color:'+item.font_set.context_color" v-html="htmlDecode(item.context)" ></div>
      <button :class="'btn btn-'+item.config.link1.color" @click='link_button(item.link1)' style='margin-right:20px' v-if="item.config.link1.name!=''" >{{ item.config.link1.name }}</button>
      <button :class="'btn btn-'+item.config.link2.color" @click='link_button(item.link2)' v-if="item.config.link2.name!=''"  >{{ item.config.link2.name }}</button>
    </div>
    <div :class="'col-md-'+img_size" >
      <image01 v-bind:item='item' ></image01>
      <div class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-if="!isEmpty(item.media)" v-html="item.media" ></div>
    </div>
  </div>
  
  <div class="row" v-if="write_type==4" :style="outline_css">
    <div class="col-md-12">
      <component :is='header_compo' v-bind:item='item' ></component>
    </div>
    <div :class="'col-md-12'">
      <div :style="type45_image_size">
        <image01 v-bind:item='item' style="width:100%;margin-bottom:10px" ></image01>
        <div class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-if="!isEmpty(item.media)" v-html="item.media" ></div>
      </div>
      <div :style="'overflow:left;color:'+item.font_set.context_color"  >
        <div :class="dropcap" v-html="htmlDecode(item.context)"></div>
      </div>
      <button :class="'btn btn-'+item.config.link1.color" @click='link_button(item.link1)' style='margin-right:20px' v-if="item.config.link1.name!=''" >{{ item.config.link1.name }}</button>
      <button :class="'btn btn-'+item.config.link2.color" @click='link_button(item.link2)' v-if="item.config.link2.name!=''"  >{{ item.config.link2.name }}</button>
    </div>
  </div>
  
  <div class="row" v-if="write_type==5" :style="outline_css">
    <div class="col-md-12">
      <component :is='header_compo' v-bind:item='item' ></component>
    </div>
    <div :class="'col-md-12'">
      <span :style="type45_image_size">
        <image01 v-bind:item='item' style="width:100%;"></image01>
        <div class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-if="!isEmpty(item.media)" v-html="item.media" ></div>
      </span>
      <span :style="'overflow:auto;color:'+item.font_set.context_color" >
        <div :class="dropcap" v-html="htmlDecode(item.context)"></div>
      </span>
      <button :class="'btn btn-'+item.config.link1.color" @click='link_button(item.link1)' style='margin-right:20px' v-if="item.config.link1.name!=''" >{{ item.config.link1.name }}</button>
      <button :class="'btn btn-'+item.config.link2.color" @click='link_button(item.link2)' v-if="item.config.link2.name!=''"  >{{ item.config.link2.name }}</button>
    </div>
  </div>

</div>

 


		
    
</template>


<script>
import Header01 from './header_01'
import Header02 from './header_02'
import Header03 from './header_03'
import Header04 from './header_04'
import Image01  from './image_01'

export default {
	props: [ 'item', 'current_section'],
	data(){
		return {
		
		}
	},
		
	mounted(){
	
	},

 computed: {
    type45_image_size(){
      var size = { "12":100, "8":80, "6":50, "4":35, "3": 25, "2": 15  }
      const float_css = this.write_type==4 ? `float:left;margin-right:20px` : `float:right;margin-left:10px`
      let css = `width:${size[this.img_size]}%;${float_css}`
      return css
    },
    outline_css(){
      if(this.outline == 3)return 'padding-bottom: 30px;border-bottom: 1px solid rgba(0,0,0,0.1);'
      return ''
    },
    outline(){
      return this.item.font_set.writer_set.outline
    },
    img_size(){
      if(this.item.font_set.writer_set.image_size==undefined)this.item.font_set.writer_set.image_size=12
      
      return this.item.font_set.writer_set.image_size;
  
    },
		dropcap(){
			return (this.item.font_set.writer_set.dropcap == 1) ? 'dropcap' : ''
		},
		write_type(){
			return this.item.font_set.writer_set.write_type
		},
		subject_location(){
			return this.item.font_set.writer_set.subject_location
		},
    header_compo(){
      const font_set = this.font_set(this.item.id)
      return `header0${font_set.writer_set.subject_type}`;
    },
 },
	
	methods: {

		


		

		font_set(id){
			return this.$store.state.items[id].font_set
		},

	
		wcontext(text){
			return this.htmlDecode(text)
		}
	},

	components: {
		Header01,
		Header02,
		Header03,
		Header04,
		Image01,
	}
}
</script>


<style scoped>
	p, span {
		line-height: 1.2em !important
	}

	@media only screen and (max-width: 768px){
		section div.row>div {
			margin-bottom: 0px;
		}
	}
</style>

<style >
	/* .controlls-over .owl-controls .owl-prev {
		display: none
	}
	.controlls-over .owl-controls .owl-next {
		display: none
	} */
</style>

