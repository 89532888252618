<template>
<div>
  <div class="heading-title heading-border-bottom heading-color" style="margin-bottom:10px; border-bottom-width: 1px;vertical-align:bottom"> 
    <h4  :style="title_css">
      {{ current_item.title }} 
      <cite :style="'font-size: 0.8em;cursor:pointer;color:'+current_item.font_set.subtitle_color" v-if='!isEmpty(current_item.subtitle)' >
        {{ current_item.subtitle }}
      </cite>
    </h4>
    <cite @click="move_board(2)" :style="'cursor:pointer;color:'+current_item.font_set.subtitle_color">
      <small class="float-right text-italic" style="margin-top:7px" >전체보기</small>
    </cite>
  </div>

    <table style="width: 100%">
      <tr v-for=" b in board_item " :key="b.id" >
        <td style="width:70%; padding-top:12px; padding-botoom: 12px">
          <a :href="move_board(1,b)" :style="'color:'+fontColor.subtitle_color" v-html="showMemo(b.title,word_count)" ></a>
        </td>
        <td style="text-align:right;min-width:100px">
          <small :style="'color:'+fontColor.subtitle_color" >{{ dateFormat(b.created_at,'YY/MM/DD') }} <cite>{{ showMemo(writer(b), 4) }}</cite></small>
        </td>
      </tr>
    </table>
</div>

</template>

<script>
export default {
  props: ['current_item','fontColor','current_section'],

  mounted(){
    // this.axios().get(`/webpages/get_board/${this.current_item.link1}`)
    // .then(result=>{
    //   console.log(result.data);
    // });

  },

  computed: {
    board_item(){
      if(this.$store.state.sumary_boards.board[this.current_item.link1]!=undefined){
        return this.$store.state.sumary_boards.board[this.current_item.link1]
      }
      return []
    },
    
    word_count(){
      if(this.current_section.item_order.length > 2){
        return 15
      }else{
        return 30
      }
    },
        
    writers(){
      return this.$store.state.sumary_boards.writers
    },

    title_css(){
      return `background-color: transparent;color: ${this.current_item.font_set.title_color}`
    }
  },

  methods: {
    title_style(subtitle){
      if(subtitle!=''){
        return 'margin-bottom: 0px'
      }
    },

    writer(b){
      return this.writers[b.member_id]==undefined ? b.writer : this.writers[b.member_id]
    }
    
 
  }

}
</script>

<style scoped>

cite:hover{ color: #3E8FAB}
a:hover{  font-weight: bold }

</style>
