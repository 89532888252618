<template>
	<div>

    <section :style="cur_section_background ">
      <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
			<smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
			<div class="container" style="z-index: 100"  v-if="current_section.item_order.length > 0" >
				<div class="row" >
					<div :class="item_view_class" v-for="(i, idx) in current_section.item_order" :key='idx'>
						<div class="box-icon box-icon-side box-icon-color box-icon-round">
							<i :class="ctem[i].icon" :style="'color:'+ctem[i].font_set.icon_color"></i>
							<h2 :style="'cursor:pointer;color:'+ctem[i].font_set.title_color+(ctem[i].subtitle =='' ? ';margin-bottom:28px' : ';margin-bottom:0px') " @click="use_fulltext(ctem[i]) ? modal_open(ctem[i]) : link_button(ctem[i].link1)" v-if="!isEmpty(ctem[i].title)" >{{ctem[i].title}}</h2>
							<h2 class="fw-300 letter-spacing-1 fs-13" style="margin-bottom: 5px;" v-if="!isEmpty(ctem[i].subtitle)" ><span :style="subtitle_color(ctem[i])">{{ctem[i].subtitle}}</span></h2>

							<template v-if="thumb_use(ctem[i]) && media_source_test(ctem[i].media)">
                <div  class="embed-responsive embed-responsive-16by9 block margin-bottom-60" v-html="ctem[i].media"></div>
              </template>
              <template v-else>
                <a href="javascript:void(0);" @click="use_fulltext(ctem[i]) ? modal_open(ctem[i]) : '' " v-if="thumb_use(ctem[i]) && ctem[i].file1.large!=''"  >
                  <img class="card-img-top" :src="cur_file1_image(ctem[i])" :style="image_height" alt="">
                </a>
              </template>
							<p :style="'margin-top:0px;color:'+ctem[i].font_set.context_color" v-html="htmlDecode(use_fulltext(ctem[i]) ? showMemo(ctem[i].context, 150) : ctem[i].context ) " ></p>
              <div style="margin-bottom: 20px"> 
								<button :class="'btn btn-sm btn-'+ctem[i].config.link1.color" @click='link_button(ctem[i].link1)' style='margin-right:10px;margin-top:10px' v-if="ctem[i].config.link1.name!=''" >{{ ctem[i].config.link1.name }}</button>
								<button :class="'btn btn-sm btn-'+ctem[i].config.link2.color" @click='link_button(ctem[i].link2)' style='margin-top:10px' v-if="ctem[i].config.link2.name!=''"  >{{ ctem[i].config.link2.name }}</button>
							</div>

							<el-button class="box-icon-more font-lato fw-300" type="text" @click="modal_open(ctem[i])" v-if="ctem[i].font_set.no_use_fulltext==undefined || !ctem[i].font_set.no_use_fulltext"  >
								<span :style="'color:'+ctem[i].font_set.subtitle_color"> <i class="fa fa-folder-open"></i>	Read more </span>
							</el-button>
						</div>
					</div>
				</div>
			</div>
			
			<div class="container" v-else >
				<default></default>
			</div>  

			<el-dialog :visible.sync="outerVisible" :fullscreen="element_modal_fullscreen" >
				<modal-view :currentItem='currentItem' :image1='image1' :image2='image2' :context_popup='context_popup' ></modal-view>
				<div slot="footer" class="dialog-footer">
					<el-button @click="outerVisible = false">닫기</el-button>
				</div>
			</el-dialog> 
		</section>
		<div>
			<slot name="setting"  ></slot>
		</div>
	</div>
    
</template>

<script>
import Default from './default/explain01_default'
import ModalView from '../modal/explain_view_modal'

export default {
	props: ['index','current_section'],
	data(){
		return {
			re_order: [],
			outerVisible: false,
			currentItem: {},
			image1:'',
			image2:'',
			context_popup: null,			
		}
	},
		
	mounted(){
		this.item_div();
		//this.lightOrDark 함수는 mixin/common.js에 있음
		this.background_color_test = this.lightOrDark(this.current_section.config.section_set.color)
	},

	watch: {
		item_order(val){
			//이 코드 없으면 데이트 업데이트시 적용이 안됨
			this.item_div();
			return val
		}
	},
	
	computed: {
    cur_section_background(){
			const grnd = this.section_background()
			const css = (!this.isEmpty(this.current_section.title) || !this.isEmpty(this.current_section.sub_title))? `;padding-top:50px;padding-bottom:40px` :`;padding-bottom:40px`
			return `${grnd}${css}` 
		},


	},

	methods: {	

		modal_open(item){
			if(this.use_fulltext(item)){
				this.outerVisible=true;
				this.currentItem=item;
				this.image1=item.file1.origin
				this.image2=item.file2.origin
				this.context_popup=!item.font_set.context_popup
			}
		}
	},
	
	components: {
		Default,
		ModalView
	}
}
</script>


<style>

.el-dialog__title{
	margin-left:20px
}

</style>


