<template>
 
  <h2 class="fs-25 mt-0" style="margin-bottom: 0px">
    <span>
      <font :style="'background:transparent; cursor:pointer;color:'+item.font_set.title_color">
        <i :class="item.icon" :style="'color:'+item.font_set.icon_color"></i>
        <font v-html="item.title "></font>
      </font>
      <span class="fw-350 fs-20" :style="'color:'+item.font_set.subtitle_color" v-if="item.subtitle!=''" v-html="': '+item.subtitle"></span>
    </span>
  </h2>


</template>

<script>
export default {
  props: ['item']
}
</script>