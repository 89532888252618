<template>
  <div>

    <div>show</div>

  </div>
</template>

<script>

export default {
  props: ['item'],
  components:{

  },
  computed: {
    // goPage: {
    //   get(){
    //     return this.compo
    //   },
    //   set(val){
    //     this.$emit('update:compo',val)
    //     // this.$emit('update:show_id',id)
    //   }
    // }
  },

}
</script>