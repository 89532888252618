<template>
   <div class="tab-content box-static box-transparent box-bordered p-30 relative" style="border-top-style: none">

      <div class="tab-pane active" >
        <div class="row">
          <div class="col-12">
            <label>푸터섹션 글자색</label>
            <color-picker  v-bind:font_color.sync='current_section_set.title_color' v-bind:label="'글자색'" v-bind:option="{label: false, input: false}" ></color-picker>
          </div>  
          

          <div class="col-12">
            <label>왼쪽 아래 글자</label>
            <input type="text" class="form-control"  v-model="right_text" placeholder="© All Rights Reserved, Company LTD"  >
            <div>빈칸사용 : 'empty'를 입력</div>
          </div>

          <div class="col-12">
            <label>오른쪽 아래 글자</label>
            <input type="text" class="form-control"  v-model="left_text" placeholder="Terms & Conditions • Privacy"  >
            <div>빈칸사용 : 'empty'를 입력</div>
          </div>
           
          <div class="col-12">
            <label>표시할 바닥글 아이템 수</label>
            <el-radio-group v-model="columnCount" size="mini">
              <el-radio-button v-for="l in [1,2,3,4]" :label="l" :key="l" >{{ l }}단</el-radio-button>
            </el-radio-group>
          </div>
          
          <div class="col-12">
            <label>배경설정</label>
            <div v-if='screen_size > 1000'>
              <el-radio v-model="current_section_set.use_background" :label="0" border style="margin-right:0px">사용안함</el-radio>
              <el-radio v-model="current_section_set.use_background" :label="1" border style="margin-right:0px">배경색사용</el-radio>
              <el-radio v-model="current_section_set.use_background" :label="2" border style="margin-right:0px">배경이미지사용</el-radio>
            </div>
            <el-radio-group v-model="current_section_set.use_background" v-else size="small">
              <el-radio-button :label="0" >사용안함</el-radio-button>
              <el-radio-button :label="1" >배경색사용</el-radio-button>
              <el-radio-button :label="2" >배경이미지사용</el-radio-button>
            </el-radio-group>
          </div>

          <div class="col-12">
            <label>배경색상설정</label>
            <color-picker v-bind:font_color.sync='current_section_set.color' v-bind:label="'글자색'"  v-bind:option="{label: false, input: true}" ></color-picker>
          </div>

          <div class="col-12">
            <label>배경이미지 설정</label>
            <el-upload
              ref="file1"
              name="file1"
              :on-success='return_section_image'
              :before-upload="before_upload"
              :action="'/webpages/'+page_type+'/section_background/file1'"
              :headers="upload_headers"
              :data="{ id: current_section.id }"
              :limit="1"
              :auto-upload="true">
              <el-button slot="trigger" size="mini" type="primary">배경이미지</el-button>
              <el-button style="margin-left: 10px;" size="mini" type="success" @click="submitUpload('file1')"  v-if="false" >이미지 업로더</el-button>
              <div class="el-upload__tip" slot="tip">jpg/png 가능하며 2mb이하로 업로더 하시면 됩니다.</div>
            </el-upload>
            <div v-if="!object_empty_check(current_section.file1) && current_section.file1.thumb!='' && current_section.file1.thumb!='/images/noimage.png'">
              <img :src="current_section.file1.thumb" alt="" height="100"><br>
              <div class="badge badge-danger" @click="image_delete" style="cursor:pointer">삭제</div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" @click="$emit('update:visible', false)">닫기</button>
        <button type="button" class="btn btn-primary" @click="footer_section_update()" >수정</button>
      </div>
    </div>
</template>

<script>
import ColorPicker from '../../../../common_components/color_picker'


export default {
  props: ['current_section', 'visible'],
  data(){
    return {
      columnCount: 3,
    } 
  },
  
  mounted(){
    if(this.current_section_set.column_count == undefined)this.current_section_set.column_count =  3 
    this.columnCount = this.current_section_set.column_count
  },

  watch: {
    columnCount(val){
      this.current_section_set.column_count = val
    }
  },

  computed: {
    current_section_set(){
      return this.current_section.config.section_set
    },
    right_text: {
      get(){
        if(this.isEmpty(this.current_section.title))return "© All Rights Reserved, Company LTD" 
        return this.current_section.title
      },
      set(val){
        this.current_section.title = val
      }
    },
    left_text: {
      get(){
        if(this.isEmpty(this.current_section.sub_title))return "Terms & Conditions • Privacy" 
        return this.current_section.sub_title
      },
      set(val){
        this.current_section.sub_title = val
      }
    }
  },

	methods:{
		//엘리먼트io 파일저장은 webpage.js에 있음

		return_section_image(result){
			this.webpageNotice(result)
			if(result.result=="success"){
				this.current_section.file1 = result.data
				this.current_section.config.section_set.use_background = 2
				this.$emit('update:visible', false)
			}
			
		},

		image_delete(){
			if(confirm('정말 삭제할까요?')){
				this.axios().post(`/webpages/${this.page_type}/section_background/file1`, {id: this.current_section.id, file1: '' })
				.then(result=>{
					this.current_section.file1 = result.data.data
					this.webpageNotice(result.data)
				})
			}
    },
    
    footer_section_update(){
      const data = {type: 'section_set', section_set: this.current_section.config.section_set, title: this.current_section.title, sub_title: this.current_section.sub_title }
			this.axios().put(`/webpages/${this.page_type}/section_update/${this.current_section.id}`, data )
			.then(result=>{
				this.webpageNotice(result.data)
				if(result.data.result=='success'){
					this.$emit('update:visible', false)
        }
      });

    }

	},

  components: {
		ColorPicker
	}
}
</script>

<style>
  .col-12 {
    margin-bottom: 20px
  }
</style>


