<template>
  <div>
    <div class="card">
      
      <div class="card-datatable table-responsive">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th>#</th>
              <th style="min-width:300px">샤플명</th>
              <th >인수인아이디</th>
              <th style="min-width:300px">양도일</th>
              <th style="min-width:300px">인수일</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='(l, index) in $store.state.list' :key='index' >
              <td scope="row" style="padding-left:10px;text-align:right" >
                {{ listNumber(index) }} 
              </td>
              <td >
                <div style="cursor:pointer"  >{{ l.webpage_name }}</div> 
              </td>
              <td>
                {{ l.userid }}
              </td>
              <td>
                {{ isEmpty(l.transfer_at) ? dateFormat(l.updated_at,'YYYY/MM/DD') : dateFormat(l.transfer_at,'YYYY/MM/DD')  }}
              </td>
              <td>
                {{ dateFormat(l.created_at,'YYYY/MM/DD')  }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td style="text-align:center" colspan="4">
              <el-pagination
                background
                layout="prev, pager, next, jumper"
                :page-size="perPage"
                :total="totalCount"
                :current-page.sync="$store.state.current_page"
                @current-change = "pageChange"
                >
              </el-pagination>
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    </div>
   

  </div>
</template>
<script>


export default {
  data(){
    return {
      
      listActive: {},
    }
  },

  created(){
    if(this.$route.query.page!=undefined)this.$store.state.current_page = Number(this.$route.query.page)
    this.webpage_data() 
    $('.datatables-demo').dataTable();
  },

  computed: {
    
  },

  methods:{ 
    pageChange(val){
      this.$store.state.current_page = val
      this.$router.push( `${this.$route.path}?page=${val}` )
      this.webpage_data() 
    },

    totalPageCount(){
      return this.allPage;
    },

    create_text_title(){
      return '비활성화 샤플은 10개까지 생성 가능합니다.'
    },

    list_active(){
      console.log("=========11111===========");
      // console.log(this.$store.state.list);
      // for( l in this.$store.state.list){
      //   console.log(l); 
      // }
    },

  },


}
</script>
