import 'core-js/es7/array'

export default {

    computed: {
		// config(){return this.$store.state.config },
		subpage_finder(){ return this.$store.state.subpages.find(s => s.subpage_name == this.$route.params.subpage ) },
		ownerCheck() { 
				if(this.$store.state.authCheck && this.$store.state.auth_set.admin_type==5)return false
				return this.$store.state.authCheck;
		},
		current_page() { return this.$store.state.current_page;},
		page_type() { return this.$store.state.page_type; },
		webpage_type() { return this.$store.state.webpage_type; },
		base_url() { return this.$store.state.base_url; },
		userinfo() { return this.$store.state.user; },
		perPage() { return this.$store.state.perPage; },
		section_id() {  return this.$store.state.section_order[this.index] },
		section_row() { 
				const section = this.$store.state.sections.filter(s=>s.id==this.section_id)
				return section[0]
		},
		section_format() { return this.$store.state.section_format},
		section_length() { return this.$store.state.section_order.length },
		section_item_order() { return this.section_row.item_order },
		section_item() { return this.section_row.items},
		// section_options() {
		//     var sec = []
		//     let section = this.$store.state.section_format
		//     let cnt = 1
		//     for (var s = 0; s < section.length; s++) {  
		//         if(section[s].section_select ){
		//             sec.push({ label: section[s].name, value: cnt })
		//             cnt++ 
		//         }
		//     }
		//     return sec;
		// },
		section_tag_id() { return `${this.current_section.component}_${this.index}` },
		upload_headers() {
				//엘리먼트 io의 파일 업로더에서 필요한 헤더
				return {'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),'webpage-id': webpage_id}
		},
		screen_size() { return this.$store.state.screenSize },
		mobile_screen() { return this.$store.state.screenSize < 1000 },
		ctem: { get() { return this.$store.state.items },  set(val) { this.$store.state.items = val } },
		item_order: { get() { return this.current_section.item_order }, set(val) { this.current_section.item_order = val }},
		use_header() { return  this.current_section.title == '' && this.current_section.sub_title == '' ? false : true; },
		section_set() {  return this.current_section.config.section_set; },
		section_title_style() {  return `font-size: 30px; color: ${this.section_set.title_color}; font-family: ${this.section_set.title_font}`; },
		section_sub_title_style() {  return `color: ${this.section_set.subtitle_color}`; },
		slide_file1_noimage() { return `https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/images/background_images/b01.jpg`; },
		slide_file2_noimage() { return `https://s3.ap-northeast-2.amazonaws.com/easyhbimages/noimage_transparent.png`; },
		element_modal_fullscreen() { return this.$store.state.screenSize > 500 ? false : true },
		footer_section(){
            let footer = {};
            let section = this.$store.state.sections;
            for(var s in section){if(section[s].component == 'footer01') footer = section[s]};
            return footer; 
		},
		image_round(){ if(this.current_section.config.section_set.image_square){ return ''}else{return 'rounded'} },
		board_pages(){
				const subpage = this.$store.state.subpages;
				let menu = {}
				for(let key in subpage){ if(subpage[key].subpage_type==1) menu[`${subpage[key].subpage_name}`] = `게시판-${subpage[key].subpage_name}` }
				return menu
		},
		mainpage_check(){return this.$route.name == 'mainpage' },
		subpage_check(){return this.$route.name=='subpage' || this.$route.name=='board_view' },

		//섹션 폼에서 현재 비디오 겔러리 다단 수 만드는 코드
		line_count_show(){
			return this.$store.state.current_section_format.line_count == undefined ? false : (this.$store.state.current_section_format.line_count.length > 0 ? true : false )
		},
		line_count(){
      const count = this.$store.state.current_section_format.line_count
			let c = []
			if(count.length == 1){
        for(let a=1;a<=count[0];a++){c.push(a)}
			}else{
        for(let a=count[0];a<=count[1];a++){c.push(a)}
			}
			return c
        },
        homepage_type(){ return this.$store.state.webpage_type == 1 || this.$store.state.webpage_type == 2  },
        newsletter_type(){ return this.$store.state.webpage_type == 3  },
        use_background(){ return this.current_section.config.section_set.use_background },
        background_transparent(){
            if(this.current_section.config.section_set.background_transparent==undefined)this.current_section.config.section_set.background_transparent = 30
            if(this.current_section.component == 'video01' && this.use_background==0)this.current_section.config.section_set.background_transparent = 60
            this.current_section.config.section_set.background_transparent = this.current_section.config.section_set.background_transparent 
            return this.current_section.config.section_set.background_transparent / 100
        },
        //섹션 폼에서 현재 비디오 겔러리 다단 수 만드는 코드
			item_view_class(){
				let column_count = 3
				if(this.current_section.config.section_set.column_count != undefined)column_count = this.current_section.config.section_set.column_count
				switch(column_count){
					case 2: 
						return "col-md-6" 
					case 3:
						return "col-md-4" 
					case 4:
						return "col-md-3" 	
					default:
						return "col-md-12"    
				}
      },

      image_height(){
			switch(this.current_section.config.section_set.column_count){
				case 2: 
					return "max-height: 700px"
				case 3:
					return "max-height: 600px" 	
				case 4:
          return "max-height: 400px" 	
        default:
          return "max-height: 1600px" 
			}
        },

        file1_thum_use:{
            get(){
                if(!this.object_empty_check(this.currentItem)){
                    return this.currentItem.font_set.file1_thum_use == undefined ? true : !this.currentItem.font_set.file1_thum_use
                }
                return true
            },
            set(val){}      
        },
        
        use_section_header(){
			if(!this.isEmpty(this.current_section.title))return true
			if(!this.isEmpty(this.current_section.sub_title))return true
			return false
        },
        
    },
    
    methods: {
        use_fulltext(item){
            return (item.font_set.no_use_fulltext==undefined || !item.font_set.no_use_fulltext)
        },
        
        subtitle_color(item){
            if(item.font_set!=undefined)return item.font_set.subtitle_color==undefined ? '' : 'color:'+item.font_set.subtitle_color
            return ''
        },

        cur_file1_image(ctem){
            const item = ctem
            if(item.file1==undefined)return false  
            if(item.file1.large==''){
              return this.no_image
            }else{
              return item.file1.large
            }
        },

        admin_head(){
            if(this.$store.state.login){
                if(this.$store.state.authData.auth_type!=undefined){
                    if(this.$store.state.authData.auth_type==1 && this.$store.state.login_type == 'member' ) return true;
                }
            }
            return false
        },
        
        admin_auth_check(level, section={}, type=''){
            if(this.$store.state.login){
                if(this.$store.state.authData.auth_type!=undefined){
                    if(this.$store.state.authData.auth_type==1){
                        // return this.$store.state.auth_set.admin_type == 1 ? true : false
                        return this.$store.state.member_type == 1 ? true : false
                    }else if(this.$store.state.authData.auth_type==2){
                        if(level == 1){
                            if(this.$store.state.member_type!=undefined){
                                // return this.$store.state.auth_set.admin_type == 1 ? true : false
                                return this.$store.state.member_type == 1 ? true : false
                            }
                        }else if(level == 2){
                            if(this.$store.state.auth_set.auth!=undefined){
                                // console.log('00--------------------------');
                                // console.log(this.$store.state.member_type == 1);
                                if(this.$store.state.member_type == 1){
                                    // console.log('11--------------------------');
                                    return true
                                }else{
                                    // console.log('22--------------------------');
                                    const auth_set = this.$store.state.auth_set.auth
                                    const section_auth = this.$route.name == 'mainpage' ? auth_set.main : auth_set.sub
                                    const section_id = this.$route.name == 'mainpage' ? section.id : section.subpage_id
                                    if(type=="menu" && this.$route.name == 'mainpage' ){
                                        return auth_set.option.length > 0
                                    }
                                    return section_auth.includes(String(section_id)) ? true : false
                                } 
                            }
                        }else{
                            return false
                        }
                    }
                }
            }
        },

        option_auth_check(auth){
			if(this.admin_auth_check(1)){
				return true
			}else{
                if(this.$store.state.auth_set.auth != undefined){
                    let result = []
                    for(let ath of auth){
                        result.push(this.$store.state.auth_set.auth.option.includes(ath))
                    }
                    return result.includes(true) ? true : false
					// if(this.$store.state.auth_set.auth.option.includes('webdesign') || this.$store.state.auth_set.auth.option.includes('subadmin') ){
					// 	return true
					// }else{
					// 	return false
					// }
				}
			}
        },
        
        board_auth_check(){
            if(this.admin_auth_check(1)){
                return true
            }else{
                if(this.$store.state.auth_set.auth != undefined){
                    if(this.$store.state.auth_set.auth.board.includes(String(this.current_section.subpage_id))) return true
                }        
                return false
            }
        },
		
        section_menu_id(section) {
            return `${section.component}_${section.id}`
        },

        webModal(id, component, type, section = {}, index = 1000, left_sidebar_close = false, action='new', data={}) {
            //type 1:section설정 2.아래에 섹션추가 3.메뉴및게시판
            this.$store.state.modal_key = `${component}_${new Date().getTime()}`
            // $(id).modal('show');
            this.$store.commit('set_ant_modal_visible', true)
            this.$store.state.modal.component = component
            this.$store.state.modal.type = type
            this.$store.state.modal.section = section
            this.$store.state.modal.index = index
            this.$store.state.section_num = 0
            this.$store.state.refresh = 0
            if(left_sidebar_close) $('#sidepanel_close').trigger('click');
            if (type == 1) {
                this.$store.state.modal.active = 'item_set'
                this.$store.state.modal.del_confirm = false
                this.$store.state.current_section_format = section_format.find(f=>f.component == section.component)
            }
            if(type == 2) this.$store.state.current_component = 'section-design'
            if(type == 3){
                //현재 코드는 뭉글일 경우에 사용한다.
                this.$store.state.modal.action = action //새로입력, 수정, 삭제 구분
                this.$store.state.modal.data = data
                if(section.component == 'newsletter01'){
                    this.$store.state.modal.id += 1 
                }
            }
        },

        move_section(from, to) {
            let origin = this.$store.state.section_order
            let sections = JSON.parse(JSON.stringify(this.$store.state.section_order)); 
            this.current_section_order = sections
            sections.splice(to, 0, sections.splice(from, 1)[0]);
            this.$store.state.linebar01_excu_cnt = 0 //_owl_carousel() 이 문제야 여러번 실행하면 에러나 ㅠㅠ
            const data = { type: 'section_move', section_order: sections}
            const section = this.$store.state.sections.filter(s=>s.id==this.section_id)
            this.axios().put(`/webpages/${this.page_type}/section_update/${section[0].id}`, data)
            .then(result => {
                this.webpageNotice(result.data);
                if(result.data.result=="success"){
                    this.$store.state.section_order = sections 
                    this.$store.commit('set_page_sections')
                }
            });
        },
       
        webpage_data() {
            this.axios().get(`/admin/index?webpage_type=${this.$route.name}&per_page=${this.perPage}&current_page=${this.current_page}`)
            .then(result => {
                if (result) {
                    this.auth_admincheck(result)
                    this.basic_save(result)
                }
            });
        },
  
        section_background(css='') {
            if (this.current_section.config == undefined) return ''
            if (this.current_section.config.section_set == undefined) return ''
            let layout_padding = design_config.layout == 'boxed' ? 'padding-left: 30px;padding-right:30px;' : ''
            if (this.current_section.config.section_set.use_background == 2) {
                if (this.current_section.file1.origin != '') {
                    //this.$vssWidth screen size 변수
                   
                    if (this.$vssWidth < 800) {
                        return `${layout_padding}background-image:url('${ this.current_section.file1.thumb }');background-attachment: initial`
                    } else {
                        if(this.current_section.config.section_set.fix_backimage==undefined)this.current_section.config.section_set.fix_backimage=false
                        const fiximage = this.current_section.config.section_set.fix_backimage ? ';background-attachment: initial' : ''
                        return `${layout_padding}background-image:url('${ this.current_section.file1.origin }')${fiximage}`
                    }
                } else {
                    return `${layout_padding}`
                }
            } else if (this.current_section.config.section_set.use_background == 1) {
                return `${layout_padding}background-color:${ this.current_section.config.section_set.color } !important;`
            } else {
								const stle = css == '' ? '' : css 
                return `${layout_padding}${stle}`
            }
        },

        webpageNotice(data) {
            if (data.title == undefined) data.title = ''
            if (data.type == undefined) data.type = 'error'
            if (data.duration == undefined) data.duration = 4000
            this.$notify({
                title: data.title,
                message: data.msg,
                dangerouslyUseHTMLString: true,
                type: data.type,
                duration: data.duration
            });
        },

        auth_admincheck(result) {
            this.$store.commit('set_login',result)
            if (this.$store.state.login == false) {
                location.href = '/'
                return false;
            }
            return true;
        },

        basic_save(result) {
            this.$store.state.list = result.data.list
            this.$store.state.totalCount = result.data.totalCount
            this.$store.state.base_url = result.data.base_url
            if (this.$store.state.login == true) {
                this.$store.state.user.userid = result.headers.userid
            }
        },

        submitUpload(file_field) {
            //엘리먼트를 이용한 파일 업로더 공통 코드
            this.$refs[file_field].submit()
        },
        

        file_exist(item, file) {
            if (item[file] == undefined) {
                return false
            } else {
                if (item[file].thumb == '') {
                    return false
                } else {
                    return true
                }
            }
        },

        link_button(url,name='_blank') {
            if (url == '' || url == 'null') {
                return false;
            } else {
                window.open(this.addhttp(url), name)
            }
        },

        addhttp(url) {
            if (!/^(f|ht)tps?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
        },

        item_div() {
            let n = 4
            var reorder = JSON.stringify(this.item_order)
            reorder = JSON.parse(reorder)
            reorder = reorder.splice(0, this.current_section.item_limit)
            let len = reorder.length
            let cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0)
            var tmp = []
            for (let i = 0; i < cnt; i++) {
                tmp.push(reorder.splice(0, n))
            }
            let tmp_len = tmp.length > 0 ? tmp[0].length : 1
            this.grid = this.item_grid_class(tmp_len)
            this.re_order = tmp
        },

        item_grid_class(n) {
            var grid = 12
            if (n < 4) {
                grid = Math.round(12 / n)
            } else {
                grid = 3
            }
            return `col-md-${grid}`
        },

        get_page_data() {
            //홈페이지, 뭉글 포탈형 홈페이지 데이터
            let page_type = ''
            const board_id = this.$route.params.board_id == undefined ? 0 : this.$route.params.board_id
            if(this.$route.name=='webpage_users'){
                page_type = this.$route.name
                var search = this.webpage_user_data_search()
            }else{
                page_type = this.page_type
                if(page_type=='')page_type='mainpage'
                var search = this.main_sub_board_search()
            } 
            this.axios().get(`/webpages/${page_type}/get_data/${board_id}.json${search}`)
            .then(result => {
                try {
                    if(result.data.result=='success' || result.data.result=='redirection'){
                        this.$store.commit('set_page_data', result)
                        this.$store.commit('set_login',result)
                        this.sections = this.$store.state.sections
                    }
                } catch (err) {
                    
                }
            });
        },
        
        main_sub_board_search(){
            const current_page = !this.isEmpty(this.$route.query.current_page) ? `?current_page=${this.$route.query.current_page}` : '?'
            let search = ''
            search += !this.isEmpty(this.$route.query.board_search) ? `&board_search=${this.$route.query.board_search}` : ''
            search += !this.isEmpty(this.$route.query.search_word) ? `&search_word=${this.$route.query.search_word}` : ''
            search += !this.isEmpty(this.$route.query.cate_year) ? `&cate_year=${this.$route.query.cate_year}` : ''
            search += `&webpage_id=${webpage_id}`
            return current_page + search
        },
        
        webpage_user_data_search(){
            const get_mode = this.$route.query.get_mode
            var current_page = ''
            var search = ''
            if(get_mode!=undefined){
                search += `?get_mode=${get_mode}`
                if(get_mode=='cate'){
                    if(this.$route.query.main_cate_id!=undefined)search += `&main_cate_id=${this.$route.query.main_cate_id}`
                    if(this.$route.query.sub_cate_id!=undefined)search += `&sub_cate_id=${this.$route.query.sub_cate_id}`
                }
                if(get_mode=='search' && !this.isEmpty(this.$route.query.search_word)){
                    search += `&search_word=${this.$route.query.search_word}`
                }
                if(this.$route.query.current_page!=undefined)current_page = `&current_page=${this.$route.query.current_page}` 
            }
            return search + current_page
        },


        get_search_page_data(cur_page) {
            //검색 페이지를 위한 데이터
            if(cur_page==undefined)cur_page=1
            const board_id = this.$route.params.board_id == undefined ? 0 : this.$route.params.board_id
            const current_page = `?current_page=${cur_page}`
            let search = ''
            if(!this.isEmpty(this.$route.query.search_word) || !this.isEmpty(this.search_word) )search = !this.isEmpty(this.$route.query.search_word) ? `&search_word=${this.$route.query.search_word}` : `&search_word=${this.search_word}`
            this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}/search_page${current_page}${search}`)
            if(!this.isEmpty(search)){
                this.axios()
                    .get(`/webpages/search_page/get_data/${board_id}.json${current_page}${search}`)
                    .then(result => {
                        try {
                            if(result.data.result=='success'){
                                this.$store.state.search_data = result.data.data
                            }else if(result.data.result=='redirection'){
                                this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}/security_pages_login`)
                            }
                        } catch (err) {
    
                        }
                    });
            } 
        },

        get_homepage_data(){
            let search = ''
            search += `?current_page=${this.page_info.current_page}`
            search += this.isEmpty(this.search_word) ? '' : `&search_word=${this.search_word}`
            this.axios().get(`/webpages/homepage_index/${this.current_section.id}/${search}`)
            .then(result=>{
                try {
                    this.$store.commit('set_homepage_list', result.data.homepage_list) 
                    if(this.$route.fullPath != `${this.$route.path}${search}`)this.$router.push(search) //네비게이터 에러를 막기 위해서 여러번 검색하면 에러남
                }catch(e){
                    console.log(e);
                }
            })
            .catch(function (err) {
                console.log(err);
            });
        },

        get_select_homepage_list(){
            let search = ''
            search += this.page_info.currentPage == 1 ? '?current_page=1' :`?current_page=${this.page_info.current_page}`
            search += this.isEmpty(this.search_word) ? '' : `&search_word=${this.search_word}`
            this.axios().get(`/webpages/homepage_index/0/${search}`)
            .then(result=>{
                try {
                    this.$store.commit('set_homepage_list', result.data.homepage_list)
                }catch(e){
                    console.log(e);
                }
            })
            .catch(function (err) {
                console.log(err);
            });
        },

        section_add(type,section_id_for_mainhome={}){
            //type  section_add : subsection_add(메인페이지에서 남의 섹션 가져올때)
            var section_order = this.$store.state.modal.index
            this.$store.state.linebar01_excu_cnt = 0 //linebar01을 위한 _owl_carousel() 두번이상 실행되면 에러가 나기 때문에 0으로 초기화 해야 실행된다.
            this.axios().post(`/webpages/${this.page_type}/section_create`, {type: type, section_order: section_order, data: this.current_section_format(), section_id_for_mainhome: section_id_for_mainhome})
            .then(result => {
                this.webpageNotice(result.data)
                if(result.data.result=='success'){
                    if(result.data.data.items!=undefined){
                        for(let [k,v] of Object.entries(result.data.data.items)){
                            this.$store.state.items[k] = v
                        }
                    }    
                    this.$store.state.section_order = result.data.data.section_order
                    result.data.data.section.hidden = 1
                    this.$store.commit('add_section',result.data.data.section)
                    this.$store.commit('config_section_count_update', result.data.data.section_order.length)
                    // $('#webpage_setting').modal('hide');
                    this.$store.commit('set_ant_modal_visible', false)
                    if(result.data.data.section.component=='countdown01'){
                        if(confirm('카운트다운섹션의 경우 생성후 새로고침하셔야 정상 작동합니다.\n\r새로고침 하시겠습니까?'))location.reload()
                        return false
                    }
                }
                     
            })
        },
        

        // set_contactus_section(section){
            // 오브젝트 이터레이터 참고
        //     for (const [key, value] of Object.entries(section)) {
        //         if(value.component.includes('contactus')){
        //             this.$store.state.contactus[value.id] = {}
        //         }
        //       }
        // },
        
        component_show(section){
            let result = false
			if(section){
                if(this.ownerCheck){
                    result = (section.hidden == 1 && this.$store.state.preview == false) ?  false : true
				}else{ 
                    if(section!=undefined){
                        console.log(section.hidden);
                        result = section.hidden == 1  ?  false : true
                    }
				}
			}else{
				result = false
            }

			return result
        },
        
        before_upload(file){
			let result = true
			let msg = ''
			if(file.size > 2500000){
				result = false
				msg = '선택하신 파일 중에 2.5MB가 넘는 파일이 있습니다. 2.5MB까지 허용합니다.!'
			} 
			if(file.type == "image/jpeg" || file.type == "image/gif" || file.type == "image/png"){
			}else{
				result = false
				msg = 'jpeg, jpg, gif, png 파일만 가능합니다.'
			} 
            if(!result){
                this.webpageNotice({type: 'error', msg: msg ,duration: 5000})
                this.file_error = msg
                const self = this
                setTimeout(function(){self.file_error=''},7000)
            }
			return result
        },
        
        move_board(type, board={} ){
            if(type==1){
              return `/${this.$route.params.userid}/${this.$route.params.url}/${this.current_item.link1}/${board.id}`
            }else{
              location.href = `/${this.$route.params.userid}/${this.$route.params.url}/${this.current_item.link1}`
            }
        },

        admin_page(){
            let domain = window.location.hostname
            console.log("=================");
            console.log(domain);
            let domain_check = domain.indexOf(/ssharple/)
            console.log(domain_check);
            const adomain = domain_check !== -1 ? "https://ssharple.net" : "https://easyhb.net"
            console.log(adomain);
            const site = (process.env.NODE_ENV === 'production') ? adomain : "http://localhost:3000"
            if(webpage_type==1){
                this.pageMove(`${site}/admin/mainhomepage`,false, 'easyhb_admin')
            }else if(webpage_type==2){
                this.pageMove(`${site}/admin/homepage`,false, 'easyhb_admin')
            }else if(webpage_type==3){
                this.pageMove(`${site}/admin/newsletter`,false, 'easyhb_admin')
            }
        },
        
        handleResize (event) {
			this.fullWidth = document.documentElement.clientWidth
			if(this.fullWidth < 992){
				this.logo_font_size = this.$store.state.logo_resize_font_size
				this.$store.state.screenSize = this.fullWidth
				$('#header #topNav a.logo>img').css("max-height", "50px")
			}else{
				this.logo_font_size = this.$store.state.logo_origin_font_size 
				this.$store.state.screenSize = this.fullWidth
			}
        },
        
        search_link(type,id){
			this.axios().post('/webpages/search_link',{ userid: this.$route.params.userid, type: type, id: id })
				.then(result=>{
					if(result.data.url == this.$route.params.url){
						location.href = result.data.link
					}else{
						this.pageMove(result.data.link,true,result.data.url  )
					}
				})
        },
        
        webpage_user_search(search){
            this.axios().get(`/${this.$route.params.userid}/${this.$route.params.url}/webpage_user/${webpage_id}${search}`)
            .then(result=>{
                if(result.data.result=='success'){
                this.$store.commit('set_webpage_users', result.data.webpage_users)
                const current_page = `&current_page=${this.$route.query.current_page==undefined ? 1 : result.data.webpage_users.current_page}`
                this.$router.push(`/${this.$route.params.userid}/${this.$route.params.url}/users/${webpage_id}${search}${current_page}`)
                }
            })
        },

        ant_modal_close(){
            this.$store.commit('set_ant_modal_visible', false)
        },

        btn_color(color){
			if(color.config.link1==undefined)return 'default'
			return color.config.link1.color
        },
        
        media_source_test(youtube){
            if(this.isEmpty(youtube)){
                return false
            }else{
                const tubeurl = /<iframe[^>]*?(?:\/>|>[^<]*?<\/iframe>)/
                return tubeurl.test(youtube) 
            }
        },

        thumb_use(item){
			return item.font_set.file1_thum_use == undefined ? false : item.font_set.file1_thum_use
        },
       

    }

}