<template>

		<div class="container">
			<div class="owl-carousel m-0 text-center" data-plugin-options='{"singleItem": false, "stopOnHover":false, "autoPlay":4000, "autoHeight": false, "navigation": false, "pagination": false}'>
				<div>
					<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/brands/1.jpg" alt="">
				</div>
				<div>
					<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/brands/2.jpg" alt="">
				</div>
				<div>
					<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/brands/3.jpg" alt="">
				</div>
				<div>
					<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/brands/4.jpg" alt="">
				</div>
				<div>
					<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/brands/5.jpg" alt="">
				</div>
				<div>
					<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/brands/6.jpg" alt="">
				</div>
				<div>
					<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/brands/7.jpg" alt="">
				</div>
				<div>
					<img class="img-fluid" src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/brands/8.jpg" alt="">
				</div>
			</div>

			
		</div>
</template>


<script>
export default {

  
}
</script>

<style>

</style>

