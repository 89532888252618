<template>
  <div>
    <span class="btn btn-sm btn-primary" v-if="compo!='index'"  @click="goIndex()" ><i class="fa fa-arrow-left"></i>뒤로</span>  
    <span class="btn btn-sm btn-danger" v-if="compo=='index' && cur_section.item_limit > 0"  @click="addItem()" >아이템추가</span>  
    <span class="btn btn-sm btn-success" v-if="item_all_set_button"  @click="itemAllset()" >아이템전체설정</span>  
    <span class="btn btn-sm btn-warning" v-if="item_writer_layout_button"  @click="itemWriteLayoutset()" >본문레이아웃설정</span>  
		<div style="font-size:9pt" v-if="cur_section.item_limit > 0" >현재 섹션에서 아이템 사용 가능수는 <font color=red>{{ cur_section.item_limit }}</font>개이며 {{ cur_item_cnt}}</div>
		<div style="font-size:12pt" v-else>현재 섹션에서 <font color="red">아이템을 사용하지 않으며</font> 섹션 설정만 가능합니다.</div>
		<div style="font-size:9pt" v-if="cur_section.item_limit > 0">현재 섹션에서 사용 항목은 <font color=blue>{{ $store.state.current_section_format.use_item.join(',') }}</font>입니다.</div>
		<div class="col-12 alert alert-warning" >
			<p>현재 아이템 사용현황</p>
			<div style="font-size: 18pt">{{ item_count }} / {{ item_limit }} </div>
			<cite style="font-size: 9pt"><b style='color:red'>{{ item_limit - item_count }}개 </b>더 추가 가능합니다.</cite>
		</div>
  

		<transition  name="slide-fade" v-if="cur_section.item_limit > 0">
			<component :is="compo" :compo.sync="compo" :data.sync="data" :current_section="cur_section" :writerType="writerType" :key="data.id"  ></component>
		</transition>
  </div>
</template>


<script>

import Index from "../item_set/index"
import Show from "../item_set/show"
import ItemForm from "../item_set/form"
import ItemAllset from "../item_set/item_all_set"
import Writerset from "../item_set/writer_set"
import SlideForm from "../item_set/form" //위엣것과 같지만 슬라이드 폼에서 뒤로 가면 슬라이드 셋으로 이동하기 구분
import SlideSet from "../item_set/slide_set"
import 'core-js/es7/array'

export default {
	data(){
		return {
			compo: 'index',
			kubun: '',
			show_id: 0,
			data: {},
			writerType: 'main'
		}
	},

	computed:{
		cur_section(){
			return this.$store.state.modal.section 
		},

		cur_item_cnt(){
			if( this.cur_section.item_order.length < this.$store.state.item_limt_count ){
				return `현재 아이템 수는 ${this.cur_section.item_order.length}개입니다.`
			}else{
				return `아이템 제한수는 ${ this.$store.state.item_limit_count }개이므로 추가 불가 합니다.`
			}
		},
		
		item_all_set_button(){
			let result = false
			if(this.compo == 'item-allset' || this.compo =='index' ){
				if(this.cur_section.item_order.length  > 0 && this.cur_section.item_limit > 1 )result = true
			}
			return result
		},
		
		item_writer_layout_button(){
			let result = false
			if(this.compo == 'item-form'){
				// console.log(this.cur_section.component);
				if(this.cur_section.component.includes('writer'))result = true
			}
			// console.log(result);
			return result
		},
		
		item_limit(){
			return this.$store.state.config.item_limit
		},

		item_count(){
			return this.$store.state.config.item_count
		}
    

	},

	methods:{
		goIndex(){
      if(this.compo=='slide-form' ){
				this.compo = 'slide-set'
			}else{
				if( this.cur_section.component.includes('slide') && this.compo == 'slide-form' ){
					this.compo = 'slide-set'
				}else{
					this.compo = 'index'
				}
			}
		},

		itemAllset(){
			this.compo = 'item-allset'
		},

		itemWriteLayoutset(){
			this.data.context = $('.note-editable').html()
			this.compo = 'writerset'
		},

		addItem(){
			this.data = {
				id: '',
				title: '',
				subtitle: '',
				context: '',
				config: {type:'title', item_board_type: 1,link1: { color: 'primary', name: '' }, link2: { color: 'primary', name: '' }, slide_set: {} },
				font_set: { icon_color: '#4E5155', title_color: '#4E5155', subtitle_color:'#4E5155', context_color: '#4E5155' },
				icon: '',
			}

			if( this.cur_section.component.includes('slide')){
				this.compo = 'slide-set'
				this.data.config.slide_set = { 
					slide_type: 1, animation: 1, delay_time: 3500, 
					left_location: 0, top_location: 0 , 
					file2_left_location:0, file2_top_location:0, 
					transition: 'random', background_color: '#4E5155', background_default_image: 'b08.jpg', background_use: 2  }
			}else{
				this.compo = 'item-form'

			}

			if(this.cur_section.item_order.length > 0){
				//아이템 새로 등록시 앞에 작성했던 폰트 색상을 적용한다.
				const last_item_id = this.cur_section.item_order[this.cur_section.item_order.length-1]
				const last_item = this.$store.state.items[last_item_id].font_set
				this.data.font_set = this.$store.state.items[last_item_id].font_set
				// this.data.font_set.icon_color = last_item.icon_color
				// this.data.font_set.title_color = last_item.title_color
				// this.data.font_set.subtitle_color = last_item.subtitle_color
				// this.data.font_set.context_color = last_item.context_color
			  // this.data.font_set.writer_set = last_item.font_set
			}


		}
	
	},

  components: {
		Index,
		Show,
		ItemForm,
		ItemAllset,
		Writerset,
		SlideForm,
		SlideSet
	}
}
</script>

<style >
.slide-fade-enter-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-leave-active {
  transition: all .1s ease-out;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(200px);
  opacity: 0.5;
}
</style>