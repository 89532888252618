<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="내정보">
        
        <webpage-user v-bind:signup_pagetype="'mypage_edit'" v-bind:current_user="current_user" ></webpage-user>
      </el-tab-pane>
      <el-tab-pane label="비밀번호변경">
        <div class="info-bar info-bar-bordered">
          <div class="container">
            
            <h3><i class="glyphicon glyphicon-info-sign"></i> 비밀번호 변경 후에는 자동으로 <font color='#EB1B51' >[로그아웃]</font> 됩니다.</h3>
          </div>
        </div>

        <webpage-user v-bind:signup_pagetype="'password_change'" ></webpage-user>
      </el-tab-pane>
      
    </el-tabs>

  </div>
</template>

<script>
import WebpageUser from '../webpage_user/user_signuppage'

export default {
  
  computed:{
    current_user(){
      return this.$store.state.current_webpage_user
    }
  },
  
  components: {
    WebpageUser
  }

}
</script>