<template>
  <div>
  
    <div class="divider" style="margin: 2px 0"></div>
      <div>
        <label>본문 레이아웃</label>
        <el-radio-group v-model="writer_set.write_type" @change="image_size_check(writer_set.write_type)" >
          <el-radio :label="index" v-for='index in 5' :key='index' >{{index}}번</el-radio> 
        </el-radio-group>
        <img :src="layout_image" width="100%">
      </div>

      <div>
        <div class="divider" style="margin: 2px 0"></div>
        <label>글단 사이즈</label>
        <el-radio-group v-model="writer_set.write_width">
          <el-radio v-for="(width, index) in [['full',12],['3/4', 8],['1/2', 6] ,['1/3', 4]]" :label="width[1]"  :key="'width_'+index" >{{width[0]}}사이즈</el-radio> 
        </el-radio-group>
      </div>
      
      <div>
        <div class="divider" style="margin: 2px 0"></div>
        <label>이미지사이즈</label>
        <el-radio-group v-model="writer_set.image_size">
          <el-radio v-for="(size, index) in image_size_type[writer_set.write_type]" :label="size[1]"  :key="'image_'+index" >{{size[0]}}사이즈</el-radio> 
        </el-radio-group>
      </div>
      <div>
        <div class="divider" style="margin: 2px 0"></div>
        <label>제목 타입</label>
        <el-radio-group v-model="writer_set.subject_type">
          <el-radio v-for="(size, index) in [['일반제목형',1],['가운데형', 2],['세로라인바형', 3],['한줄형',4]]" :label="size[1]"  :key="'subject_'+index" >{{size[0]}}</el-radio> 
        </el-radio-group>
      </div>
      <div v-if="writer_set.write_type==1">
        <div class="divider" style="margin: 2px 0"></div>
        <label>제목 위치</label>
        <el-radio-group v-model="writer_set.subject_location">
          <el-radio v-for="(size, index) in [['이미지위',1],['이미지아래', 2]]" :label="size[1]"  :key="'location_'+index" >{{size[0]}}</el-radio> 
        </el-radio-group>
      </div>
      <div>
        <div class="divider" style="margin: 2px 0"></div>
        <label>아웃라인</label>
        <el-radio-group v-model="writer_set.outline">
          <el-radio v-for="(size, index) in [['사용안함',1],['테두리', 2],['구분선', 3]]" :label="size[1]"  :key="'outline_'+index" >{{size[0]}}</el-radio> 
        </el-radio-group>
      </div>
      <div>
        <div class="divider" style="margin: 2px 0"></div>
        <label>첫글크게</label>
        <el-radio-group v-model="writer_set.dropcap">
          <el-radio v-for="(size, index) in [['사용함',1],['사용안함', 2]]" :label="size[1]"  :key="'dropcap_'+index" >{{size[0]}}</el-radio> 
        </el-radio-group>
      </div>

    <div class="divider" style="margin: 2px 0"></div>
    <div style="text-align:right">
      <button class="btn btn-md btn-success" @click="layout_update()" >뒤로</button>
    </div>

  </div>

</template>


<script>
export default {
  props: ['data','compo', 'writerType'],
  data(){
    return {
      writer_set: { write_type: 1, write_width: 12, image_size: 12, subject_type: 1, subject_location: 1, outline: 1, dropcap: 2 }
    }
  },
  
  created(){
    if(this.data.font_set.writer_set == undefined )this.data.font_set.writer_set = this.$store.state.default_writer_set
    let wset = this.data.font_set.writer_set
    const keys = Object.keys( wset)
    for(let key in keys){
      this.writer_set[keys[key]] = wset[keys[key]]
    }
  },

  computed: {
    layout_image(){
      return `/images/context_layout/w0${this.writer_set.write_type}.png`
    },
    
    layout_45(){
      let result= true
      if(this.writer_set.write_type == 4 || this.writer_set.write_type == 5 )result = false
      return result;
    },

    image_size_type(){
      return {
        '1':[['full',12],['3/4', 8],['1/2', 6] ,['1/3', 4],['1/4',3]],
        '2':[['3/4', 8],['1/2', 6] ,['1/3', 4],['1/4',3]],
        '3':[['3/4', 8],['1/2', 6] ,['1/3', 4],['1/4',3]],
        '4':[['3/4', 8],['1/2', 6] ,['1/3', 4],['1/4',3],['1/6',2]],
        '5':[['3/4', 8],['1/2', 6] ,['1/3', 4],['1/4',3],['1/6',2]],
      }
    }
   
   
  },

  methods: {

    image_size_check(type){
      if(type==1){
        if(this.writer_set.image_size==2 )this.writer_set.image_size=3
      }else{
        if(type==4 || type==5){
          if(this.writer_set.image_size==12)this.writer_set.image_size=8
        }else{
          if(this.writer_set.image_size==12)this.writer_set.image_size=8
          if(this.writer_set.image_size==2)this.writer_set.image_size=3
        }
      }
    },


    layout_update(){
      
      if(this.writerType != 'board'){
        this.$emit('update:compo','item-form')
      }else{
        this.$emit('update:compo','board-form')
      }

      const keys = Object.keys( this.writer_set)
      for(let key in keys){
        this.data.font_set.writer_set[keys[key]] = this.writer_set[keys[key]]
      }
      this.$emit('update:data', this.data)
      
      
      // let url = ''
      // if(this.writerType != 'board'){
      //   url = `/webpages/${this.page_type}/item_update/${this.data.id}`
      // }else{
      //   //this.writerType ==> board에서 글쓰기 구분을 위한 type이다 보통은 undefind 글쓰기에서는 board
      //   url = `/webpages/${this.page_type}/board/${this.data.id}`
      // }

      // let font_set = this.data.font_set
      // font_set.writer_set = this.writer_set
      // const data = { font_set: font_set } 
      // this.axios().put(url, {data: data} )
      //   .then(result=>{
      //     this.webpageNotice(result.data);
      //     if(this.writerType != 'board'){
      //       this.$emit('update:compo','index');
      //     }else{
      //       this.$emit('update:compo','board-form');
      //     }
      //   })
    }
    
  },

  components: {
	}
  
}
</script>