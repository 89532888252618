<template>
	<div>
		<section :id="section_tag_id+' testimonials'"  class="alternate" :style="cur_background()" >
      <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>
			<smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
			<div class="container" style="z-index: 100" >
				<div class="row margin-bottom-40"  v-if="current_section.item_order.length > 0">
					<div :class="item_view_class" v-for="(i, idx) in current_section.item_order" :key='idx'>
						<div class="testimonial testimonial-bordered padding-15" style="background:#f8f8f8;">
							<table style="width:100%;margin-bottom:0px">
								<tr>
									<td style="width:100px;padding-top:0px;vertical-align: top" v-if="!object_empty_check(ctem[i].file1.thumb)" >
										<img  :class="image_round" :src="ctem[i].file1.thumb" style="width: 100px; height: 100px" alt="" />
									</td>
									<td :style="'padding-left:20px;color:'+ctem[i].font_set.title_color" >
										<cite style="font-size: 1.3em" >
											{{ ctem[i].title }}
											<span :style="'font-weight:bold;font-size:9pt;margin-bottom:0px;color:'+ctem[i].font_set.subtitle_color">{{ ctem[i].subtitle}}</span>
										</cite>

										<div class="embed-responsive embed-responsive-16by9 block margin-bottom-60"  v-html="ctem[i].media" v-if="media_source_test(ctem[i].media)" ></div>
										<div :style="profile_css(ctem[i])+';color:'+ctem[i].font_set.icon_color" v-html="showAllMemo(ctem[i].media)" v-else ></div>
										<div :style="context_css(ctem[i])+';color:'+ctem[i].font_set.context_color" v-html="htmlDecode(ctem[i].context)"></div>

									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<default v-else></default>
			</div>
		</section>
		<slot name="setting"></slot>
  </div>
    
</template>


<script>
import Default from './default/introduce01_default'

export default {
		props: ['index','current_section'],

	mounted(){
		_misc(); //뒤집어지는 소개 섹션 다시 로더 
	},
	components: {
		Default,
	},

	computed: {
		item_count_css(){
			if(this.current_section.item_order.length > 3){
				return "col-md-3 col-sm-6"
			}else{
				return "col-md-4 col-sm-6"
			}
		},

	},
	methods:{
		profile_css(item){
			if(!this.media_source_test(item.media) && !this.isEmpty(item.context)){
				return 'width: 100%;padding-top:20px;padding-bottom:5px;font-size:0.9em;border-bottom: 1px solid #d1d1d1'
			}else if(!this.media_source_test(item.media) && this.isEmpty(item.context)){
				return 'mairgin-top:10px;width:100%;padding-top:10px;padding-bottom:5px;font-size:0.9em;border-top: 1px solid #d1d1d1'
			}else{
				return 'padding-top:20px;padding-bottom:5px;font-size:0.9em'
			}
		},
		
		cur_background(){
			let cssp = this.section_background()
			let padding_css = 0
      if(this.$store.state.design_config.layout=="boxed") padding_css = '50px'
			cssp += (!this.isEmpty(this.current_section.title) || !this.isEmpty(this.current_section.sub_title))? `;padding: 60px ${padding_css}` :`;padding:20px ${padding_css}`
			return cssp
		},
		

		context_css(item){
			if(this.media_source_test(item.media) && !this.isEmpty(item.context)){
				return 'margin-top:15px;padding-top:10px;border-top: 1px solid #d1d1d1'
			}else {
				return 'padding-top:20px;'
			}
		}
	}
}
</script>


<style>

</style>

