<template>
  <div>
    <div class="modal-body"> 
      <div class="row">
        <div class="col-sm-3 " id="knob" >
          <div>
           <div class="card card-default" v-if="$store.state.modal.type==2" style="margin-bottom: 10px;" >
	            <div class="card-block">
								<p>현재 {{ page_type == "mainpage" ? '메인' : '서브' }} 섹션사용현황</p>
								<div style="font-size: 18pt">{{ section_count }} / {{ section_limit }} </div>
                <cite style="font-size: 9pt"><b style='color:red'>{{ section_limit - section_count }}개 </b>더 추가 가능합니다.</cite>
	            </div>
            </div>  
            <knob-control
            :min="0"
            :max="sec_options.length"
            :size="150"
            :value-display-function="display_num"
            v-model="kval"
            style="cursor:pointer"
            ></knob-control>
          </div>
          <div style="margin-bottom: 30px">
            <el-select v-model="section_num" placeholder="Select">
              <el-option
                v-for="item in sec_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <div>
              <label>사용가능항목</label>
              <ul style="list-style:decimal;padding-left:25px;" >
                <li>아이템 제한수 : {{ item_limit() }}개 </li>
                <li v-for="(i, idx) in use_item()" :key="'useitem_'+idx" style="padding-left:0px" >{{i}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-9"  >
          <img v-bind:src="image_link()" style="width:100%" />
          <div v-if="current_section_format()!=undefined && current_section_format().explain!=''">{{ current_section_format().explain}}</div>
        </div>	
      </div>
    </div>
    <div class="modal-footer" v-if="$store.state.modal.type==2">
      <button type="button" class="btn btn-default" @click="ant_modal_close()">닫기</button>
      <button type="button" class="btn btn-primary" @click="section_add('section_add')" >추가</button>
    </div>  
  </div>

</template>


<script>
import KnobControl from 'vue-knob-control'

export default {
  data(){
    return {
      section_formats: [],
      sec_options: [],
      kval: 1,
      section_num: 0
    }
  },

  watch:{
    section_num(val){
      this.kval = val + 1
      this.$store.state.section_num = val
    }
  },

  mounted(){
    this.select_section_format();
  },

  computed: {
    
    
    page_type(){
      return this.$store.state.page_type
    },

    section_limit(){
      return this.page_type == "mainpage" ? this.$store.state.config.section_limit : this.$store.state.config.subpage_section_limit
    },

    section_count(){
      return this.$store.state.config.section_count 
    },

  },
 
	methods:{

    item_limit(){
      if(this.current_section_format()==undefined)return 0
      return this.current_section_format().item_limit 
    },

    use_item(){
      if(this.current_section_format()==undefined)return []
      return this.current_section_format().use_item
    },
   
    current_section_format(){
      return this.section_formats[this.section_num]
    },

    select_section_format(){
      const format = section_format
      let cnt = 0
      const cur_section = this.$store.state.modal.section
      if(format != undefined){
        for(let i=0;i<format.length;i++){
          if(this.$store.state.page_type == 'mainpage'){
            if(format[i].mainpage_use && format[i].section_select){
              this._sec_option(format, cur_section, cnt, i)
              cnt++ 
            } 
          }else{
            if(format[i].subpage_use && format[i].section_select){
              this._sec_option(format, cur_section, cnt, i)
              cnt++ 
            }
          }
        }
      }
    },

    _sec_option( format, cur_section, cnt, i ){
      this.section_formats.push(format[i])
      this.sec_options.push({ label: format[i].name, value: cnt })
      if(cur_section!=undefined && (format[i].component == cur_section.component)){
        this.section_num = cnt
        this.kval = cnt + 1
      }
    },

    display_num(val){
      if(val==0) this.kval = 1
      this.section_num = this.kval - 1 
      return this.kval
    },

		image_link(){
      if(this.current_section_format()==undefined)return this.no_image
			let src = `/images/${this.section_formats[this.section_num].component}.png`
			return src
		},
  },
  
	components: {
		KnobControl,
	}
}
</script>

<style>
		@media (max-width: 500px) {
			#knob {
				padding-left: 100px
			}
		}
</style>
