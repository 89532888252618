<template>
  <div>
    <div class="table-responsive" style="margin-top: 20px" >
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>no</th>
            <th>타입</th>
            <th>이동</th>
            <th>옵션</th>
          </tr>
        </thead>
        <tbody  >
          <tr v-for=" (item, index) in item_order" :key='item' >
            <td style="text-align:right"> {{ index + 1 }} </td>
            <td style="min-width: 180px;max-width:210px;word-wrap: break-word;">
              {{ type_name[citem(item).config.compo] }}
            </td>
            <td style="text-align:center;min-width:60px;padding-left: 0;padding-right: 0 ">
              <ul class="list-inline" style="margin-bottom:0px">
              <li class="badge badge-info" style="padding: 5px 10px;cursor:pointer" v-if="index!=0" @click="move_item(index, index-1)" >
                <i class="fa fa-sort-up" ></i>
              </li>
              <li class="badge badge-info" style="padding: 5px 10px;cursor:pointer" v-if="index<item_order.length-1" @click="move_item(index+1, index)" >
                <i class="fa fa-sort-down"></i>
              </li>
              </ul>
            </td>
            <td>
              <a href="javascript:void(0);" class="btn btn-default btn-sm" @click="item_edit(item)" ><i class="fa fa-edit white"></i></a>
              <a href="javascript:void(0);" class="btn btn-danger btn-sm"  @click="item_delete(item)" ><i class="fa fa-trash white"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


  </div>
</template>

<script>
export default {
  props: ['current_section', 'compo', 'mode'],
  data(){
    return{
      type_name: { 'address1': '연락처형', 'board': '보드연결형', 'general': '글쓰기형'  }
    }
  },

  computed:{
    items: {
      get(){
        return this.$store.state.footer_item;
      },
      set(val){
        this.$store.state.footer_item = val;
      }
    },

    item_order: {
      get(){
        let order = []
        let item_order = this.current_section.item_order
        if(item_order!=undefined){
          for(let i=0; i<item_order.length; i++ ){
            if(this.$store.state.footer_item[item_order[i]] != undefined ){
              order.push(item_order[i])
            }
          }
        }
        return order
      },
      set(val){
        return val
      }
    }
    
  },

  methods: {
    citem(id){
      return this.items[id]
    },
    
    item_delete(item){
      if(confirm('정말 삭제하시겠습니까?')){
        this.axios().delete(`/webpages/${this.page_type}/item_delete/${this.current_section.id}/${item}`)
        .then(result=>{
					this.webpageNotice(result.data)
          if(result.data.result=='success'){
            delete this.$store.state.footer_item[item]
            this.current_section.item_order = result.data.data.item_order
            this.$store.commit('config_item_count_update', result.data.item_count)
          }
        })
      }
    },

    item_edit(item){
      this.$emit("update:compo",this.items[item].config.compo);
      this.$emit("update:mode",'edit');
      this.$emit("update:data", this.items[item])
    },

    move_item(from, to){
      let items_order = this.item_order
      items_order.splice(to, 0, items_order.splice(from, 1)[0]);
      this.$store.state.linebar01_excu_cnt = 0 //_owl_carousel() 이 문제야 여러번 실행하면 에러나 ㅠㅠ
      this.axios().put(`/webpages/${this.page_type}/item_move/${this.current_section.id}`, { item_order: items_order })
      .then(result=>{
        this.webpageNotice(result.data)
        if(result.data.result=='success'){
          this.current_section.item_order = result.data.item_order
        }
      });
    }
  }
  
}
</script>


<style scoped>
.btn>i {
  padding-right: 0px
}

ul>li>i {
  margin-right: 0px;
  cursor: pointer;
}

th {
  text-align: center
}

</style>
