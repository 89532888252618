<template>
<div>
	<section :style="section_background()" :id='section_tag_id' >
    <div class="overlay" v-if="use_background==2" :style="'background:rgba(0,0,0,'+background_transparent+')'"></div>

    <div class="container" style="z-index: 100">
  	  <smarty-sectionheader style="z-index: 100;margin-bottom: 30px" v-bind:current_section='current_section' v-if="use_section_header" ></smarty-sectionheader>
      <el-card class="box-card"  >
        <el-input placeholder="Search Homepage" size="mini" v-model="search_word" @keyup.enter.native="get_homepage_search()" class="input-with-select" style="width: 300px" clearable  >
          <el-button slot="prepend" icon="el-icon-refresh"  @click="clear()" v-show="!isEmpty(search_word)" ></el-button>
          <el-button slot="append" icon="el-icon-search" @click="get_homepage_search()"  ></el-button>
        </el-input>
        <button class="btn btn-primary btn-sm"  v-if="ownerCheck"  @click='webModal("#webpage_setting", "homepagelist-form", 3, current_section, 1000, false, "new")'  >
          <i class="fa fa-home"> 샤플 추가하기</i>
        </button>
      </el-card>


      <div class="row" v-if="homepage_list.list.length > 0"  style="margin-top: 50px">
        <div class="col-md-3 col-6" v-for="(s, idx) in homepage_list.list " :key='idx' >
          <div class="card">
            <a href="javascript:void(0);" @click="pageMove(homelink(s.webpage_id), true, homepage_list.webpage[s.webpage_id].url) " >
              <img class="card-img-top" :src="thumb_image(s.webpage_id)" style="max-height: 200px" alt="" >
            </a>
              
            <div class="card-block">
              <h2 class="fw-500 letter-spacing-1 fs-20" style="margin-bottom: 5px;cursor:pointer" @click="pageMove(homelink(s.webpage_id), true, homepage_list.webpage[s.webpage_id].url) " ><span>{{s.title}}</span></h2>
              <p  class="fw-500 letter-spacing-1" style="margin-bottom: 5px;font-size: 9pt" v-if="!isEmpty(s.subtitle)" ><span>{{s.subtitle}}</span></p>
              <p style="margin-bottom:5px;margin-top:0px;font-size: 9pt" v-if="ownerCheck" >추가일 {{ dateFormat(s.created_at ,'YYYY년 MM월 DD일')  }} </p>
            </div>

            <div style="padding:20px;padding-top:0px" v-if="ownerCheck" >
              <span class="btn btn-sm btn-primary"  @click='webModal("#webpage_setting", "homepagesection-form", 3, current_section, 1000, false, "edit", s)' ><i class="fa fa-edit" style="padding-right:0px"></i></span> 
              <span class="btn btn-sm btn-danger"   @click="homepage_list_delete(s.id)"><i class="fa fa-trash" style="padding-right:0px"></i></span> 
            </div>
          </div>
        </div>
       
        <div class="col-md-12 text-center" style="margin-top:50px">
          <el-pagination
            :page-size="page_info.per_page"
            :page-count="10"
            :current-page="page_info.current_page"
            @current-change="current_change"
            layout="prev, pager, next"
            :total="page_info.totalCount">
          </el-pagination>
        </div>

        
      </div>
      <div v-else style="margin-top: 20px" >
        <div class="alert alert-danger" >
          샤플 목록이 없습니다.
        </div>
      </div>

    </div>
	</section>
	
	<slot name="setting"  ></slot>
</div>

    
</template>


<script>

export default {
	props: ['index','current_section'],
  
  data(){
    return {
      fullWidth: document.documentElement.clientWidth,
      current_data: '',
      page_info: {
        per_page: 20,
        totalCount: 0,
        current_page: 1,
      },
      search_word: '',
    }
  },

  created(){
    this.$store.state.screenSize = this.fullWidth
    if(this.$route.query.search_word!=undefined)this.search_word = this.$route.query.search_word
    if(this.$route.query.current_page!=undefined) this.page_info.current_page = Number(this.$route.query.current_page) 
  },

  mounted(){
    this.page_info.totalCount = this.homepage_list.page_info.totalCount
    this.page_info.per_page = this.homepage_list.page_info.per_page
  },

  ready: function () {
    window.addEventListener('resize', this.handleResize)
  },

  // watch:{
  //   page_info(val){
  //     console.log("test================");
  //     console.log(val);
  //     val = this.homepage_list.page_info
  //     return val
  //   }
  // },

  computed: {
    homepage_list:{
      get(){
        return this.$store.state.homepage_list
      },
      set(val){
        this.$store.state.homepage_list = val
      }
    }
  },

	methods: {
    thumb_image(webpage_id){
      return this.homepage_list.webpage[webpage_id].image
    },

    current_change(val){
      this.page_info.current_page = val
      this.get_homepage_data()
    },

    get_homepage_search(){
      this.page_info.current_page = 1
      this.get_homepage_data()
    },

    homepage_list_delete(id){
      if(confirm("정말로 삭제 하시겠습니까?")){
        let search = ''
        search += `?current_page=${this.page_info.current_page}`
        search += this.isEmpty(this.search_word) ? '' : `&search_word=${this.search_word}`
        this.axios().delete(`/webpages/delete_homepagelist/${id}${search}`)
        .then(result=>{
          this.webpageNotice(result.data);
          this.get_homepage_data()
          // if(result.data.result=='success'){
          //   setTimeout(function(){ location.reload() }, 1500)
          // }
        })
      }
    },

    homelink(webpage_id){
      return `/${this.$route.params.userid}/${this.homepage_list.webpage[webpage_id].url}`
    },

    clear(){
      this.search_word = ''
      this.get_homepage_search()
    }

	}

}
</script>


<style>
@media only screen and (max-width: 1215px) {
  .container {
      width: 100%;
      max-width: 100%;

  }
}

</style>

