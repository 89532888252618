import Vue from 'vue'
import Vuex from 'vuex'
import Es6Promise from 'es6-promise';
import axios from 'axios'
Es6Promise.polyfill();

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        design_config: {}, 
        page_type: '',
        webpage_type: null,
        get_data_status: false,
        login: false,
        login_type: 'guest',
        use_signup: false,
        authCheck: false,
        security_page: false,
        security_page_logined: false,
        authData: {},
        auth_set: {},
        user: {},
        webpage_users: {},
        member_type: 100,
        current_webpage_user: {},
        preview: true,
        section_order: [],
        sections: {},
        page_sections: [],
        items: {},
        section_name: '',
        section_num: 0,
        webconfig: { "setting-form": { title: '웹페이지설정' }, "section-form": { title: '섹션설정' } },
        modal: { index: 0, component: '', del_confirm: false, active: 'item_set', data: {}, section: {}, current_section_id: 0, id: 0 },
        section_format: [],
        current_section_format: {},
        item_limt_count: 20,
        current_section_id: 0,
        linebar01_excu_cnt: 0,
        logo_set: {},
        logo_origin_font_size: 0,
        logo_resize_font_size: 0,
        logo_font_size: 0,
        screenSize: 0,
        font_list: ['Nanum Gothic', 'Nanum Brush Script', 'Nanum Gothic Coding', 'Nanum Myeongjo', 'Nanum Pen Script', 'Gugi', 'Noto Sans KR', 'Black And White Picture', 'Jua', 'Song Myung', 'East Sea Dokdo', 'Gamja Flower', 'Stylish', 'Cute Font', 'Hi Melody', 'Arial', 'Arial Black', 'Courier New', 'Helvetica', 'Helvetica Neue', 'Impact', 'Lucide Grande', 'Tahoma', 'Times New Roman', 'Verdana'],
        link_type: { title: [ ['제목', 'link1'],['부제목', 'link2'] ], button: [['버튼1', 'link1'],['버튼2', 'link2']], },
        default_writer_set: { write_type: 1, write_width: 12, image_size: 12, subject_type: 1, subject_location: 1, outline: 1, dropcap: 2 },
        scrolled: false,
        current_component: '',
        paid_type: {}, //유료버젼과 무료버젼 구분 { paid_type: 0(0은 무료 ) , subpage_limit_cnt: 20 (서브페이지 작성 제한 기본 20개)     }
        subpages: {},
        board_data: { board: [], board_item: {}, board_item_keys: [], totalCount: 0, perPage: 0, current_page: 1 },
        board_writer_type: 'create',
        footer_section: {},
        footer_item: {},
        sumary_boards: {board: {}, writers: {} },
        footer_board_item: {},
        menu: { main_order: [], menus: {}, config: {transparent: false} },
        sns_set: {},
        current_contactus: { },
        countdown_excute_count: 0,
        pageinfo: {},
        homepage_list:  { search_word: '', page_info:{ total_count: 0, per_page: 0, current_page: 1 } },
        refresh: 0,
        search_data: { mainitem: [], subitem: [], boarditem: [], page_info: { per_page: 20, total_count: 0, current_page: 1 } },
        config: {},
        terms: [],
        service_config: {},
        ant_modal_visible: false,
    },
    mutations: {
        set_page_data(state,result){
            state.board_data = result.data.board_data
            state.sections = result.data.sections;
            state.items = result.data.items;
            state.section_order = result.data.section_order;
            state.subpages = result.data.subpages;
            state.menu = result.data.menu;
            state.footer_section = result.data.footer_section
            state.footer_item = result.data.footer_item
            state.sumary_boards = result.data.sumary_boards
            state.sns_set = result.data.sns_set
            state.get_data_status = true
            state.auth_set = result.data.auth_set

            //로그인관련
            state.authData = result.data.auth_check[0].info;
            state.authCheck = result.data.auth_check[0].verify;
            state.use_signup = result.data.use_signup
            //보안페이지 로그인 
            state.security_page = result.data.auth_check[1].security_page == 1 ? true : false 
            state.security_page_logined = result.data.auth_check[1].logined  
            state.login_type = result.headers.login_type //member or webpage_user 로그인 타입 저장
            
            this.commit('set_page_sections')
            
            //멤버타입
            if(result.data.auth_set!=undefined)state.member_type = result.data.auth_set.admin_type
            //웹페이지 유저관리 페이지
            if(result.data.webpage_users!=undefined)state.webpage_users = result.data.webpage_users 
            //뭉글의 경우 page 정보를 위한 데이터
            if(result.data.newsletter_pageinfo!=undefined)state.pageinfo = result.data.newsletter_pageinfo
            //포탈형 홈페이지 목록 섹션을 위한 데이터
            if(result.data.homepage_list!=undefined)state.homepage_list = result.data.homepage_list
            //회원가입의 약관
            if(result.data.terms!=undefined)state.terms = result.data.terms
        },
        set_page_sections(state){
            let sections = []
			const order = state.section_order
			let section = ''
			for(let or in order){
				section = state.sections.filter(s=> s.id==order[or])
				if(section.length>0)sections.push(section[0])
            }
            state.page_sections = sections
        },
        set_menu(state, menu){
            state.menu = menu
        },
        set_menu_config(state, config){
            // const keys = Object.keys(config)
            // keys.forEach(k=>{
            //     state.menu.config[k] = config[k]
            // })
        },
        add_homepage_image(state, image){
            state.homepage_list.webpage[image[0]]= image[1] 
        },
        set_homepage_list(state, data){
            state.homepage_list = data
        },
        add_section(state, section){
            state.sections.push(section)
        },
        footer_logo(state, data){
            state.footer_item[data[0]]['file1'] = data[1]
        },
        set_board_data(state,data){
            state.board_data = data
        },
        set_webpage_users(state, webpage_users){
            state.webpage_users = JSON.parse(JSON.stringify(webpage_users));
        },
        set_current_webpage_user(state, webpage_user){
            state.current_webpage_user = JSON.parse(JSON.stringify(webpage_user))//마이페이지에 내정보 수정을 위한 데이터
        },
        set_webpage_user(state, webpage_user){
            let user = state.webpage_users.webpage_users.filter(u=>u.id==webpage_user.id)
            const keys = Object.keys(user[0]) 
            if(user[0].id==webpage_user.id){
                for(let k in keys){
                    if(user[0][keys[k]]!=webpage_user[keys[k]])user[0][keys[k]] = webpage_user[keys[k]]
                }
            }
        },
        remove_webpage_user(state, webpage_users){
            state.webpage_users= JSON.parse(JSON.stringify(webpage_users.data.webpage_users))
        },
        set_webpage_user_categories(state, categories){
            state.webpage_users.categories= JSON.parse(JSON.stringify(categories))
        },
        set_webpage_user_category(state, category){
            let webpage_user = state.webpage_users.webpage_users.filter(u=>u.id==category.user_id)
            console.log(webpage_user[0]);
            webpage_user[0].group1 = category.main_cate_id
            webpage_user[0].group2 = category.sub_cate_id
        },
        remove_webpage_user_categories(state, category){
            const idx = state.webpage_users.categories.findIndex(c=>c.id==category.id);
            state.webpage_users.categories.splice(idx,1);
        },
        current_component(state,data){
            state.current_component = data
        },
        add_items(state, items){
            state.items[items.id] = items
            this.commit('set_page_sections')
        },
        modal_section_item_order(state, item_order){
            state.modal.section.item_order = item_order
        },
        section_item_order_change(state, data){
            state.sections[data.section_id].item_order = data.item_order
        },
        config(state, config){
            if(config.webconfig!=undefined) state.config = JSON.parse(config.webconfig) 
        },
        config_item_count_update(state, item_count){
            state.config.item_count = item_count
        },
        config_section_count_update(state, section_count){
            state.config.section_count = section_count
            this.commit('set_page_sections')
        },
        config_subpage_count_update(state, subpage_count){
            state.config.subpage_count = subpage_count
        },
        sumary_board_update(state, sumary_boards){
            for(const s in sumary_boards.board){
                if(state.sumary_boards.board[s]==undefined)state.sumary_boards.board[s] = sumary_boards.board[s]
            }
            if(state.sumary_boards.writers==null)state.sumary_boards.writers = {} //아무것도 없을때 이거 없어서 꽤 고생했네 왜그런지 모르겠지만 state.sumary_boards.writers == null임
            for(const w in sumary_boards.writers){
                if(state.sumary_boards.writers[w]==undefined)state.sumary_boards.writers[w] = sumary_boards.writers[w]
            }
        },
        set_login(state, result){
            state.login = (result.headers.logined == 'true') ? true : false
        },
        set_ant_modal_visible(state,result){
            state.ant_modal_visible = result
        }
    },

    actions: {
        get_page_data({state, commit, dispatch},data){
            
           
        },

    
    }
});
