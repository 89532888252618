import Vue from 'vue';
import axios from "axios";

import Smarty from '../views/webpage/smarty_main.vue';
switch (design_type) {
    case 'smarty':
        var Main = Smarty;
        break;
}
export default Main;

import Router from 'vue-router';
import Mainrouter from '../router/webpage_router';
import { store } from '../store/webpage_store.js';
import Common from '../mixins/common';
import Mymodules from '../mixins/webpage';
// import Vuetify from 'vuetify'
import Element from 'element-ui'
import ElementLocale from 'element-ui/lib/locale/lang/ko';
import 'babel-polyfill'
// import 'vuetify/dist/vuetify.min.css'
import VueScreenSize from 'vue-screen-size'
import browserDetect from "vue-browser-detect-plugin";
import VueAnimated from '@codekraft-studio/vue-animated'
import SmartySectionheader from '../views/webpage/smarty_pages/components/common/section_header.vue'

Vue.mixin(Mymodules);
Vue.mixin(Common);
Vue.use(Router);
// Vue.use(Vuetify);
Vue.use(Element, { locale: ElementLocale });
Vue.use(VueScreenSize);
Vue.use(browserDetect);
Vue.use(VueAnimated)
Vue.component('smarty-sectionheader', SmartySectionheader)

let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
var headers = {
    'Content-Type': 'application/json',
    'request-type': 'client',
    'Accept': 'application/json',
    'X-CSRF-Token': token
}

Vue.prototype.$http = axios.create({ headers: headers });

const router = new Router(Mainrouter)

document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        el: '#main',
        router,
        store,
        render: h => h(Main)
    })
})