<template>
  <div>
    <span class="btn btn-sm btn-danger"  @click="addMenuForm('maincategory')" >카테고리추가</span>  
    <span class="btn btn-sm btn-warning" @click="addMenuForm('subcategory')" v-if='object_empty_check(current_subs)==false' >서브카테고리추가</span>  

    <div class="card card-default" v-if="current_mode=='maincategory' || current_mode=='subcategory' " style="margin-top:20px">
      <div class="card-block">
        <div class="row">
          <div class="col-sm-6" >
            <label > {{ current_mode=='maincategory' ? '메인카테고리명' : '서브카테고리명' }} </label>
            <input type="text" class="form-control"  :placeholder="current_mode=='maincategory' ? 'Main Category' : 'Sub Category'" v-model="cate_name" style="height: 35px !important" >
          </div>
          
          <div class="col-12" style="margin-top:20px" v-if="user_url">
            <label >사용자메뉴 링크</label>
            <input class="form-control" v-model="userLink" style="height: 35px" >
          </div>
        </div>
        <div></div>
        <div class="divider" style="margin: 0px 0"></div>
        <div class="text-right">
          <div v-if="create_mode" >
            <span class="btn btn-sm btn-primary"  @click="current_mode=''" >닫기</span>
            <span class="btn btn-sm btn-danger"   @click="create_category()" >추가</span>
          </div>
          <div v-else>
            <span class="btn btn-sm btn-primary"  @click="current_mode=''" >닫기</span>
            <span class="btn btn-sm btn-success"   @click="update_category()" >수정</span>
          </div>
        </div>
      </div>
    </div> 

    <div class="card card-default"  style="margin-top:20px"  >
      <div class="card-block">
        <div class="row">
          <div class="col-sm-6" >
            <table class="table" >
              <thead>
                <th>메인카테고리명</th>
                <th style="max-width:60px">옵션</th>
              </thead>
              <tbody >
                <tr v-for="(mc, index) in main_categories()" :key="index" :style="line_marked(current_category.id, mc.id )" >
                  <td  style="cursor: pointer" @click="select_sub(mc)" > {{ mc.cate_name }} </td>
                  <td style="padding-left:5px;padding-right:5px" >
                    <ul class="list-inline" style="margin-bottom:0px" >
                     
                      <li class="badge badge-warning" style="padding: 5px 5px;cursor:pointer" @click="editMenuForm('maincategory', mc)"  > <i class="fa fa-edit"></i></li>
                      <li class="badge badge-danger"  style="padding: 5px 5px;cursor:pointer" @click="deleteMenuForm(mc)"> <i class="fa fa-trash"></i></li>

                    </ul>
                  </td>
                </tr>
              </tbody>

            </table>
          </div>
          <div class="col-sm-6" >
            <table class="table" >
              <thead>
                <th>서브카테고리명</th>
                <th>옵션</th>
              </thead>
              <tbody  >
                <tr v-for="(sc, index) in current_subs" :key="'sub_'+index" :style="line_marked(current_subs.id, sc.id )" >
                  <td style="cursor: pointer" > {{ sc.cate_name }} </td>
                  <td>
                    <ul class="list-inline" style="margin-bottom:0px" >
                      
                      <li class="badge badge-warning" style="padding: 5px 5px;cursor:pointer" @click="editMenuForm('subcategory', sc)"  > <i class="fa fa-edit"></i></li>
                      <li class="badge badge-danger"  style="padding: 5px 5px;cursor:pointer" @click="deleteMenuForm(sc)"> <i class="fa fa-trash"></i></li>

                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {

  data(){
    return {
      current_mode: '',
      create_mode: true,
      current_category: {},
      cate_name: '',
      value: '',
      user_url: false,
      userLink: '',
      current_main: {},
      current_main_id: 0,
      current_sub_id: 0,
      current_sub: {},
      current_subs: [],
    }
  },

  computed:{

    categories() {
      return this.$store.state.webpage_users.categories
    },

  },

  methods:{
    
    main_categories(){
      let cate = this.categories.filter(c=>c.deep==1)
      return this.cate_sort(cate)
    },

    sub_categories(main_id){
      let cate = this.categories.filter(c=>c.deep==2 && c.parent_id==main_id )
      return this.cate_sort(cate)
    },

    cate_sort(cate){
      const csort = cate.sort(function(a,b){ 
        if ( a.cate_name < b.cate_name )return -1;
        if ( a.cate_name > b.cate_name )return 1;
        return 0;
      }) 
      return csort  
    },

    select_sub(main){
      this.current_mode = ''
      this.current_category = main
      this.current_main = main
      this.current_subs = this.sub_categories(main.id)
    },

    line_marked(data_id, id){
      if(data_id == id){
        return 'background: #c9eaff'
      }
      return ''
    },

    addMenuForm(mode){
      this.cate_name = ''
      this.current_mode = mode
      this.create_mode = true
      // if(mode == 'maincategory'){} this.current_main = {}
    },

    editMenuForm(mode, category){
      this.current_mode = mode
      this.create_mode = false
      this.category = category
      this.current_category = category
      this.cate_name = category.cate_name
      // if(mode == 'maincategory'){
      //   this.current_main_id = category.id
      // }else{
      //   this.current_sub_id = category.id
      //   this.current_category.title = category.cate_name
      // }
    },

    deleteMenuForm(category){
      const confirmation = this.current_mode == 'maincategory' ? confirm('서브메뉴까지 삭제됩니다. 그래도 삭제하시겠습니까?') : confirm('현재 카테고리를 정말 삭제하시겠습니까?')
      if(confirmation){
        const parent_id = category.parent_id
        const ctype = category.deep
        this.axios().delete(`/webpage_user_delete_category/${category.id}`)
        .then(result=>{
          this.webpageNotice(result.data);
          if(result.data.result=='success'){
            // this.$store.commit('remove_webpage_user_categories', category)
            this.$store.commit('set_webpage_user_categories', result.data.categories)
            if(ctype == 1){
              this.current_subs = []
              this.current_main = {}
              this.current_main_id = 0
              this.current_sub_id = 0
              this.current_sub = {}
              this.current_mode = false
            }else{
              this.current_subs = this.sub_categories(parent_id)
            }
          } 
        })
      }
    },

    create_category(){
      console.log("category create=======================");
      if(this.cate_name_duple_check()){
        this.current_category.type = this.current_mode == 'maincategory' ? 1 : 2
        if(this.current_mode == 'subcategory')this.current_category.main_category_id = this.current_main.id
        this.current_category.cate_name = this.cate_name
        this.axios().post(`/${this.$route.params.userid}/${this.$route.params.url}/webpage_user_create_category`, this.current_category)
        .then(result=>{
          this.webpageNotice(result.data);
          if(result.data.result=="success"){
            this.$store.commit('set_webpage_user_categories', result.data.categories)
            if(this.current_category.type==2)this.current_subs = this.sub_categories(this.current_main.id)
            this.cate_name = ''
          } 
        })
      }
    },

    update_category(){
      this.current_category.cate_name = this.cate_name
      this.axios().put(`/webpage_user_update_category`, this.current_category )
      .then(result => {
        this.webpageNotice(result.data);
        if(result.data.result == 'success'){
          this.current_mode = ''
        }
      })

    },

    cate_name_duple_check(){
      if(this.isEmpty(this.cate_name)){
        this.webpageNotice({type:'error', msg:'카테고리명이 비어 있습니다.'});
        return false
      }
      const deep = this.current_mode == 'maincategory' ? 1 : 2
      const cnt = this.categories.filter(c=>Number(c.deep)==deep && c.cate_name == this.current_category.title)
      if(cnt.length > 0){
        this.webpageNotice({type:'error',msg:'같은 카테고리명이 있습니다.'});
        return false
      }else{
        return true
      }
      
    },

  }
  
}
</script>


<style scoped>
  i { margin-right: 0px }
</style>
