<template>


			
					<ul class="hide">

						<!-- SLIDE  -->
						<li data-transition="random" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off"  data-title="Slide">

							<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/assets/images/_smarty/1x1.png" data-lazyload="/images/slide_type/slide02_02.jpg" alt="" data-bgfit="cover" data-bgposition="center bottom" data-bgrepeat="no-repeat" />

							<div class="tp-dottedoverlay twoxtwo"><!-- dotted overlay --></div>
							<div class="overlay dark-3"><!-- dark overlay [1 to 9 opacity] --></div>

							<div class="tp-caption customin ltl tp-resizeme text_white"
								data-x="center"
								data-y="180"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1000"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 10;">
								<span class="fw-300">DEVELOPMENT / MARKETING / DESIGN / PHOTO</span>
							</div>

							<div class="tp-caption customin ltl tp-resizeme large_bold_white"
								data-x="center"
								data-y="230"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1200"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 10;">
								WELCOME TO SMARTY
							</div>

							<div class="tp-caption customin ltl tp-resizeme small_light_white"
								data-x="center"
								data-y="350"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1400"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 10; width: 750px; max-width: 750px; white-space: normal; text-align:center;">
								Fabulas definitiones ei pri per recteque hendrerit scriptorem in errem scribentur mel fastidii propriae philosophia cu mea.
							</div>

							<div class="tp-caption customin ltl tp-resizeme"
								data-x="center"
								data-y="438"
								data-customin="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1550"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 10;">
								<a href="#purchase" class="btn btn-default btn-lg">
									<span>Purchase Smarty Now</span> 
								</a>
							</div>

						</li>

						<!-- SLIDE  -->
						<li data-transition="random" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off"  data-title="Slide">

							<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/assets/images/_smarty/1x1.png" data-lazyload="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/1200x800/18-min.jpg" alt="" data-bgfit="cover" data-bgposition="center bottom" data-bgrepeat="no-repeat" />

							<div class="tp-caption mediumlarge_light_white lfb tp-resizeme" 
								data-x="left" data-hoffset="70"
								data-y="133" 
								data-speed="1000" 
								data-start="1400" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300">
								<span class="fw-300 font-lato">+ Amazing Layouts</span>
							</div>

							<div class="tp-caption mediumlarge_light_white lft tp-resizeme" 
								data-x="left" data-hoffset="70"
								data-y="85" 
								data-speed="1000" 
								data-start="1200" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300">
								<span class="fw-300 font-lato">+ Awesome  Sliders</span>
							</div>

							<div class="tp-caption block_black sfl tp-resizeme" 
								data-x="70" 
								data-y="216" 
								data-speed="750" 
								data-start="1900" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300">
								<span class="fw-300 font-lato">Premium Sliders That Are Easy To Use With Any Content</span>
							</div>

							<div class="tp-caption block_theme_color sfr tp-resizeme" 
								data-x="70" 
								data-y="260" 
								data-speed="750" 
								data-start="2200" 
								data-easing="easeOutExpo" 
								data-splitin="none" 
								data-splitout="none" 
								data-elementdelay="0.1" 
								data-endelementdelay="0.1" 
								data-endspeed="300">
								<span class="fw-300 font-lato">Loaded With Options, And Is Simply A Joy To Use</span>
							</div>

						</li>


						<!-- SLIDE -->
						<li data-transition="random" data-slotamount="1" data-masterspeed="1500" data-delay="10000"  data-saveperformance="off"  data-title="Slide">
							<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/assets/images/_smarty/1x1.png" data-lazyload="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/1200x800/28-min.jpg" alt="kenburns6" data-bgposition="left top" data-kenburns="on" data-duration="10000" data-ease="Linear.easeNone" data-bgfit="130" data-bgfitend="100" data-bgpositionend="right bottom">
							<!-- LAYERS -->

							<!-- LAYER NR. 2 -->
							<div class="tp-caption customin ltl tp-resizeme"
								data-x="200"
								data-y="150"
								data-customin="x:-200;y:0;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 0%;"
								data-speed="800"
								data-start="1500"
								data-easing="easeOutQuad"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 3; color: #212121; font-size:20px; font-weight:300; text-shadow:none;">
								DEVELOPMENT / MARKETING / DESIGN
							</div>

							<div class="tp-caption customin ltl tp-resizeme"
								data-x="200"
								data-y="180"
								data-customin="x:10;y:0;z:0;rotationY:120;rotationZ:0;scaleX:0.8;scaleY:0.8;skewX:0;skewY:0;opacity:0;transformPerspective:200;transformOrigin:50% 50%;"
								data-speed="600"
								data-start="1700"
								data-easing="easeOutCubic"
								data-splitin="chars"
								data-splitout="none"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								data-endspeed="1000"
								data-endeasing="Power4.easeIn" style="z-index: 3; color: #666; line-height: 1.2; max-width: 450px; width: 450px; white-space: normal; font-size:60px; font-weight:bold;">
								Responsive Multipurpose Template
							</div>

						</li>

						<!-- SLIDE  -->
						<li data-transition="random" data-slotamount="1" data-masterspeed="1000" data-saveperformance="off"  data-title="Slide">

							<img src="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/assets/images/_smarty/1x1.png" data-lazyload="https://s3.ap-northeast-2.amazonaws.com/easyhbimages/smartweb/demo_files/images/1200x800/19-min.jpg" alt="" data-bgfit="cover" data-bgposition="center bottom" data-bgrepeat="no-repeat" />

							<div class="tp-caption very_large_text lfb ltt tp-resizeme"
								data-x="right" data-hoffset="-100"
								data-y="center" data-voffset="-100"
								data-speed="600"
								data-start="800"
								data-easing="Power4.easeOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="500"
								data-endeasing="Power4.easeIn">
								RUN WILD
							</div>

							<div class="tp-caption medium_light_white lfb ltt tp-resizeme"
								data-x="right" data-hoffset="-100"
								data-y="center" data-voffset="10"
								data-speed="600"
								data-start="900"
								data-easing="Power4.easeOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.01"
								data-endelementdelay="0.1"
								data-endspeed="500"
								data-endeasing="Power4.easeIn">
								Happiness is an accident of nature,<br/>
								a beautiful and flawless aberration.<br/>
								<span style="font-size:24px;font-weight:400;">&ndash; Pat Conroy</span>
							</div>

						</li>

					</ul>
				

</template>


<script>
export default {
	mounted(){
		// console.log("slider================");
		var revapi;
    // Make Content Visible
    jQuery(".fullwidthbanner ul , .fullscreenbanner ul").removeClass('hide');


    /**
    	@HALFSCREEN SLIDER
    **/
    if (jQuery(".fullwidthbanner").length > 0) {

        // Default Thumbs [small]
        var thumbWidth = 100,
            thumbHeight = 50,
            hideThumbs = 200,
            navigationType = "bullet",
            navigationArrows = "solo",
            navigationVOffset = 10;

        // Shadow
        var _shadow = jQuery(".fullwidthbanner").attr('data-shadow') || 0;

        // Small Thumbnails
        if (jQuery(".fullwidthbanner").hasClass('thumb-small')) {
            var navigationType = "thumb";
        }

        // Large Thumbnails
        if (jQuery(".fullwidthbanner").hasClass('thumb-large')) {
            var navigationType = "thumb";
            thumbWidth = 195,
                thumbHeight = 95,
                hideThumbs = 0,
                navigationArrows = "solo",
                navigationVOffset = -94;

            // Hide thumbs on mobile - Avoid gaps
            /**
            if(jQuery(window).width() < 800) {
            	setTimeout(function() {
            		var navigationVOffset = 10;
            		jQuery("div.tp-thumbs").addClass('hidden');
            	}, 100);
            }
            **/
        }

        // Init Revolution Slider
        revapi = jQuery('.fullwidthbanner').revolution({
            dottedOverlay: "none",
            delay: 9000,
            startwidth: 1170,
            startheight: jQuery(".fullwidthbanner").attr('data-height') || 500,
            hideThumbs: hideThumbs,

            thumbWidth: thumbWidth,
            thumbHeight: thumbHeight,
            thumbAmount: parseInt(jQuery(".fullwidthbanner ul li").length) || 2,

            navigationType: navigationType,
            navigationArrows: navigationArrows,
            navigationStyle: jQuery('.fullwidthbanner').attr('data-navigationStyle') || "round", // round,square,navbar,round-old,square-old,navbar-old (see docu - choose between 50+ different item)

            touchenabled: "on",
            onHoverStop: "on",

            navigationHAlign: "center",
            navigationVAlign: "bottom",
            navigationHOffset: 0,
            navigationVOffset: navigationVOffset,

            soloArrowLeftHalign: "left",
            soloArrowLeftValign: "center",
            soloArrowLeftHOffset: 20,
            soloArrowLeftVOffset: 0,

            soloArrowRightHalign: "right",
            soloArrowRightValign: "center",
            soloArrowRightHOffset: 20,
            soloArrowRightVOffset: 0,

            parallax: "mouse",
            parallaxBgFreeze: "on",
            parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

            shadow: parseInt(_shadow),
            fullWidth: "on",
            fullScreen: "off",

            stopLoop: "off",
            stopAfterLoops: -1,
            stopAtSlide: -1,

            spinner: "spinner0",
            shuffle: "off",

            autoHeight: "off",
            forceFullWidth: "off",

            hideThumbsOnMobile: "off",
            hideBulletsOnMobile: "on",
            hideArrowsOnMobile: "on",
            hideThumbsUnderResolution: 0,

            hideSliderAtLimit: 0,
            hideCaptionAtLimit: 768,
            hideAllCaptionAtLilmit: 0,
            startWithSlide: 0,
            fullScreenOffsetContainer: ""
        });

        // Used by styleswitcher onle - delete this on production!
        jQuery("#is_wide, #is_boxed").bind("click", function() { revapi.revredraw(); });
    }


    /**
    	@FULLSCREEN SLIDER
    **/
    if (jQuery(".fullscreenbanner").length > 0) {
			// var tpj = jQuery;
        // tpj.noConflict();
        var revapi25;

        // Shadow
        _shadow = jQuery(".fullscreenbanner").attr('data-shadow') || 0;
       console.log("fullscreen------------------------------");

        $(document).ready(function() {

            if ($('.fullscreenbanner').revolution != undefined) {
                revapi25 = $('.fullscreenbanner').show().revolution({
                    dottedOverlay: "none",
                    delay: 9000,
                    startwidth: 1200,
                    startheight: 700,
                    hideThumbs: 10,

                    thumbWidth: 100,
                    thumbHeight: 50,
                    thumbAmount: 4,

                    navigationType: "none",
                    navigationArrows: "solo",
                    navigationStyle: jQuery('.fullscreenbanner').attr('data-navigationStyle') || "round",

                    touchenabled: "on",
                    onHoverStop: "on",

                    swipe_velocity: 0.7,
                    swipe_min_touches: 1,
                    swipe_max_touches: 1,
                    drag_block_vertical: false,

                    keyboardNavigation: "on",

                    navigationHAlign: "center",
                    navigationVAlign: "bottom",
                    navigationHOffset: 0,
                    navigationVOffset: 30,

                    soloArrowLeftHalign: "left",
                    soloArrowLeftValign: "center",
                    soloArrowLeftHOffset: 20,
                    soloArrowLeftVOffset: 0,

                    soloArrowRightHalign: "right",
                    soloArrowRightValign: "center",
                    soloArrowRightHOffset: 20,
                    soloArrowRightVOffset: 0,

                    parallax: "mouse",
                    parallaxBgFreeze: "on",
                    parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

                    shadow: parseInt(_shadow),
                    fullWidth: "off",
                    fullScreen: "on",

                    stopLoop: "off",
                    stopAfterLoops: -1,
                    stopAtSlide: -1,

                    shuffle: "off",

                    forceFullWidth: "off",
                    fullScreenAlignForce: "off",

                    hideThumbsOnMobile: "off",
                    hideBulletsOnMobile: "on",
                    hideArrowsOnMobile: "off",
                    hideThumbsUnderResolution: 0,

                    hideSliderAtLimit: 0,
                    hideCaptionAtLimit: 768,
                    hideAllCaptionAtLilmit: 0,
                    startWithSlide: 0,
                    fullScreenOffsetContainer: jQuery("#header").hasClass('transparent') || jQuery("#header").hasClass('translucent') ? null : "#header"
                });

            }
        }); //ready

    }

    /**
    	@KEN BURNS
    **/
    if (jQuery(".fullscreenbanner.ken-burns").length > 0) {

        revapi = jQuery('.fullwidthbanner').revolution({
            dottedOverlay: "none",
            delay: 9000,
            startwidth: 1170,
            startheight: 400,
            hideThumbs: 200,

            thumbWidth: 100,
            thumbHeight: 50,
            thumbAmount: 5,

            navigationType: "bullet",
            navigationArrows: "solo",
            navigationStyle: jQuery('.fullwidthbanner').attr('data-navigationStyle') || "round",

            touchenabled: "on",
            onHoverStop: "off",

            navigationHAlign: "center",
            navigationVAlign: "bottom",
            navigationHOffset: 0,
            navigationVOffset: 10,

            soloArrowLeftHalign: "left",
            soloArrowLeftValign: "center",
            soloArrowLeftHOffset: 20,
            soloArrowLeftVOffset: 0,

            soloArrowRightHalign: "right",
            soloArrowRightValign: "center",
            soloArrowRightHOffset: 20,
            soloArrowRightVOffset: 0,

            shadow: 0,
            fullWidth: "on",
            fullScreen: "off",

            stopLoop: "off",
            stopAfterLoops: -1,
            stopAtSlide: -1,


            shuffle: "off",

            autoHeight: "off",
            forceFullWidth: "off",

            hideThumbsOnMobile: "off",
            hideBulletsOnMobile: "off",
            hideArrowsOnMobile: "off",
            hideThumbsUnderResolution: 0,

            hideSliderAtLimit: 0,
            hideCaptionAtLimit: 0,
            hideAllCaptionAtLilmit: 0,
            startWithSlide: 0,
            fullScreenOffsetContainer: ""
        });

        // Used by styleswitcher only - delete this on production!
        jQuery("#is_wide, #is_boxed").bind("click", function() { revapi.revredraw(); });

    }

	}
  
}
</script>

<style>
 .slider {
	 margin-bottom: 0px;
 }

 .tp-bullets {
	 margin-bottom:30px;
 }
</style>

