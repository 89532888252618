import Mainpage from '../views/webpage/smarty_pages/mainpage.vue'
import Loginpage from '../views/webpage/smarty_pages/loginpage.vue'
import WebpageSignuppage from '../views/webpage/smarty_pages/webpage_signuppage.vue'
import WebpageUser from '../views/webpage/smarty_pages/webpage_user.vue'
import WebpageSecurityLogin from '../views/webpage/smarty_pages/webpage_security_login.vue'


export default {
    mode: 'history',
    routes: [
        {
            path: '/:userid/:url/webpage_users_sign_up',
            name: 'webpage_signuppage',
            component: WebpageSignuppage
        },
        {
            path: '/:userid/:url/find_password',
            name: 'webpage_find_password',
            component: WebpageSignuppage
        },
        {
            path: '/:userid/:url/reset_password',
            name: 'webpage_reset_password',
            component: WebpageSignuppage
        },
        {
            path: '/:userid/:url/security_pages_login',
            name: 'security_pages_login',
            component: WebpageSecurityLogin
        },
        {
            path: '/:userid/:url/users/:webpage_id',
            name: 'webpage_users',
            component: WebpageUser,
        },
        {
            path: '/:userid/:url/search_page',
            name: 'search_page',
            component: Mainpage
        },
        {
            path: '/:userid/:url/:subpage',
            name: 'subpage',
            component: Mainpage
        },
        {
            path: '/:userid/:url/:subpage/:board_id',
            name: 'board_view',
            component: Mainpage
        },
        {
            path: '/:userid',
            name: 'mainpage',
            component: Mainpage
        },
        {
            path: '/login',
            name: 'Loginpage',
            component: Loginpage
        },
        {
            path: '/:userid/:url',
            name: 'mainpage',
            component: Mainpage
        },
    ]
};




