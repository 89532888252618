<template>
  <div>
    <span class="btn btn-sm btn-primary" @click="configMenuForm()" >메뉴환경설정</span>  
    <span class="btn btn-sm btn-danger"  @click="addMenuForm('mainmenu')" >메인메뉴추가</span>  
    <span class="btn btn-sm btn-warning" @click="addMenuForm('submenu')" v-if='object_empty_check(current_main)==false' >서브메뉴추가</span>  
    
    <div class="card card-default" v-if="current_mode=='menuconfig'" style="margin-top:20px;">
      <div class="card-block">
        <div class="row">
          <div class="col-md-2 col-6" >
            <color-picker  v-bind:font_color.sync='menu_config.main_font_color' v-bind:label="'메인메뉴글자색'" v-bind:option="{label: true, input: false, location: 'center'}" ></color-picker>
          </div>
          <div class="col-md-2 col-6" >
            <color-picker  v-bind:font_color.sync='menu_config.main_menu_color' v-bind:label="'메인메뉴바탕색'" v-bind:option="{label: true, input: false, location: 'center'}" ></color-picker>
          </div>
          <div class="col-md-2 col-6" >
            <color-picker  v-bind:font_color.sync='menu_config.sub_font_color' v-bind:label="'서브메뉴글자색'" v-bind:option="{label: true, input: false, location: 'center'}" ></color-picker>
          </div>
          <div class="col-md-2 col-6" >
            <color-picker  v-bind:font_color.sync='menu_config.sub_menu_color' v-bind:label="'서브메뉴바탕색'" v-bind:option="{label: true, input: false, location: 'center'}" ></color-picker>
          </div>
          <div class="col-md-2 col-6" >
            <color-picker  v-bind:font_color.sync='menu_config.sub_border_color' v-bind:label="'서브테두리색'" v-bind:option="{label: true, input: false, location: 'center'}" ></color-picker>
          </div>
          <div class="col-md-2 col-6" >
            <label>투명배경</label>
            <label class="switch switch-primary switch-round" >
              <input type="checkbox"  @click="transparent_click()" v-model='menu_config.transparent' >
              <span class="switch-label" data-on="ON" data-off="OFF"></span>
            </label>
          </div>
        </div> 
        <div class="divider" style="margin: 0px 0"></div>
        <div class="text-right">
          <span class="btn btn-sm btn-primary"  @click="current_mode=''" >취소</span>
          <span class="btn btn-sm btn-danger"   @click="update_config()" >수정</span>  
        </div>   
      </div>  
    </div>

    <div class="card card-default" v-if="current_mode=='mainmenu' || current_mode=='submenu' " style="margin-top:20px">
      <div class="card-block">
        <div class="row">
          <div class="col-sm-6" >
            <label > {{ current_mode=='mainmenu' ? '메인메뉴명' : '서브메뉴명' }} </label>
            <input type="text" class="form-control"  :placeholder="current_mode=='mainmenu' ? 'Main Menu' : 'Sub Menu'" v-model="menu.title" style="height: 35px !important" >
          </div>
          <div class="col-sm-6">
            <label>연결 섹션 또는 서브페이지선택</label>
            <el-select v-model="menu.link" size="small" @change="user_link" placeholder="메뉴링크선택">
              <el-option
                v-for="(key, value) in menus"
                :key="key"
                :label="key"
                :value="value">
              </el-option>
            </el-select>
          </div>
          <div class="col-12" style="margin-top:20px" v-if="user_url">
            <label >사용자메뉴 링크</label>
            <input class="form-control" v-model="userLink" style="height: 35px" >
          </div>
        </div>
        <div></div>
        <div class="divider" style="margin: 0px 0"></div>
        <div class="text-right">
          <div v-if="create_mode" >
            <span class="btn btn-sm btn-primary"  @click="current_mode=''" >취소</span>
            <span class="btn btn-sm btn-danger"   @click="create_menu('main')" >추가</span>
          </div>
          <div v-else>
            <span class="btn btn-sm btn-primary"  @click="current_mode=''" >취소</span>
            <span class="btn btn-sm btn-danger"   @click="update_menu()" >수정</span>
          </div>
        </div>
      </div>
    </div> 

    <div class="card card-default"  style="margin-top:20px">
      <div class="card-block">
        <div class="row">
          <div class="col-sm-6" >
            <table class="table" >
              <thead>
                <th>메인메뉴명</th>
                <th style="max-width:60px">옵션</th>
              </thead>
              <tbody >
                <tr v-for="(id, index) in all_menus.main_order" :key="id" :style="line_marked(current_main_id, id )" >
                  <td @click="submenu_list(all_menus.menus[id],id)" style="cursor: pointer" > {{ all_menus.menus[id].main.title }} </td>
                  <td style="padding-left:5px;padding-right:5px" >
                    <ul class="list-inline" style="margin-bottom:0px" >
                      <li class="badge badge-info" :style="'padding: 5px 5px;cursor:pointer;'+order_margin(index, all_menus.main_order.length-1)" v-if="index!=0" @click="move_item(index, index-1, all_menus.main_order )" >
                        <i class="fa fa-sort-up" ></i>
                      </li>
                      <li class="badge badge-info" :style="'padding: 5px 5px;cursor:pointer;'+order_margin(index, all_menus.main_order.length-1)" v-if="index<all_menus.main_order.length-1" @click="move_item(index+1, index, all_menus.main_order)" >
                        <i class="fa fa-sort-down"></i>
                      </li>
                      <li class="badge badge-warning" style="padding: 5px 5px;cursor:pointer"  @click="editMenuForm('mainmenu', all_menus.menus[id].main)"  > <i class="fa fa-edit"></i></li>
                      <li class="badge badge-danger" style="padding: 5px 5px;cursor:pointer"  @click="deleteMenuForm('mainmenu', all_menus.menus[id].main)"> <i class="fa fa-trash"></i></li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-sm-6">
            <table class="table" >
              <thead>
                <th>서브메뉴명</th>
                <th>옵션</th>
              </thead>
              <tbody  >
                <tr v-for="(id, index) in current_main.sub_order" :key="id" :style="line_marked(current_sub_id, id )" >
                  <td style="cursor: pointer" @click="submenu_click(id)" > {{ current_main.submenu[id].title }} </td>
                  <td>
                    <ul class="list-inline" style="margin-bottom:0px" >
                      <li class="badge badge-info" :style="'padding: 5px 5px;cursor:pointer;'+order_margin(index, current_main.sub_order.length-1)" v-if="index!=0" @click="move_item(index, index-1, current_main.sub_order )" ><i class="fa fa-sort-up" ></i></li>
                      <li class="badge badge-info" :style="'padding: 5px 5px;cursor:pointer;'+order_margin(index, current_main.sub_order.length-1)" v-if="index<current_main.sub_order.length-1" @click="move_item(index+1, index, current_main.sub_order)" >
                        <i class="fa fa-sort-down"></i>
                      </li>
                      <li class="badge badge-warning" style="padding: 5px 5px;cursor:pointer"  @click="editMenuForm('submenu',   current_main.submenu[id])" > <i class="fa fa-edit"></i></li>
                      <li class="badge badge-danger"  style="padding: 5px 5px;cursor:pointer"  @click="deleteMenuForm('submenu', current_main.submenu[id])" > <i class="fa fa-trash"></i></li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ColorPicker from '../../../../common_components/color_picker'

export default {

  data(){
    return {
      current_mode: '',
      create_mode: true,
      menu: {},
      menu_config: {},
      value: '',
      user_url: false,
      userLink: '',
      current_main: {},
      current_main_id: 0,
      current_sub_id: 0,
      current_sub: {},
    }
  },

  created(){
    this.menu_config = this.menuConfig
  },

  computed:{
    menus(){
      const format = this.$store.state.section_format
      const order = this.$store.state.section_order;
      const sections = this.$store.state.sections;
      const subpage = this.$store.state.subpages;
      let frt = {}
      for(let i=0;i<format.length;i++){
        frt[format[i].component] = format[i].name 
      }
      let menu = {  }
      menu['home_path'] = 'MainPage'
      let cur_section = []  
      for(let i=0;i<order.length;i++){
        cur_section = sections.filter(s=>s.id==order[i])[0]
        if(cur_section!=undefined)menu[`#${this.section_menu_id(cur_section)}`] = `${i+1}번[${frt[cur_section.component]}]섹션`
      }
      for(let key in subpage){
        menu[`/${subpage[key].subpage_name}`] = `서브페이지-${subpage[key].subpage_name}`
      }
      menu['user_link'] = "사용자메뉴 링크 등록"
      return menu
    },

    form_view(){
      const mode = ['mainmenu','submenu', 'edit_mainmenu']
      if(mode.includes(this.current_mode)) return true
      return false
    },

    all_menus: {
      get(){
        return this.$store.state.menu
      },
      set(val){
        this.$store.state.menu = val
      }
    },

    menuConfig(){
      const config = this.all_menus.config
      if(config == undefined) config = { transparent: false, main_font_color: '#1F262D',main_menu_color: '#fff', sub_font_color: '#fff',sub_menu_color: '#333' }      
      if(config.main_font_color==undefined)config.main_font_color='#1F262D' 
      if(config.main_menu_color==undefined)config.main_menu_color='#fff' 
      if(config.sub_font_color==undefined)config.sub_font_color='#fff' 
      if(config.sub_menu_color==undefined)config.sub_menu_color='#333' 
      if(config.sub_border_color==undefined)config.sub_border_color='#333' 
      return this.all_menus.config
    },

  },

  methods:{

    transparent_click(){
      return !this.menu_config.transparent
    },

    configMenuForm(){
      this.current_mode = 'menuconfig'
      this.create_mode = false
    },

    order_margin(index, last){
      if(index==0){
        return "margin-left: 20px;"
      }else if(index == last){
        return "margin-right: 20px;"
      }
      return ''
    },

    line_marked(data_id, id){
      if(data_id == id){
        return 'background: #c9eaff'
      }
      return ''
    },

    addMenuForm(mode){
      this.current_mode = mode
      this.create_mode = true
      this.menu = { title: '', link: '', hidden: 0, userlink: false } 
      // if(mode == 'mainmenu'){} this.current_main = {}
    },

    editMenuForm(mode, menu){
      this.current_mode = mode
      this.create_mode = false
      this.menu = menu
      if(mode == 'mainmenu'){
        this.current_main = menu
        this.current_main_id = menu.id
        if(this.current_main.userlink==true){
          this.userLink = this.menu.link
          this.menu.link = 'user_link'
          this.user_url = true
        }
      }else{
        this.current_sub_id = menu.id
        // console.log(menu);
        if(this.menu.userlink==true){
          this.userLink = this.menu.link
          this.menu.link = 'user_link'
          this.user_url = true
        }
      }
    },

    deleteMenuForm(mode, menu){
      if(mode == 'mainmenu'){
        if(confirm('서브메뉴까지 다 삭제됩니다. 그래도 삭제하시겠습니까?')){
          delete this.all_menus.menus[menu.id]
          const idx = this.all_menus.main_order.indexOf(menu.id)
          this.all_menus.main_order.splice(idx, 1)
          this.send_server_update()
        }
      }else{
        if(confirm('현재 서브메뉴를 정말 삭제하시겠습니까?')){
          let data = this.all_menus.menus[this.current_main_id]
          delete data.submenu[menu.id]
          const idx = data.sub_order.indexOf(menu.id)
          data.sub_order.splice(idx, 1)
          this.send_server_update()
        }
      }
    },

    user_link(val){
      if(val=='user_link'){
        this.user_url = true
      }else{
        this.user_url = false
      }
    },

    create_menu(){
      if(this.current_mode == 'mainmenu'){
        var menu = this.menu
        var order = this.all_menus.main_order
        var menus = this.all_menus.menus
      }else{
        var menu = this.menu
        var order = this.current_main.sub_order 
        var menus = this.current_main.submenu 
      }
      if(this.menu.link=='user_link'){
        this.menu.link = this.addhttp(this.userLink)
        this.menu.userlink = true
      }else{
        this.menu.userlink = false
      }
      if(order.length == 0){
        var num = 1
      }else{
        var num = Math.max(...order) + 1
      }

      order.push(num)
      menu.id = num
      if(menus[num]==undefined) menus[num] = {}
      if(this.current_mode == 'mainmenu'){
        if(menus[num].sub_order==undefined) menus[num].sub_order = []
        if(menus[num].submenu==undefined) menus[num].submenu = {}
        menus[num].main = menu
      }else{
        menus[num] = menu
      }  
      this.send_server_update()
      
    },

    update_menu(){
      if(this.current_mode == 'mainmenu'){
        var menu = this.menu
        var menus = this.all_menus.menus
      }else{
        var menu = this.menu
        var menus = this.current_main.submenu 
      }
      if(this.menu.link=='user_link'){
        this.menu.link = this.addhttp(this.userLink)
        this.menu.userlink = true
      }else{
        this.menu.userlink = false
      }
      if(this.current_mode == 'mainmenu'){
        menus[this.current_main_id].main = menu
        if(menu.user_link==true)menus[this.current_main_id].main.link = this.userLink
      }else{
        menus[this.current_sub_id] = menu
        if(menu.user_link==true)menus[this.current_sub_id].link = this.userLink
      }  
      this.send_server_update()

    },

    update_config(){
      this.send_server_update()
      .then(result=>{
        if(result.data.result=='success'){
          this.$store.commit('set_menu_config', this.menu_config)
          if(confirm('새로고침 되어야 정상적용됩니다. 새로고침할까요?'))location.reload();
        }
      })
    },

    submenu_list(main, id){
      this.current_main = main
      this.current_main_id = id
      this.current_mode = ''
      this.current_sub_id = 0

    },

    submenu_click(id){
      this.current_sub_id = id
    },

    move_item(from, to, order){
      order.splice(to, 0, order.splice(from, 1)[0]);
      this.send_server_update()
    },

    send_server_update(){
      return this.axios().put(`/webpages/menu`, this.all_menus )
      .then(result => {
        this.webpageNotice(result.data);
        if(result.data.result == 'success'){
          this.$store.commit('set_menu', this.all_menus)
          this.current_mode = ''
        }
        return result
      })
    }


  },
  components: {
    ColorPicker
  }
  
}
</script>


<style scoped>
  i { margin-right: 0px }
</style>
